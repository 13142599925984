import React from "react";
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames";

import SweetAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Card, 
  CardHeader, 
  CardBody, 
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col
} from "reactstrap";

import { UserProfile } from "sessions/userState.js"
import { SessionConfigs } from "sessions/sessionState.js"
import { extractWorldInfo } from "sessions/gameInfo.js"
import { useHuntTrackerContext } from "tracker-context";

import { 
  Box,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import LanguageDependentText from "components/TimerTable/LanguageDependentText";

const Login = () => {
  const history = useHistory();
  const [controller, dispatch] = useHuntTrackerContext();
  const { darkMode, language, user } = controller;

  if (user.profile_src) {
    history.push("/timer");
  }

  const textLibrary = SessionConfigs.translateText;
  const [authenticationInfo, setAuthenticationInfo] = React.useState({username: "", password: ""});

  const [alert, setAlert] = React.useState(null);

  const [state, setState] = React.useState({});
  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });

  const handleUsernameChange = (event) => {
    setAuthenticationInfo({...authenticationInfo, username: event.target.value});
  }

  const handlePasswordChange = (event) => {
    setAuthenticationInfo({...authenticationInfo, password: event.target.value});
  }

  const handleAuthentication = () => {
    SessionConfigs.authenticate(authenticationInfo).then((response) => {
      UserProfile.setUser(response.data.user);
      dispatch({name: "User", value: UserProfile.getUser()});
      history.push("/timer");
      
    }).catch((error) => {
      if (error.response.status == 403) {
        setAlert(
          <SweetAlert
              error style={{display: "block",marginTop: "-100px"}}
              title="Error Received" onConfirm={() => setAlert(null)}
              onCancel={() => setAlert(null)} confirmBtnBsStyle="info" >
          {"Error"}
        </SweetAlert>);
      }
    })
  }

  return (
    <>
      <div className="content">
        {alert}
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form">
              <Card className="card-white">
                <CardHeader>
                  <div className="bg-gradient-info shadow-info border-radius-lg py-3 pe-1" style={{transform: "translate(0%, -50%)"}}>
                    <LanguageDependentText variant="h4" header={"accountSignIn"} align="center" className="text-white font-weight-bolder text-center mt-2 mb-0"/>
                  </div>
                </CardHeader>
                <CardBody>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": state.emailFocus
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={textLibrary("header","accountUsername")}
                      type="text"
                      value={authenticationInfo.username}
                      onFocus={(e) => setState({ ...state, emailFocus: true })}
                      onBlur={(e) => setState({ ...state, emailFocus: false })}
                      onChange={handleUsernameChange}
                    />
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": state.passFocus
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={textLibrary("header","accountPassword")}
                      type="password"
                      value={authenticationInfo.password}
                      onFocus={(e) => setState({ ...state, passFocus: true })}
                      onBlur={(e) => setState({ ...state, passFocus: false })}
                      onChange={handlePasswordChange}
                    />
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="mb-3"
                    color="primary"
                    onClick={handleAuthentication}
                    size="lg"
                  >
                    {textLibrary("header","profileSignin")}
                  </Button>
                  <div className="pull-left">
                    <h6>
                      <Link to="/register"
                        className="link footer-link"
                      >
                        {textLibrary("header","profileSignup")}
                      </Link>
                    </h6>
                  </div>
                  <div className="pull-right">
                    <h6>
                      <Link
                        to="/resetpassword"
                        className="link footer-link"
                      >
                        {textLibrary("header","accountResetPassword")}
                      </Link>
                    </h6>
                  </div>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default Login;
