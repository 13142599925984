import React from "react";
import { useHistory } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";

// reactstrap components
import {
  Button,
  Card, 
  CardHeader, 
  CardBody, 
  CardFooter,
  Collapse,
  Container,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  UncontrolledDropdown,
  UncontrolledTooltip
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";
import { toast, ToastContainer } from 'react-toastify';

import flatpickr from "flatpickr"
import Flatpickr from "react-flatpickr";
import { english } from "flatpickr/dist/l10n/default.js"
import { Mandarin } from "flatpickr/dist/l10n/zh.js"
import { German } from "flatpickr/dist/l10n/de.js"
import { French } from "flatpickr/dist/l10n/fr.js"

// reactstrap components
import {
  Avatar,
  Box,
  Divider,
  IconButton,
} from "@mui/material"

import { extractWorldInfo } from "sessions/gameInfo.js"
import { useHuntTrackerContext, setContextState } from "tracker-context";
import { SessionConfigs } from "sessions/sessionState"
import { UserProfile } from "sessions/userState";

import LanguageDependentText from "components/TimerTable/LanguageDependentText";

import RegisterModal from "./RegisterModal";
import LoginModal from "./LoginModal";

const DashboardNavbar = (props) => {
  const history = useHistory();
  const [controller, dispatch] = useHuntTrackerContext();
  const { darkMode, language, user, audioPermission } = controller;

  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [modalSearch, setModalSearch] = React.useState(false);
  const [state, setState] = React.useState({});

  const [alert, setAlert] = React.useState(null);
  const [loginModal, setLoginModal] = React.useState({show: false, username: "", password: ""});
  const [registerModal, setRegisterModal] = React.useState({show: false, username: "", password: "", ingameName: "", ingameServer: ""});

  const [color, setColor] = React.useState("navbar-transparent");
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("bg-white");
    }
    setCollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setModalSearch(!modalSearch);
  };

  const handleDarkModeUpdate = () => {
    setContextState(dispatch, "darkMode", !darkMode);
  };

  const handleLanguageChange = (lang) => {
    setContextState(dispatch, "language", lang);
  };

  const handleAudioPermission = () => {
    setContextState(dispatch, "audioPermission", audioPermission*-1);
  }

  const handleDataCenterChange = (datacenter) => {
    setContextState(dispatch, "datacenters", [datacenter]);
  }

  const handleLogOut = () => {
    SessionConfigs.query("/api/logout").then((response) => {
      setContextState(dispatch, "user", response.data.User);
      SessionConfigs.setCSRFToken(response.data.Token);
      UserProfile.nullifyUser();
    }).catch((error) => {
      
    });
  }

  const handleLoginModal = () => {
    setLoginModal({...loginModal, show: true});
  }

  const confirmVerification = async () => {
    SessionConfigs.query("/api/getVerification", {Update: true}).then((response) => {
      window.location.reload();
    }).catch((error) => {
      setAlert(<SweetAlert
        danger style={{display: "block",marginTop: "-100px"}}
        title="Lodestone Verification" onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)} confirmBtnBsStyle="info" >
        {"Verification Failed"}
      </SweetAlert>)
    });
  }

  const requestVerificationCode = () => {
    SessionConfigs.query("/api/getVerification").then((response) => {
      setAlert(
        <SweetAlert
            info style={{display: "block",marginTop: "-100px"}}
            title="Lodestone Verification" onConfirm={() => confirmVerification()}
            onCancel={() => setAlert(null)} confirmBtnBsStyle="info" >
        <LanguageDependentText> {"Please enter the following verification code to your LodeStone Profile."} </LanguageDependentText>
        <br></br>
        <LanguageDependentText fontWeight={"700"}> {response.data} </LanguageDependentText>
      </SweetAlert>);
    }).catch((error) => SessionConfigs.handleErrorDisplay(error, setAlert));
  }

  React.useEffect(() => {
    
  }, []);

  const handleAdminPage = () => {
    history.push("/admin");
  }

  const handleProfilePage = () => {
    history.push("/profile");
  }
  
  React.useEffect(() => {
    if (language == "de") {
      flatpickr.localize(German);
    } else if (language == "fr") {
      flatpickr.localize(French);
    } else if (language == "cn") {
      flatpickr.localize(Mandarin);
    }  else {
      flatpickr.localize(english);
    }
  }, [language]);

  const textLibrary = SessionConfigs.translateText;

  return (
    <>
      {alert}
      <Navbar
        className={classNames("navbar-absolute", {
          [color]: props.location.pathname.indexOf("full-screen-map") === -1
        })}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize d-inline">
              <Button
                className="minimize-sidebar btn-just-icon"
                color="link"
                id="tooltip209599"
                onClick={props.handleMiniClick}
              >
                <i className="fa-solid fa-list visible-on-sidebar-regular" />
                <i className="fa-solid fa-ellipsis-vertical visible-on-sidebar-mini" />
              </Button>
            </div>
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={props.toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              {props.brandText}
            </NavbarBrand>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <NavItem style={{paddingTop: 1}}>
                <NavLink onClick={() => handleAudioPermission()} className="nav-link" style={{ cursor: "pointer", paddingTop: 12, paddingBottom: 15 }}>
                  <i className={audioPermission>0 ? "fa-solid fa-bell" : "fa-solid fa-bell-slash"} />
                  {collapseOpen ? textLibrary("header", "navbarAudioPermission") : null}
                </NavLink>
              </NavItem>
              <NavItem style={{paddingTop: 1}}>
                <NavLink onClick={() => handleDarkModeUpdate()} className="nav-link" style={{ cursor: "pointer", paddingTop: 12, paddingBottom: 15 }}>
                  <i className="fa-solid fa-lightbulb" />
                  {collapseOpen ? textLibrary("header", "navbarDarkModeSelect") : null}
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  style={{paddingTop: 12, paddingBottom: 15}}
                  nav
                >
                
                  <i className="fa-solid fa-language" />
                  {collapseOpen ? textLibrary("header", "navbarLanguageSelect") : null}
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => handleLanguageChange("en")}>
                      English
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => handleLanguageChange("de")}>
                      Deutsch
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => handleLanguageChange("fr")}>
                      Français
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => handleLanguageChange("jp")}>
                      日本語
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => handleLanguageChange("cn")}>
                      中文
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown key="dcChange" nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  style={{paddingTop: 12, paddingBottom: 15}}
                  nav
                >
                
                  <i className="fa-solid fa-globe" />
                  {collapseOpen ? textLibrary("header", "navbarDataCenterSelect") : null}
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <Divider>{textLibrary("header", "northAmericaServers")}</Divider>
                  {["Aether","Crystal","Dynamis","Primal"].map((dc) => (
                    <NavLink key={dc} tag="li">
                      <DropdownItem className="nav-item" onClick={() => handleDataCenterChange(dc)}>
                        {dc}
                      </DropdownItem>
                    </NavLink>
                  ))}
                  <Divider>{textLibrary("header", "europeOceaniaServers")}</Divider>
                  {["Chaos","Light","Shadow"].map((dc) => (
                    <NavLink key={dc} tag="li">
                      <DropdownItem className="nav-item" onClick={() => handleDataCenterChange(dc)}>
                        {dc}
                      </DropdownItem>
                    </NavLink>
                  ))}
                  {["Materia"].map((dc) => (
                    <NavLink key={dc} tag="li">
                      <DropdownItem className="nav-item" onClick={() => handleDataCenterChange(dc)}>
                        {dc}
                      </DropdownItem>
                    </NavLink>
                  ))}
                  <Divider>{textLibrary("header", "japanServers")}</Divider>
                  {["Elemental","Gaia","Mana","Meteor"].map((dc) => (
                    <NavLink key={dc} tag="li">
                      <DropdownItem className="nav-item" onClick={() => handleDataCenterChange(dc)}>
                        {dc}
                      </DropdownItem>
                    </NavLink>
                  ))}
                  <Divider>{textLibrary("header", "chinaServers")}</Divider>
                  {["陆行鸟","猫小胖","莫古力","豆豆柴"].map((dc) => (
                    <NavLink key={dc} tag="li">
                      <DropdownItem className="nav-item" onClick={() => handleDataCenterChange(dc)}>
                        {dc}
                      </DropdownItem>
                    </NavLink>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>

              {user.Permissions ? 
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar src={user.ProfilePic} style={{width: 30, height: 30}}> </Avatar>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">{textLibrary("header","profile")}</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  {user.Permissions.NotVerified ? <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => requestVerificationCode()}>{textLibrary("header","profileVerification")}</DropdownItem>
                  </NavLink> : null}
                  {user.Permissions.AdminTable ? 
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => handleAdminPage()}>{textLibrary("header","adminDashboard")}</DropdownItem>
                  </NavLink> : null}
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => handleProfilePage()}>{textLibrary("header","profileSetting")}</DropdownItem>
                  </NavLink>
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => handleLogOut()}>{textLibrary("header","profileSignout")}</DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown> 
              : 
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar src={require("assets/images/anonymous-avatar.jpg")} style={{width: 30, height: 30}}> </Avatar>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">{textLibrary("header","profile")}</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => handleLoginModal()}>{textLibrary("header","profileSignin")}</DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => setRegisterModal({...registerModal, show: true})}>{textLibrary("header","profileSignup")}</DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              }
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
        
        <LoginModal loginModal={loginModal} setLoginModal={setLoginModal} alert={alert} setAlert={setAlert}/>
        <RegisterModal datacenter={SessionConfigs.getDataCenter()} registerModal={registerModal} setRegisterModal={setRegisterModal} alert={alert} setAlert={setAlert}/>
      </Navbar>
    </>
  );
};

export default DashboardNavbar;
