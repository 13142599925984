import { useEffect, useState } from "react";

import {
    Box,
    Typography,
    TableCell,
} from "@mui/material";

import { useHuntTrackerContext } from "tracker-context";
import { SessionConfigs } from "sessions/sessionState";

const TimerHeader = ({defaultHeaders}) => {
  const [controller, dispatch] = useHuntTrackerContext();
  const textLibrary = SessionConfigs.translateText;

  const [headerState, setHeaderState] = useState(defaultHeaders);
  useEffect(() => {
    setHeaderState(headerState);
    return ;
  },[])
  
  const handleSortingOrder = (callback, name, suffix) => {
    if (name == "tableTimer") {
      if (suffix === " (%)") {
        var newHeaderState = [...defaultHeaders]
        for (var i = 0; i < newHeaderState.length; i++) {
          if (newHeaderState[i]["name"] == name) {
            newHeaderState[i]["suffix"] = " (s)";
          } else {
            newHeaderState[i]["suffix"] = "";
          }
        }
        
        setHeaderState(newHeaderState);
        callback(name, -1);
      } else if (suffix === " (s)") {
        var newHeaderState = [...defaultHeaders]
        for (var i = 0; i < newHeaderState.length; i++) {
          if (newHeaderState[i]["name"] == name) {
            newHeaderState[i]["suffix"] = "";
          } else {
            newHeaderState[i]["suffix"] = "";
          }
        }
        
        setHeaderState(newHeaderState);
        callback(name, 0);
      } else {
        var newHeaderState = [...defaultHeaders]
        for (var i = 0; i < newHeaderState.length; i++) {
          if (newHeaderState[i]["name"] == name) {
            newHeaderState[i]["suffix"] = " (%)";
          } else {
            newHeaderState[i]["suffix"] = "";
          }
        }

        setHeaderState(newHeaderState);
        callback(name, 1);
      }
    } else {
      if (suffix === " ↑") {
        var newHeaderState = [...defaultHeaders]
        for (var i = 0; i < newHeaderState.length; i++) {
          if (newHeaderState[i]["name"] == name) {
            newHeaderState[i]["suffix"] = " ↓";
          } else {
            newHeaderState[i]["suffix"] = "";
          }
        }
        setHeaderState(newHeaderState);
        callback(name, -1);
      } else if (suffix === " ↓") {
        var newHeaderState = [...defaultHeaders]
        for (var i = 0; i < newHeaderState.length; i++) {
          if (newHeaderState[i]["name"] == name) {
            newHeaderState[i]["suffix"] = "";
          } else {
            newHeaderState[i]["suffix"] = "";
          }
        }
        setHeaderState(newHeaderState);
        callback(name, 0);
      } else {
        var newHeaderState = [...defaultHeaders]
        for (var i = 0; i < newHeaderState.length; i++) {
          if (newHeaderState[i]["name"] == name) {
            newHeaderState[i]["suffix"] = " ↑";
          } else {
            newHeaderState[i]["suffix"] = "";
          }
        }
        setHeaderState(newHeaderState);
        callback(name, 1);
      }
    }
  }

  return (
    <Box component="thead">
      <tr>
        {headerState.map((header) => (
          <TableCell key={header.name} align={header.align} style={{width: header.width, minWidth: header.minWidth, maxWidth: header.maxWidth, paddingBottom: "0px"}} >
            <Typography component="p" variant="button" sx={{ fontSize: 13, cursor: "pointer" }} fontWeight="medium" onClick={()=> handleSortingOrder(header.callback, header.name, header.suffix)}>
              {textLibrary("header", header.name) + header.suffix}
            </Typography>
          </TableCell>
        ))}
      </tr>
    </Box>
  );
};

export default TimerHeader;