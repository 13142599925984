/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import { Avatar, Box } from "@mui/material";

import { useHuntTrackerContext } from "tracker-context";
import { SessionConfigs } from "sessions/sessionState";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

const ImageUpload = ({
  avatar,
  defaultSrc,
  addBtnColor,
  addBtnClasses,
  changeBtnColor,
  changeBtnClasses,
  saveBtnColor,
  saveBtnClasses,
  handleSubmit
}) => {
  const [context, dispatch] = useHuntTrackerContext();
  const { user } = context;

  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    avatar ? (defaultSrc ? defaultSrc : defaultAvatar) : defaultImage
  );
  const fileInput = React.useRef(null);
  
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };
  
  const handleClick = () => {
    fileInput.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
  };

  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <Box style={{display: "flex"}}>
        <Avatar src={imagePreviewUrl} style={{width: 120, height: 120, marginLeft: "auto", marginRight: "auto", marginBottom: 15}}> </Avatar>
      </Box>
      
      <div>
        {file === null && !defaultSrc ? (
          <Button
            color={addBtnColor}
            className={addBtnClasses}
            onClick={() => handleClick()}
          >
            {avatar ? SessionConfigs.translateText("header", "profileAddPic") : "Select image"}
          </Button>
        ) : (
          <span>
            <Button
              color={changeBtnColor}
              className={changeBtnClasses}
              onClick={() => handleClick()}
            >
              {SessionConfigs.translateText("header", "profileChangePic")}
            </Button>
            {avatar ? <br /> : null}
            <Button
              color={saveBtnColor}
              className={saveBtnClasses}
              onClick={(event) => handleSubmit(event, file)}
            >
              {SessionConfigs.translateText("header", "profileSavePic")}
            </Button>
          </span>
        )}
      </div>
    </div>
  );
};

ImageUpload.defaultProps = {
  avatar: false,
  saveBtnClasses: "btn-round",
  saveBtnColor: "success",
  addBtnClasses: "btn-round",
  addBtnColor: "primary",
  changeBtnClasses: "btn-round",
  changeBtnColor: "primary"
};

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  removeBtnClasses: PropTypes.string,
  removeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  addBtnClasses: PropTypes.string,
  addBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  changeBtnClasses: PropTypes.string,
  changeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ])
};

export default ImageUpload;
