import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom"

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableFooter,
  TableHead,
} from "@mui/material"

import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import HistoryTable from "components/HistoryTable/HistoryTable.js";
import ReactDatetime from "react-datetime"

import LanguageDependentText from "components/TimerTable/LanguageDependentText";
import { useHuntTrackerContext } from "tracker-context";

import { SocketIO } from "sessions/socket"
import { SessionConfigs } from "sessions/sessionState";
import { extractPatchInfo, extractWorldInfo, extractHuntInfo } from "sessions/gameInfo";
import { huntInfos } from "sessions/resources/hunts.js";
import UserTable from "components/Admin/UserTable";
import MaintenanceTable from "components/Admin/MaintenanceTable"

const AdminPage = () => {
  const history = useHistory();
  const [alert, setAlert] = React.useState(null);
  const [controller, dispatch] = useHuntTrackerContext();
  const { language, dataCenter, user } = controller;

  const [activeTab, setActiveTab] = React.useState("UserTab");

  if (!user.permission) {
    history.push("/index");
  }

  if (!user.permission.AdminTable) {
    history.push("/index");
  }

  const textLibrary = SessionConfigs.translateText;

  return (
    <>
      <div className="content">
        {alert}
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={3}>
                    <Nav className="nav-pills-info flex-column" pills>
                      <NavItem>
                        <NavLink
                          data-toggle="tab" className={activeTab === "UserTab" ? "active" : ""}
                          style={{cursor: "pointer"}}
                          onClick={(e) => {
                            e.preventDefault();
                            setActiveTab("UserTab")
                          }}
                        >
                          {textLibrary("header", "adminUserTab")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          data-toggle="tab" className={activeTab === "Maintenance" ? "active" : ""}
                          style={{cursor: "pointer"}}
                          onClick={(e) => {
                            e.preventDefault();
                            setActiveTab("Maintenance")
                          }}
                        >
                          {textLibrary("header", "adminMaintenance")}
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                  <Col>
                    <TabContent className="tab-space" activeTab={activeTab}>
                      <TabPane tabId="UserTab">
                        <UserTable/>
                      </TabPane>
                      <TabPane tabId="Maintenance">
                        <MaintenanceTable></MaintenanceTable>
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AdminPage;
