import React, { useEffect } from "react";
import { useHistory } from "react-router-dom"

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  CustomInput,
} from "reactstrap";

import {
  Box,
  Button,
  TableContainer,
} from "@mui/material"

import SweetAlert from "react-bootstrap-sweetalert";
import { ToastContainer } from "react-toastify";
import notification from "assets/ringtone.ogg";

import TimerRow from "components/TimerTable/TimerRow"
import TimerTable from "components/TimerTable/TimerTable"
import LanguageDependentText from "components/TimerTable/LanguageDependentText";
import { useHuntTrackerContext, setContextState } from "tracker-context";

import { HuntTimers } from "sessions/databaseStorage"
import { SessionConfigs } from "sessions/sessionState";

const SingleRankTimer = (props) => {
  const [controller, dispatch ] = useHuntTrackerContext();
  const { language, datacenters, audioPermission } = controller;

  const history = useHistory();
  const [alert, setAlert] = React.useState(null);
  const audioRef = React.createRef();
  const rankType = props.match.path.split("/")[1];
  const [HuntInfo, setHuntInfo] = React.useState([]);

  const [traveling, setTraveling] = React.useState(true);
  const [loadingState, setLoadingState] = React.useState(true);

  const dataCenterTravel = async () => {
    if (props.match.params["world"]) {
      const dcList = SessionConfigs.getDataCenterResources();
      const worldKey = props.match.params["world"];
      for (var key of Object.keys(dcList)) {
        if (key.toLocaleLowerCase() === worldKey.toLocaleLowerCase()) {
          if (!datacenters.includes(key)) {
            setContextState(dispatch, "datacenters", [key]);
          }
          setTraveling(false);
          return;
        }

        for (var world of dcList[key]["Names"]) {
          if (world.toLocaleLowerCase() === worldKey.toLocaleLowerCase()) {
            if (!datacenters.includes(key)) {
              setContextState(dispatch, "datacenters", [key]);
            }
            SessionConfigs.setWorldSelect(rankType, "select", world, [key], true);
            setTraveling(false);
            return;
          }
        }
      }
    } else {
      setTraveling(false);
    }
  }
  
  React.useEffect(() => {
    setTraveling(true);
    setLoadingState(true);
    dataCenterTravel();
  }, []);

  React.useEffect(() => {
    if (traveling) return; 

    if (datacenters.length == 0) {
      history.push("/index");
      return;
    }

    SessionConfigs.query("/api/lastDeathTimers", {
      "QueryDeathTimers": SessionConfigs.getCurrentDCInfo(datacenters),
      "RankType": rankType == "srank" ? "sRank" : (rankType == "arank" ? "aRank" : rankType)
    }).then((response) => {
      if (response.status == 200) {
        if (rankType == "fate") {
          HuntTimers.setFATETimers(response.data.timers);
        } else {
          HuntTimers.setTimers(response.data.timers);
        }
        setHuntInfo(HuntTimers.getRankInfo(rankType))
      }
      setLoadingState(false);
    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
      setLoadingState(false);
    });
  }, [traveling, datacenters]);

  React.useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = audioPermission > 0 ? audioPermission : 0
    }
  }, [audioRef.current, audioPermission]);

  const hideDeadRef = React.useRef();
  React.useEffect(() => {
    if (document.getElementById(rankType + "HideDead") && HuntInfo.length > 0) document.getElementById(rankType + "HideDead").checked = !HuntInfo[0].hideDead;
  }, [hideDeadRef.current, HuntInfo])

  var timerConfigs = SessionConfigs.getToggleStates(rankType);
  const worlds = SessionConfigs.getCurrentDCInfo(datacenters);
  const patches = SessionConfigs.getPatches();
  var lastClick = new Date().getTime();
  var lastClickType = "";

  const updateDisplayStatus = (type, calculate) => {
    var huntInfo = HuntTimers.getRankInfo(type);
    const currentTime = new Date().getTime();
    var huntCount = 0;

    for (var i = 0; i < huntInfo.length; i++) {
      const row = document.getElementById(huntInfo[i].worldName + "_" + huntInfo[i].huntName + "_Row");
      if (!row) continue;
      if (!calculate) {
        if ((huntInfo[i].displayState && (!huntInfo[i].hideDead || currentTime > huntInfo[i].expectMinTime )) == row.classList.contains("d-none")) {
          row.classList.toggle("d-none");
        }
      } else {
        if (!row.classList.contains("d-none")) {
          if (currentTime > huntInfo[i].expectMinTime) {
            huntCount+=1;
          }
        }
      }
    }
    return huntCount;
  }

  const toggleWorldState = (type, world, event) => {
    var newClick = new Date().getTime();

    if (newClick - lastClick < 300 && lastClickType == world) {
      lastClick = newClick;

      for (var i = 0; i < worlds.length; i++) {
        if (worlds[i] != world) {
          document.getElementById(type+worlds[i]).classList.remove("btn-selected");
        } else {
          document.getElementById(type+worlds[i]).classList.add("btn-selected");
        }
      }
      SessionConfigs.setWorldSelect(type, "select", world, datacenters);
      HuntTimers.updateDisplayState(type);
      updateDisplayStatus(type);

    } else {
      lastClick = newClick;
      lastClickType = world;

      event.currentTarget.classList.toggle("btn-selected");
      const state = event.currentTarget.classList.contains("btn-selected");
      SessionConfigs.setWorldSelect(type, "toggle", world);
      HuntTimers.updateDisplayState(type);
      updateDisplayStatus(type);
    }
  }
  
  const togglePatchState = (type, patch, event) => {
    var newClick = new Date().getTime();
    if (newClick - lastClick < 300 && lastClickType == patch) {
      lastClick = newClick;
      
      for (var i = 0; i < patches.length; i++) {
        if (patches[i] != patch) {
          document.getElementById(type+patches[i]).classList.remove("btn-selected");
        } else {
          document.getElementById(type+patches[i]).classList.add("btn-selected");
        }
      }
      SessionConfigs.setPatchSelect(type, "select", patch);
      HuntTimers.updateDisplayState(type);
      updateDisplayStatus(type);

    } else {
      lastClick = newClick;
      lastClickType = patch;

      event.currentTarget.classList.toggle("btn-selected");
      const state = event.currentTarget.classList.contains("btn-selected");
      SessionConfigs.setPatchSelect(type, "toggle", patch);
      HuntTimers.updateDisplayState(type);
      updateDisplayStatus(type);
    }
  }

  const toggleInstanceState = (type, instance, event) => {
    event.currentTarget.classList.toggle("btn-selected");
    const state = event.currentTarget.classList.contains("btn-selected");
    SessionConfigs.setInstanceSelect(type, instance);
    HuntTimers.updateDisplayState(type);
    updateDisplayStatus(type);
  }

  const toggleHiddenHunts = (type, event) => {
    HuntTimers.setHideDeadHunts(type, !event.currentTarget.checked);
    HuntTimers.updateDisplayState(type);
    SessionConfigs.setHideDeadHunts(type, !event.currentTarget.checked);
    updateDisplayStatus(type);
  }

  React.useEffect(() => {
    if (rankType == "srank") {
      const timerID = setInterval(() => {
        const huntCount = updateDisplayStatus("srank", true);
        if (document.getElementById("srankCounter")) document.getElementById("srankCounter").innerHTML = SessionConfigs.translateText("header", "tableSpawnableHunt") + " " + huntCount.toString();
      }, 1000);
  
      return () => {
        clearInterval(timerID);
      };
    }
  }, [])

  return (
    <>
      <div className="content" ref={hideDeadRef} >
        {alert}
        <audio src={notification} ref={audioRef} volume={0.5}/>
        <Row style={{marginTop: 15}}>
          <ToastContainer
              style={{width: 400}}
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover
            />
          <Col xs={12}>
            <Card>
              <CardBody>
              {loadingState ? null : ([
                  <Box key={"tableTitle"} pt={1} px={3}>
                    <Row>
                      <Col xs={rankType == "arank" ? 6 : 12} sm={rankType == "arank" ? 6 : 4}>
                        <LanguageDependentText header={rankType + "Table"} variant="h4" fontSize={24}/>
                      </Col>
                      {rankType == "arank" ? null : 
                      <Col xs={6} sm={4}>
                        <Box display="flex" alignContent={"center"}>
                          <LanguageDependentText fontSize={20}>
                            <span id={"srankCounter"}></span>
                          </LanguageDependentText>
                        </Box>
                      </Col>}
                      <Col xs={6} sm={rankType == "arank" ? 6 : 4}>
                        <Box className="pull-right">
                          <CustomInput type="switch" id={rankType + "HideDead"} onClick={(event) => toggleHiddenHunts(rankType, event)} label={<LanguageDependentText header={"tableHideDead"} component="p" fontSize={15}/>} />
                        </Box>
                      </Col>
                    </Row>
                  </Box>,
                  <Box key={"worldSelect"} pt={3} px={3}>
                    <LanguageDependentText header="worldSelectTitle" variant="h6"/>
                  </Box>,
                  <Box key={"worldGroup"} pt={1} px={3}>
                    {worlds.map((world)=>(
                      (timerConfigs.worlds.includes(world)) ? (
                      <Button id={rankType+world} key={world} variant="outlined" className="btn-selected" onClick={(event) => toggleWorldState(rankType, world, event)}>
                        <LanguageDependentText text={world} variant="body2" fontSize={13}></LanguageDependentText>
                      </Button>
                      ) : (
                      <Button id={rankType+world} key={world} variant="outlined" onClick={(event) => toggleWorldState(rankType, world, event)}>
                        <LanguageDependentText text={world} variant="body2" fontSize={13}></LanguageDependentText>
                      </Button> 
                    )))}
                  </Box>,
                  <Box key={"patchSelect"} pt={1} px={3}>
                    <LanguageDependentText header="patchSelectTitle" variant="h6"/>
                  </Box>,
                  <Box key={"patchGroup"} pt={1} px={3}>
                    {patches.map((patch)=>(
                      (timerConfigs.patches.includes(patch)) ? (
                      <Button id={rankType+patch} key={patch} variant="outlined" className="btn-selected" onClick={(event) => togglePatchState(rankType, patch, event)}>
                        <LanguageDependentText patchName={patch} variant="body2" fontSize={13}></LanguageDependentText>
                      </Button>
                      ) : (
                      <Button id={rankType+patch} key={patch} variant="outlined" onClick={(event) => togglePatchState(rankType, patch, event)}>
                        <LanguageDependentText patchName={patch} variant="body2" fontSize={13}></LanguageDependentText>
                      </Button> 
                    )))}
                  </Box>,
                  rankType == "srank" ? null : [
                    <Box key={"instanceSelect"} pt={1} px={3}>
                      <LanguageDependentText header="instanceSelectTitle" variant="h6"/>
                    </Box>,
                    <Box key={"instanceGroup"} pt={1} px={3} mb={4}>
                      {["1","2","3","4","5","6"].map((instance)=>(
                        (timerConfigs.instance.includes(instance)) ? (
                        <Button key={instance} variant="outlined" className="btn-selected" onClick={(event) => toggleInstanceState(rankType, instance, event)}>
                          <LanguageDependentText header={"tableInstanceName"} variant="body2" fontSize={13}> {instance} </LanguageDependentText>
                        </Button>
                        ) : (
                        <Button key={instance} variant="outlined" onClick={(event) => toggleInstanceState(rankType, instance, event)}>
                          <LanguageDependentText header={"tableInstanceName"} variant="body2" fontSize={13}> {instance} </LanguageDependentText>
                        </Button> 
                      )))}
                    </Box>
                  ],
                  <Box key={"tableContent"}>
                    <TableContainer sx={{ boxShadow: "none", overflowY: "hidden"}}>
                      <TimerTable name={rankType == "srank" ? "SHuntTable" : "AHuntTable"} audioRef={audioRef} huntInfo={HuntInfo}/>
                    </TableContainer>
                  </Box>
                ])}
              </CardBody>
            </Card>
            
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SingleRankTimer;
