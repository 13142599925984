/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

const Footer = (props) => {
  return (
    <footer className={"footer" + (props.default ? " footer-default" : "")} style={props.style} >
      <Container fluid={props.fluid ? true : false}>
        <p>
          FINAL FANTASY is a registered trademark of Square Enix Holdings Co., Ltd.
          Final Fantasy art and icons reused non-commercially under the FINAL FANTASY® XIV
          <a href="https://support.na.square-enix.com/rule.php?id=5382&amp;tag=authc"> Materials Usage License </a>
        </p>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
