import React from "react";
import { useHistory } from "react-router-dom"

// reactstrap components
import {
  Button,
} from "reactstrap";

import { UserProfile } from "sessions/userState.js"
import { SessionConfigs } from "sessions/sessionState.js"
import { extractWorldInfo } from "sessions/gameInfo.js"
import { useHuntTrackerContext, setContextStates } from "tracker-context";
import { SocketIO } from "sessions/socket";

import { 
  Box,
  Grid,
  Typography,
} from "@mui/material";

import LanguageDependentText from "components/TimerTable/LanguageDependentText";

const HomePage = () => {
  const history = useHistory();
  const [controller, dispatch] = useHuntTrackerContext();
  const { darkMode, language } = controller;

  React.useEffect(() => {
    
  }, []);

  const textLibrary = SessionConfigs.translateText;

  const handleDataCenterUpdate = async (name, newLanguage) => {
    setContextStates(dispatch, ["language", "datacenters"], [newLanguage ? newLanguage : language, [name]]);
    history.push("/timer");
  }

  const worldNameDisplay = (worlds) => {
    var worldNamesLogs = [];
    for (var world of worlds) {
      worldNamesLogs.push(
        <Grid key={world} item xs={12} sm={4} mb={1}>
          <Typography variant="body2" align="center" fontWeight="medium" style={{color: "#FFFFFF"}}>
            {world}
          </Typography>
        </Grid>
      );
    }
    return worldNamesLogs;
  }

  const dataCenterCard = (name, worlds, language) => (
    <Grid key={name} item xs={12} lg={4}>
      <Box mb={4}
        sx={{
          borderRadius: "40px",
          background: "linear-gradient(to bottom right, #3446AB, #123267)"
        }}
      >
        <Box
          width="max-content" lineHeight={1}
          px={4} pt={0} pb={0.5} mx="auto"
          sx={{
            backgroundColor: "error.main",
            borderRadius: "20px",
            marginTop: -2,
            transform: "translateY(-50%)"
          }}
        >
          <Typography
            variant="h6"
            textTransform="uppercase"
            fontWeight="medium"
            style={{color: "#000000"}}
          >
            {name}
          </Typography>
        </Box>

        <Grid key={name+"WorldNameArrangementGrid"} container mt={1}>
          {worldNameDisplay(worlds)}
        </Grid>

        <Box pb={3} px={3}> 
          <Box mt={2}>
            <Button className="btn-just-icon btn-block" color="default" onClick={() => handleDataCenterUpdate(name, language)} >
              <Typography
                variant="body2"
                fontWeight="medium"
                style={{color: "#FFFFFF"}}
              >
                {textLibrary("header", "viewDataCenter")}  
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );

  var northAmericaDataCenters = [];
  for (var datacenter of ["Aether", "Crystal", "Dynamis", "Primal"]){
    const worlds = extractWorldInfo(datacenter)["Names"];
    northAmericaDataCenters.push(dataCenterCard(datacenter, worlds));
  }

  var europeOceaniaDataCenters = [];
  for (var datacenter of ["Chaos", "Light", "Shadow", "Materia"]){
    const worlds = extractWorldInfo(datacenter)["Names"];
    europeOceaniaDataCenters.push(dataCenterCard(datacenter, worlds));
  }

  var japanDataCenters = [];
  for (var datacenter of ["Elemental", "Gaia", "Mana","Meteor"]){
    const worlds = extractWorldInfo(datacenter)["Names"];
    japanDataCenters.push(dataCenterCard(datacenter, worlds));
  }

  var chinaDataCenters = [];
  for (var datacenter of ["豆豆柴", "陆行鸟", "莫古力", "猫小胖"]){
    const worlds = extractWorldInfo(datacenter)["Names"];
    chinaDataCenters.push(dataCenterCard(datacenter, worlds, "cn"));
  }

  return (
    <>
      <div className="content">
        <Box
          position="relative"
          borderRadius="30px"
          mx={2}
          pb={5}
          sx={{
            backgroundImage: 'url("/images/character_selection.png")',
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid key="HeaderGrid" container spacing={3} justifyContent="center"
            sx={{
              position: "relative",
              py: 5,
              textAlign: "center"
            }}
          >
            <Grid item xs={11} lg={7}>
              <Box mb={1}>
                <Typography variant="h1" fontWeight="bold" style={{color: "#FFFFFF"}}>
                  {textLibrary("header", "indexWelcomeMessage")}
                </Typography>
              </Box>
              <br/>
              <Box mb={2}>
                <Typography variant="h4" fontWeight="regular" style={{color: "#FFFFFF"}}>
                  {textLibrary("header", "indexWelcomeMessageSub")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          
          <Grid container sx={{ px: 6, my: 8, borderRadius: "50px" }}>
            <Grid item xs={12}>
              <Box position="relative" zIndex={10} mt={8} mx={3} px={{ xs: 1, sm: 0 }}>
                <Grid container spacing={3} mb={6} justifyContent="center">
                  <Typography variant="h2" fontWeight="bold" style={{color: "#FFFFFF"}}>
                    {textLibrary("header", "northAmericaServers")}
                  </Typography>
                </Grid>
                <Grid container spacing={3} justifyContent="center">
                  {northAmericaDataCenters}
                </Grid>
              </Box>
              <Box position="relative" zIndex={10} mt={8} mx={3} px={{ xs: 1, sm: 0 }}>
                <Grid container spacing={3} mb={6} justifyContent="center">
                  <Typography variant="h2" fontWeight="bold" style={{color: "#FFFFFF"}}>
                    {textLibrary("header", "europeOceaniaServers")}
                  </Typography>
                </Grid>
                <Grid container spacing={3} justifyContent="center">
                  {europeOceaniaDataCenters}
                </Grid>
              </Box>
              <Box position="relative" zIndex={10} mt={8} mx={3} px={{ xs: 1, sm: 0 }}>
                <Grid container spacing={3} mb={6} justifyContent="center">
                  <Typography variant="h2" fontWeight="bold" style={{color: "#FFFFFF"}}>
                    {textLibrary("header", "japanServers")}
                  </Typography>
                </Grid>
                <Grid container spacing={3} justifyContent="center">
                  {japanDataCenters}
                </Grid>
              </Box>
              <Box position="relative" zIndex={10} mt={8} mx={3} px={{ xs: 1, sm: 0 }}>
                <Grid container spacing={3} mb={6} justifyContent="center">
                  <Typography variant="h2" fontWeight="bold" style={{color: "#FFFFFF"}}>
                    {textLibrary("header", "chinaServers")}
                  </Typography>
                </Grid>
                <Grid container spacing={3} justifyContent="center">
                  {chinaDataCenters}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default HomePage;
