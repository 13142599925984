import { Typography, Tooltip } from "@mui/material";
import { useHuntTrackerContext } from "tracker-context";
import { useEffect, useState } from "react";

import { predictSpawnCondition } from "sessions/gameInfo";
import { SessionConfigs } from "sessions/sessionState";

function formatCountUpTimer(timestamp, lastSeen, lastDeath, state) {
  var lastTime = 0;
  var colorCode = "timer-ready";
  var message = SessionConfigs.translateText("header", "fateFound");
  if (lastSeen > lastDeath) {
    lastTime = lastSeen;
  } else {
    lastTime = lastDeath;
    message = SessionConfigs.translateText("header", "fateUnknown");
    colorCode = "timer-soon";
  }

  if (state) {
    message = SessionConfigs.translateText("header", "fateCompleted");
  }

  var distance = timestamp - lastTime;
  var hours = Math.floor(distance / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  var textString = String(hours).padStart(2, '0') + ":" + String(minutes).padStart(2, '0') + ":" + String(seconds).padStart(2, '0') + " (" + message + ")";
  return <Typography variant="h5" fontSize={12} className={"timer " + colorCode}> {textString} </Typography>;
}

export const CountUpTimer = ({rowID, lastSeen, lastDeath, state, interval}) => {
  const [controller, dispatch] = useHuntTrackerContext();
  const { DarkMode, Language } = controller;

  const [timeString, setTimeString] = useState(formatCountUpTimer(new Date().getTime(), lastSeen, lastDeath, state));

  useEffect(() => {
    const timerID = setInterval(() => {
      var TimeString = formatCountUpTimer(new Date().getTime(), lastSeen, lastDeath, state);
      setTimeString(TimeString);
    }, interval);
    return () => {
      clearInterval(timerID);
    };
  }, [lastSeen, lastDeath]);

  return timeString;
};
