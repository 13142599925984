import React from "react"
import { useHistory } from "react-router-dom";

import { 
  Row,
  Col,
  Badge,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {
  Avatar,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
} from "@mui/material"

import SweetAlert from "react-bootstrap-sweetalert";
import Flatpickr from "react-flatpickr";

import { dataCenters } from "sessions/resources/datacenters";
import { SessionConfigs } from "sessions/sessionState";
import TablePagination from "../Pagination/Pagination.js";
import LanguageDependentText from "components/TimerTable/LanguageDependentText.js";
import { UserProfile } from "sessions/userState.js";

function MaintenanceTable({children}) {
  const history = useHistory();
  const [alert, setAlert] = React.useState(null);

  const viewPerPage = 20;
  const [displayData, setDisplayData] = React.useState([]);

  const [searchName, setSearchName] = React.useState("");
  const [searchResult, setSearchResult] = React.useState([]);
  const [filterResult, setFilterResult] = React.useState([]);

  const [maintTime, setMaintTime] = React.useState({date: new Date(), time: new Date()});

  const admin = UserProfile.getUser();

  React.useEffect(() => {
    SessionConfigs.queryData("/api/fetchMaintTimer", {}).then((response) => {
      setSearchResult(response.data);
      setFilterResult(response.data.filter((world) => world.worldName.includes(searchName) || world.dcName.includes(searchName)));
    }).catch((error) => {
      setAlert(
        <SweetAlert
            error style={{display: "block",marginTop: "-100px"}}
            title="Error Received" onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)} confirmBtnBsStyle="info" >
        {"Error"}
      </SweetAlert>);
    })
  }, [])
  
  const updateMaintTimers = () => {
    const localeString = maintTime.date.toLocaleDateString("en", {
      year: 'numeric', 
      month: 'numeric', 
      day: 'numeric'
    }) + " " + maintTime.time.toLocaleTimeString("en", {
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric',
      hourCycle: 'h23',
    });

    setAlert(
      <SweetAlert
          info style={{display: "block",marginTop: "-100px"}}
          title="Processing" onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)} showConfirm={false} >
      {"Processing"}
    </SweetAlert>);

    var oldDateTime = {}
    if (new Date(localeString) < new Date()) {
      for (var world of filterResult) {
        oldDateTime[world.worldName] = new Date(world.deathTime).getTime()
      }
    }

    SessionConfigs.queryData("/api/updateMaintTimers", {
      worlds: filterResult.map((world) => world.worldName),
      dateTime: new Date(localeString).getTime(),
      oldDateTime: oldDateTime,
    }).then((response) => {
      setAlert(null);
    }).catch((error) => {
      setAlert(
        <SweetAlert
            error style={{display: "block",marginTop: "-100px"}}
            title="Error Received" onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)} confirmBtnBsStyle="info" >
        {"Error"}
      </SweetAlert>);
    })
  }

  const syncLastDeathTimer = () => {
    setAlert(
      <SweetAlert
          info style={{display: "block",marginTop: "-100px"}}
          title="Processing" onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)} showConfirm={false} >
      {"Error"}
    </SweetAlert>);

    SessionConfigs.queryData("/api/applyMaintTimers", {
      worlds: filterResult.map((world) => world.worldName),
    }).then((response) => {
      setAlert(null);
    }).catch((error) => {
      setAlert(
        <SweetAlert
            error style={{display: "block",marginTop: "-100px"}}
            title="Error Received" onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)} confirmBtnBsStyle="info" >
        {"Error"}
      </SweetAlert>);
    })
  }

  React.useEffect(() => {
    const newFilterData = searchResult.filter((world) => world.worldName.includes(searchName) || world.dcName.includes(searchName));
    setFilterResult(newFilterData);
  }, [searchResult, searchName])

  const textLibrary = SessionConfigs.translateText;

  return (
    <>
      {alert}
      <Form action="#">
        <LanguageDependentText variant="span" header={"maintTableFilterWorlds"} fontSize={12} fontWeight={"bold"}/>
        <FormGroup>
          <Input type="text" value={searchName} onChange={(event) => {setSearchName(event.currentTarget.value)}} />
        </FormGroup>
        
      </Form>
      <Row style={{marginBottom: 30}}>
        <Col>
          <Flatpickr options={{
            enableTime: false,
            defaultDate: maintTime.date
          }} className={"form-control datetime-picker"} onChange={([date]) => { setMaintTime({...maintTime, date: new Date(date)}) }}/>
        </Col>
        <Col>
          <Flatpickr options={{
            enableTime: true,
            noCalendar: true,
            minuteIncrement: 1,
            allowInput: true,
            defaultDate: maintTime.time
          }} className={"form-control datetime-picker"} onChange={([date]) => { setMaintTime({...maintTime, time: new Date(date)}) }}/>
        </Col>
        <Col style={{display: "flex"}}>
          <Button variant="contained" onClick={()=> updateMaintTimers()}>
            <LanguageDependentText variant="h5" header={"maintTableApply"} color="#EEEEEE !important" fontSize={12} fontWeight={"bold"} align={"center"}/>
          </Button>
          <Button variant="contained" onClick={()=> syncLastDeathTimer()} style={{marginLeft: 15}}>
            <LanguageDependentText variant="h5" header={"maintTableSync"} color="#EEEEEE !important" fontSize={12} fontWeight={"bold"} align={"center"}/>
          </Button>
        </Col>
      </Row>
      
      <Table size="large">
        <TableHead>
          <TableRow>
            <TableCell variant="head" style={{width: "20%", verticalAlign: "bottom", paddingBottom: 0, paddingTop: 0}}>
              <LanguageDependentText variant="span" header={"maintTableWorldName"} fontSize={12} fontWeight={"bold"} color="#111111" style={{cursor: "pointer"}}/>
            </TableCell>
            <TableCell variant="head" style={{width: "15%", verticalAlign: "bottom", paddingBottom: 0, paddingTop: 0}}>
              <LanguageDependentText variant="span" header={"maintTableDCName"} fontSize={12} fontWeight={"bold"} color="#111111" style={{cursor: "pointer"}}/>
            </TableCell>
            <TableCell variant="head" style={{width: "40%", verticalAlign: "bottom", paddingBottom: 0, paddingTop: 0}}>
              <LanguageDependentText variant="span" header={"maintTableTime"} fontSize={12} fontWeight={"bold"} color="#111111" align={"center"} style={{cursor: "pointer"}}/>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filterResult.map((world) => {
            return <TableRow key={world.worldName}>
              <TableCell>
                <LanguageDependentText variant="span" text={world.worldName} fontSize={15}/>
              </TableCell>
              <TableCell>
                <LanguageDependentText variant="span" text={world.dcName} fontSize={15}/>
              </TableCell>
              <TableCell>
                <LanguageDependentText variant="span" date={new Date(world.deathTime)} align={"center"} fontSize={15}/>
              </TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </>
  );
}

export default MaintenanceTable;