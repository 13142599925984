export const FATEChains = {
  "505": {
    "DatabaseName": "FATE Behemoth Chain #1",
    "Name": {
      "English": "Behold Now Behemoth",
      "German": "Der legendäre Behemoth",
      "French": "Défi\u0002\u001d\u0001\u0003: l'ire du béhémoth",
      "Japanese": "伝説の魔獣「ベヒーモス」",
      "ChineseSimplified": "传说的魔兽——贝希摩斯"
    },
    "Coordinates": {
      "X": -763.5457,
      "Y": 29.18999,
      "Z": 224.6077
    },
    "Patch": "ARR",
    "Region": "Coerthas Central Highlands"
  },
  "902": {
    "DatabaseName": "FATE Coeurlregina Chain #1",
    "Name": {
      "English": "Long Live the Coeurl",
      "German": "Angriff der Königin",
      "French": "Défi\u0002\u001d\u0001\u0003: mort à la reine des coeurls",
      "Japanese": "雷獣の女王「クァールレギナ」",
      "ChineseSimplified": "雷兽女王——长须豹女王"
    },
    "Coordinates": {
      "X": 702.3181,
      "Y": 118.1909,
      "Z": -49.77981
    },
    "Patch": "HW",
    "Region": "The Dravanian Forelands"
  },
  "903": {
    "DatabaseName": "FATE Coeurlregina Chain #2",
    "Name": {
      "English": "Coeurls Chase Boys",
      "German": "Rückkehr der Königin",
      "French": "Défi\u0002\u001d\u0001\u0003: du coeurl à l'ouvrage",
      "Japanese": "逆襲の女王「クァールレギナ」",
      "ChineseSimplified": "逆袭女王——长须豹女王"
    },
    "Coordinates": {
      "X": 731.8091,
      "Y": 256.3963,
      "Z": -36.64782
    },
    "Patch": "HW",
    "Region": "The Dravanian Forelands"
  },
  "904": {
    "DatabaseName": "FATE Coeurlregina Chain #2",
    "Name": {
      "English": "Coeurls Chase Boys",
      "German": "Rückkehr der Königin",
      "French": "Défi\u0002\u001d\u0001\u0003: du coeurl à l'ouvrage",
      "Japanese": "逆襲の女王「クァールレギナ」",
      "ChineseSimplified": "逆袭女王——长须豹女王"
    },
    "Coordinates": {
      "X": 221.4674,
      "Y": -91.98718,
      "Z": -56.65508
    },
    "Patch": "HW",
    "Region": "The Dravanian Forelands"
  },
  "1109": {
    "DatabaseName": "FATE Foxy Lady Chain #1",
    "Name": {
      "English": "Outfoxed",
      "German": "Ausgefuchst",
      "French": "Rusera bien qui rusera le dernier",
      "Japanese": "その名はミクズメ",
      "ChineseSimplified": "其名为藻女"
    },
    "Coordinates": {
      "X": 487.9019,
      "Y": -630.512,
      "Z": 21.79869
    },
    "Patch": "SB",
    "Region": "Yanxia"
  },
  "1426": {
    "DatabaseName": "FATE Archaeotania Chain #1",
    "Name": {
      "English": "Where Has the Dagon",
      "German": "Schrecken der Tiefsee Archaeotania: Die Jagd beginnt",
      "French": "L'annonciatrice du chaos\u0002\u001d\u0001\u0003: l'ouverture de la chasse",
      "Japanese": "厄災のアルケオタニア：追跡開始",
      "ChineseSimplified": "灾厄的古塔尼亚之开始追踪"
    },
    "Coordinates": {
      "X": 62.76828,
      "Y": 76.16266,
      "Z": 268.134
    },
    "Patch": "ShB",
    "Region": "The Tempest"
  },
  "1427": {
    "DatabaseName": "FATE Archaeotania Chain #2",
    "Name": {
      "English": "Ondo of Blood",
      "German": "Schrecken der Tiefsee Archaeotania: Zumshs Ritual",
      "French": "L'annonciatrice du chaos\u0002\u001d\u0001\u0003: Zumshs la terreur des abysses",
      "Japanese": "厄災のアルケオタニア：ズムスィー登場",
      "ChineseSimplified": "灾厄的古塔尼亚之兹姆嘶登场"
    },
    "Coordinates": {
      "X": 361.0932,
      "Y": -457.8093,
      "Z": 356.4004
    },
    "Patch": "ShB",
    "Region": "The Tempest"
  },
  "1428": {
    "DatabaseName": "FATE Archaeotania Chain #3",
    "Name": {
      "English": "Lookin' Back on the Track",
      "German": "Schrecken der Tiefsee Archaeotania: Luntenschwarm",
      "French": "L'annonciatrice du chaos\u0002\u001d\u0001\u0003: oléofurors furax",
      "Japanese": "厄災のアルケオタニア：テウスィー防衛",
      "ChineseSimplified": "灾厄的古塔尼亚之保护提乌嘶"
    },
    "Coordinates": {
      "X": 140.5996,
      "Y": -167.6926,
      "Z": 200.2473
    },
    "Patch": "ShB",
    "Region": "The Tempest"
  },
  "1429": {
    "DatabaseName": "FATE Archaeotania Chain #4",
    "Name": {
      "English": "Coral Support",
      "German": "Schrecken der Tiefsee Archaeotania: Dunkle Grotten",
      "French": "L'annonciatrice du chaos\u0002\u001d\u0001\u0003: expédition spéléologique",
      "Japanese": "厄災のアルケオタニア：テウスィー護衛",
      "ChineseSimplified": "灾厄的古塔尼亚之护卫提乌嘶"
    },
    "Coordinates": {
      "X": 688.553,
      "Y": -586.7186,
      "Z": 429.481
    },
    "Patch": "ShB",
    "Region": "The Tempest"
  },
  "1430": {
    "DatabaseName": "FATE Archaeotania Chain #5",
    "Name": {
      "English": "Low Coral Fiber",
      "German": "Schrecken der Tiefsee Archaeotania: Blutkorallen",
      "French": "L'annonciatrice du chaos\u0002\u001d\u0001\u0003: pêche aux coraux",
      "Japanese": "厄災のアルケオタニア：紅血珊瑚の収集",
      "ChineseSimplified": "灾厄的古塔尼亚之收集红血珊瑚"
    },
    "Coordinates": {
      "X": 733.6972,
      "Y": -788.4423,
      "Z": 462.0334
    },
    "Patch": "ShB",
    "Region": "The Tempest"
  },
  "1431": {
    "DatabaseName": "FATE Archaeotania Chain #6",
    "Name": {
      "English": "Nothing Like a Trappin' Life",
      "German": "Schrecken der Tiefsee Archaeotania: Köder",
      "French": "L'annonciatrice du chaos\u0002\u001d\u0001\u0003: les rets de Zumshs",
      "Japanese": "厄災のアルケオタニア：決戦の下準備",
      "ChineseSimplified": "灾厄的古塔尼亚之准备决战"
    },
    "Coordinates": {
      "X": 278.9897,
      "Y": 161.0433,
      "Z": 373.2685
    },
    "Patch": "ShB",
    "Region": "The Tempest"
  },
  "1477": {
    "DatabaseName": "FATE Formidable Chain #1",
    "Name": {
      "English": "Ironbeard Builders - Rebuilt",
      "German": "Krieg der Baumeister: Konstruktionsphase",
      "French": "Bataille d'ingénieurs\u0002\u001d\u0001\u0003: automate en con\u0002\u0016\u0001\u0003struc\u0002\u0016\u0001\u0003tion",
      "Japanese": "ビルドウォリアーズ：オートマトン製作",
      "ChineseSimplified": "制作战士之自走人偶"
    },
    "Coordinates": {
      "X": 210.4918,
      "Y": -225.5979,
      "Z": 313.112
    },
    "Patch": "ShB",
    "Region": "Kholusia"
  },
  "1478": {
    "DatabaseName": "FATE Formidable Chain #2",
    "Name": {
      "English": "Ironbeard Builders - Resist",
      "German": "Krieg der Baumeister: Testphase",
      "French": "Bataille d'ingénieurs\u0002\u001d\u0001\u0003: dernière mise au point",
      "Japanese": "ビルドウォリアーズ：試作品実戦テスト",
      "ChineseSimplified": "制作战士之实机测试"
    },
    "Coordinates": {
      "X": -174.6144,
      "Y": -336.3591,
      "Z": 368.0382
    },
    "Patch": "ShB",
    "Region": "Kholusia"
  },
  "1479": {
    "DatabaseName": "FATE Formidable Chain #3",
    "Name": {
      "English": "Ironbeard Builders - Revolt",
      "German": "Krieg der Baumeister: Duell der Giganten",
      "French": "Bataille d'ingénieurs\u0002\u001d\u0001\u0003: la lutte finale",
      "Japanese": "ビルドウォリアーズ：没収試合",
      "ChineseSimplified": "制作战士之不公平对决"
    },
    "Coordinates": {
      "X": -263.7579,
      "Y": -27.84834,
      "Z": 337.5144
    },
    "Patch": "ShB",
    "Region": "Kholusia"
  },
  "1462": {
    "DatabaseName": "FATE Formidable Chain #4",
    "Name": {
      "English": "Foes Most Formidable",
      "German": "Ein formidabler Kampf: Die Bedrohung",
      "French": "Formidable\u0002\u001d\u0001\u0003: la menace",
      "Japanese": "激闘フォーミダブル：迫る脅威",
      "ChineseSimplified": "激斗畏惧装甲之危险逼近"
    },
    "Coordinates": {
      "X": 57.12122,
      "Y": -387.2979,
      "Z": 343.9606
    },
    "Patch": "ShB",
    "Region": "Kholusia"
  },
  "1463": {
    "DatabaseName": "FATE Formidable Chain #5",
    "Name": {
      "English": "A Family Most Formidable",
      "German": "Ein formidabler Kampf: Ein finsterer Plan",
      "French": "Formidable\u0002\u001d\u0001\u0003: la filature",
      "Japanese": "激闘動フォーミダブル：新たな情報",
      "ChineseSimplified": "激斗畏惧装甲之新的情报"
    },
    "Coordinates": {
      "X": 213.5123,
      "Y": -54.94828,
      "Z": 313.2114
    },
    "Patch": "ShB",
    "Region": "Kholusia"
  },
  "1772": {
    "DatabaseName": "FATE Daivadipa Chain #1",
    "Name": {
      "English": "Devout Pilgrims vs. the Jungle Lizards",
      "German": "Im Dienste der Mrga: Pilger in Not",
      "French": "Le culte des Mrga\u0002\u001d\u0001\u0003: pélerin perdu",
      "Japanese": "ムリガ信仰：迷える巡礼者"
    },
    "Coordinates": {
      "X": 641.8092,
      "Y": 299.0134,
      "Z": 47.07766
    },
    "Patch": "EW",
    "Region": "Thavnair"
  },
  "1779": {
    "DatabaseName": "FATE Daivadipa Chain #2",
    "Name": {
      "English": "Devout Pilgrims vs. the Apalala",
      "German": "Im Dienste der Mrga: Stoßgebet",
      "French": "Le culte des Mrga\u0002\u001d\u0001\u0003: l'heure de la prière",
      "Japanese": "ムリガ信仰：祈りの時"
    },
    "Coordinates": {
      "X": 75.8764,
      "Y": 89.62692,
      "Z": 12.99517
    },
    "Patch": "EW",
    "Region": "Thavnair"
  },
  "1843": {
    "DatabaseName": "FATE Chi Chain #1",
    "Name": {
      "English": "Omicron Recall: Comms Expansion",
      "German": "Aus dem Speicher von \u00022\u0012\ufffd\tBNpcName\u0006\ufffd(\ufffd\u0002\u0002\u0002\u0003: Aufrüstung des Kommunikators",
      "French": "Mémoire vive\u0002\u001d\u0001\u0003: mise à niveau",
      "Japanese": "\u0002(\u000f\ufffd\tBNpcName\ufffd(\ufffd\u0001\u0003のメモリーより：通信機拡張"
    },
    "Coordinates": {
      "X": 393.88,
      "Y": 215.6,
      "Z": 437.8165
    },
    "Patch": "EW",
    "Region": "Ultima Thule"
  },
  "1844": {
    "DatabaseName": "FATE Chi Chain #2",
    "Name": {
      "English": "Omicron Recall: Secure Connection",
      "German": "Aus dem Speicher von \u00022\u0012\ufffd\tBNpcName\u0006\ufffd(\ufffd\u0002\u0002\u0002\u0003: Gefahrenabwehr",
      "French": "Mémoire vive\u0002\u001d\u0001\u0003: pare-feu",
      "Japanese": "\u0002(\u000f\ufffd\tBNpcName\ufffd(\ufffd\u0001\u0003のメモリーより：\u0002(\u000f\ufffd\tBNpcName\ufffd+\ufffd\u0001\u0003防衛"
    },
    "Coordinates": {
      "X": 587.2,
      "Y": 266.4,
      "Z": 438.6276
    },
    "Patch": "EW",
    "Region": "Ultima Thule"
  },
  "1867": {
    "DatabaseName": "FATE Ttokrrone Chain #1",
    "Name": {
      "Japanese": "トクローネ：目覚めの予兆",
      "English": "The Serpentlord Stirs",
      "German": "Künder seines Erwachens",
      "French": "Ttokkrone: prélude à l'éveil"
    },
    "Coordinates": {
        "X": 100,
        "Y": 551,
        "Z": 8
    },
    "Patch": "DT",
    "Region": "Shaaloani"
  },
  "1868": {
    "DatabaseName": "FATE Ttokrrone Chain #2",
    "Name": {
      "Japanese": "トクローネ：目覚めの前兆",
      "English": "The Serpentlord Speaks",
      "German": "Das Erwachen rückt näher",
      "French": "Ttokkrone: le roi des serpents"
    },
    "Coordinates": {
        "X": 374,
        "Y": -187,
        "Z": -15
    },
    "Patch": "DT",
    "Region": "Shaaloani"
  },
  "1869": {
    "DatabaseName": "FATE Ttokrrone Chain #3",
    "Name": {
      "Japanese": "トクローネ：狩猟の下準備",
      "English": "The Serpentlord Sires",
      "German": "Vorbereitung der Jagd",
      "French": "Ttokkrone: la grande chasse commence"
    },
    "Coordinates": {
        "X": -31,
        "Y": -651,
        "Z": 27
    },
    "Patch": "DT",
    "Region": "Shaaloani"
  },
  "1870": {
    "DatabaseName": "FATE Ttokrrone Chain #4",
    "Name": {
      "Japanese": "トクローネ：狩猟の秘策",
      "English": "The Serpentlord Suffers",
      "German": "Mit Blut fängt man Schlangen",
      "French": "Ttokrrone: massacre ophidien"
    },
    "Coordinates": {
        "X": -31,
        "Y": -651,
        "Z": 27
    },
    "Patch": "DT",
    "Region": "Shaaloani"
  },
  "1920": {
    "DatabaseName": "FATE Mascot Chain #1",
    "Name": {
      "Japanese": "マイカ・ザ・ムー：出発進行",
      "English": "Mascot March",
      "German": "Alles andere als fade, diese Parade",
      "French": "Mica le mu: début du spectacle"
    },
    "Coordinates": {
        "X": 712,
        "Y": 636,
        "Z": 9
    },
    "Patch": "DT",
    "Region": "Living Memory"
  },
  "1921": {
    "DatabaseName": "FATE Mascot Chain #2",
    "Name": {
      "Japanese": "マイカ・ザ・ムー：大行進",
      "English": "Mascot Mayhem",
      "German": "Bizarres Zwischenspiel",
      "French": "Mica le mu: la grotesque parade"
    },
    "Coordinates": {
        "X": 712,
        "Y": 636,
        "Z": 9
    },
    "Patch": "DT",
    "Region": "Living Memory"
  }
}

export const BossFATEs = {
  "196": {
    "DatabaseName": "FATE Odin",
    "Name": {
      "English": "Steel Reign",
      "German": "Der dunkle Reiter",
      "French": "Défi\u0002\u001d\u0001\u0003: le Chevalier des ombres",
      "Japanese": "古の闘神「オーディン」",
      "ChineseSimplified": "上古斗神——奥丁"
    },
    "Coordinates": {
      "X": -371.1006,
      "Y": -148.474,
      "Z": 62.29517
    },
    "Patch": "ARR",
    "Region": "Central Shroud",
    "RespawnTimer": [24,48],
    "MaintTimer": [0,72],
    "Chain": []
  },
  "197": {
    "DatabaseName": "FATE Odin",
    "Name": {
      "English": "Steel Reign",
      "German": "Der dunkle Reiter",
      "French": "Défi\u0002\u001d\u0001\u0003: le Chevalier des ombres",
      "Japanese": "古の闘神「オーディン」",
      "ChineseSimplified": "上古斗神——奥丁"
    },
    "Coordinates": {
      "X": -328.1389,
      "Y": 14.65138,
      "Z": 59.08601
    },
    "Patch": "ARR",
    "Region": "Central Shroud",
    "RespawnTimer": [24,48],
    "MaintTimer": [0,72],
    "Chain": []
  },
  "198": {
    "DatabaseName": "FATE Odin",
    "Name": {
      "English": "Steel Reign",
      "German": "Der dunkle Reiter",
      "French": "Défi\u0002\u001d\u0001\u0003: le Chevalier des ombres",
      "Japanese": "古の闘神「オーディン」",
      "ChineseSimplified": "上古斗神——奥丁"
    },
    "Coordinates": {
      "X": 244.6293,
      "Y": 143.9635,
      "Z": 1.123879
    },
    "Patch": "ARR",
    "Region": "East Shroud",
    "RespawnTimer": [24,48],
    "MaintTimer": [0,72],
    "Chain": []
  },
  "199": {
    "DatabaseName": "FATE Odin",
    "Name": {
      "English": "Steel Reign",
      "German": "Der dunkle Reiter",
      "French": "Défi\u0002\u001d\u0001\u0003: le Chevalier des ombres",
      "Japanese": "古の闘神「オーディン」",
      "ChineseSimplified": "上古斗神——奥丁"
    },
    "Coordinates": {
      "X": 258.4733,
      "Y": 14.55389,
      "Z": -6.695335
    },
    "Patch": "ARR",
    "Region": "East Shroud",
    "RespawnTimer": [24,48],
    "MaintTimer": [0,72],
    "Chain": []
  },
  "200": {
    "DatabaseName": "FATE Odin",
    "Name": {
      "English": "Steel Reign",
      "German": "Der dunkle Reiter",
      "French": "Défi\u0002\u001d\u0001\u0003: le Chevalier des ombres",
      "Japanese": "古の闘神「オーディン」",
      "ChineseSimplified": "上古斗神——奥丁"
    },
    "Coordinates": {
      "X": 93.60974,
      "Y": 431.57,
      "Z": 8.103525
    },
    "Patch": "ARR",
    "Region": "East Shroud",
    "RespawnTimer": [24,48],
    "MaintTimer": [0,72],
    "Chain": []
  },
  "201": {
    "DatabaseName": "FATE Odin",
    "Name": {
      "English": "Steel Reign",
      "German": "Der dunkle Reiter",
      "French": "Défi\u0002\u001d\u0001\u0003: le Chevalier des ombres",
      "Japanese": "古の闘神「オーディン」",
      "ChineseSimplified": "上古斗神——奥丁"
    },
    "Coordinates": {
      "X": 619.8268,
      "Y": 89.30245,
      "Z": 22.44987
    },
    "Patch": "ARR",
    "Region": "South Shroud",
    "RespawnTimer": [24,48],
    "MaintTimer": [0,72],
    "Chain": []
  },
  "202": {
    "DatabaseName": "FATE Odin",
    "Name": {
      "English": "Steel Reign",
      "German": "Der dunkle Reiter",
      "French": "Défi\u0002\u001d\u0001\u0003: le Chevalier des ombres",
      "Japanese": "古の闘神「オーディン」",
      "ChineseSimplified": "上古斗神——奥丁"
    },
    "Coordinates": {
      "X": -257.653,
      "Y": 578.5528,
      "Z": 6.597018
    },
    "Patch": "ARR",
    "Region": "South Shroud",
    "RespawnTimer": [24,48],
    "MaintTimer": [0,72],
    "Chain": []
  },
  "203": {
    "DatabaseName": "FATE Odin",
    "Name": {
      "English": "Steel Reign",
      "German": "Der dunkle Reiter",
      "French": "Défi\u0002\u001d\u0001\u0003: le Chevalier des ombres",
      "Japanese": "古の闘神「オーディン」",
      "ChineseSimplified": "上古斗神——奥丁"
    },
    "Coordinates": {
      "X": -166.9337,
      "Y": 104.2238,
      "Z": 8.435027
    },
    "Patch": "ARR",
    "Region": "South Shroud",
    "RespawnTimer": [24,48],
    "MaintTimer": [0,72],
    "Chain": []
  },
  "204": {
    "DatabaseName": "FATE Odin",
    "Name": {
      "English": "Steel Reign",
      "German": "Der dunkle Reiter",
      "French": "Défi\u0002\u001d\u0001\u0003: le Chevalier des ombres",
      "Japanese": "古の闘神「オーディン」",
      "ChineseSimplified": "上古斗神——奥丁"
    },
    "Coordinates": {
      "X": -66.09518,
      "Y": -51.25509,
      "Z": 3.801062
    },
    "Patch": "ARR",
    "Region": "South Shroud",
    "RespawnTimer": [24,48],
    "MaintTimer": [0,72],
    "Chain": []
  },
  "205": {
    "DatabaseName": "FATE Odin",
    "Name": {
      "English": "Steel Reign",
      "German": "Der dunkle Reiter",
      "French": "Défi\u0002\u001d\u0001\u0003: le Chevalier des ombres",
      "Japanese": "古の闘神「オーディン」",
      "ChineseSimplified": "上古斗神——奥丁"
    },
    "Coordinates": {
      "X": 38.42992,
      "Y": 48.73484,
      "Z": -7.645442
    },
    "Patch": "ARR",
    "Region": "North Shroud",
    "RespawnTimer": [24,48],
    "MaintTimer": [0,72],
    "Chain": []
  },
  "206": {
    "DatabaseName": "FATE Odin",
    "Name": {
      "English": "Steel Reign",
      "German": "Der dunkle Reiter",
      "French": "Défi\u0002\u001d\u0001\u0003: le Chevalier des ombres",
      "Japanese": "古の闘神「オーディン」",
      "ChineseSimplified": "上古斗神——奥丁"
    },
    "Coordinates": {
      "X": 181.1689,
      "Y": 160.9323,
      "Z": -24.84926
    },
    "Patch": "ARR",
    "Region": "North Shroud",
    "RespawnTimer": [24,48],
    "MaintTimer": [0,72],
    "Chain": []
  },
  "207": {
    "DatabaseName": "FATE Odin",
    "Name": {
      "English": "Steel Reign",
      "German": "Der dunkle Reiter",
      "French": "Défi\u0002\u001d\u0001\u0003: le Chevalier des ombres",
      "Japanese": "古の闘神「オーディン」",
      "ChineseSimplified": "上古斗神——奥丁"
    },
    "Coordinates": {
      "X": -265.433,
      "Y": 301.2105,
      "Z": -57.03028
    },
    "Patch": "ARR",
    "Region": "North Shroud",
    "RespawnTimer": [24,48],
    "MaintTimer": [0,72],
    "Chain": []
  },
  "506": {
    "DatabaseName": "FATE Behemoth",
    "Name": {
      "English": "He Taketh It with His Eyes",
      "German": "Kein Weg zurück",
      "French": "Défi\u0002\u001d\u0001\u0003: contre-traque",
      "Japanese": "手負いの魔獣「ベヒーモス」",
      "ChineseSimplified": "受伤的魔兽贝希摩斯"
    },
    "Coordinates": {
      "X": -855.8987,
      "Y": -321.2746,
      "Z": 249.6836
    },
    "Patch": "ARR",
    "Region": "Coerthas Central Highlands",
    "RespawnTimer": [24,48],
    "MaintTimer": [24,48],
    "Chain": ["505"]
  },
  "877": {
    "DatabaseName": "FATE Noctilucale",
    "Name": {
      "English": "Prey Online",
      "German": "Ultima: Ein Prototyp tankt auf",
      "French": "Proto-Ultima\u0002\u001d\u0001\u0003: la menace surgie du passé",
      "Japanese": "太古の脅威：ノクチルカ撃滅戦",
      "ChineseSimplified": "太古威胁 夜光花歼灭战"
    },
    "Coordinates": {
      "X": 262.6718,
      "Y": -831.4194,
      "Z": 29.1797
    },
    "Patch": "HW",
    "Region": "Azys Lla",
    "RespawnTimer": [24,24],
    "MaintTimer": [24,24],
    "Chain": []
  },
  "878": {
    "DatabaseName": "FATE Noctilucale",
    "Name": {
      "English": "Prey Online",
      "German": "Ultima: Ein Prototyp tankt auf",
      "French": "Proto-Ultima\u0002\u001d\u0001\u0003: la menace surgie du passé",
      "Japanese": "太古の脅威：ノクチルカ撃滅戦",
      "ChineseSimplified": "太古威胁 夜光花歼灭战"
    },
    "Coordinates": {
      "X": -820.5443,
      "Y": -124.5055,
      "Z": 23.97895
    },
    "Patch": "HW",
    "Region": "Azys Lla",
    "RespawnTimer": [24,24],
    "MaintTimer": [24,24],
    "Chain": []
  },
  "879": {
    "DatabaseName": "FATE Noctilucale",
    "Name": {
      "English": "Prey Online",
      "German": "Ultima: Ein Prototyp tankt auf",
      "French": "Proto-Ultima\u0002\u001d\u0001\u0003: la menace surgie du passé",
      "Japanese": "太古の脅威：ノクチルカ撃滅戦",
      "ChineseSimplified": "太古威胁 夜光花歼灭战"
    },
    "Coordinates": {
      "X": 815.8714,
      "Y": -212.1276,
      "Z": 24.85468
    },
    "Patch": "HW",
    "Region": "Azys Lla",
    "RespawnTimer": [24,24],
    "MaintTimer": [24,24],
    "Chain": []
  },
  "905": {
    "DatabaseName": "FATE Coeurlregina",
    "Name": {
      "English": "Coeurls Chase Boys Chase Coeurls",
      "German": "Entmachtung der Königin",
      "French": "Défi\u0002\u001d\u0001\u0003: coeurls brisés",
      "Japanese": "幻影の女王「クァールレギナ」",
      "ChineseSimplified": "幻影女王——长须豹女王"
    },
    "Coordinates": {
      "X": 451.3485,
      "Y": -844.3855,
      "Z": 58.89029
    },
    "Patch": "HW",
    "Region": "The Dravanian Forelands",
    "RespawnTimer": [24,48],
    "MaintTimer": [24,48],
    "Chain": ["902", "903"]
  },
  "905": {
    "DatabaseName": "FATE Coeurlregina",
    "Name": {
      "English": "Coeurls Chase Boys Chase Coeurls",
      "German": "Entmachtung der Königin",
      "French": "Défi\u0002\u001d\u0001\u0003: coeurls brisés",
      "Japanese": "幻影の女王「クァールレギナ」",
      "ChineseSimplified": "幻影女王——长须豹女王"
    },
    "Coordinates": {
      "X": 451.3485,
      "Y": -844.3855,
      "Z": 58.89029
    },
    "Patch": "HW",
    "Region": "The Dravanian Forelands",
    "RespawnTimer": [24,48],
    "MaintTimer": [24,48],
    "Chain": ["902", "903"]
  },
  "906": {
    "DatabaseName": "FATE Coeurlregina",
    "Name": {
      "English": "Coeurls Chase Boys Chase Coeurls",
      "German": "Entmachtung der Königin",
      "French": "Défi\u0002\u001d\u0001\u0003: coeurls brisés",
      "Japanese": "幻影の女王「クァールレギナ」",
      "ChineseSimplified": "幻影女王——长须豹女王"
    },
    "Coordinates": {
      "X": -661.2598,
      "Y": 696.6986,
      "Z": -103.0876
    },
    "Patch": "HW",
    "Region": "The Dravanian Forelands",
    "RespawnTimer": [24,48],
    "MaintTimer": [24,48],
    "Chain": ["902", "903"]
  },
  "907": {
    "DatabaseName": "FATE Coeurlregina",
    "Name": {
      "English": "Coeurls Chase Boys Chase Coeurls",
      "German": "Entmachtung der Königin",
      "French": "Défi\u0002\u001d\u0001\u0003: coeurls brisés",
      "Japanese": "幻影の女王「クァールレギナ」",
      "ChineseSimplified": "幻影女王——长须豹女王"
    },
    "Coordinates": {
      "X": -483.0349,
      "Y": -559.9859,
      "Z": -10.71782
    },
    "Patch": "HW",
    "Region": "The Dravanian Forelands",
    "RespawnTimer": [24,48],
    "MaintTimer": [24,48],
    "Chain": ["902", "903"]
  },
  "1103": {
    "DatabaseName": "FATE Ixion",
    "Name": {
      "English": "A Horse Outside",
      "German": "Höllenross",
      "French": "Défi\u0002\u001d\u0001\u0003: l'étalon survolté",
      "Japanese": "伝説の雷馬「イクシオン」",
      "ChineseSimplified": "传说中的雷马——伊克西翁"
    },
    "Coordinates": {
      "X": -840.8375,
      "Y": -295.4034,
      "Z": 78.38177
    },
    "Patch": "SB",
    "Region": "The Lochs",
    "RespawnTimer": [36,36],
    "MaintTimer": [36,36],
    "Chain": []
  },
  "1104": {
    "DatabaseName": "FATE Ixion",
    "Name": {
      "English": "A Horse Outside",
      "German": "Höllenross",
      "French": "Défi\u0002\u001d\u0001\u0003: l'étalon survolté",
      "Japanese": "伝説の雷馬「イクシオン」",
      "ChineseSimplified": "传说中的雷马——伊克西翁"
    },
    "Coordinates": {
      "X": 399.1169,
      "Y": -590.0641,
      "Z": 24.12723
    },
    "Patch": "SB",
    "Region": "The Lochs",
    "RespawnTimer": [36,36],
    "MaintTimer": [36,36],
    "Chain": []
  },
  "1105": {
    "DatabaseName": "FATE Ixion",
    "Name": {
      "English": "A Horse Outside",
      "German": "Höllenross",
      "French": "Défi\u0002\u001d\u0001\u0003: l'étalon survolté",
      "Japanese": "伝説の雷馬「イクシオン」",
      "ChineseSimplified": "传说中的雷马——伊克西翁"
    },
    "Coordinates": {
      "X": -248.7414,
      "Y": 48.85049,
      "Z": 5.005669
    },
    "Patch": "SB",
    "Region": "The Lochs",
    "RespawnTimer": [36,36],
    "MaintTimer": [36,36],
    "Chain": []
  },
  "1106": {
    "DatabaseName": "FATE Foxy Lady",
    "Name": {
      "English": "Foxy Lady",
      "German": "Fuchsige Dame",
      "French": "Défi\u0002\u001d\u0001\u0003: la renarde fait surface",
      "Japanese": "九尾の妖狐「玉藻御前」",
      "ChineseSimplified": "九尾妖狐玉藻御前"
    },
    "Coordinates": {
      "X": -251.063,
      "Y": -446.2655,
      "Z": 53.21751
    },
    "Patch": "SB",
    "Region": "Yanxia",
    "RespawnTimer": [24,24],
    "MaintTimer": [24,24],
    "Chain": ["1109"]
  },
  "1107": {
    "DatabaseName": "FATE Foxy Lady",
    "Name": {
      "English": "Foxy Lady",
      "German": "Fuchsige Dame",
      "French": "Défi\u0002\u001d\u0001\u0003: la renarde fait surface",
      "Japanese": "九尾の妖狐「玉藻御前」",
      "ChineseSimplified": "九尾妖狐玉藻御前"
    },
    "Coordinates": {
      "X": -497.2336,
      "Y": -181.6863,
      "Z": 10.88827
    },
    "Patch": "SB",
    "Region": "Yanxia",
    "RespawnTimer": [24,24],
    "MaintTimer": [24,24],
    "Chain": ["1109"]
  },
  "1108": {
    "DatabaseName": "FATE Foxy Lady",
    "Name": {
      "English": "Foxy Lady",
      "German": "Fuchsige Dame",
      "French": "Défi\u0002\u001d\u0001\u0003: la renarde fait surface",
      "Japanese": "九尾の妖狐「玉藻御前」",
      "ChineseSimplified": "九尾妖狐玉藻御前"
    },
    "Coordinates": {
      "X": 153.0472,
      "Y": 626.4675,
      "Z": 8.320316
    },
    "Patch": "SB",
    "Region": "Yanxia",
    "RespawnTimer": [24,24],
    "MaintTimer": [24,24],
    "Chain": ["1109"]
  },
  "1432": {
    "DatabaseName": "FATE Archaeotania",
    "Name": {
      "English": "The Head, the Tail, the Whole Damned Thing",
      "German": "Schrecken der Tiefsee Archaeotania: Die Bestie",
      "French": "L'annonciatrice du chaos\u0002\u001d\u0001\u0003: bataille au sommet des fonds",
      "Japanese": "厄災のアルケオタニア：深海の討伐戦",
      "ChineseSimplified": "灾厄的古塔尼亚之深海讨伐战"
    },
    "Coordinates": {
      "X": 278.9897,
      "Y": 248.9746,
      "Z": 390
    },
    "Patch": "ShB",
    "Region": "The Tempest",
    "RespawnTimer": [24,48],
    "MaintTimer": [24,48],
    "Chain": ["1426","1427","1428","1429","1430","1431"]
  },
  "1464": {
    "DatabaseName": "FATE Formidable",
    "Name": {
      "English": "A Finale Most Formidable",
      "German": "Ein formidabler Kampf: Der Gigant",
      "French": "Formidable\u0002\u001d\u0001\u0003: l'arme secrète",
      "Japanese": "激闘フォーミダブル：切り札起動",
      "ChineseSimplified": "激斗畏惧装甲之秘密武器"
    },
    "Coordinates": {
      "X": 611.9377,
      "Y": -11.53454,
      "Z": 287.0095
    },
    "Patch": "ShB",
    "Region": "Kholusia",
    "RespawnTimer": [24,48],
    "MaintTimer": [24,48],
    "Chain": ["1477", "1478", "1479", "1462", "1463"]
  },
  "1763": {
    "DatabaseName": "FATE Daivadipa",
    "Name": {
      "English": "Devout Pilgrims vs. Daivadipa",
      "German": "Im Dienste der Mrga: Blasphemie",
      "French": "Le culte des Mrga\u0002\u001d\u0001\u0003: le faux dieu",
      "Japanese": "ムリガ信仰：偽りの神",
      "ChineseSimplified": "兽道诸神信仰：伪神降临"
    },
    "Coordinates": {
      "X": -608,
      "Y": 811,
      "Z": 8.504924
    },
    "Patch": "EW",
    "Region": "Thavnair",
    "RespawnTimer": [36,36],
    "MaintTimer": [36,36],
    "Chain": ["1772", "1779"]
  },
  "1855": {
    "DatabaseName": "FATE Chi",
    "Name": {
      "English": "Omicron Recall: Killing Order",
      "German": "Aus dem Speicher von \u00022\u0012\ufffd\tBNpcName\u0006\ufffd(\ufffd\u0002\u0002\u0002\u0003: Zerstörung",
      "French": "Mémoire vive\u0002\u001d\u0001\u0003: arrêter le processus",
      "Japanese": "\u0002(\u000f\ufffd\tBNpcName\ufffd(\ufffd\u0001\u0003のメモリーより：侵略兵器の破壊",
      "ChineseSimplified": "侵略兵器召回指令：破坏侵略兵器希"
    },
    "Coordinates": {
      "X": 650,
      "Y": 0,
      "Z": 340
    },
    "Patch": "EW",
    "Region": "Ultima Thule",
    "RespawnTimer": [36,36],
    "MaintTimer": [36,36],
    "Chain": ["1843", "1844"]
  },
  "1871": {
    "DatabaseName": "FATE Ttokrrone",
    "Name": {
      "Japanese": "トクローネ：荒野の死闘",
      "English": "The Serpentlord Seethes",
      "German": "In den sandigen Schlund",
      "French": "Ttokrrone: lutte ensablée",
      "ChineseSimplified": "????"
    },
    "Coordinates": {
      "X": 51,
      "Y": -822,
      "Z": 48
    },
    "Patch": "DT",
    "Region": "Shaaloani",
    "RespawnTimer": [36,36],
    "MaintTimer": [36,36],
    "Chain": ["1867", "1868", "1869", "1870"]
  },
  "1922": {
    "DatabaseName": "FATE Mascot",
    "Name": {
      "Japanese": "マイカ・ザ・ムー：大団円",
      "English": "Mascot Murder",
      "German": "Mordendes Maskottchen",
      "French": "Mica le mu: le grand final",
      "ChineseSimplified": "????"
    },
    "Coordinates": {
      "X": 791,
      "Y": 593,
      "Z": 60
    },
    "Patch": "DT",
    "Region": "Living Memory",
    "RespawnTimer": [36,36],
    "MaintTimer": [36,36],
    "Chain": ["1920", "1921"]
  }
}
