import { Typography, Tooltip } from "@mui/material";
import { useHuntTrackerContext } from "tracker-context";
import { useEffect, useState } from "react";

import { predictSpawnCondition } from "sessions/gameInfo";
import { SessionConfigs } from "sessions/sessionState";

function formatCountDownTimer(timestamp, minTime, maxTime, isFound, progress, missing) {
  if (isFound) {
    var distance = timestamp - isFound;
    var hours = Math.floor(distance / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    var textString = String(hours).padStart(2, '0') + ":" + String(minutes).padStart(2, '0') + ":" + String(seconds).padStart(2, '0');

    textString += ` (HP: ${(progress*100).toFixed(2)} %)`

    return <Typography variant="h5" fontSize={13} className={"timer timer-found"}> {textString} </Typography>;
  }

  if (timestamp < minTime) {
    var distance = minTime - timestamp;
    var hours = Math.floor(distance / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var colorCode = "timer-early";
    if (distance < 600000) {
      colorCode = "timer-soon";
    }

    var textString = String(hours).padStart(2, '0') + ":" + String(minutes).padStart(2, '0');
    if (missing) textString += " (" + SessionConfigs.translateText("header", "tableMissing") + ")";

    return <Typography variant="h5" fontSize={13} className={"timer " + colorCode}> {textString} </Typography>;

  } else {
    var distance = timestamp - minTime;
    var hours = Math.floor(distance / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var percent = Math.round((distance / (maxTime - minTime)) * 100);
    if (percent > 999) percent = 999;

    var colorCode = "timer-ready";
    if (distance > (maxTime - minTime)) {
      colorCode = "timer-cap";
    }

    var textString = String(hours).padStart(2, '0') + ":" + String(minutes).padStart(2, '0');
    if (maxTime - minTime > 7200000 || percent <= 100) {
      textString += " (" + percent + "%)";
    }
    if (missing) textString += " (" + SessionConfigs.translateText("header", "tableMissing") + ")";

    return <Typography variant="h5" fontSize={13} className={"timer " + colorCode}> {textString} </Typography>;
  }
}

export const CountDownTimer = ({rowID, minTime, maxTime, interval, isFound, progress, missing, handleDeadOpen}) => {
  const [controller, dispatch] = useHuntTrackerContext();
  const { DarkMode, Language } = controller;

  const [timeString, setTimeString] = useState(formatCountDownTimer(new Date().getTime(), minTime, maxTime, isFound, progress, missing));
  var lastOpen = new Date().getTime() > minTime;
  
  useEffect(() => {
    const timerID = setInterval(() => {
      var TimeString = formatCountDownTimer(new Date().getTime(), minTime, maxTime, isFound, progress, missing);
      if (new Date().getTime() > minTime && !lastOpen) {
        handleDeadOpen();
      }
      lastOpen = new Date().getTime() > minTime;
      setTimeString(TimeString);
    }, interval);
    return () => {
      clearInterval(timerID);
    };
  }, [minTime]);

  return timeString;
};

function constructHoverText(startTime, duration) {
  switch (SessionConfigs.getLanguage()) {
    case "en": {
      const dateOptions = { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
      var hoverText = new Date(startTime).toLocaleDateString("en", dateOptions);

      return (
        <span style={{fontSize: 14}}>
          {hoverText}
          <br/>
          {"Open for " + duration.toString() + " minutes"}
        </span> 
      );
    }
    case "de": {
      const dateOptions = { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
      var hoverText = new Date(startTime).toLocaleDateString("de", dateOptions);

      return (
        <span style={{fontSize: 14}}>
          {"Offen am " + hoverText}
          <br/>
          {"Offen für " + duration.toString() + " Minuten"}
        </span> 
      );
    }
    case "cn": {
      const dateOptions = { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
      var hoverText = new Date(startTime).toLocaleDateString("zh", dateOptions);

      return (
        <span style={{fontSize: 14}}>
          {hoverText + "后"}
          <br/>
          {duration.toString() + "分钟内可触发"}
        </span> 
      );
    }
    case "jp": {
      const dateOptions = { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
      var hoverText = new Date(startTime).toLocaleDateString("ja", dateOptions);

      return (
        <span style={{fontSize: 14}}>
          {hoverText}
          <br/>
          {"Open for " + duration.toString() + " minutes"}
        </span> 
      );
    }
    default: {
      const dateOptions = { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
      var hoverText = new Date(startTime).toLocaleDateString("en", dateOptions);

      return (
        <span style={{fontSize: 14}}>
          {hoverText}
          <br/>
          {"Open for " + duration.toString() + " minutes"}
        </span> 
      );
    }
  }
}

function formatConditionTimer(timestamp, startTime, endTime) {

  if (timestamp < startTime) {
    var distance = startTime - timestamp;
    var hours = Math.floor(distance / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    var colorCode = "timer-early";
    if (distance < 600000) {
      colorCode = "timer-soon";
    }

    var textString = String(hours).padStart(2, '0') + ":" + String(minutes).padStart(2, '0') + ":" + String(seconds).padStart(2, '0');
    return (
      <Tooltip title={constructHoverText(startTime, Math.floor((endTime-startTime)/60000))} placement="top" disableFocusListener disableTouchListener disableInteractive arrow>
        <Typography variant="h5" fontSize={13} className={"timer " + colorCode} style={{cursor: "pointer"}}> {textString} </Typography>
      </Tooltip>
    );

  } else {
    var distance = endTime - timestamp;
    var hours = Math.floor(distance / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var percent = Math.round((distance / (endTime - startTime)) * 100);
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    if (percent > 999) percent = 999;
    var colorCode = "timer-ready";

    var textString = String(hours).padStart(2, '0') + ":" + String(minutes).padStart(2, '0') + ":" + String(seconds).padStart(2, '0');
    
    return (
      <Tooltip title={constructHoverText(startTime, Math.floor((endTime-startTime)/60000))} placement="top" disableFocusListener disableTouchListener disableInteractive arrow>
        <Typography variant="h5" fontSize={13} className={"timer " + colorCode} style={{cursor: "pointer"}}> {textString} </Typography>
      </Tooltip>
    );
  }
}


export const ConditionTimer = ({minTime, name, interval}) => {
  const [controller, dispatch] = useHuntTrackerContext();
  const { DarkMode, Language } = controller;
  const [timeString, setTimeString] = useState("");

  useEffect(() => {
    var prediction = null;
    var currentTime = new Date().getTime();
    if (currentTime < minTime) {
      prediction = predictSpawnCondition(name, new Date(minTime));
      if (prediction["Duration"][0] < minTime/1000) {
        prediction["Duration"][0] = minTime/1000;
      }
    } else {
      prediction = predictSpawnCondition(name, new Date(currentTime));
    }
    
    var expectedTimeRange = prediction["Duration"];
    setTimeString(formatConditionTimer(currentTime, expectedTimeRange[0]*1000, expectedTimeRange[1]*1000));

    const timerID = setInterval(() => {
      currentTime = new Date().getTime();
      if (currentTime > expectedTimeRange[1]*1000) {
        expectedTimeRange = predictSpawnCondition(name, new Date(expectedTimeRange[1]*1000))["Duration"];
      }
      setTimeString(formatConditionTimer(currentTime, expectedTimeRange[0]*1000, expectedTimeRange[1]*1000));
    }, interval);

    return () => {
      clearInterval(timerID);
    };

  }, [minTime]);

  return timeString;
};


