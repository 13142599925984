export const spawnPoints = {
  "Forneus": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      9.584042071252735,
      16.931341459245075
    ],
    "SpawnPoint02": [
      10.087243982263514,
      18.9002079002079
    ],
    "SpawnPoint03": [
      12.103420660652757,
      19.326240668070444
    ],
    "SpawnPoint04": [
      14.251786596981095,
      19.494123294029706
    ],
    "SpawnPoint05": [
      12.743950509891457,
      20.82665400144433
    ],
    "SpawnPoint06": [
      10.450286458333334,
      21.738999837239582
    ],
    "SpawnPoint07": [
      11.581770487034575,
      23.597194980053192
    ],
    "SpawnPoint08": [
      15.902145651743265,
      24.014370635647783
    ],
    "SpawnPoint09": [
      16.036129629441003,
      21.114408689496415
    ],
    "SpawnPoint10": [
      15.819068219431465,
      19.6936894592095
    ],
    "SpawnPoint11": [
      16.66520417865954,
      17.839718467310856
    ],
    "SpawnPoint12": [
      19.1117453994614,
      18.345288480419658
    ],
    "SpawnPoint13": [
      21.732142857142858,
      16.373430524553573
    ],
    "SpawnPoint14": [
      22.075069518008476,
      24.485941362111582
    ],
    "SpawnPoint15": [
      23.46135796440972,
      27.566093578673247
    ],
    "SpawnPoint16": [
      21.63358866373698,
      30.034618123372397
    ],
    "SpawnPoint17": [
      28.79300798688616,
      14.479936872209821
    ],
    "SpawnPoint18": [
      31.162909371512278,
      18.652713230678014
    ],
    "SpawnPoint19": [
      29.12646085778061,
      19.423892897002553
    ],
    "SpawnPoint20": [
      26.79015027623373,
      20.58665774132321
    ],
    "SpawnPoint21": [
      27.097503990255376,
      22.632717993951612
    ],
    "SpawnPoint22": [
      28.31089817236991,
      22.0184696249293
    ],
    "SpawnPoint23": [
      29.644671461744334,
      23.354050643498113
    ],
    "DisplayPoints": 23,
    "Version": 2
  },
  "Laideronnette": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      9.584042071252735,
      16.931341459245075
    ],
    "SpawnPoint02": [
      10.087243982263514,
      18.9002079002079
    ],
    "SpawnPoint03": [
      12.103420660652757,
      19.326240668070444
    ],
    "SpawnPoint04": [
      14.251786596981095,
      19.494123294029706
    ],
    "SpawnPoint05": [
      12.743950509891457,
      20.82665400144433
    ],
    "SpawnPoint06": [
      10.450286458333334,
      21.738999837239582
    ],
    "SpawnPoint07": [
      11.581770487034575,
      23.597194980053192
    ],
    "SpawnPoint08": [
      15.902145651743265,
      24.014370635647783
    ],
    "SpawnPoint09": [
      16.036129629441003,
      21.114408689496415
    ],
    "SpawnPoint10": [
      15.819068219431465,
      19.6936894592095
    ],
    "SpawnPoint11": [
      16.66520417865954,
      17.839718467310856
    ],
    "SpawnPoint12": [
      19.1117453994614,
      18.345288480419658
    ],
    "SpawnPoint13": [
      21.732142857142858,
      16.373430524553573
    ],
    "SpawnPoint14": [
      22.075069518008476,
      24.485941362111582
    ],
    "SpawnPoint15": [
      23.46135796440972,
      27.566093578673247
    ],
    "SpawnPoint16": [
      21.63358866373698,
      30.034618123372397
    ],
    "SpawnPoint17": [
      28.79300798688616,
      14.479936872209821
    ],
    "SpawnPoint18": [
      31.162909371512278,
      18.652713230678014
    ],
    "SpawnPoint19": [
      29.12646085778061,
      19.423892897002553
    ],
    "SpawnPoint20": [
      26.79015027623373,
      20.58665774132321
    ],
    "SpawnPoint21": [
      27.097503990255376,
      22.632717993951612
    ],
    "SpawnPoint22": [
      28.31089817236991,
      22.0184696249293
    ],
    "SpawnPoint23": [
      29.644671461744334,
      23.354050643498113
    ],
    "DisplayPoints": 23,
    "Version": 2
  },
  "Melt": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      13.610341151385928,
      23.283531075093283
    ],
    "SpawnPoint02": [
      14.345327524038462,
      25.38285792151163
    ],
    "SpawnPoint03": [
      14.576318873355262,
      27.67444490131579
    ],
    "SpawnPoint04": [
      15.575067362114643,
      23.930617962728807
    ],
    "SpawnPoint05": [
      16.892799856582126,
      21.738977109752415
    ],
    "SpawnPoint06": [
      17.418172200520832,
      22.939737955729168
    ],
    "SpawnPoint07": [
      19.10755838373656,
      25.403055170530912
    ],
    "SpawnPoint08": [
      19.920409485533998,
      28.810511327051856
    ],
    "SpawnPoint09": [
      22.05004017065603,
      28.092616217863476
    ],
    "SpawnPoint10": [
      22.110254813273993,
      29.649374758126935
    ],
    "SpawnPoint11": [
      23.775309838807786,
      30.876447023266422
    ],
    "SpawnPoint12": [
      21.307829946214596,
      21.84307981004902
    ],
    "SpawnPoint13": [
      23.005689497682983,
      21.799036990553706
    ],
    "SpawnPoint14": [
      25.725059509277344,
      20.336169242858887
    ],
    "SpawnPoint15": [
      29.047313106986216,
      20.511015086544486
    ],
    "SpawnPoint16": [
      31.795094499684343,
      21.575123796559343
    ],
    "SpawnPoint17": [
      27.583356857299805,
      22.6102237701416
    ],
    "SpawnPoint18": [
      25.33244026481331,
      23.652186274012447
    ],
    "SpawnPoint19": [
      27.731683556008456,
      24.63663501094505
    ],
    "SpawnPoint20": [
      25.85266208400974,
      25.255404068587662
    ],
    "SpawnPoint21": [
      24.325109145220587,
      15.20460324754902
    ],
    "SpawnPoint22": [
      24.015262313402232,
      16.53778348507218
    ],
    "SpawnPoint23": [
      24.798768769710435,
      17.392736032468463
    ],
    "SpawnPoint24": [
      26.76986687052506,
      16.087320769316825
    ],
    "SpawnPoint25": [
      27.014190508868243,
      17.932248997043917
    ],
    "SpawnPoint26": [
      27.82971700032552,
      18.740641276041668
    ],
    "SpawnPoint27": [
      30.263410780164932,
      17.483952275028937
    ],
    "SpawnPoint28": [
      31.907479543871865,
      15.363706965964484
    ],
    "SpawnPoint29": [
      28.130837530838814,
      12.806623920641448
    ],
    "SpawnPoint30": [
      29.587820870535715,
      11.503138950892858
    ],
    "SpawnPoint31": [
      29.884229736328123,
      13.344393310546875
    ],
    "SpawnPoint32": [
      25.03999911221591,
      9.843112275094697
    ],
    "SpawnPoint33": [
      24.438311485209073,
      11.670196424599645
    ],
    "SpawnPoint34": [
      26.545737334521384,
      11.260397108897658
    ],
    "SpawnPoint35": [
      25.86995144065367,
      13.15663076978211
    ],
    "DisplayPoints": 35,
    "Version": 2
  },
  "Wulgaru": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      13.610341151385928,
      23.283531075093283
    ],
    "SpawnPoint02": [
      14.345327524038462,
      25.38285792151163
    ],
    "SpawnPoint03": [
      14.576318873355262,
      27.67444490131579
    ],
    "SpawnPoint04": [
      15.575067362114643,
      23.930617962728807
    ],
    "SpawnPoint05": [
      16.892799856582126,
      21.738977109752415
    ],
    "SpawnPoint06": [
      17.418172200520832,
      22.939737955729168
    ],
    "SpawnPoint07": [
      19.10755838373656,
      25.403055170530912
    ],
    "SpawnPoint08": [
      19.920409485533998,
      28.810511327051856
    ],
    "SpawnPoint09": [
      22.05004017065603,
      28.092616217863476
    ],
    "SpawnPoint10": [
      22.110254813273993,
      29.649374758126935
    ],
    "SpawnPoint11": [
      23.775309838807786,
      30.876447023266422
    ],
    "SpawnPoint12": [
      21.307829946214596,
      21.84307981004902
    ],
    "SpawnPoint13": [
      23.005689497682983,
      21.799036990553706
    ],
    "SpawnPoint14": [
      25.725059509277344,
      20.336169242858887
    ],
    "SpawnPoint15": [
      29.047313106986216,
      20.511015086544486
    ],
    "SpawnPoint16": [
      31.795094499684343,
      21.575123796559343
    ],
    "SpawnPoint17": [
      27.583356857299805,
      22.6102237701416
    ],
    "SpawnPoint18": [
      25.33244026481331,
      23.652186274012447
    ],
    "SpawnPoint19": [
      27.731683556008456,
      24.63663501094505
    ],
    "SpawnPoint20": [
      25.85266208400974,
      25.255404068587662
    ],
    "SpawnPoint21": [
      24.325109145220587,
      15.20460324754902
    ],
    "SpawnPoint22": [
      24.015262313402232,
      16.53778348507218
    ],
    "SpawnPoint23": [
      24.798768769710435,
      17.392736032468463
    ],
    "SpawnPoint24": [
      26.76986687052506,
      16.087320769316825
    ],
    "SpawnPoint25": [
      27.014190508868243,
      17.932248997043917
    ],
    "SpawnPoint26": [
      27.82971700032552,
      18.740641276041668
    ],
    "SpawnPoint27": [
      30.263410780164932,
      17.483952275028937
    ],
    "SpawnPoint28": [
      31.907479543871865,
      15.363706965964484
    ],
    "SpawnPoint29": [
      28.130837530838814,
      12.806623920641448
    ],
    "SpawnPoint30": [
      29.587820870535715,
      11.503138950892858
    ],
    "SpawnPoint31": [
      29.884229736328123,
      13.344393310546875
    ],
    "SpawnPoint32": [
      25.03999911221591,
      9.843112275094697
    ],
    "SpawnPoint33": [
      24.438311485209073,
      11.670196424599645
    ],
    "SpawnPoint34": [
      26.545737334521384,
      11.260397108897658
    ],
    "SpawnPoint35": [
      25.86995144065367,
      13.15663076978211
    ],
    "DisplayPoints": 35,
    "Version": 2
  },
  "Ghede Ti Malice": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      16.906024024600075,
      18.44067600795201
    ],
    "SpawnPoint02": [
      18.726547924440297,
      18.487747784514927
    ],
    "SpawnPoint03": [
      15.833930541283848,
      19.69684817758704
    ],
    "SpawnPoint04": [
      17.03662859423003,
      21.455563406057987
    ],
    "SpawnPoint05": [
      18.601318748753986,
      22.07204134270335
    ],
    "SpawnPoint06": [
      16.896695132310544,
      23.635625547003706
    ],
    "SpawnPoint07": [
      16.1912062311747,
      27.580818717578648
    ],
    "SpawnPoint08": [
      15.716254340277779,
      28.608018663194443
    ],
    "SpawnPoint09": [
      17.20997757523148,
      30.566237702546296
    ],
    "SpawnPoint10": [
      18.283581776224125,
      30.92617078647492
    ],
    "SpawnPoint11": [
      16.33291140596435,
      32.06006484232176
    ],
    "SpawnPoint12": [
      17.17302144591083,
      32.478413053884026
    ],
    "SpawnPoint13": [
      18.986217185164424,
      28.736387244254484
    ],
    "SpawnPoint14": [
      19.38719150561407,
      27.378867435910173
    ],
    "SpawnPoint15": [
      21.417524326347305,
      27.667454154191617
    ],
    "SpawnPoint16": [
      22.70748996559633,
      25.673539635894496
    ],
    "SpawnPoint17": [
      23.012501252003204,
      24.213191888271233
    ],
    "SpawnPoint18": [
      22.63676240650791,
      22.658379094308803
    ],
    "SpawnPoint19": [
      21.417558209414132,
      21.580113938114938
    ],
    "SpawnPoint20": [
      22.17676692708333,
      19.226795572916668
    ],
    "SpawnPoint21": [
      24.3948974609375,
      18.21868896484375
    ],
    "SpawnPoint22": [
      27.738155637995646,
      17.706202021881044
    ],
    "SpawnPoint23": [
      27.017068111535274,
      19.920011578184187
    ],
    "SpawnPoint24": [
      27.086748421367993,
      22.750777793141594
    ],
    "SpawnPoint25": [
      28.846962850601948,
      24.64048191758453
    ],
    "SpawnPoint26": [
      30.59577728211292,
      25.664180958764792
    ],
    "SpawnPoint27": [
      31.764504891854745,
      23.798399748625577
    ],
    "SpawnPoint28": [
      32.73003020109954,
      23.970604790581596
    ],
    "DisplayPoints": 28,
    "Version": 2
  },
  "Mindflayer": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      16.906024024600075,
      18.44067600795201
    ],
    "SpawnPoint02": [
      18.726547924440297,
      18.487747784514927
    ],
    "SpawnPoint03": [
      15.833930541283848,
      19.69684817758704
    ],
    "SpawnPoint04": [
      17.03662859423003,
      21.455563406057987
    ],
    "SpawnPoint05": [
      18.601318748753986,
      22.07204134270335
    ],
    "SpawnPoint06": [
      16.896695132310544,
      23.635625547003706
    ],
    "SpawnPoint07": [
      16.1912062311747,
      27.580818717578648
    ],
    "SpawnPoint08": [
      15.716254340277779,
      28.608018663194443
    ],
    "SpawnPoint09": [
      17.20997757523148,
      30.566237702546296
    ],
    "SpawnPoint10": [
      18.283581776224125,
      30.92617078647492
    ],
    "SpawnPoint11": [
      16.33291140596435,
      32.06006484232176
    ],
    "SpawnPoint12": [
      17.17302144591083,
      32.478413053884026
    ],
    "SpawnPoint13": [
      18.986217185164424,
      28.736387244254484
    ],
    "SpawnPoint14": [
      19.38719150561407,
      27.378867435910173
    ],
    "SpawnPoint15": [
      21.417524326347305,
      27.667454154191617
    ],
    "SpawnPoint16": [
      22.70748996559633,
      25.673539635894496
    ],
    "SpawnPoint17": [
      23.012501252003204,
      24.213191888271233
    ],
    "SpawnPoint18": [
      22.63676240650791,
      22.658379094308803
    ],
    "SpawnPoint19": [
      21.417558209414132,
      21.580113938114938
    ],
    "SpawnPoint20": [
      22.17676692708333,
      19.226795572916668
    ],
    "SpawnPoint21": [
      24.3948974609375,
      18.21868896484375
    ],
    "SpawnPoint22": [
      27.738155637995646,
      17.706202021881044
    ],
    "SpawnPoint23": [
      27.017068111535274,
      19.920011578184187
    ],
    "SpawnPoint24": [
      27.086748421367993,
      22.750777793141594
    ],
    "SpawnPoint25": [
      28.846962850601948,
      24.64048191758453
    ],
    "SpawnPoint26": [
      30.59577728211292,
      25.664180958764792
    ],
    "SpawnPoint27": [
      31.764504891854745,
      23.798399748625577
    ],
    "SpawnPoint28": [
      32.73003020109954,
      23.970604790581596
    ],
    "DisplayPoints": 28,
    "Version": 2
  },
  "Girtab": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      16.646633978772815,
      25.43137133820893
    ],
    "SpawnPoint02": [
      15.914903160237898,
      28.30201565078383
    ],
    "SpawnPoint03": [
      18.16664041498656,
      28.981707829301076
    ],
    "SpawnPoint04": [
      19.423259196696698,
      28.59693384791041
    ],
    "SpawnPoint05": [
      18.23720072534904,
      27.046336015897687
    ],
    "SpawnPoint06": [
      21.609775606439918,
      30.062137835592196
    ],
    "SpawnPoint07": [
      22.459658434471464,
      28.111317258726668
    ],
    "SpawnPoint08": [
      24.587100202866022,
      28.28759810586441
    ],
    "SpawnPoint09": [
      27.033260898735687,
      27.22971210042939
    ],
    "SpawnPoint10": [
      25.84551647686049,
      26.015964691516377
    ],
    "SpawnPoint11": [
      24.772862247534988,
      25.748385648589906
    ],
    "SpawnPoint12": [
      22.19695431240257,
      23.769228690690763
    ],
    "SpawnPoint13": [
      18.691161184599906,
      20.413561271898676
    ],
    "SpawnPoint14": [
      22.68174115349265,
      20.722633750765933
    ],
    "SpawnPoint15": [
      23.47198263990893,
      19.96131905937867
    ],
    "SpawnPoint16": [
      24.51350034516433,
      20.267806480670796
    ],
    "SpawnPoint17": [
      25.970613518176517,
      22.801409636834176
    ],
    "SpawnPoint18": [
      27.62900784228622,
      22.29574365553513
    ],
    "SpawnPoint19": [
      28.629765170784882,
      21.67347156613372
    ],
    "SpawnPoint20": [
      27.991957486861438,
      24.29214538243106
    ],
    "DisplayPoints": 20,
    "Version": 2
  },
  "Thousand-cast Theda": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      16.646633978772815,
      25.43137133820893
    ],
    "SpawnPoint02": [
      15.914903160237898,
      28.30201565078383
    ],
    "SpawnPoint03": [
      18.16664041498656,
      28.981707829301076
    ],
    "SpawnPoint04": [
      19.423259196696698,
      28.59693384791041
    ],
    "SpawnPoint05": [
      18.23720072534904,
      27.046336015897687
    ],
    "SpawnPoint06": [
      21.609775606439918,
      30.062137835592196
    ],
    "SpawnPoint07": [
      22.459658434471464,
      28.111317258726668
    ],
    "SpawnPoint08": [
      24.587100202866022,
      28.28759810586441
    ],
    "SpawnPoint09": [
      27.033260898735687,
      27.22971210042939
    ],
    "SpawnPoint10": [
      25.84551647686049,
      26.015964691516377
    ],
    "SpawnPoint11": [
      24.772862247534988,
      25.748385648589906
    ],
    "SpawnPoint12": [
      22.19695431240257,
      23.769228690690763
    ],
    "SpawnPoint13": [
      18.691161184599906,
      20.413561271898676
    ],
    "SpawnPoint14": [
      22.68174115349265,
      20.722633750765933
    ],
    "SpawnPoint15": [
      23.47198263990893,
      19.96131905937867
    ],
    "SpawnPoint16": [
      24.51350034516433,
      20.267806480670796
    ],
    "SpawnPoint17": [
      25.970613518176517,
      22.801409636834176
    ],
    "SpawnPoint18": [
      27.62900784228622,
      22.29574365553513
    ],
    "SpawnPoint19": [
      28.629765170784882,
      21.67347156613372
    ],
    "SpawnPoint20": [
      27.991957486861438,
      24.29214538243106
    ],
    "DisplayPoints": 20,
    "Version": 2
  },
  "Alectryon": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      18.98428438203829,
      29.36598043355856
    ],
    "SpawnPoint02": [
      20.303148277723906,
      29.40009104482546
    ],
    "SpawnPoint03": [
      21.352578067215237,
      27.765254455205252
    ],
    "SpawnPoint04": [
      20.62053733648256,
      25.625283216484267
    ],
    "SpawnPoint05": [
      26.163140955105632,
      26.385768168035995
    ],
    "SpawnPoint06": [
      22.31390285986075,
      24.006875784285945
    ],
    "SpawnPoint07": [
      22.816162392272595,
      24.76211650401941
    ],
    "SpawnPoint08": [
      21.307250446590086,
      24.070264449167873
    ],
    "SpawnPoint09": [
      21.93547269830293,
      22.096275512111244
    ],
    "SpawnPoint10": [
      22.872992684248555,
      20.118235752348266
    ],
    "SpawnPoint11": [
      26.274934854938756,
      19.12761009638034
    ],
    "SpawnPoint12": [
      26.843894406582447,
      16.998161153590427
    ],
    "SpawnPoint13": [
      18.265795373735777,
      17.388352732893487
    ],
    "SpawnPoint14": [
      16.928710308271587,
      16.51585907297036
    ],
    "SpawnPoint15": [
      18.35301353893979,
      15.062305710078535
    ],
    "SpawnPoint16": [
      8.475774451147151,
      5.519472532634493
    ],
    "SpawnPoint17": [
      10.1424560546875,
      6.643359375
    ],
    "SpawnPoint18": [
      10.31524411240065,
      5.186577346007948
    ],
    "SpawnPoint19": [
      12.187257512697517,
      5.950066353343679
    ],
    "SpawnPoint20": [
      11.976299983198924,
      7.543717447916666
    ],
    "SpawnPoint21": [
      14.033394624098047,
      7.875978220500849
    ],
    "SpawnPoint22": [
      14.984431525169335,
      6.000198439424261
    ],
    "DisplayPoints": 22,
    "Version": 2
  },
  "Zona Seeker": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      18.98428438203829,
      29.36598043355856
    ],
    "SpawnPoint02": [
      20.303148277723906,
      29.40009104482546
    ],
    "SpawnPoint03": [
      21.352578067215237,
      27.765254455205252
    ],
    "SpawnPoint04": [
      20.62053733648256,
      25.625283216484267
    ],
    "SpawnPoint05": [
      26.163140955105632,
      26.385768168035995
    ],
    "SpawnPoint06": [
      22.31390285986075,
      24.006875784285945
    ],
    "SpawnPoint07": [
      22.816162392272595,
      24.76211650401941
    ],
    "SpawnPoint08": [
      21.307250446590086,
      24.070264449167873
    ],
    "SpawnPoint09": [
      21.93547269830293,
      22.096275512111244
    ],
    "SpawnPoint10": [
      22.872992684248555,
      20.118235752348266
    ],
    "SpawnPoint11": [
      26.274934854938756,
      19.12761009638034
    ],
    "SpawnPoint12": [
      26.843894406582447,
      16.998161153590427
    ],
    "SpawnPoint13": [
      18.265795373735777,
      17.388352732893487
    ],
    "SpawnPoint14": [
      16.928710308271587,
      16.51585907297036
    ],
    "SpawnPoint15": [
      18.35301353893979,
      15.062305710078535
    ],
    "SpawnPoint16": [
      8.475774451147151,
      5.519472532634493
    ],
    "SpawnPoint17": [
      8.6424560546875,
      5.4443359375
    ],
    "SpawnPoint18": [
      10.31524411240065,
      5.786577346007948
    ],
    "SpawnPoint19": [
      12.187257512697517,
      5.950066353343679
    ],
    "SpawnPoint20": [
      11.976299983198924,
      7.543717447916666
    ],
    "SpawnPoint21": [
      14.033394624098047,
      7.875978220500849
    ],
    "SpawnPoint22": [
      14.984431525169335,
      6.000198439424261
    ],
    "DisplayPoints": 15,
    "Version": 2
  },
  "Sabotender Bailarina": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      27.19563134036847,
      21.54101015916511
    ],
    "SpawnPoint02": [
      29.49553227471647,
      20.936728573409763
    ],
    "SpawnPoint03": [
      27.75343309622418,
      17.930527915232357
    ],
    "SpawnPoint04": [
      25.456551342966687,
      17.947035011955805
    ],
    "SpawnPoint05": [
      20.822591584540657,
      16.420237305564914
    ],
    "SpawnPoint06": [
      21.895637786645683,
      15.52301771863759
    ],
    "SpawnPoint07": [
      21.778656588426944,
      13.703994728570118
    ],
    "SpawnPoint08": [
      18.034756156428234,
      13.517421243761882
    ],
    "SpawnPoint09": [
      16.34925518613873,
      12.406696666370738
    ],
    "SpawnPoint10": [
      16.610307557950605,
      16.466944120992203
    ],
    "SpawnPoint11": [
      18.15054569897432,
      17.505240808552244
    ],
    "SpawnPoint12": [
      17.871972174591555,
      18.906224110858506
    ],
    "SpawnPoint13": [
      16.37444571330415,
      19.615392629020764
    ],
    "SpawnPoint14": [
      16.93583955820541,
      23.32271393000731
    ],
    "SpawnPoint15": [
      19.175858956158297,
      21.73008903692861
    ],
    "SpawnPoint16": [
      21.65479582906421,
      21.110592768101093
    ],
    "SpawnPoint17": [
      19.420041417669,
      23.74113897400274
    ],
    "SpawnPoint18": [
      18.7658687644453,
      25.280236571889446
    ],
    "SpawnPoint19": [
      20.43880904973249,
      26.148997345168613
    ],
    "SpawnPoint20": [
      22.376569475446427,
      31.382284109933035
    ],
    "SpawnPoint21": [
      23.983177329009433,
      31.95652223982901
    ],
    "SpawnPoint22": [
      23.649626608455883,
      35.08162010898109
    ],
    "SpawnPoint23": [
      25.859451799203228,
      29.848376545685017
    ],
    "DisplayPoints": 23,
    "Version": 2
  },
  "Brontes": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      27.19563134036847,
      21.54101015916511
    ],
    "SpawnPoint02": [
      29.49553227471647,
      20.936728573409763
    ],
    "SpawnPoint03": [
      27.75343309622418,
      17.930527915232357
    ],
    "SpawnPoint04": [
      25.456551342966687,
      17.947035011955805
    ],
    "SpawnPoint05": [
      20.822591584540657,
      16.420237305564914
    ],
    "SpawnPoint06": [
      21.895637786645683,
      15.52301771863759
    ],
    "SpawnPoint07": [
      21.778656588426944,
      13.703994728570118
    ],
    "SpawnPoint08": [
      18.034756156428234,
      13.517421243761882
    ],
    "SpawnPoint09": [
      16.34925518613873,
      12.406696666370738
    ],
    "SpawnPoint10": [
      16.610307557950605,
      16.466944120992203
    ],
    "SpawnPoint11": [
      18.15054569897432,
      17.505240808552244
    ],
    "SpawnPoint12": [
      17.871972174591555,
      18.906224110858506
    ],
    "SpawnPoint13": [
      16.37444571330415,
      19.615392629020764
    ],
    "SpawnPoint14": [
      16.93583955820541,
      23.32271393000731
    ],
    "SpawnPoint15": [
      19.175858956158297,
      21.73008903692861
    ],
    "SpawnPoint16": [
      21.65479582906421,
      21.110592768101093
    ],
    "SpawnPoint17": [
      19.420041417669,
      23.74113897400274
    ],
    "SpawnPoint18": [
      18.7658687644453,
      25.280236571889446
    ],
    "SpawnPoint19": [
      20.43880904973249,
      26.148997345168613
    ],
    "SpawnPoint20": [
      22.376569475446427,
      31.382284109933035
    ],
    "SpawnPoint21": [
      23.983177329009433,
      31.95652223982901
    ],
    "SpawnPoint22": [
      23.649626608455883,
      35.08162010898109
    ],
    "SpawnPoint23": [
      25.859451799203228,
      29.848376545685017
    ],
    "DisplayPoints": 23,
    "Version": 2
  },
  "Maahes": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      12.758138020833334,
      15.880867125496032
    ],
    "SpawnPoint02": [
      14.53915688894052,
      16.27475349965149
    ],
    "SpawnPoint03": [
      10.395160075111276,
      19.132170634736646
    ],
    "SpawnPoint04": [
      12.68312405813769,
      18.836690989847718
    ],
    "SpawnPoint05": [
      14.651793896627566,
      18.740533644153224
    ],
    "SpawnPoint06": [
      15.708124490148093,
      20.14269186119478
    ],
    "SpawnPoint07": [
      17.476321883054123,
      20.01112475837629
    ],
    "SpawnPoint08": [
      17.49378380408654,
      21.43198492588141
    ],
    "SpawnPoint09": [
      18.948905549676294,
      22.540935865911354
    ],
    "SpawnPoint10": [
      19.8334747928901,
      24.34522549417995
    ],
    "SpawnPoint11": [
      17.48318260303442,
      24.75201327558877
    ],
    "SpawnPoint12": [
      17.832722534626832,
      25.56983137305403
    ],
    "SpawnPoint13": [
      16.629831176078387,
      25.93326762523191
    ],
    "SpawnPoint14": [
      14.505441063174114,
      26.19811202932396
    ],
    "SpawnPoint15": [
      15.4207763671875,
      26.908284505208332
    ],
    "SpawnPoint16": [
      12.890950520833334,
      29.004286024305557
    ],
    "SpawnPoint17": [
      17.417468146194796,
      28.093688387223974
    ],
    "SpawnPoint18": [
      19.590381855867346,
      28.50508495809038
    ],
    "SpawnPoint19": [
      20.411572850392965,
      28.565036431044163
    ],
    "SpawnPoint20": [
      23.463148143193493,
      26.735793022260275
    ],
    "SpawnPoint21": [
      25.467654666385137,
      25.559291980190416
    ],
    "SpawnPoint22": [
      27.373953683035715,
      25.459774925595237
    ],
    "SpawnPoint23": [
      28.32666015625,
      25.583984375
    ],
    "SpawnPoint24": [
      29.363566509046052,
      26.8141845703125
    ],
    "SpawnPoint25": [
      28,
      21.4
    ],
    "SpawnPoint26": [
      25.051033049938727,
      23.33779296875
    ],
    "SpawnPoint27": [
      23.498456990644492,
      22.848508965696464
    ],
    "SpawnPoint28": [
      23.940984389361212,
      21.223701028262866
    ],
    "SpawnPoint29": [
      22.813978827938545,
      19.638900283412887
    ],
    "SpawnPoint30": [
      24.35428026484604,
      19.054212105938415
    ],
    "SpawnPoint31": [
      25.758051477530213,
      17.72017943967145
    ],
    "SpawnPoint32": [
      26.684982328869047,
      19.26280784970238
    ],
    "SpawnPoint33": [
      27.37327388294956,
      17.891391687225877
    ],
    "SpawnPoint34": [
      29.59326803954288,
      17.300887123280745
    ],
    "DisplayPoints": 34,
    "Version": 2
  },
  "Lampalagua": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      12.758138020833334,
      15.880867125496032
    ],
    "SpawnPoint02": [
      14.53915688894052,
      16.27475349965149
    ],
    "SpawnPoint03": [
      10.395160075111276,
      19.132170634736646
    ],
    "SpawnPoint04": [
      12.68312405813769,
      18.836690989847718
    ],
    "SpawnPoint05": [
      14.651793896627566,
      18.740533644153224
    ],
    "SpawnPoint06": [
      15.708124490148093,
      20.14269186119478
    ],
    "SpawnPoint07": [
      17.476321883054123,
      20.01112475837629
    ],
    "SpawnPoint08": [
      17.49378380408654,
      21.43198492588141
    ],
    "SpawnPoint09": [
      18.948905549676294,
      22.540935865911354
    ],
    "SpawnPoint10": [
      19.8334747928901,
      24.34522549417995
    ],
    "SpawnPoint11": [
      17.48318260303442,
      24.75201327558877
    ],
    "SpawnPoint12": [
      17.832722534626832,
      25.56983137305403
    ],
    "SpawnPoint13": [
      16.629831176078387,
      25.93326762523191
    ],
    "SpawnPoint14": [
      14.505441063174114,
      26.19811202932396
    ],
    "SpawnPoint15": [
      15.4207763671875,
      26.908284505208332
    ],
    "SpawnPoint16": [
      12.890950520833334,
      29.004286024305557
    ],
    "SpawnPoint17": [
      17.417468146194796,
      28.093688387223974
    ],
    "SpawnPoint18": [
      19.590381855867346,
      28.50508495809038
    ],
    "SpawnPoint19": [
      20.411572850392965,
      28.565036431044163
    ],
    "SpawnPoint20": [
      23.463148143193493,
      26.735793022260275
    ],
    "SpawnPoint21": [
      25.467654666385137,
      25.559291980190416
    ],
    "SpawnPoint22": [
      27.373953683035715,
      25.459774925595237
    ],
    "SpawnPoint23": [
      28.32666015625,
      25.583984375
    ],
    "SpawnPoint24": [
      29.363566509046052,
      26.8141845703125
    ],
    "SpawnPoint25": [
      28,
      21.4
    ],
    "SpawnPoint26": [
      25.051033049938727,
      23.33779296875
    ],
    "SpawnPoint27": [
      23.498456990644492,
      22.848508965696464
    ],
    "SpawnPoint28": [
      23.940984389361212,
      21.223701028262866
    ],
    "SpawnPoint29": [
      22.813978827938545,
      19.638900283412887
    ],
    "SpawnPoint30": [
      24.35428026484604,
      19.054212105938415
    ],
    "SpawnPoint31": [
      25.758051477530213,
      17.72017943967145
    ],
    "SpawnPoint32": [
      26.684982328869047,
      19.26280784970238
    ],
    "SpawnPoint33": [
      27.37327388294956,
      17.891391687225877
    ],
    "SpawnPoint34": [
      29.59326803954288,
      17.300887123280745
    ],
    "DisplayPoints": 34,
    "Version": 2
  },
  "Zanigoh": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      25.09878879123264,
      40.85151841905382
    ],
    "SpawnPoint02": [
      22.479490812059858,
      38.67658863336268
    ],
    "SpawnPoint03": [
      24.44189979573787,
      36.9173054245283
    ],
    "SpawnPoint04": [
      28.502546037946427,
      34.7535652281746
    ],
    "SpawnPoint05": [
      23.5087113107086,
      33.34435801900876
    ],
    "SpawnPoint06": [
      21.80790965416131,
      34.3400847525707
    ],
    "SpawnPoint07": [
      21.267436151919156,
      32.15430748980978
    ],
    "SpawnPoint08": [
      23.814056176933452,
      30.45535563736511
    ],
    "SpawnPoint09": [
      21.544264638300174,
      29.985926011029413
    ],
    "SpawnPoint10": [
      20.32149174528302,
      28.58777743851078
    ],
    "SpawnPoint11": [
      18.84423593374399,
      30.57298630934495
    ],
    "SpawnPoint12": [
      16.854245334201387,
      33.04342854817708
    ],
    "SpawnPoint13": [
      13.772231706226146,
      34.875492008587784
    ],
    "SpawnPoint14": [
      16.108196953415426,
      36.7863633393355
    ],
    "SpawnPoint15": [
      14.798198499177632,
      38.91672877261513
    ],
    "SpawnPoint16": [
      19.06886735635807,
      38.40265754457853
    ],
    "SpawnPoint17": [
      17.09351348876953,
      25.76384735107422
    ],
    "SpawnPoint18": [
      15.996302365518575,
      24.441693474264707
    ],
    "SpawnPoint19": [
      16.679694045325068,
      23.215400102630696
    ],
    "SpawnPoint20": [
      18.413068606529706,
      22.129255883487655
    ],
    "SpawnPoint21": [
      19.82829444088153,
      24.69856831564832
    ],
    "SpawnPoint22": [
      21.160372173366834,
      26.342463047660175
    ],
    "SpawnPoint23": [
      24.421945701357465,
      24.891453531532804
    ],
    "SpawnPoint24": [
      21.064993470821324,
      22.969214359690202
    ],
    "SpawnPoint25": [
      21.46357477838467,
      20.753024825393982
    ],
    "SpawnPoint26": [
      19.75109798693783,
      19.329257864169975
    ],
    "SpawnPoint27": [
      15.947100522706835,
      20.252884020908272
    ],
    "SpawnPoint28": [
      24.141929719129553,
      21.30669399987348
    ],
    "SpawnPoint29": [
      28.496886837121213,
      20.768741122159092
    ],
    "SpawnPoint30": [
      31.031763736670616,
      18.391754295023695
    ],
    "SpawnPoint31": [
      32.92492177535077,
      19.835416832748724
    ],
    "SpawnPoint32": [
      17.210685320082323,
      11.733417901050693
    ],
    "SpawnPoint33": [
      18.027739189040492,
      10.201723770356514
    ],
    "SpawnPoint34": [
      18.274995546083193,
      11.228526347392314
    ],
    "SpawnPoint35": [
      21.22203650841346,
      10.866075143306213
    ],
    "SpawnPoint36": [
      23.80889946912092,
      12.491686178597924
    ],
    "SpawnPoint37": [
      24.697606419538122,
      11.431232530700147
    ],
    "SpawnPoint38": [
      24.646363740808823,
      9.40419921875
    ],
    "SpawnPoint39": [
      23.196276115648676,
      7.943895744554924
    ],
    "SpawnPoint40": [
      17.247945516978792,
      16.97786252679377
    ],
    "SpawnPoint41": [
      19.543614716731625,
      17.28680270392539
    ],
    "DisplayPoints": 41,
    "Version": 2
  },
  "Nunyunuwi": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      25.09878879123264,
      40.85151841905382
    ],
    "SpawnPoint02": [
      22.479490812059858,
      38.67658863336268
    ],
    "SpawnPoint03": [
      24.44189979573787,
      36.9173054245283
    ],
    "SpawnPoint04": [
      28.502546037946427,
      34.7535652281746
    ],
    "SpawnPoint05": [
      23.5087113107086,
      33.34435801900876
    ],
    "SpawnPoint06": [
      21.80790965416131,
      34.3400847525707
    ],
    "SpawnPoint07": [
      21.267436151919156,
      32.15430748980978
    ],
    "SpawnPoint08": [
      23.814056176933452,
      30.45535563736511
    ],
    "SpawnPoint09": [
      21.544264638300174,
      29.985926011029413
    ],
    "SpawnPoint10": [
      20.32149174528302,
      28.58777743851078
    ],
    "SpawnPoint11": [
      18.84423593374399,
      30.57298630934495
    ],
    "SpawnPoint12": [
      16.854245334201387,
      33.04342854817708
    ],
    "SpawnPoint13": [
      13.772231706226146,
      34.875492008587784
    ],
    "SpawnPoint14": [
      16.108196953415426,
      36.7863633393355
    ],
    "SpawnPoint15": [
      14.798198499177632,
      38.91672877261513
    ],
    "SpawnPoint16": [
      19.06886735635807,
      38.40265754457853
    ],
    "SpawnPoint17": [
      17.09351348876953,
      25.76384735107422
    ],
    "SpawnPoint18": [
      15.996302365518575,
      24.441693474264707
    ],
    "SpawnPoint19": [
      16.679694045325068,
      23.215400102630696
    ],
    "SpawnPoint20": [
      18.413068606529706,
      22.129255883487655
    ],
    "SpawnPoint21": [
      19.82829444088153,
      24.69856831564832
    ],
    "SpawnPoint22": [
      21.160372173366834,
      26.342463047660175
    ],
    "SpawnPoint23": [
      24.421945701357465,
      24.891453531532804
    ],
    "SpawnPoint24": [
      21.064993470821324,
      22.969214359690202
    ],
    "SpawnPoint25": [
      21.46357477838467,
      20.753024825393982
    ],
    "SpawnPoint26": [
      19.75109798693783,
      19.329257864169975
    ],
    "SpawnPoint27": [
      15.947100522706835,
      20.252884020908272
    ],
    "SpawnPoint28": [
      24.141929719129553,
      21.30669399987348
    ],
    "SpawnPoint29": [
      28.496886837121213,
      20.768741122159092
    ],
    "SpawnPoint30": [
      31.031763736670616,
      18.391754295023695
    ],
    "SpawnPoint31": [
      32.92492177535077,
      19.835416832748724
    ],
    "SpawnPoint32": [
      17.210685320082323,
      11.733417901050693
    ],
    "SpawnPoint33": [
      18.027739189040492,
      10.201723770356514
    ],
    "SpawnPoint34": [
      18.274995546083193,
      11.228526347392314
    ],
    "SpawnPoint35": [
      21.22203650841346,
      10.866075143306213
    ],
    "SpawnPoint36": [
      23.80889946912092,
      12.491686178597924
    ],
    "SpawnPoint37": [
      24.697606419538122,
      11.431232530700147
    ],
    "SpawnPoint38": [
      24.646363740808823,
      9.40419921875
    ],
    "SpawnPoint39": [
      23.196276115648676,
      7.943895744554924
    ],
    "SpawnPoint40": [
      17.247945516978792,
      16.97786252679377
    ],
    "SpawnPoint41": [
      19.543614716731625,
      17.28680270392539
    ],
    "DisplayPoints": 31,
    "Version": 2
  },
  "Dalvags Final Flame": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      20.646766143033155,
      27.8838625672043
    ],
    "SpawnPoint02": [
      22.217703455822477,
      27.039736868892508
    ],
    "SpawnPoint03": [
      21.094420853505387,
      25.036636165914384
    ],
    "SpawnPoint04": [
      23.493385267158143,
      26.368967331028184
    ],
    "SpawnPoint05": [
      23.60938124118891,
      25.26845849976504
    ],
    "SpawnPoint06": [
      23.685661764705884,
      24.17696461397059
    ],
    "SpawnPoint07": [
      24.308555438701923,
      23.19714806189904
    ],
    "SpawnPoint08": [
      24.000747911159365,
      22.82674756006892
    ],
    "SpawnPoint09": [
      15.509586021548412,
      18.615485019371157
    ],
    "SpawnPoint10": [
      16.352309750372832,
      19.36952669553281
    ],
    "SpawnPoint11": [
      18.742872791342634,
      18.016308504403018
    ],
    "SpawnPoint12": [
      18.23891211475551,
      17.512841034636136
    ],
    "SpawnPoint13": [
      19.09037825324144,
      16.767931182338874
    ],
    "SpawnPoint14": [
      16.91361957177322,
      16.991645030722257
    ],
    "SpawnPoint15": [
      17.24027660472973,
      15.696889780405405
    ],
    "SpawnPoint16": [
      16.056276728191648,
      15.879137084382405
    ],
    "SpawnPoint17": [
      16.082201864080698,
      14.65471934796173
    ],
    "DisplayPoints": 17,
    "Version": 2
  },
  "Minhocao": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      20.646766143033155,
      27.8838625672043
    ],
    "SpawnPoint02": [
      22.217703455822477,
      27.039736868892508
    ],
    "SpawnPoint03": [
      21.094420853505387,
      25.036636165914384
    ],
    "SpawnPoint04": [
      23.493385267158143,
      26.368967331028184
    ],
    "SpawnPoint05": [
      23.60938124118891,
      25.26845849976504
    ],
    "SpawnPoint06": [
      23.685661764705884,
      24.17696461397059
    ],
    "SpawnPoint07": [
      24.308555438701923,
      23.19714806189904
    ],
    "SpawnPoint08": [
      24.000747911159365,
      22.82674756006892
    ],
    "SpawnPoint09": [
      15.509586021548412,
      18.615485019371157
    ],
    "SpawnPoint10": [
      16.352309750372832,
      19.36952669553281
    ],
    "SpawnPoint11": [
      18.742872791342634,
      18.016308504403018
    ],
    "SpawnPoint12": [
      18.23891211475551,
      17.512841034636136
    ],
    "SpawnPoint13": [
      19.09037825324144,
      16.767931182338874
    ],
    "SpawnPoint14": [
      16.91361957177322,
      16.991645030722257
    ],
    "SpawnPoint15": [
      17.24027660472973,
      15.696889780405405
    ],
    "SpawnPoint16": [
      16.056276728191648,
      15.879137084382405
    ],
    "SpawnPoint17": [
      16.082201864080698,
      14.65471934796173
    ],
    "DisplayPoints": 17,
    "Version": 2
  },
  "Vogaal Ja": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      16.575014304080018,
      7.88461905232821
    ],
    "SpawnPoint02": [
      18.67693674116998,
      9.497738600303531
    ],
    "SpawnPoint03": [
      15.933153000553098,
      10.85151388792865
    ],
    "SpawnPoint04": [
      17.176017967430322,
      11.49307498416385
    ],
    "SpawnPoint05": [
      13.925170097100656,
      11.669534027967725
    ],
    "SpawnPoint06": [
      13.876689017483736,
      13.608211474790892
    ],
    "SpawnPoint07": [
      15.125790088645143,
      15.354578418184326
    ],
    "SpawnPoint08": [
      16.374308346329176,
      14.033867599868042
    ],
    "SpawnPoint09": [
      20.357571807009442,
      13.711810633466749
    ],
    "SpawnPoint10": [
      20.736461981411637,
      15.568316439924569
    ],
    "SpawnPoint11": [
      18.73347873263889,
      16.377520978009258
    ],
    "SpawnPoint12": [
      17.124370146079883,
      17.141349235700197
    ],
    "SpawnPoint13": [
      18.459211235254084,
      18.199465992230035
    ],
    "SpawnPoint14": [
      23.328667031793163,
      17.002912713644264
    ],
    "SpawnPoint15": [
      21.03238620673785,
      18.223460224134758
    ],
    "SpawnPoint16": [
      19.239530512971697,
      20.362550670695754
    ],
    "SpawnPoint17": [
      20.27138437644142,
      21.333010515163746
    ],
    "SpawnPoint18": [
      19.908993820690327,
      22.45431637578003
    ],
    "SpawnPoint19": [
      23.872149456988325,
      19.725824041680976
    ],
    "SpawnPoint20": [
      24.439803371656208,
      20.93395238193527
    ],
    "SpawnPoint21": [
      22.940990508935702,
      22.55108935078874
    ],
    "SpawnPoint22": [
      25.131046408948194,
      23.447551541650313
    ],
    "SpawnPoint23": [
      23.28812619274809,
      24.165409559875954
    ],
    "SpawnPoint24": [
      18.384606020602494,
      13.319137784582756
    ],
    "DisplayPoints": 24,
    "Version": 2
  },
  "Croque-Mitaine": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      16.575014304080018,
      7.88461905232821
    ],
    "SpawnPoint02": [
      18.67693674116998,
      9.497738600303531
    ],
    "SpawnPoint03": [
      15.933153000553098,
      10.85151388792865
    ],
    "SpawnPoint04": [
      17.176017967430322,
      11.49307498416385
    ],
    "SpawnPoint05": [
      13.925170097100656,
      11.669534027967725
    ],
    "SpawnPoint06": [
      13.876689017483736,
      13.608211474790892
    ],
    "SpawnPoint07": [
      15.125790088645143,
      15.354578418184326
    ],
    "SpawnPoint08": [
      16.374308346329176,
      14.033867599868042
    ],
    "SpawnPoint09": [
      20.357571807009442,
      13.711810633466749
    ],
    "SpawnPoint10": [
      20.736461981411637,
      15.568316439924569
    ],
    "SpawnPoint11": [
      18.73347873263889,
      16.377520978009258
    ],
    "SpawnPoint12": [
      17.124370146079883,
      17.141349235700197
    ],
    "SpawnPoint13": [
      18.459211235254084,
      18.199465992230035
    ],
    "SpawnPoint14": [
      23.328667031793163,
      17.002912713644264
    ],
    "SpawnPoint15": [
      21.03238620673785,
      18.223460224134758
    ],
    "SpawnPoint16": [
      19.239530512971697,
      20.362550670695754
    ],
    "SpawnPoint17": [
      20.27138437644142,
      21.333010515163746
    ],
    "SpawnPoint18": [
      19.908993820690327,
      22.45431637578003
    ],
    "SpawnPoint19": [
      23.872149456988325,
      19.725824041680976
    ],
    "SpawnPoint20": [
      24.439803371656208,
      20.93395238193527
    ],
    "SpawnPoint21": [
      22.940990508935702,
      22.55108935078874
    ],
    "SpawnPoint22": [
      25.131046408948194,
      23.447551541650313
    ],
    "SpawnPoint23": [
      23.28812619274809,
      24.165409559875954
    ],
    "SpawnPoint24": [
      18.384606020602494,
      13.319137784582756
    ],
    "DisplayPoints": 24,
    "Version": 2
  },
  "Unktehi": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      22.718084927262932,
      34.53426808324353
    ],
    "SpawnPoint02": [
      23.161354403724467,
      36.008844135074376
    ],
    "SpawnPoint03": [
      22.93887471043786,
      38.29766703760901
    ],
    "SpawnPoint04": [
      20.062902589507452,
      37.5395224868067
    ],
    "SpawnPoint05": [
      19.25387894993011,
      35.479133386581466
    ],
    "SpawnPoint06": [
      16.888713853930994,
      35.34640461503111
    ],
    "SpawnPoint07": [
      20.651661306839102,
      35.58163213940538
    ],
    "SpawnPoint08": [
      20.051869632633586,
      34.38713770276718
    ],
    "SpawnPoint09": [
      20.266234923245612,
      32.038878323739034
    ],
    "SpawnPoint10": [
      24.25780597376215,
      26.549957164147933
    ],
    "SpawnPoint11": [
      26.461491081513554,
      26.34887518825301
    ],
    "SpawnPoint12": [
      25.92907429484578,
      24.86226727374188
    ],
    "SpawnPoint13": [
      25.110658143182544,
      22.445449243726024
    ],
    "SpawnPoint14": [
      28.680241265032798,
      20.516066303753643
    ],
    "SpawnPoint15": [
      28.576482116284012,
      18.23191366390306
    ],
    "SpawnPoint16": [
      29.077456172569885,
      16.94981746843234
    ],
    "SpawnPoint17": [
      30.12549540944343,
      14.321259124087591
    ],
    "SpawnPoint18": [
      31.526627068329905,
      16.424595711162553
    ],
    "SpawnPoint19": [
      32.9947068854944,
      16.005803987873133
    ],
    "SpawnPoint20": [
      33.48715064218524,
      17.529266489914978
    ],
    "DisplayPoints": 20,
    "Version": 2
  },
  "Croakadile": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      22.718084927262932,
      34.53426808324353
    ],
    "SpawnPoint02": [
      23.161354403724467,
      36.008844135074376
    ],
    "SpawnPoint03": [
      22.93887471043786,
      38.29766703760901
    ],
    "SpawnPoint04": [
      20.062902589507452,
      37.5395224868067
    ],
    "SpawnPoint05": [
      19.25387894993011,
      35.479133386581466
    ],
    "SpawnPoint06": [
      16.888713853930994,
      35.34640461503111
    ],
    "SpawnPoint07": [
      20.651661306839102,
      35.58163213940538
    ],
    "SpawnPoint08": [
      20.051869632633586,
      34.38713770276718
    ],
    "SpawnPoint09": [
      20.266234923245612,
      32.038878323739034
    ],
    "SpawnPoint10": [
      24.25780597376215,
      26.549957164147933
    ],
    "SpawnPoint11": [
      26.461491081513554,
      26.34887518825301
    ],
    "SpawnPoint12": [
      25.92907429484578,
      24.86226727374188
    ],
    "SpawnPoint13": [
      25.110658143182544,
      22.445449243726024
    ],
    "SpawnPoint14": [
      28.680241265032798,
      20.516066303753643
    ],
    "SpawnPoint15": [
      28.576482116284012,
      18.23191366390306
    ],
    "SpawnPoint16": [
      29.077456172569885,
      16.94981746843234
    ],
    "SpawnPoint17": [
      30.12549540944343,
      14.321259124087591
    ],
    "SpawnPoint18": [
      31.526627068329905,
      16.424595711162553
    ],
    "SpawnPoint19": [
      32.9947068854944,
      16.005803987873133
    ],
    "SpawnPoint20": [
      33.48715064218524,
      17.529266489914978
    ],
    "DisplayPoints": 20,
    "Version": 2
  },
  "Hellsclaw": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      21.52019241148532,
      28.32897675140328
    ],
    "SpawnPoint02": [
      17.89843480727252,
      27.527718936695774
    ],
    "SpawnPoint03": [
      18.292851782305064,
      26.239929787982316
    ],
    "SpawnPoint04": [
      20.294689324511868,
      25.224000705876723
    ],
    "SpawnPoint05": [
      22.22755665822072,
      25.198228362290862
    ],
    "SpawnPoint06": [
      25.559344951923077,
      20.42860508631993
    ],
    "SpawnPoint07": [
      25.406274767889492,
      21.869490913722824
    ],
    "SpawnPoint08": [
      28.66654398903918,
      20.646480731110074
    ],
    "SpawnPoint09": [
      29.727273920530912,
      21.431115591397848
    ],
    "SpawnPoint10": [
      28.98954216452206,
      19.328234145220588
    ],
    "SpawnPoint11": [
      30.431640625,
      19.2626953125
    ],
    "SpawnPoint12": [
      21.288538635501013,
      31.11695324361083
    ],
    "SpawnPoint13": [
      19.546340259153453,
      31.401675096198694
    ],
    "SpawnPoint14": [
      20.51530981845543,
      33.24461389760502
    ],
    "SpawnPoint15": [
      16.98100340921819,
      33.989389070900536
    ],
    "SpawnPoint16": [
      16.514146061533506,
      32.76092441204897
    ],
    "SpawnPoint17": [
      14.974244520399306,
      30.640195041232637
    ],
    "SpawnPoint18": [
      14.747745768229167,
      28.85055222284226
    ],
    "SpawnPoint19": [
      17.454426481631238,
      29.978475010108596
    ],
    "SpawnPoint20": [
      21.311882891778136,
      29.543197575530442
    ],
    "SpawnPoint21": [
      31.1043701171875,
      19.964469663558468
    ],
    "DisplayPoints": 21,
    "Version": 2
  },
  "The Garlok": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      32.05090760348136,
      27.24933717961897
    ],
    "SpawnPoint02": [
      30.729775371808767,
      26.707083935452793
    ],
    "SpawnPoint03": [
      30.13134178345146,
      25.024572628060568
    ],
    "SpawnPoint04": [
      27.63848876953125,
      25.0264404296875
    ],
    "SpawnPoint05": [
      28.16530942347852,
      26.562361323463605
    ],
    "SpawnPoint06": [
      28.421191634418808,
      28.02126875547605
    ],
    "SpawnPoint07": [
      26.69972855328857,
      30.920430333161157
    ],
    "SpawnPoint08": [
      26.71736149520649,
      33.15455613938053
    ],
    "SpawnPoint09": [
      26.005962325865966,
      33.855909967996986
    ],
    "SpawnPoint10": [
      28.934812999906995,
      35.66399710518973
    ],
    "SpawnPoint11": [
      31.795235872971976,
      36.27565421045354
    ],
    "SpawnPoint12": [
      21.288538635501013,
      31.11695324361083
    ],
    "SpawnPoint13": [
      19.546340259153453,
      31.401675096198694
    ],
    "SpawnPoint14": [
      20.51530981845543,
      33.24461389760502
    ],
    "SpawnPoint15": [
      16.98100340921819,
      33.989389070900536
    ],
    "SpawnPoint16": [
      16.514146061533506,
      32.76092441204897
    ],
    "SpawnPoint17": [
      14.974244520399306,
      30.640195041232637
    ],
    "SpawnPoint18": [
      14.747745768229167,
      28.85055222284226
    ],
    "SpawnPoint19": [
      17.454426481631238,
      29.978475010108596
    ],
    "SpawnPoint20": [
      21.311882891778136,
      29.543197575530442
    ],
    "SpawnPoint21": [
      21.52019241148532,
      28.32897675140328
    ],
    "SpawnPoint22": [
      17.89843480727252,
      27.527718936695774
    ],
    "SpawnPoint23": [
      18.292851782305064,
      26.239929787982316
    ],
    "SpawnPoint24": [
      20.294689324511868,
      25.224000705876723
    ],
    "SpawnPoint25": [
      22.22755665822072,
      25.198228362290862
    ],
    "SpawnPoint26": [
      25.559344951923077,
      20.42860508631993
    ],
    "SpawnPoint27": [
      25.406274767889492,
      21.869490913722824
    ],
    "SpawnPoint28": [
      28.66654398903918,
      20.646480731110074
    ],
    "SpawnPoint29": [
      29.727273920530912,
      21.431115591397848
    ],
    "SpawnPoint30": [
      28.98954216452206,
      19.328234145220588
    ],
    "SpawnPoint31": [
      30.431640625,
      19.2626953125
    ],
    "SpawnPoint32": [
      31.1043701171875,
      19.964469663558468
    ],
    "DisplayPoints": 25,
    "Version": 2
  },
  "Marberry": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      27.741551007643665,
      19.85550760145506
    ],
    "SpawnPoint02": [
      28.204145024896977,
      21.419261890453296
    ],
    "SpawnPoint03": [
      29.014552604166667,
      21.226314322916668
    ],
    "SpawnPoint04": [
      28.742162800948442,
      22.282241761916342
    ],
    "SpawnPoint05": [
      29.447684047466975,
      23.942722126588983
    ],
    "SpawnPoint06": [
      28.096840566293352,
      25.59908581331286
    ],
    "SpawnPoint07": [
      27.387875605288738,
      23.737124957247264
    ],
    "SpawnPoint08": [
      30.503476617056563,
      25.34396249781774
    ],
    "SpawnPoint09": [
      33.48904713733326,
      24.06426805427783
    ],
    "SpawnPoint10": [
      33.27516635913945,
      25.80643255339196
    ],
    "SpawnPoint11": [
      35.21753696491895,
      23.709896624873355
    ],
    "SpawnPoint12": [
      13.89693549633752,
      25.849266017408677
    ],
    "SpawnPoint13": [
      12.69029497527444,
      25.62906563923985
    ],
    "SpawnPoint14": [
      12.63341389227351,
      23.57171537533448
    ],
    "SpawnPoint15": [
      11.572663220093055,
      22.713202030770336
    ],
    "SpawnPoint16": [
      12.165763382847533,
      21.777526362808295
    ],
    "SpawnPoint17": [
      10.577165152522044,
      21.45082339211618
    ],
    "DisplayPoints": 17,
    "Version": 2
  },
  "Nandi": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      27.741551007643665,
      19.85550760145506
    ],
    "SpawnPoint02": [
      28.204145024896977,
      21.419261890453296
    ],
    "SpawnPoint03": [
      29.014552604166667,
      21.226314322916668
    ],
    "SpawnPoint04": [
      28.742162800948442,
      22.282241761916342
    ],
    "SpawnPoint05": [
      29.447684047466975,
      23.942722126588983
    ],
    "SpawnPoint06": [
      28.096840566293352,
      25.59908581331286
    ],
    "SpawnPoint07": [
      27.387875605288738,
      23.737124957247264
    ],
    "SpawnPoint08": [
      30.503476617056563,
      25.34396249781774
    ],
    "SpawnPoint09": [
      33.48904713733326,
      24.06426805427783
    ],
    "SpawnPoint10": [
      33.27516635913945,
      25.80643255339196
    ],
    "SpawnPoint11": [
      35.21753696491895,
      23.709896624873355
    ],
    "SpawnPoint12": [
      13.89693549633752,
      25.849266017408677
    ],
    "SpawnPoint13": [
      12.69029497527444,
      25.62906563923985
    ],
    "SpawnPoint14": [
      12.63341389227351,
      23.57171537533448
    ],
    "SpawnPoint15": [
      11.572663220093055,
      22.713202030770336
    ],
    "SpawnPoint16": [
      12.165763382847533,
      21.777526362808295
    ],
    "SpawnPoint17": [
      10.577165152522044,
      21.45082339211618
    ],
    "DisplayPoints": 17,
    "Version": 2
  },
  "Nahn": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      32.99882558990547,
      29.834632477426638
    ],
    "SpawnPoint02": [
      34.10023369153768,
      28.1201868480821
    ],
    "SpawnPoint03": [
      30.810718505194142,
      28.863532708191418
    ],
    "SpawnPoint04": [
      31.782355095358454,
      28.723223517922793
    ],
    "SpawnPoint05": [
      31.253890991210938,
      27.40823287963867
    ],
    "SpawnPoint06": [
      28.98211990567396,
      25.21651065668203
    ],
    "SpawnPoint07": [
      27.683584166910048,
      24.05179797021028
    ],
    "SpawnPoint08": [
      26.113758474156477,
      23.567723813264266
    ],
    "SpawnPoint09": [
      24.71663818359375,
      22.70839045597957
    ],
    "SpawnPoint10": [
      23.1708641998719,
      21.740428739381066
    ],
    "SpawnPoint11": [
      23.313494927060287,
      24.664082484962666
    ],
    "SpawnPoint12": [
      16.930820574916027,
      36.074334025611805
    ],
    "SpawnPoint13": [
      16.166334885817307,
      34.43891703999126
    ],
    "SpawnPoint14": [
      13.917396345388577,
      35.15152080407304
    ],
    "SpawnPoint15": [
      19.835648970170453,
      21.977125107359935
    ],
    "SpawnPoint16": [
      17.0980175216763,
      19.397640632526493
    ],
    "SpawnPoint17": [
      19.609217605311354,
      19.534859346382785
    ],
    "SpawnPoint18": [
      20.2291468836326,
      18.37402208865677
    ],
    "SpawnPoint19": [
      16.10870845734127,
      14.515549217372135
    ],
    "SpawnPoint20": [
      14.564107476012794,
      14.260954574227078
    ],
    "SpawnPoint21": [
      12.890114167607932,
      14.585543345256024
    ],
    "SpawnPoint22": [
      14.23590867634912,
      15.831503533406755
    ],
    "SpawnPoint23": [
      14.39906853759311,
      17.036688380179235
    ],
    "DisplayPoints": 23,
    "Version": 2
  },
  "Bonnacon": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      32.99882558990547,
      29.834632477426638
    ],
    "SpawnPoint02": [
      34.10023369153768,
      28.1201868480821
    ],
    "SpawnPoint03": [
      30.810718505194142,
      28.863532708191418
    ],
    "SpawnPoint04": [
      31.782355095358454,
      28.723223517922793
    ],
    "SpawnPoint05": [
      31.253890991210938,
      27.40823287963867
    ],
    "SpawnPoint06": [
      28.98211990567396,
      25.21651065668203
    ],
    "SpawnPoint07": [
      27.683584166910048,
      24.05179797021028
    ],
    "SpawnPoint08": [
      26.113758474156477,
      23.567723813264266
    ],
    "SpawnPoint09": [
      24.71663818359375,
      22.70839045597957
    ],
    "SpawnPoint10": [
      23.1708641998719,
      21.740428739381066
    ],
    "SpawnPoint11": [
      23.313494927060287,
      24.664082484962666
    ],
    "SpawnPoint12": [
      16.930820574916027,
      36.074334025611805
    ],
    "SpawnPoint13": [
      16.166334885817307,
      34.43891703999126
    ],
    "SpawnPoint14": [
      13.917396345388577,
      35.15152080407304
    ],
    "SpawnPoint15": [
      19.835648970170453,
      21.977125107359935
    ],
    "SpawnPoint16": [
      17.0980175216763,
      19.397640632526493
    ],
    "SpawnPoint17": [
      19.609217605311354,
      19.534859346382785
    ],
    "SpawnPoint18": [
      20.2291468836326,
      18.37402208865677
    ],
    "SpawnPoint19": [
      16.10870845734127,
      14.515549217372135
    ],
    "SpawnPoint20": [
      14.564107476012794,
      14.260954574227078
    ],
    "SpawnPoint21": [
      12.890114167607932,
      14.585543345256024
    ],
    "SpawnPoint22": [
      14.23590867634912,
      15.831503533406755
    ],
    "SpawnPoint23": [
      14.39906853759311,
      17.036688380179235
    ],
    "DisplayPoints": 14,
    "Version": 2
  },
  "Cornu": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      15.61313940293296,
      10.326137777147347
    ],
    "SpawnPoint02": [
      15.380166295874725,
      12.648141082022628
    ],
    "SpawnPoint03": [
      14.100838630822054,
      14.012217138982882
    ],
    "SpawnPoint04": [
      15.287794555483751,
      14.838079590564993
    ],
    "SpawnPoint05": [
      13.554100251677852,
      16.238168519295304
    ],
    "SpawnPoint06": [
      14.767376097896562,
      16.84131742875452
    ],
    "SpawnPoint07": [
      15.133442057291667,
      18.02284765625
    ],
    "SpawnPoint08": [
      16.491912090132388,
      17.81803345327894
    ],
    "SpawnPoint09": [
      18.951494898231033,
      15.153535522565004
    ],
    "SpawnPoint10": [
      21.41068993829618,
      14.575613928144904
    ],
    "SpawnPoint11": [
      23.016722447663835,
      13.278775409587379
    ],
    "SpawnPoint12": [
      22.94506144248426,
      15.170404007902675
    ],
    "SpawnPoint13": [
      23.01511564619297,
      16.244663224414303
    ],
    "SpawnPoint14": [
      24.641325577445652,
      16.18188153503072
    ],
    "SpawnPoint15": [
      21.501689219284494,
      6.886449833416477
    ],
    "SpawnPoint16": [
      22.49924701336393,
      6.858674743520519
    ],
    "SpawnPoint17": [
      21.76171740301724,
      8.350315867456896
    ],
    "SpawnPoint18": [
      21.817464771514935,
      9.499643686655405
    ],
    "SpawnPoint19": [
      21.951484606263456,
      10.313930679830463
    ],
    "SpawnPoint20": [
      23.670340958707584,
      9.292161770209582
    ],
    "SpawnPoint21": [
      24.537638346354168,
      7.371134440104167
    ],
    "SpawnPoint22": [
      26.942724609375,
      5.26591796875
    ],
    "SpawnPoint23": [
      27.44411348590547,
      7.209885303957859
    ],
    "DisplayPoints": 23,
    "Version": 2
  },
  "Chernobog": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      15.61313940293296,
      10.326137777147347
    ],
    "SpawnPoint02": [
      15.380166295874725,
      12.648141082022628
    ],
    "SpawnPoint03": [
      14.100838630822054,
      14.012217138982882
    ],
    "SpawnPoint04": [
      15.287794555483751,
      14.838079590564993
    ],
    "SpawnPoint05": [
      13.554100251677852,
      16.238168519295304
    ],
    "SpawnPoint06": [
      14.767376097896562,
      16.84131742875452
    ],
    "SpawnPoint07": [
      15.133442057291667,
      18.02284765625
    ],
    "SpawnPoint08": [
      16.491912090132388,
      17.81803345327894
    ],
    "SpawnPoint09": [
      18.951494898231033,
      15.153535522565004
    ],
    "SpawnPoint10": [
      21.41068993829618,
      14.575613928144904
    ],
    "SpawnPoint11": [
      23.016722447663835,
      13.278775409587379
    ],
    "SpawnPoint12": [
      22.94506144248426,
      15.170404007902675
    ],
    "SpawnPoint13": [
      23.01511564619297,
      16.244663224414303
    ],
    "SpawnPoint14": [
      24.641325577445652,
      16.18188153503072
    ],
    "SpawnPoint15": [
      21.501689219284494,
      6.886449833416477
    ],
    "SpawnPoint16": [
      22.49924701336393,
      6.858674743520519
    ],
    "SpawnPoint17": [
      21.76171740301724,
      8.350315867456896
    ],
    "SpawnPoint18": [
      21.817464771514935,
      9.499643686655405
    ],
    "SpawnPoint19": [
      21.951484606263456,
      10.313930679830463
    ],
    "SpawnPoint20": [
      23.670340958707584,
      9.292161770209582
    ],
    "SpawnPoint21": [
      24.537638346354168,
      7.371134440104167
    ],
    "SpawnPoint22": [
      26.942724609375,
      5.26591796875
    ],
    "SpawnPoint23": [
      27.44411348590547,
      7.209885303957859
    ],
    "DisplayPoints": 23,
    "Version": 2
  },
  "Marraco": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      4.156829833984375,
      16.622116088867188
    ],
    "SpawnPoint02": [
      5.2852716619318185,
      15.996448863636363
    ],
    "SpawnPoint03": [
      5.575325801454741,
      18.088568325700432
    ],
    "SpawnPoint04": [
      6.459670770363729,
      19.805724097079917
    ],
    "SpawnPoint05": [
      8.411862664473684,
      21.081064967105263
    ],
    "SpawnPoint06": [
      10.519533251152664,
      18.24608774654201
    ],
    "SpawnPoint07": [
      11.562001142260831,
      19.644326771209386
    ],
    "SpawnPoint08": [
      8.810207950367648,
      14.832671760110294
    ],
    "SpawnPoint09": [
      8.892731672562894,
      13.53764064956761
    ],
    "SpawnPoint10": [
      8.98463920697774,
      11.90461124785959
    ],
    "SpawnPoint11": [
      10.865716294279316,
      12.881559191775244
    ],
    "SpawnPoint12": [
      11.77457771720467,
      14.301328554258241
    ],
    "SpawnPoint13": [
      14.382222493489584,
      18.0486328125
    ],
    "SpawnPoint14": [
      15.452748891469595,
      18.56240432326858
    ],
    "SpawnPoint15": [
      14.313068680973895,
      19.77229033320783
    ],
    "SpawnPoint16": [
      16.866325737166928,
      20.443044058581506
    ],
    "SpawnPoint17": [
      17.55862487549801,
      18.817622089765937
    ],
    "SpawnPoint18": [
      19.558968379579742,
      18.65739914466595
    ],
    "SpawnPoint19": [
      20.5740966796875,
      16.209302629743302
    ],
    "SpawnPoint20": [
      21.620970149984334,
      18.869965440946114
    ],
    "SpawnPoint21": [
      6.621421813964844,
      28.698741912841797
    ],
    "SpawnPoint22": [
      8.990482159514926,
      27.262537410603233
    ],
    "SpawnPoint23": [
      10.99670940896739,
      27.292694887907608
    ],
    "SpawnPoint24": [
      11.083674208922956,
      28.99372604658019
    ],
    "SpawnPoint25": [
      12.695219494047619,
      28.06524109209656
    ],
    "SpawnPoint26": [
      13.424394087357955,
      25.25621004971591
    ],
    "SpawnPoint27": [
      14.870423312014248,
      25.603588993685232
    ],
    "SpawnPoint28": [
      15.787474423363095,
      27.44418015252976
    ],
    "SpawnPoint29": [
      15.565218372505251,
      28.769024504332982
    ],
    "SpawnPoint30": [
      17.723332990679825,
      29.33367770010965
    ],
    "SpawnPoint31": [
      19.77053886925795,
      29.200869934297703
    ],
    "SpawnPoint32": [
      21.41625295692231,
      28.219862736553786
    ],
    "SpawnPoint33": [
      23.485573508522727,
      26.842455553519063
    ],
    "SpawnPoint34": [
      25.66577357104701,
      25.66791240985577
    ],
    "SpawnPoint35": [
      24.611162488957596,
      23.78696616883834
    ],
    "SpawnPoint36": [
      24.19166892275165,
      21.721271980713695
    ],
    "SpawnPoint37": [
      24.62375217013889,
      19.99582248263889
    ],
    "SpawnPoint38": [
      27.161029805222604,
      20.620182737585615
    ],
    "SpawnPoint39": [
      27.456779233870968,
      22.131385216346153
    ],
    "SpawnPoint40": [
      28.089146205357142,
      25.663434709821427
    ],
    "SpawnPoint41": [
      29.311491935483872,
      27.697917716733873
    ],
    "SpawnPoint42": [
      30.323910706795303,
      29.889930264261746
    ],
    "SpawnPoint43": [
      30.75452330872253,
      31.060316148695055
    ],
    "SpawnPoint44": [
      22.94298673930921,
      8.044240851151315
    ],
    "SpawnPoint45": [
      24.724117702907986,
      9.141832139756945
    ],
    "SpawnPoint46": [
      25.342817190300178,
      11.231989597334229
    ],
    "SpawnPoint47": [
      24.74934088756887,
      13.554893304493802
    ],
    "SpawnPoint48": [
      27.090361074000928,
      13.449915776022305
    ],
    "SpawnPoint49": [
      28.393932995495497,
      14.848706128003004
    ],
    "SpawnPoint50": [
      28.146006092683447,
      11.416852202431741
    ],
    "SpawnPoint51": [
      28.9291015625,
      7.8001953125
    ],
    "SpawnPoint52": [
      32.064306640625,
      7.06241455078125
    ],
    "SpawnPoint53": [
      30.227281544306507,
      10.640364137414384
    ],
    "SpawnPoint54": [
      30.23359099911972,
      12.592083998129402
    ],
    "SpawnPoint55": [
      31.521380009541986,
      14.297247733778626
    ],
    "SpawnPoint56": [
      35.03102150941506,
      15.335267678285257
    ],
    "SpawnPoint57": [
      31.956169727585078,
      16.96741042212042
    ],
    "SpawnPoint58": [
      32.837656873337764,
      23.68521702543218
    ],
    "SpawnPoint59": [
      34.824891786317565,
      22.80668021537162
    ],
    "SpawnPoint60": [
      35.921327652469756,
      23.063541535408266
    ],
    "DisplayPoints": 60,
    "Version": 2
  },
  "Safat": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      4.156829833984375,
      16.622116088867188
    ],
    "SpawnPoint02": [
      5.2852716619318185,
      15.996448863636363
    ],
    "SpawnPoint03": [
      5.575325801454741,
      18.088568325700432
    ],
    "SpawnPoint04": [
      6.459670770363729,
      19.805724097079917
    ],
    "SpawnPoint05": [
      8.411862664473684,
      21.081064967105263
    ],
    "SpawnPoint06": [
      10.519533251152664,
      18.24608774654201
    ],
    "SpawnPoint07": [
      11.562001142260831,
      19.644326771209386
    ],
    "SpawnPoint08": [
      8.810207950367648,
      14.832671760110294
    ],
    "SpawnPoint09": [
      8.892731672562894,
      13.53764064956761
    ],
    "SpawnPoint10": [
      8.98463920697774,
      11.90461124785959
    ],
    "SpawnPoint11": [
      10.865716294279316,
      12.881559191775244
    ],
    "SpawnPoint12": [
      11.77457771720467,
      14.301328554258241
    ],
    "SpawnPoint13": [
      14.382222493489584,
      18.0486328125
    ],
    "SpawnPoint14": [
      15.452748891469595,
      18.56240432326858
    ],
    "SpawnPoint15": [
      14.313068680973895,
      19.77229033320783
    ],
    "SpawnPoint16": [
      16.866325737166928,
      20.443044058581506
    ],
    "SpawnPoint17": [
      17.55862487549801,
      18.817622089765937
    ],
    "SpawnPoint18": [
      19.558968379579742,
      18.65739914466595
    ],
    "SpawnPoint19": [
      20.5740966796875,
      16.209302629743302
    ],
    "SpawnPoint20": [
      21.620970149984334,
      18.869965440946114
    ],
    "SpawnPoint21": [
      6.621421813964844,
      28.698741912841797
    ],
    "SpawnPoint22": [
      8.990482159514926,
      27.262537410603233
    ],
    "SpawnPoint23": [
      10.99670940896739,
      27.292694887907608
    ],
    "SpawnPoint24": [
      11.083674208922956,
      28.99372604658019
    ],
    "SpawnPoint25": [
      12.695219494047619,
      28.06524109209656
    ],
    "SpawnPoint26": [
      13.424394087357955,
      25.25621004971591
    ],
    "SpawnPoint27": [
      14.870423312014248,
      25.603588993685232
    ],
    "SpawnPoint28": [
      15.787474423363095,
      27.44418015252976
    ],
    "SpawnPoint29": [
      15.565218372505251,
      28.769024504332982
    ],
    "SpawnPoint30": [
      17.723332990679825,
      29.33367770010965
    ],
    "SpawnPoint31": [
      19.77053886925795,
      29.200869934297703
    ],
    "SpawnPoint32": [
      21.41625295692231,
      28.219862736553786
    ],
    "SpawnPoint33": [
      23.485573508522727,
      26.842455553519063
    ],
    "SpawnPoint34": [
      25.66577357104701,
      25.66791240985577
    ],
    "SpawnPoint35": [
      24.611162488957596,
      23.78696616883834
    ],
    "SpawnPoint36": [
      24.19166892275165,
      21.721271980713695
    ],
    "SpawnPoint37": [
      24.62375217013889,
      19.99582248263889
    ],
    "SpawnPoint38": [
      27.161029805222604,
      20.620182737585615
    ],
    "SpawnPoint39": [
      27.456779233870968,
      22.131385216346153
    ],
    "SpawnPoint40": [
      28.089146205357142,
      25.663434709821427
    ],
    "SpawnPoint41": [
      29.311491935483872,
      27.697917716733873
    ],
    "SpawnPoint42": [
      30.323910706795303,
      29.889930264261746
    ],
    "SpawnPoint43": [
      30.75452330872253,
      31.060316148695055
    ],
    "SpawnPoint44": [
      22.94298673930921,
      8.044240851151315
    ],
    "SpawnPoint45": [
      24.724117702907986,
      9.141832139756945
    ],
    "SpawnPoint46": [
      25.342817190300178,
      11.231989597334229
    ],
    "SpawnPoint47": [
      24.74934088756887,
      13.554893304493802
    ],
    "SpawnPoint48": [
      27.090361074000928,
      13.449915776022305
    ],
    "SpawnPoint49": [
      28.393932995495497,
      14.848706128003004
    ],
    "SpawnPoint50": [
      28.146006092683447,
      11.416852202431741
    ],
    "SpawnPoint51": [
      28.9291015625,
      7.8001953125
    ],
    "SpawnPoint52": [
      32.064306640625,
      7.06241455078125
    ],
    "SpawnPoint53": [
      30.227281544306507,
      10.640364137414384
    ],
    "SpawnPoint54": [
      30.23359099911972,
      12.592083998129402
    ],
    "SpawnPoint55": [
      31.521380009541986,
      14.297247733778626
    ],
    "SpawnPoint56": [
      35.03102150941506,
      15.335267678285257
    ],
    "SpawnPoint57": [
      31.956169727585078,
      16.96741042212042
    ],
    "SpawnPoint58": [
      32.837656873337764,
      23.68521702543218
    ],
    "SpawnPoint59": [
      34.824891786317565,
      22.80668021537162
    ],
    "SpawnPoint60": [
      35.921327652469756,
      23.063541535408266
    ],
    "DisplayPoints": 60,
    "Version": 2
  },
  "Kurrea": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      19.181598189722067,
      8.09165909812359
    ],
    "SpawnPoint02": [
      16.07952654803241,
      10.450302428329772
    ],
    "SpawnPoint03": [
      15.759028682002315,
      11.807210286458334
    ],
    "SpawnPoint04": [
      13.986675609241832,
      10.519685225053268
    ],
    "SpawnPoint05": [
      13.669557309214959,
      12.199066079851752
    ],
    "SpawnPoint06": [
      11.865715742533187,
      11.82312551852876
    ],
    "SpawnPoint07": [
      12.712702537069516,
      13.785458311742666
    ],
    "SpawnPoint08": [
      14.200669866967871,
      13.896785710550535
    ],
    "SpawnPoint09": [
      16.434092593074045,
      15.578649028962147
    ],
    "SpawnPoint10": [
      16.884868061625873,
      17.435288507065852
    ],
    "SpawnPoint11": [
      29.133222121960486,
      7.702648591850304
    ],
    "SpawnPoint12": [
      26.825806595896946,
      8.522510790642892
    ],
    "SpawnPoint13": [
      27.21720715605032,
      8.890509559704473
    ],
    "SpawnPoint14": [
      27.454095911096644,
      10.539955421730324
    ],
    "SpawnPoint15": [
      23.18567738862837,
      10.651995713657678
    ],
    "SpawnPoint16": [
      24.926948170477303,
      10.6456186373498
    ],
    "SpawnPoint17": [
      23.453869960819485,
      12.207520268834969
    ],
    "SpawnPoint18": [
      25.160450291895604,
      12.73932112809066
    ],
    "SpawnPoint19": [
      26.751831304320042,
      12.655624920117587
    ],
    "SpawnPoint20": [
      28.245107724685077,
      13.556384498142766
    ],
    "SpawnPoint21": [
      29.004949020127118,
      14.792809249807396
    ],
    "SpawnPoint22": [
      30.152983288298874,
      15.033450569783835
    ],
    "SpawnPoint23": [
      32.49609085648148,
      14.40107421875
    ],
    "SpawnPoint24": [
      33.58388756670586,
      12.057966262662807
    ],
    "SpawnPoint25": [
      31.520720622730025,
      11.347140776331718
    ],
    "SpawnPoint26": [
      32.361850059719615,
      10.207930715460526
    ],
    "SpawnPoint27": [
      32.504054344093404,
      8.622557520604396
    ],
    "SpawnPoint28": [
      32.29985211437638,
      6.841195415976821
    ],
    "SpawnPoint29": [
      30.59478105340536,
      6.40365260017779
    ],
    "SpawnPoint30": [
      29.08427079696229,
      6.529044096252328
    ],
    "DisplayPoints": 30,
    "Version": 2
  },
  "Agrippa the Mighty": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      19.181598189722067,
      8.09165909812359
    ],
    "SpawnPoint02": [
      16.07952654803241,
      10.450302428329772
    ],
    "SpawnPoint03": [
      15.759028682002315,
      11.807210286458334
    ],
    "SpawnPoint04": [
      13.986675609241832,
      10.519685225053268
    ],
    "SpawnPoint05": [
      13.669557309214959,
      12.199066079851752
    ],
    "SpawnPoint06": [
      11.865715742533187,
      11.82312551852876
    ],
    "SpawnPoint07": [
      12.712702537069516,
      13.785458311742666
    ],
    "SpawnPoint08": [
      14.200669866967871,
      13.896785710550535
    ],
    "SpawnPoint09": [
      16.434092593074045,
      15.578649028962147
    ],
    "SpawnPoint10": [
      16.884868061625873,
      17.435288507065852
    ],
    "SpawnPoint11": [
      9.819513494318182,
      13.934437144886363
    ],
    "SpawnPoint12": [
      10.620749733664773,
      15.190207741477273
    ],
    "SpawnPoint13": [
      12.604893275669642,
      16.01655505952381
    ],
    "SpawnPoint14": [
      12.234882429534315,
      16.935350605085784
    ],
    "SpawnPoint15": [
      23.18567738862837,
      10.651995713657678
    ],
    "SpawnPoint16": [
      24.926948170477303,
      10.6456186373498
    ],
    "SpawnPoint17": [
      23.453869960819485,
      12.207520268834969
    ],
    "SpawnPoint18": [
      25.160450291895604,
      12.73932112809066
    ],
    "SpawnPoint19": [
      26.751831304320042,
      12.655624920117587
    ],
    "SpawnPoint20": [
      28.245107724685077,
      13.556384498142766
    ],
    "SpawnPoint21": [
      29.004949020127118,
      14.792809249807396
    ],
    "SpawnPoint22": [
      30.152983288298874,
      15.033450569783835
    ],
    "SpawnPoint23": [
      32.49609085648148,
      14.40107421875
    ],
    "SpawnPoint24": [
      33.58388756670586,
      12.057966262662807
    ],
    "SpawnPoint25": [
      31.520720622730025,
      11.347140776331718
    ],
    "SpawnPoint26": [
      32.361850059719615,
      10.207930715460526
    ],
    "SpawnPoint27": [
      32.504054344093404,
      8.622557520604396
    ],
    "SpawnPoint28": [
      32.29985211437638,
      6.841195415976821
    ],
    "SpawnPoint29": [
      30.59478105340536,
      6.40365260017779
    ],
    "SpawnPoint30": [
      29.08427079696229,
      6.529044096252328
    ],
    "SpawnPoint31": [
      29.133222121960486,
      7.702648591850304
    ],
    "SpawnPoint32": [
      26.825806595896946,
      8.522510790642892
    ],
    "SpawnPoint33": [
      27.21720715605032,
      8.890509559704473
    ],
    "SpawnPoint34": [
      27.454095911096644,
      10.539955421730324
    ],
    "DisplayPoints": 14,
    "Version": 2
  },
  "Mirka": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      34.02536050238578,
      20.82723838404607
    ],
    "SpawnPoint02": [
      32.464393926505906,
      17.683172011300925
    ],
    "SpawnPoint03": [
      36.27233530195329,
      12.579577049234159
    ],
    "SpawnPoint04": [
      35.72703793174337,
      9.546923988743833
    ],
    "SpawnPoint05": [
      28.061287143034175,
      7.941516505589267
    ],
    "SpawnPoint06": [
      26.13131583689828,
      11.537801453910472
    ],
    "SpawnPoint07": [
      29.258665049160314,
      13.081248649809181
    ],
    "SpawnPoint08": [
      27.3453622351487,
      15.895132455737526
    ],
    "SpawnPoint09": [
      22.075291439821072,
      20.92823881979046
    ],
    "SpawnPoint10": [
      21.981277729389124,
      17.651003371393422
    ],
    "SpawnPoint11": [
      18.101172813329136,
      18.915845908810464
    ],
    "SpawnPoint12": [
      18.1601470325395,
      13.184009603983826
    ],
    "SpawnPoint13": [
      15.228791713281725,
      12.096720805921215
    ],
    "SpawnPoint14": [
      10.089751047049685,
      13.223053391633613
    ],
    "SpawnPoint15": [
      6.697657321528155,
      12.466653307889953
    ],
    "SpawnPoint16": [
      11.61375200919562,
      17.380981445312756
    ],
    "SpawnPoint17": [
      17.42050486788315,
      25.79466789439685
    ],
    "SpawnPoint18": [
      16.561889568128183,
      29.70796500758131
    ],
    "SpawnPoint19": [
      33.973911315151454,
      22.44442947787261
    ],
    "SpawnPoint20": [
      27.946645420674294,
      21.474410218705923
    ],
    "SpawnPoint21": [
      18.71333696073457,
      31.42371434040353
    ],
    "SpawnPoint22": [
      23.65525165867488,
      28.22520757303088
    ],
    "SpawnPoint23": [
      25.176058600264927,
      32.41627363313776
    ],
    "SpawnPoint24": [
      30.000356367598783,
      26.76188365696951
    ],
    "DisplayPoints": 24,
    "Version": 2
  },
  "Lyuba": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      34.02536050238578,
      20.82723838404607
    ],
    "SpawnPoint02": [
      32.464393926505906,
      17.683172011300925
    ],
    "SpawnPoint03": [
      36.27233530195329,
      12.579577049234159
    ],
    "SpawnPoint04": [
      35.72703793174337,
      9.546923988743833
    ],
    "SpawnPoint05": [
      28.061287143034175,
      7.941516505589267
    ],
    "SpawnPoint06": [
      26.13131583689828,
      11.537801453910472
    ],
    "SpawnPoint07": [
      29.258665049160314,
      13.081248649809181
    ],
    "SpawnPoint08": [
      27.3453622351487,
      15.895132455737526
    ],
    "SpawnPoint09": [
      22.075291439821072,
      20.92823881979046
    ],
    "SpawnPoint10": [
      21.981277729389124,
      17.651003371393422
    ],
    "SpawnPoint11": [
      18.101172813329136,
      18.915845908810464
    ],
    "SpawnPoint12": [
      18.1601470325395,
      13.184009603983826
    ],
    "SpawnPoint13": [
      15.228791713281725,
      12.096720805921215
    ],
    "SpawnPoint14": [
      10.089751047049685,
      13.223053391633613
    ],
    "SpawnPoint15": [
      6.697657321528155,
      12.466653307889953
    ],
    "SpawnPoint16": [
      11.61375200919562,
      17.380981445312756
    ],
    "SpawnPoint17": [
      17.42050486788315,
      25.79466789439685
    ],
    "SpawnPoint18": [
      16.561889568128183,
      29.70796500758131
    ],
    "SpawnPoint19": [
      33.973911315151454,
      22.44442947787261
    ],
    "SpawnPoint20": [
      27.946645420674294,
      21.474410218705923
    ],
    "SpawnPoint21": [
      18.71333696073457,
      31.42371434040353
    ],
    "SpawnPoint22": [
      23.65525165867488,
      28.22520757303088
    ],
    "SpawnPoint23": [
      25.176058600264927,
      32.41627363313776
    ],
    "SpawnPoint24": [
      30.000356367598783,
      26.76188365696951
    ],
    "DisplayPoints": 24,
    "Version": 2
  },
  "Kaiser Behemoth": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      34.02536050238578,
      20.82723838404607
    ],
    "SpawnPoint02": [
      32.464393926505906,
      17.683172011300925
    ],
    "SpawnPoint03": [
      36.27233530195329,
      12.579577049234159
    ],
    "SpawnPoint04": [
      35.72703793174337,
      9.546923988743833
    ],
    "SpawnPoint05": [
      28.061287143034175,
      7.941516505589267
    ],
    "SpawnPoint06": [
      26.13131583689828,
      11.537801453910472
    ],
    "SpawnPoint07": [
      29.258665049160314,
      13.081248649809181
    ],
    "SpawnPoint08": [
      27.3453622351487,
      15.895132455737526
    ],
    "SpawnPoint09": [
      22.075291439821072,
      20.92823881979046
    ],
    "SpawnPoint10": [
      21.981277729389124,
      17.651003371393422
    ],
    "SpawnPoint11": [
      18.101172813329136,
      18.915845908810464
    ],
    "SpawnPoint12": [
      18.1601470325395,
      13.184009603983826
    ],
    "SpawnPoint13": [
      15.228791713281725,
      12.096720805921215
    ],
    "SpawnPoint14": [
      10.089751047049685,
      13.223053391633613
    ],
    "SpawnPoint15": [
      6.697657321528155,
      12.466653307889953
    ],
    "SpawnPoint16": [
      11.61375200919562,
      17.380981445312756
    ],
    "SpawnPoint17": [
      17.42050486788315,
      25.79466789439685
    ],
    "SpawnPoint18": [
      16.561889568128183,
      29.70796500758131
    ],
    "SpawnPoint19": [
      33.973911315151454,
      22.44442947787261
    ],
    "SpawnPoint20": [
      27.946645420674294,
      21.474410218705923
    ],
    "SpawnPoint21": [
      18.71333696073457,
      31.42371434040353
    ],
    "SpawnPoint22": [
      23.65525165867488,
      28.22520757303088
    ],
    "SpawnPoint23": [
      25.176058600264927,
      32.41627363313776
    ],
    "SpawnPoint24": [
      30.000356367598783,
      26.76188365696951
    ],
    "DisplayPoints": 18,
    "Version": 2
  },
  "Pylraster": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      16.920373587346116,
      10.928533491933054
    ],
    "SpawnPoint02": [
      13.63263060202044,
      14.714750158203953
    ],
    "SpawnPoint03": [
      21.232349632400357,
      20.1201477737533
    ],
    "SpawnPoint04": [
      12.42753075534415,
      27.638299339360795
    ],
    "SpawnPoint05": [
      10.345661909601628,
      35.041095631361245
    ],
    "SpawnPoint06": [
      14.217274280217406,
      31.519461630232623
    ],
    "SpawnPoint07": [
      27.353982651758244,
      37.2898062752061
    ],
    "SpawnPoint08": [
      17.916463821376425,
      38.68648268920029
    ],
    "SpawnPoint09": [
      21.092018999591183,
      33.9675103909125
    ],
    "SpawnPoint10": [
      20.104914312024814,
      30.231120749837505
    ],
    "SpawnPoint11": [
      25.070134636305713,
      32.503923469744755
    ],
    "SpawnPoint12": [
      27.76117460648263,
      31.13298069915166
    ],
    "SpawnPoint13": [
      25.726718504120512,
      24.828659643416216
    ],
    "SpawnPoint14": [
      28.97243146456992,
      21.943162123125862
    ],
    "SpawnPoint15": [
      33.79157977352046,
      8.481905813589727
    ],
    "SpawnPoint16": [
      26.625247919891684,
      7.647428160951138
    ],
    "DisplayPoints": 16,
    "Version": 2
  },
  "Lord of the Wyverns": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      16.920373587346116,
      10.928533491933054
    ],
    "SpawnPoint02": [
      13.63263060202044,
      14.714750158203953
    ],
    "SpawnPoint03": [
      21.232349632400357,
      20.1201477737533
    ],
    "SpawnPoint04": [
      12.42753075534415,
      27.638299339360795
    ],
    "SpawnPoint05": [
      10.345661909601628,
      35.041095631361245
    ],
    "SpawnPoint06": [
      14.217274280217406,
      31.519461630232623
    ],
    "SpawnPoint07": [
      27.353982651758244,
      37.2898062752061
    ],
    "SpawnPoint08": [
      17.916463821376425,
      38.68648268920029
    ],
    "SpawnPoint09": [
      21.092018999591183,
      33.9675103909125
    ],
    "SpawnPoint10": [
      20.104914312024814,
      30.231120749837505
    ],
    "SpawnPoint11": [
      25.070134636305713,
      32.503923469744755
    ],
    "SpawnPoint12": [
      27.76117460648263,
      31.13298069915166
    ],
    "SpawnPoint13": [
      25.726718504120512,
      24.828659643416216
    ],
    "SpawnPoint14": [
      28.97243146456992,
      21.943162123125862
    ],
    "SpawnPoint15": [
      33.79157977352046,
      8.481905813589727
    ],
    "SpawnPoint16": [
      26.625247919891684,
      7.647428160951138
    ],
    "DisplayPoints": 16,
    "Version": 2
  },
  "Senmurv": {
      "Dimensions": [
          43,
          43
      ],
      "SpawnPoint01": [
          16.952588022599386,
          10.919635922488517
      ],
      "SpawnPoint02": [
          13.423381933731452,
          14.712710145883307
      ],
      "SpawnPoint03": [
          21.14396964343049,
          20.222240542465947
      ],
      "SpawnPoint04": [
          12.437779909134687,
          27.534006823045104
      ],
      "SpawnPoint05": [
          10.346139896595004,
          35.23150708501599
      ],
      "SpawnPoint06": [
          14.209701141940107,
          31.476919372730784
      ],
      "SpawnPoint07": [
          17.942771346324676,
          38.693007045430676
      ],
      "SpawnPoint08": [
          21.155048644794448,
          33.89673816012087
      ],
      "SpawnPoint09": [
          20.079692518829713,
          30.26730646760284
      ],
      "SpawnPoint10": [
          25.070134636305713,
          32.503923469744755
      ],
      "SpawnPoint11": [
          26.882639608183627,
          31.68835121556016
      ],
      "SpawnPoint12": [
          34.61794545387832,
          28.552153358682542
      ],
      "SpawnPoint13": [
          38.665740772240405,
          25.8980420945099
      ],
      "SpawnPoint14": [
          25.63504697517451,
          24.84572666066733
      ],
      "SpawnPoint15": [
          29.023707404695095,
          21.890924476905607
      ],
      "SpawnPoint16": [
          32.659988561960134,
          18.22922902892856
      ],
      "SpawnPoint17": [
          34.99407326259948,
          13.606378895371085
      ],
      "SpawnPoint18": [
          33.87707856048279,
          8.514541045117184
      ],
      "SpawnPoint19": [
          26.637093386479005,
          7.591473917466051
      ],
      "SpawnPoint20": [
          27.366126348582114,
          37.3109679543707
      ],
      "DisplayPoints": 19,
      "Version": 3
  },
  "Bune": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      26.42842664216653,
      27.53957648026272
    ],
    "SpawnPoint02": [
      7.344597408485204,
      11.31317507392628
    ],
    "SpawnPoint03": [
      7.765033691867939,
      15.64565623584255
    ],
    "SpawnPoint04": [
      15.22007431291672,
      14.72642628387583
    ],
    "SpawnPoint05": [
      22.608114897394284,
      30.76532812108507
    ],
    "SpawnPoint06": [
      11.844081690698703,
      20.09905556914988
    ],
    "SpawnPoint07": [
      14.014343044232843,
      23.518132236796443
    ],
    "SpawnPoint08": [
      17.773646310107807,
      24.70303365823576
    ],
    "SpawnPoint09": [
      16.81834064521086,
      27.94589617250184
    ],
    "SpawnPoint10": [
      15.714234214279237,
      30.3124128477112
    ],
    "SpawnPoint11": [
      24.726132157141805,
      20.302604866948116
    ],
    "SpawnPoint12": [
      37.0135066865085,
      26.164220029844948
    ],
    "SpawnPoint13": [
      36.11339292334603,
      28.91290201200986
    ],
    "SpawnPoint14": [
      32.01604335142317,
      32.57757732195475
    ],
    "DisplayPoints": 14,
    "Version": 2
  },
  "Agathos": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      9.492756929935785,
      8.410169215267992
    ],
    "SpawnPoint02": [
      7.344597408485204,
      11.31317507392628
    ],
    "SpawnPoint03": [
      7.765033691867939,
      15.64565623584255
    ],
    "SpawnPoint04": [
      22.608114897394284,
      30.76532812108507
    ],
    "SpawnPoint05": [
      6.720690510187664,
      20.098490881192156
    ],
    "SpawnPoint06": [
      28.682345311788186,
      20.16631395255914
    ],
    "SpawnPoint07": [
      33.648107995303285,
      20.9104520982782
    ],
    "SpawnPoint08": [
      37.0135066865085,
      26.164220029844948
    ],
    "SpawnPoint09": [
      36.11339292334603,
      28.91290201200986
    ],
    "SpawnPoint10": [
      15.714234214279237,
      30.3124128477112
    ],
    "SpawnPoint11": [
      6.381551405398215,
      35.645041390769336
    ],
    "SpawnPoint12": [
      10.190239000054053,
      38.6254245907209
    ],
    "SpawnPoint13": [
      25.627930955164466,
      7.874287457406561
    ],
    "SpawnPoint14": [
      28.388174228855643,
      10.71309974527485
    ],
    "SpawnPoint15": [
      32.01604335142317,
      32.57757732195475
    ],
    "DisplayPoints": 15,
    "Version": 2
  },
  "Gandarewa": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      9.492756929935785,
      8.410169215267992
    ],
    "SpawnPoint02": [
      7.344597408485204,
      11.31317507392628
    ],
    "SpawnPoint03": [
      7.765033691867939,
      15.64565623584255
    ],
    "SpawnPoint04": [
      15.22007431291672,
      14.72642628387583
    ],
    "SpawnPoint05": [
      6.720690510187664,
      20.098490881192156
    ],
    "SpawnPoint06": [
      11.844081690698703,
      20.09905556914988
    ],
    "SpawnPoint07": [
      14.014343044232843,
      23.518132236796443
    ],
    "SpawnPoint08": [
      17.773646310107807,
      24.70303365823576
    ],
    "SpawnPoint09": [
      16.81834064521086,
      27.94589617250184
    ],
    "SpawnPoint10": [
      15.714234214279237,
      30.3124128477112
    ],
    "SpawnPoint11": [
      6.381551405398215,
      35.645041390769336
    ],
    "SpawnPoint12": [
      10.190239000054053,
      38.6254245907209
    ],
    "SpawnPoint13": [
      25.627930955164466,
      7.874287457406561
    ],
    "SpawnPoint14": [
      28.388174228855643,
      10.71309974527485
    ],
    "SpawnPoint15": [
      24.726132157141805,
      20.302604866948116
    ],
    "SpawnPoint16": [
      28.682345311788186,
      20.16631395255914
    ],
    "SpawnPoint17": [
      33.648107995303285,
      20.9104520982782
    ],
    "SpawnPoint18": [
      37.0135066865085,
      26.164220029844948
    ],
    "SpawnPoint19": [
      36.11339292334603,
      28.91290201200986
    ],
    "SpawnPoint20": [
      32.01604335142317,
      32.57757732195475
    ],
    "SpawnPoint21": [
      22.608114897394284,
      30.76532812108507
    ],
    "SpawnPoint22": [
      26.42842664216653,
      27.53957648026272
    ],
    "DisplayPoints": 22,
    "Version": 2
  },
  "Slipkinx Steeljoints": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      13.411733703139074,
      16.52500310665314
    ],
    "SpawnPoint02": [
      5.441358204018877,
      22.122389414057213
    ],
    "SpawnPoint03": [
      9.156615353136623,
      21.273750299705394
    ],
    "SpawnPoint04": [
      15.088342491527298,
      21.69964102372426
    ],
    "SpawnPoint05": [
      15.060404369917476,
      25.448079492332777
    ],
    "SpawnPoint06": [
      31.8364655099959,
      20.163628475916482
    ],
    "SpawnPoint07": [
      36.02217471217096,
      21.93851455592128
    ],
    "SpawnPoint08": [
      34.34006734609927,
      24.249033863956736
    ],
    "SpawnPoint09": [
      34.16653485970794,
      26.85042569284789
    ],
    "SpawnPoint10": [
      38.106260600842724,
      28.65865928248367
    ],
    "SpawnPoint11": [
      26.890073678245567,
      25.412199031248008
    ],
    "SpawnPoint12": [
      26.469493371733567,
      29.464381741241805
    ],
    "SpawnPoint13": [
      26.810453333171644,
      16.4341820865047
    ],
    "SpawnPoint14": [
      26.852834806112707,
      19.987879620146813
    ],
    "SpawnPoint15": [
      24.279264897425566,
      23.703421662021405
    ],
    "SpawnPoint16": [
      26.33624469388389,
      36.95355657533796
    ],
    "DisplayPoints": 16,
    "Version": 2
  },
  "Stolas": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      36.02217471217096,
      21.93851455592128
    ],
    "SpawnPoint02": [
      34.34006734609927,
      24.249033863956736
    ],
    "SpawnPoint03": [
      34.16653485970794,
      26.85042569284789
    ],
    "SpawnPoint04": [
      38.106260600842724,
      28.65865928248367
    ],
    "SpawnPoint05": [
      15.060404369917476,
      25.448079492332777
    ],
    "SpawnPoint06": [
      8.494898825043327,
      28.137964228662554
    ],
    "SpawnPoint07": [
      12.5455863283754,
      29.297247409650705
    ],
    "SpawnPoint08": [
      8.47401837091399,
      33.94114354897677
    ],
    "SpawnPoint09": [
      15.550674331706926,
      31.969033283341496
    ],
    "SpawnPoint10": [
      14.461911017151568,
      35.56242433240953
    ],
    "SpawnPoint11": [
      16.30749806844465,
      37.702057009252854
    ],
    "SpawnPoint12": [
      26.33624469388389,
      36.95355657533796
    ],
    "SpawnPoint13": [
      31.8364655099959,
      20.163628475916482
    ],
    "SpawnPoint14": [
      26.852834806112707,
      19.987879620146813
    ],
    "SpawnPoint15": [
      24.279264897425566,
      23.703421662021405
    ],
    "SpawnPoint16": [
      26.890073678245567,
      25.412199031248008
    ],
    "SpawnPoint17": [
      26.469493371733567,
      29.464381741241805
    ],
    "DisplayPoints": 17,
    "Version": 2
  },
  "The Pale Rider": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      13.411733703139074,
      16.52500310665314
    ],
    "SpawnPoint02": [
      5.441358204018877,
      22.122389414057213
    ],
    "SpawnPoint03": [
      9.156615353136623,
      21.273750299705394
    ],
    "SpawnPoint04": [
      15.088342491527298,
      21.69964102372426
    ],
    "SpawnPoint05": [
      15.060404369917476,
      25.448079492332777
    ],
    "SpawnPoint06": [
      8.494898825043327,
      28.137964228662554
    ],
    "SpawnPoint07": [
      12.5455863283754,
      29.297247409650705
    ],
    "SpawnPoint08": [
      8.47401837091399,
      33.94114354897677
    ],
    "SpawnPoint09": [
      15.550674331706926,
      31.969033283341496
    ],
    "SpawnPoint10": [
      14.461911017151568,
      35.56242433240953
    ],
    "SpawnPoint11": [
      16.30749806844465,
      37.702057009252854
    ],
    "SpawnPoint12": [
      26.33624469388389,
      36.95355657533796
    ],
    "SpawnPoint13": [
      26.810453333171644,
      16.4341820865047
    ],
    "SpawnPoint14": [
      26.852834806112707,
      19.987879620146813
    ],
    "SpawnPoint15": [
      24.279264897425566,
      23.703421662021405
    ],
    "SpawnPoint16": [
      26.890073678245567,
      25.412199031248008
    ],
    "SpawnPoint17": [
      26.469493371733567,
      29.464381741241805
    ],
    "SpawnPoint18": [
      31.8364655099959,
      20.163628475916482
    ],
    "SpawnPoint19": [
      36.02217471217096,
      21.93851455592128
    ],
    "SpawnPoint20": [
      34.34006734609927,
      24.249033863956736
    ],
    "SpawnPoint21": [
      34.16653485970794,
      26.85042569284789
    ],
    "SpawnPoint22": [
      38.106260600842724,
      28.65865928248367
    ],
    "DisplayPoints": 22,
    "Version": 2
  },
  "Enkelados": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      31.80073202623127,
      18.981540338254845
    ],
    "SpawnPoint02": [
      9.114990462859136,
      16.61131326265799
    ],
    "SpawnPoint03": [
      6.555721369925109,
      19.39434311123548
    ],
    "SpawnPoint04": [
      36.62207031249968,
      21.230134662829233
    ],
    "SpawnPoint05": [
      15.696236378286606,
      14.886553277047415
    ],
    "SpawnPoint06": [
      19.205689315800516,
      9.499754688673494
    ],
    "SpawnPoint07": [
      34.566752513760534,
      31.298901024320166
    ],
    "SpawnPoint08": [
      23.4130863757293,
      10.140868686744687
    ],
    "SpawnPoint09": [
      21.602401799873874,
      16.0156089884227
    ],
    "SpawnPoint10": [
      25.016046889362272,
      13.064075951255857
    ],
    "SpawnPoint11": [
      31.59604642502477,
      36.270795835401984
    ],
    "SpawnPoint12": [
      26.76114992891003,
      33.7733708669043
    ],
    "SpawnPoint13": [
      37.562769271076114,
      14.598772451385697
    ],
    "SpawnPoint14": [
      18.369872937284555,
      31.809082442214674
    ],
    "SpawnPoint15": [
      18.035797687601846,
      30.03577101190362
    ],
    "SpawnPoint16": [
      13.999013804451986,
      29.20864280997113
    ],
    "SpawnPoint17": [
      14.681723015433022,
      23.449935853946595
    ],
    "SpawnPoint18": [
      20.82762337503185,
      21.776698340288704
    ],
    "SpawnPoint19": [
      24.892939496804306,
      25.13464212613346
    ],
    "SpawnPoint20": [
      26.30755691478622,
      29.287381946422784
    ],
    "DisplayPoints": 20,
    "Version": 2
  },
  "Sisiutl": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      7.436803758870565,
      8.388653741314402
    ],
    "SpawnPoint02": [
      9.114990462859136,
      16.61131326265799
    ],
    "SpawnPoint03": [
      6.555721369925109,
      19.39434311123548
    ],
    "SpawnPoint04": [
      15.516906056868102,
      7.525958495315034
    ],
    "SpawnPoint05": [
      15.696236378286606,
      14.886553277047415
    ],
    "SpawnPoint06": [
      19.205689315800516,
      9.499754688673494
    ],
    "SpawnPoint07": [
      21.772402067991205,
      7.63268025114579
    ],
    "SpawnPoint08": [
      23.4130863757293,
      10.140868686744687
    ],
    "SpawnPoint09": [
      21.602401799873874,
      16.0156089884227
    ],
    "SpawnPoint10": [
      25.016046889362272,
      13.064075951255857
    ],
    "SpawnPoint11": [
      29.216390664792176,
      6.494570429835484
    ],
    "SpawnPoint12": [
      37.00855695120054,
      8.707701679840675
    ],
    "SpawnPoint13": [
      37.562769271076114,
      14.598772451385697
    ],
    "SpawnPoint14": [
      31.80073202623127,
      18.981540338254845
    ],
    "SpawnPoint15": [
      36.62207031249968,
      21.230134662829233
    ],
    "SpawnPoint16": [
      34.566752513760534,
      31.298901024320166
    ],
    "SpawnPoint17": [
      14.681723015433022,
      23.449935853946595
    ],
    "SpawnPoint18": [
      20.82762337503185,
      21.776698340288704
    ],
    "SpawnPoint19": [
      24.892939496804306,
      25.13464212613346
    ],
    "SpawnPoint20": [
      36.31737463554154,
      38.61665132513487
    ],
    "DisplayPoints": 20,
    "Version": 2
  },
  "Bird of Paradise": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      7.436803758870565,
      8.388653741314402
    ],
    "SpawnPoint02": [
      9.114990462859136,
      16.61131326265799
    ],
    "SpawnPoint03": [
      6.555721369925109,
      19.39434311123548
    ],
    "SpawnPoint04": [
      15.516906056868102,
      7.525958495315034
    ],
    "SpawnPoint05": [
      15.696236378286606,
      14.886553277047415
    ],
    "SpawnPoint06": [
      19.205689315800516,
      9.499754688673494
    ],
    "SpawnPoint07": [
      21.772402067991205,
      7.63268025114579
    ],
    "SpawnPoint08": [
      23.4130863757293,
      10.140868686744687
    ],
    "SpawnPoint09": [
      21.602401799873874,
      16.0156089884227
    ],
    "SpawnPoint10": [
      25.016046889362272,
      13.064075951255857
    ],
    "SpawnPoint11": [
      29.216390664792176,
      6.494570429835484
    ],
    "SpawnPoint12": [
      37.00855695120054,
      8.707701679840675
    ],
    "SpawnPoint13": [
      37.562769271076114,
      14.598772451385697
    ],
    "SpawnPoint14": [
      18.369872937284555,
      31.809082442214674
    ],
    "SpawnPoint15": [
      18.035797687601846,
      30.03577101190362
    ],
    "SpawnPoint16": [
      13.999013804451986,
      29.20864280997113
    ],
    "SpawnPoint17": [
      14.681723015433022,
      23.449935853946595
    ],
    "SpawnPoint18": [
      20.82762337503185,
      21.776698340288704
    ],
    "SpawnPoint19": [
      24.892939496804306,
      25.13464212613346
    ],
    "SpawnPoint20": [
      26.30755691478622,
      29.287381946422784
    ],
    "SpawnPoint21": [
      26.76114992891003,
      33.7733708669043
    ],
    "SpawnPoint22": [
      31.59604642502477,
      36.270795835401984
    ],
    "SpawnPoint23": [
      36.31737463554154,
      38.61665132513487
    ],
    "SpawnPoint24": [
      34.566752513760534,
      31.298901024320166
    ],
    "SpawnPoint25": [
      36.62207031249968,
      21.230134662829233
    ],
    "SpawnPoint26": [
      31.80073202623127,
      18.981540338254845
    ],
    "DisplayPoints": 26,
    "Version": 2
  },
  "Campacti": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      17.145995412336998,
      8.551625813023293
    ],
    "SpawnPoint02": [
      18.457443488889812,
      13.184036047242914
    ],
    "SpawnPoint03": [
      13.904895080296924,
      16.867445309441532
    ],
    "SpawnPoint04": [
      9.600768489110678,
      26.63543365164676
    ],
    "SpawnPoint05": [
      11.18975500171373,
      30.451605521766272
    ],
    "SpawnPoint06": [
      16.063669066260243,
      29.508905329039372
    ],
    "SpawnPoint07": [
      8.307030235791496,
      34.00089340599423
    ],
    "SpawnPoint08": [
      12.740160877262158,
      38.05740965177212
    ],
    "SpawnPoint09": [
      30.199512907629785,
      28.838325106446934
    ],
    "SpawnPoint10": [
      29.88830066703236,
      35.1825861940145
    ],
    "SpawnPoint11": [
      34.38769152175648,
      26.802199935869716
    ],
    "SpawnPoint12": [
      38.71333144701744,
      27.59082192675974
    ],
    "SpawnPoint13": [
      35.9929644469097,
      31.02220795163348
    ],
    "SpawnPoint14": [
      35.98862030695501,
      34.50396575662102
    ],
    "SpawnPoint15": [
      36.62100442420333,
      37.25113609502157
    ],
    "SpawnPoint16": [
      27.54061075810571,
      15.778801373960382
    ],
    "SpawnPoint17": [
      27.843890036787602,
      11.106494634677075
    ],
    "SpawnPoint18": [
      33.980156262342625,
      12.998726025421426
    ],
    "SpawnPoint19": [
      32.85558690402892,
      5.925927611511252
    ],
    "SpawnPoint20": [
      37.675935551606365,
      7.020211628685251
    ],
    "DisplayPoints": 20,
    "Version": 2
  },
  "Stench Blossom": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      17.145995412336998,
      8.551625813023293
    ],
    "SpawnPoint02": [
      18.457443488889812,
      13.184036047242914
    ],
    "SpawnPoint03": [
      13.904895080296924,
      16.867445309441532
    ],
    "SpawnPoint04": [
      9.600768489110678,
      26.63543365164676
    ],
    "SpawnPoint05": [
      11.18975500171373,
      30.451605521766272
    ],
    "SpawnPoint06": [
      16.063669066260243,
      29.508905329039372
    ],
    "SpawnPoint07": [
      8.307030235791496,
      34.00089340599423
    ],
    "SpawnPoint08": [
      12.740160877262158,
      38.05740965177212
    ],
    "SpawnPoint09": [
      30.199512907629785,
      28.838325106446934
    ],
    "SpawnPoint10": [
      29.88830066703236,
      35.1825861940145
    ],
    "SpawnPoint11": [
      34.38769152175648,
      26.802199935869716
    ],
    "SpawnPoint12": [
      38.71333144701744,
      27.59082192675974
    ],
    "SpawnPoint13": [
      35.9929644469097,
      31.02220795163348
    ],
    "SpawnPoint14": [
      35.98862030695501,
      34.50396575662102
    ],
    "SpawnPoint15": [
      36.62100442420333,
      37.25113609502157
    ],
    "SpawnPoint16": [
      27.54061075810571,
      15.778801373960382
    ],
    "SpawnPoint17": [
      27.843890036787602,
      11.106494634677075
    ],
    "SpawnPoint18": [
      33.980156262342625,
      12.998726025421426
    ],
    "SpawnPoint19": [
      32.85558690402892,
      5.925927611511252
    ],
    "SpawnPoint20": [
      37.675935551606365,
      7.020211628685251
    ],
    "DisplayPoints": 20,
    "Version": 2
  },
  "Leucrotta": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      17.145995412336998,
      8.551625813023293
    ],
    "SpawnPoint02": [
      18.457443488889812,
      13.184036047242914
    ],
    "SpawnPoint03": [
      13.904895080296924,
      16.867445309441532
    ],
    "SpawnPoint04": [
      9.600768489110678,
      26.63543365164676
    ],
    "SpawnPoint05": [
      11.18975500171373,
      30.451605521766272
    ],
    "SpawnPoint06": [
      16.063669066260243,
      29.508905329039372
    ],
    "SpawnPoint07": [
      8.307030235791496,
      34.00089340599423
    ],
    "SpawnPoint08": [
      12.740160877262158,
      38.05740965177212
    ],
    "SpawnPoint09": [
      30.199512907629785,
      28.838325106446934
    ],
    "SpawnPoint10": [
      29.88830066703236,
      35.1825861940145
    ],
    "SpawnPoint11": [
      34.38769152175648,
      26.802199935869716
    ],
    "SpawnPoint12": [
      38.71333144701744,
      27.59082192675974
    ],
    "SpawnPoint13": [
      35.9929644469097,
      31.02220795163348
    ],
    "SpawnPoint14": [
      35.98862030695501,
      34.50396575662102
    ],
    "SpawnPoint15": [
      36.62100442420333,
      37.25113609502157
    ],
    "SpawnPoint16": [
      27.54061075810571,
      15.778801373960382
    ],
    "SpawnPoint17": [
      27.843890036787602,
      11.106494634677075
    ],
    "SpawnPoint18": [
      33.980156262342625,
      12.998726025421426
    ],
    "SpawnPoint19": [
      32.85558690402892,
      5.925927611511252
    ],
    "SpawnPoint20": [
      37.675935551606365,
      7.020211628685251
    ],
    "DisplayPoints": 20,
    "Version": 2
  },
  "Orcus": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      28.077851626747535,
      23.678651829769738
    ],
    "SpawnPoint02": [
      25.253798296662413,
      28.42067024075255
    ],
    "SpawnPoint03": [
      16.680654776644214,
      24.114468291897783
    ],
    "SpawnPoint04": [
      15.418294514851949,
      26.109954788230883
    ],
    "SpawnPoint05": [
      17.51305751827746,
      21.721043041191155
    ],
    "SpawnPoint06": [
      34.75262543930471,
      17.47208856739172
    ],
    "SpawnPoint07": [
      25.64296684936863,
      31.936818035362606
    ],
    "SpawnPoint08": [
      33.30352713047773,
      33.325349186572076
    ],
    "DisplayPoints": 8,
    "Version": 2
  },
  "Erle": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      18.24709670608108,
      8.234880359449523
    ],
    "SpawnPoint02": [
      17.96582824616876,
      12.951187021094904
    ],
    "SpawnPoint03": [
      8.367922431396844,
      30.31241539326358
    ],
    "SpawnPoint04": [
      14.144094366776315,
      12.33508943256579
    ],
    "SpawnPoint05": [
      10.363343723695285,
      14.404907363566219
    ],
    "SpawnPoint06": [
      12.417823144280206,
      18.056203180229755
    ],
    "SpawnPoint07": [
      8.743112450823931,
      25.06385698114608
    ],
    "DisplayPoints": 7,
    "Version": 2
  },
  "Udumbara": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      18.24709670608108,
      8.234880359449523
    ],
    "SpawnPoint02": [
      17.96582824616876,
      12.951187021094904
    ],
    "SpawnPoint03": [
      25.485239389780407,
      11.81825709987331
    ],
    "SpawnPoint04": [
      28.077851626747535,
      23.678651829769738
    ],
    "SpawnPoint05": [
      28.975479679404145,
      30.686959433020295
    ],
    "SpawnPoint06": [
      25.253798296662413,
      28.42067024075255
    ],
    "SpawnPoint07": [
      16.680654776644214,
      24.114468291897783
    ],
    "SpawnPoint08": [
      8.367922431396844,
      30.31241539326358
    ],
    "SpawnPoint09": [
      21.242086038961038,
      10.525656960227273
    ],
    "SpawnPoint10": [
      14.144094366776315,
      12.33508943256579
    ],
    "SpawnPoint11": [
      10.363343723695285,
      14.404907363566219
    ],
    "SpawnPoint12": [
      12.417823144280206,
      18.056203180229755
    ],
    "SpawnPoint13": [
      8.743112450823931,
      25.06385698114608
    ],
    "SpawnPoint14": [
      15.418294514851949,
      26.109954788230883
    ],
    "SpawnPoint15": [
      17.51305751827746,
      21.721043041191155
    ],
    "SpawnPoint16": [
      24.307206823470743,
      18.15460854388298
    ],
    "SpawnPoint17": [
      34.75262543930471,
      17.47208856739172
    ],
    "SpawnPoint18": [
      33.86542330473856,
      21.030208333333334
    ],
    "SpawnPoint19": [
      25.64296684936863,
      31.936818035362606
    ],
    "SpawnPoint20": [
      33.30352713047773,
      33.325349186572076
    ],
    "DisplayPoints": 8,
    "Version": 2
  },
  "Vochstein": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      5.66455078125,
      14.74657643169438
    ],
    "SpawnPoint02": [
      9.017406429664318,
      12.060791015625
    ],
    "SpawnPoint03": [
      11.234079580168428,
      8.145412349322985
    ],
    "SpawnPoint04": [
      23.544794148920126,
      14.981188446398408
    ],
    "SpawnPoint05": [
      31.907634622296,
      7.968529123596659
    ],
    "SpawnPoint06": [
      36.63937882133152,
      12.427989838088768
    ],
    "SpawnPoint07": [
      23.636450123294615,
      24.33512585845317
    ],
    "SpawnPoint08": [
      18.011263020833333,
      26.793549053485577
    ],
    "SpawnPoint09": [
      12.551164026473979,
      28.355357455327734
    ],
    "SpawnPoint10": [
      11.851555741782008,
      32.80117547938293
    ],
    "SpawnPoint11": [
      23.57976045571219,
      36.43644959053129
    ],
    "SpawnPoint12": [
      26.373597704674587,
      11.86657260863249
    ],
    "SpawnPoint13": [
      27.09253642506843,
      7.714762666799726
    ],
    "SpawnPoint14": [
      7.303080039449258,
      25.719826474834985
    ],
    "SpawnPoint15": [
      16.76486054977568,
      23.080774892674814
    ],
    "SpawnPoint16": [
      5.988883430527383,
      34.885513398516736
    ],
    "SpawnPoint17": [
      25.5,
      20.5
    ],
    "DisplayPoints": 17,
    "Version": 2
  },
  "Aqrabuamelu": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      25.5,
      20.5
    ],
    "SpawnPoint02": [
      9.017406429664318,
      12.060791015625
    ],
    "SpawnPoint03": [
      11.234079580168428,
      8.145412349322985
    ],
    "SpawnPoint04": [
      23.544794148920126,
      14.981188446398408
    ],
    "SpawnPoint05": [
      7.303080039449258,
      25.719826474834985
    ],
    "SpawnPoint06": [
      36.63937882133152,
      12.427989838088768
    ],
    "SpawnPoint07": [
      23.636450123294615,
      24.33512585845317
    ],
    "SpawnPoint08": [
      18.011263020833333,
      26.793549053485577
    ],
    "SpawnPoint09": [
      12.551164026473979,
      28.355357455327734
    ],
    "SpawnPoint10": [
      11.851555741782008,
      32.80117547938293
    ],
    "SpawnPoint11": [
      23.57976045571219,
      36.43644959053129
    ],
    "SpawnPoint12": [
      16.76486054977568,
      23.080774892674814
    ],
    "SpawnPoint13": [
      5.988883430527383,
      34.885513398516736
    ],
    "DisplayPoints": 13,
    "Version": 2
  },
  "Bone Crawler": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      5.66455078125,
      14.74657643169438
    ],
    "SpawnPoint02": [
      9.017406429664318,
      12.060791015625
    ],
    "SpawnPoint03": [
      11.234079580168428,
      8.145412349322985
    ],
    "SpawnPoint04": [
      23.544794148920126,
      14.981188446398408
    ],
    "SpawnPoint05": [
      31.907634622296,
      7.968529123596659
    ],
    "SpawnPoint06": [
      36.63937882133152,
      12.427989838088768
    ],
    "SpawnPoint07": [
      23.636450123294615,
      24.33512585845317
    ],
    "SpawnPoint08": [
      18.011263020833333,
      26.793549053485577
    ],
    "SpawnPoint09": [
      12.551164026473979,
      28.355357455327734
    ],
    "SpawnPoint10": [
      11.851555741782008,
      32.80117547938293
    ],
    "SpawnPoint11": [
      23.57976045571219,
      36.43644959053129
    ],
    "SpawnPoint12": [
      26.373597704674587,
      11.86657260863249
    ],
    "SpawnPoint13": [
      27.09253642506843,
      7.714762666799726
    ],
    "SpawnPoint14": [
      7.303080039449258,
      25.719826474834985
    ],
    "SpawnPoint15": [
      16.76486054977568,
      23.080774892674814
    ],
    "SpawnPoint16": [
      5.988883430527383,
      34.885513398516736
    ],
    "SpawnPoint17": [
      26.79219553654925,
      30.012394550732544
    ],
    "DisplayPoints": 11,
    "Version": 2
  },
  "Mahisha": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      6.521594434816637,
      8.322886676822451
    ],
    "SpawnPoint02": [
      28.88178646049626,
      8.130744199024086
    ],
    "SpawnPoint03": [
      14.077807910049755,
      20.631693766187297
    ],
    "SpawnPoint04": [
      18.898608768971332,
      25.575105560971753
    ],
    "SpawnPoint05": [
      7.748700662624617,
      26.501136826591257
    ],
    "SpawnPoint06": [
      6.964416136961423,
      31.098542788702403
    ],
    "SpawnPoint07": [
      26.58485002596953,
      34.62626871970222
    ],
    "SpawnPoint08": [
      24.768306559097095,
      33.11808607928766
    ],
    "SpawnPoint09": [
      30.34106562126196,
      36.15812836423445
    ],
    "SpawnPoint10": [
      7.648647502317267,
      14.023765434653072
    ],
    "DisplayPoints": 10,
    "Version": 2
  },
  "Luminare": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      19.135834967709776,
      31.890414382151526
    ],
    "SpawnPoint02": [
      28.88178646049626,
      8.130744199024086
    ],
    "SpawnPoint03": [
      14.077807910049755,
      20.631693766187297
    ],
    "SpawnPoint04": [
      18.898608768971332,
      25.575105560971753
    ],
    "SpawnPoint05": [
      7.748700662624617,
      26.501136826591257
    ],
    "SpawnPoint06": [
      26.58485002596953,
      34.62626871970222
    ],
    "SpawnPoint07": [
      3.104585721895292,
      34.97372951755276
    ],
    "SpawnPoint08": [
      24.768306559097095,
      33.11808607928766
    ],
    "SpawnPoint09": [
      30.34106562126196,
      36.15812836423445
    ],
    "SpawnPoint10": [
      4.556530026355421,
      29.308051346009037
    ],
    "SpawnPoint11": [
      35.29777496936274,
      11.135883064163165
    ],
    "SpawnPoint12": [
      7.648647502317267,
      14.023765434653072
    ],
    "DisplayPoints": 12,
    "Version": 2
  },
  "Salt and Light": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      6.521594434816637,
      8.322886676822451
    ],
    "SpawnPoint02": [
      28.88178646049626,
      8.130744199024086
    ],
    "SpawnPoint03": [
      14.077807910049755,
      20.631693766187297
    ],
    "SpawnPoint04": [
      18.898608768971332,
      25.575105560971753
    ],
    "SpawnPoint05": [
      7.748700662624617,
      26.501136826591257
    ],
    "SpawnPoint06": [
      10.490868579382184,
      33.576916457136015
    ],
    "SpawnPoint07": [
      3.104585721895292,
      34.97372951755276
    ],
    "SpawnPoint08": [
      24.768306559097095,
      33.11808607928766
    ],
    "SpawnPoint09": [
      30.34106562126196,
      36.15812836423445
    ],
    "SpawnPoint10": [
      7.648647502317267,
      14.023765434653072
    ],
    "SpawnPoint11": [
      12,
      7
    ],
    "SpawnPoint12": [
      22.493357188666046,
      10.331940152751866
    ],
    "SpawnPoint13": [
      25.1056630487673,
      16.661057627324826
    ],
    "SpawnPoint14": [
      23.278479929956898,
      25.960793372844826
    ],
    "SpawnPoint15": [
      4.556530026355421,
      29.308051346009037
    ],
    "SpawnPoint16": [
      6.964416136961423,
      31.098542788702403
    ],
    "SpawnPoint17": [
      35.29777496936274,
      11.135883064163165
    ],
    "SpawnPoint18": [
      19.135834967709776,
      31.890414382151526
    ],
    "SpawnPoint19": [
      26.58485002596953,
      34.62626871970222
    ],
    "DisplayPoints": 9,
    "Version": 2
  },
  "Funa Yurei": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      5.073683965773809,
      22.913736979166668
    ],
    "SpawnPoint02": [
      18.86040152616279,
      8.470595879764211
    ],
    "SpawnPoint03": [
      25.96181330605159,
      7.092134021577381
    ],
    "SpawnPoint04": [
      6.695001922430728,
      29.717992739996898
    ],
    "SpawnPoint05": [
      32.39754314181171,
      24.49520062796677
    ],
    "SpawnPoint06": [
      31.41689543252918,
      38.86012889352606
    ],
    "SpawnPoint07": [
      27.310034700439147,
      31.66376781583281
    ],
    "SpawnPoint08": [
      24.15859375,
      33.15677554086538
    ],
    "SpawnPoint09": [
      14.519363286841006,
      13.644530784082777
    ],
    "DisplayPoints": 9,
    "Version": 2
  },
  "Oni Yumemi": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      5.073683965773809,
      22.913736979166668
    ],
    "SpawnPoint02": [
      18.86040152616279,
      8.470595879764211
    ],
    "SpawnPoint03": [
      25.96181330605159,
      7.092134021577381
    ],
    "SpawnPoint04": [
      6.695001922430728,
      29.717992739996898
    ],
    "SpawnPoint05": [
      32.39754314181171,
      24.49520062796677
    ],
    "SpawnPoint06": [
      31.41689543252918,
      38.86012889352606
    ],
    "SpawnPoint07": [
      27.310034700439147,
      31.66376781583281
    ],
    "SpawnPoint08": [
      24.15859375,
      33.15677554086538
    ],
    "SpawnPoint09": [
      14.519363286841006,
      13.644530784082777
    ],
    "DisplayPoints": 9,
    "Version": 2
  },
  "Okina": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      24.15859375,
      33.15677554086538
    ],
    "SpawnPoint02": [
      18.86040152616279,
      8.470595879764211
    ],
    "SpawnPoint03": [
      25.96181330605159,
      7.092134021577381
    ],
    "SpawnPoint04": [
      6.695001922430728,
      29.717992739996898
    ],
    "SpawnPoint05": [
      32.39754314181171,
      24.49520062796677
    ],
    "SpawnPoint06": [
      35.04562742425351,
      20.981062806462237
    ],
    "SpawnPoint07": [
      27.310034700439147,
      31.66376781583281
    ],
    "SpawnPoint08": [
      16.655971366112386,
      10.59644495412844
    ],
    "SpawnPoint09": [
      7.4612873014856556,
      5.9698978611680324
    ],
    "SpawnPoint10": [
      5.073683965773809,
      22.913736979166668
    ],
    "SpawnPoint11": [
      14.519363286841006,
      13.644530784082777
    ],
    "SpawnPoint12": [
      24.901207139756945,
      26.34550509982639
    ],
    "SpawnPoint13": [
      36.56199166517431,
      17.916236214332617
    ],
    "SpawnPoint14": [
      31.41689543252918,
      38.86012889352606
    ],
    "DisplayPoints": 7,
    "Version": 2
  },
  "Angada": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      27.064888843970802,
      10.364980290160016
    ],
    "SpawnPoint02": [
      28.655593079405946,
      11.471799181653308
    ],
    "SpawnPoint03": [
      23.722149941256752,
      11.364528664593651
    ],
    "SpawnPoint04": [
      21.76647713187903,
      9.310537191683192
    ],
    "SpawnPoint05": [
      17.09663646092522,
      13.875421523048717
    ],
    "SpawnPoint06": [
      12.299635145399305,
      30.612315990306712
    ],
    "SpawnPoint07": [
      17.5849694355058,
      34.47914553968002
    ],
    "SpawnPoint08": [
      18.438215025524116,
      16.25142815756786
    ],
    "SpawnPoint09": [
      18.41799042915386,
      10.654049732646005
    ],
    "SpawnPoint10": [
      29.966669256036933,
      35.24746519146544
    ],
    "SpawnPoint11": [
      23.665905863349906,
      27.193528760262488
    ],
    "SpawnPoint12": [
      24.54762241296601,
      21.67687431469298
    ],
    "SpawnPoint13": [
      34.17892589681953,
      26.77198363535503
    ],
    "SpawnPoint14": [
      32.52530798265762,
      18.666487114379635
    ],
    "DisplayPoints": 8,
    "Version": 2
  },
  "Gajasura": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      27.064888843970802,
      10.364980290160016
    ],
    "SpawnPoint02": [
      28.655593079405946,
      11.471799181653308
    ],
    "SpawnPoint03": [
      23.722149941256752,
      11.364528664593651
    ],
    "SpawnPoint04": [
      21.76647713187903,
      9.310537191683192
    ],
    "SpawnPoint05": [
      17.09663646092522,
      13.875421523048717
    ],
    "SpawnPoint06": [
      12.299635145399305,
      30.612315990306712
    ],
    "SpawnPoint07": [
      17.5849694355058,
      34.47914553968002
    ],
    "SpawnPoint08": [
      18.438215025524116,
      16.25142815756786
    ],
    "SpawnPoint09": [
      18.41799042915386,
      10.654049732646005
    ],
    "SpawnPoint10": [
      29.966669256036933,
      35.24746519146544
    ],
    "SpawnPoint11": [
      23.665905863349906,
      27.193528760262488
    ],
    "SpawnPoint12": [
      24.54762241296601,
      21.67687431469298
    ],
    "SpawnPoint13": [
      34.17892589681953,
      26.77198363535503
    ],
    "SpawnPoint14": [
      32.52530798265762,
      18.666487114379635
    ],
    "DisplayPoints": 8,
    "Version": 2
  },
  "Gamma": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      27.064888843970802,
      10.364980290160016
    ],
    "SpawnPoint02": [
      28.655593079405946,
      11.471799181653308
    ],
    "SpawnPoint03": [
      23.722149941256752,
      11.364528664593651
    ],
    "SpawnPoint04": [
      21.76647713187903,
      9.310537191683192
    ],
    "SpawnPoint05": [
      17.09663646092522,
      13.875421523048717
    ],
    "SpawnPoint06": [
      12.299635145399305,
      30.612315990306712
    ],
    "SpawnPoint07": [
      17.5849694355058,
      34.47914553968002
    ],
    "SpawnPoint08": [
      18.438215025524116,
      16.25142815756786
    ],
    "SpawnPoint09": [
      18.41799042915386,
      10.654049732646005
    ],
    "SpawnPoint10": [
      29.966669256036933,
      35.24746519146544
    ],
    "SpawnPoint11": [
      23.665905863349906,
      27.193528760262488
    ],
    "SpawnPoint12": [
      24.54762241296601,
      21.67687431469298
    ],
    "SpawnPoint13": [
      34.17892589681953,
      26.77198363535503
    ],
    "SpawnPoint14": [
      32.52530798265762,
      18.666487114379635
    ],
    "DisplayPoints": 7,
    "Version": 2
  },
  "Girimekhala": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      28.01292965414176,
      25.648949548996583
    ],
    "SpawnPoint02": [
      24.342141241776314,
      30.400859203673246
    ],
    "SpawnPoint03": [
      26.769039204467237,
      34.28529283968888
    ],
    "SpawnPoint04": [
      21.813156842699847,
      34.223512979728866
    ],
    "SpawnPoint05": [
      17.848616901094704,
      34.461260203188644
    ],
    "SpawnPoint06": [
      15.696981757902025,
      30.281890845826968
    ],
    "SpawnPoint07": [
      11.385285046635842,
      28.354578550170068
    ],
    "SpawnPoint08": [
      9.83356483958809,
      25.766247428534058
    ],
    "SpawnPoint09": [
      15.069538774602178,
      25.77834484924623
    ],
    "SpawnPoint10": [
      9.6783778515892,
      22.441109543211724
    ],
    "SpawnPoint11": [
      12.788986582014656,
      17.296850938562024
    ],
    "SpawnPoint12": [
      17.35643546664955,
      18.71227653566094
    ],
    "SpawnPoint13": [
      22.23710007440476,
      16.406602260044643
    ],
    "SpawnPoint14": [
      27.99310968314965,
      18.783614368837007
    ],
    "SpawnPoint15": [
      34.817042080412776,
      15.14266584323079
    ],
    "SpawnPoint16": [
      27.376774002425044,
      9.01021343143739
    ],
    "SpawnPoint17": [
      19.332973186183196,
      10.14524284590185
    ],
    "SpawnPoint18": [
      13.54033203125,
      10.3701416015625
    ],
    "DisplayPoints": 18,
    "Version": 2
  },
  "Sum": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      28.01292965414176,
      25.648949548996583
    ],
    "SpawnPoint02": [
      24.342141241776314,
      30.400859203673246
    ],
    "SpawnPoint03": [
      26.769039204467237,
      34.28529283968888
    ],
    "SpawnPoint04": [
      21.813156842699847,
      34.223512979728866
    ],
    "SpawnPoint05": [
      17.848616901094704,
      34.461260203188644
    ],
    "SpawnPoint06": [
      15.696981757902025,
      30.281890845826968
    ],
    "SpawnPoint07": [
      11.385285046635842,
      28.354578550170068
    ],
    "SpawnPoint08": [
      9.83356483958809,
      25.766247428534058
    ],
    "SpawnPoint09": [
      15.069538774602178,
      25.77834484924623
    ],
    "SpawnPoint10": [
      9.6783778515892,
      22.441109543211724
    ],
    "SpawnPoint11": [
      12.788986582014656,
      17.296850938562024
    ],
    "SpawnPoint12": [
      17.35643546664955,
      18.71227653566094
    ],
    "SpawnPoint13": [
      22.23710007440476,
      16.406602260044643
    ],
    "SpawnPoint14": [
      27.99310968314965,
      18.783614368837007
    ],
    "SpawnPoint15": [
      34.817042080412776,
      15.14266584323079
    ],
    "SpawnPoint16": [
      27.376774002425044,
      9.01021343143739
    ],
    "SpawnPoint17": [
      19.332973186183196,
      10.14524284590185
    ],
    "SpawnPoint18": [
      13.54033203125,
      10.3701416015625
    ],
    "DisplayPoints": 18,
    "Version": 2
  },
  "Orghana": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      28.014951651068106,
      25.64597378648477
    ],
    "SpawnPoint02": [
      24.342141241776314,
      30.400859203673246
    ],
    "SpawnPoint03": [
      26.769039204467237,
      34.28529283968888
    ],
    "SpawnPoint04": [
      21.813156842699847,
      34.223512979728866
    ],
    "SpawnPoint05": [
      17.848616901094704,
      34.461260203188644
    ],
    "SpawnPoint06": [
      15.696981757902025,
      30.281890845826968
    ],
    "SpawnPoint07": [
      11.385285046635842,
      28.354578550170068
    ],
    "SpawnPoint08": [
      9.83356483958809,
      25.766247428534058
    ],
    "SpawnPoint09": [
      15.069538774602178,
      25.77834484924623
    ],
    "SpawnPoint10": [
      9.6783778515892,
      22.441109543211724
    ],
    "SpawnPoint11": [
      12.788986582014656,
      17.296850938562024
    ],
    "SpawnPoint12": [
      17.35643546664955,
      18.71227653566094
    ],
    "SpawnPoint13": [
      22.23710007440476,
      16.406602260044643
    ],
    "SpawnPoint14": [
      27.99310968314965,
      18.783614368837007
    ],
    "SpawnPoint15": [
      34.817042080412776,
      15.14266584323079
    ],
    "SpawnPoint16": [
      27.376774002425044,
      9.01021343143739
    ],
    "SpawnPoint17": [
      19.332973186183196,
      10.14524284590185
    ],
    "SpawnPoint18": [
      13.54033203125,
      10.3701416015625
    ],
    "DisplayPoints": 18,
    "Version": 2
  },
  "Nuckelavee": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      27.7416571506941,
      30.76857438410421
    ],
    "SpawnPoint02": [
      26.923171886338896,
      37.34110059585903
    ],
    "SpawnPoint03": [
      23.1112768954918,
      29.75573743809768
    ],
    "SpawnPoint04": [
      25.503805929409104,
      23.92979815170866
    ],
    "SpawnPoint05": [
      18.456389517697204,
      23.151793781426775
    ],
    "SpawnPoint06": [
      14.098573894169979,
      24.733400201521857
    ],
    "DisplayPoints": 6,
    "Version": 2
  },
  "Nariphon": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      36.7170526088949,
      12.292833040546439
    ],
    "SpawnPoint02": [
      35.49340105256276,
      15.939959112404114
    ],
    "SpawnPoint03": [
      30.89894634702058,
      22.35967725450702
    ],
    "SpawnPoint04": [
      27.7416571506941,
      30.76857438410421
    ],
    "SpawnPoint05": [
      26.923171886338896,
      37.34110059585903
    ],
    "SpawnPoint06": [
      23.1112768954918,
      29.75573743809768
    ],
    "SpawnPoint07": [
      25.503805929409104,
      23.92979815170866
    ],
    "SpawnPoint08": [
      18.456389517697204,
      23.151793781426775
    ],
    "SpawnPoint09": [
      14.098573894169979,
      24.733400201521857
    ],
    "SpawnPoint10": [
      11.675253822364889,
      17.271221534985685
    ],
    "SpawnPoint11": [
      11.728971480093488,
      12.680951175652805
    ],
    "SpawnPoint12": [
      19.713644702672735,
      9.747862230829831
    ],
    "SpawnPoint13": [
      23.140856649709303,
      12.338857648982557
    ],
    "SpawnPoint14": [
      27.497498401799948,
      15.491374908674283
    ],
    "SpawnPoint15": [
      29.566009169629133,
      19.123877065718677
    ],
    "SpawnPoint16": [
      8.066933823754907,
      22.906671675662412
    ],
    "SpawnPoint17": [
      35.84458677070661,
      26.95930831179035
    ],
    "SpawnPoint18": [
      35.30079909990419,
      32.34551023087412
    ],
    "DisplayPoints": 18,
    "Version": 2
  },
  "Tyger": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      36.7170526088949,
      12.292833040546439
    ],
    "SpawnPoint02": [
      35.49340105256276,
      15.939959112404114
    ],
    "SpawnPoint03": [
      30.89894634702058,
      22.35967725450702
    ],
    "SpawnPoint04": [
      27.7416571506941,
      30.76857438410421
    ],
    "SpawnPoint05": [
      26.923171886338896,
      37.34110059585903
    ],
    "SpawnPoint06": [
      23.1112768954918,
      29.75573743809768
    ],
    "SpawnPoint07": [
      25.503805929409104,
      23.92979815170866
    ],
    "SpawnPoint08": [
      18.456389517697204,
      23.151793781426775
    ],
    "SpawnPoint09": [
      14.098573894169979,
      24.733400201521857
    ],
    "SpawnPoint10": [
      11.675253822364889,
      17.271221534985685
    ],
    "SpawnPoint11": [
      11.728971480093488,
      12.680951175652805
    ],
    "SpawnPoint12": [
      19.713644702672735,
      9.747862230829831
    ],
    "SpawnPoint13": [
      23.140856649709303,
      12.338857648982557
    ],
    "SpawnPoint14": [
      27.497498401799948,
      15.491374908674283
    ],
    "SpawnPoint15": [
      29.566009169629133,
      19.123877065718677
    ],
    "SpawnPoint16": [
      8.066933823754907,
      22.906671675662412
    ],
    "SpawnPoint17": [
      35.84458677070661,
      26.95930831179035
    ],
    "SpawnPoint18": [
      35.30079909990419,
      32.34551023087412
    ],
    "DisplayPoints": 15,
    "Version": 2
  },
  "Lil Murderer": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      16.981149807224025,
      7.069263223755411
    ],
    "SpawnPoint02": [
      19.689040501912434,
      10.670515378316244
    ],
    "SpawnPoint03": [
      24.953336321721313,
      11.516194127817624
    ],
    "SpawnPoint04": [
      22.198113221065665,
      14.094741608584005
    ],
    "SpawnPoint05": [
      22.946241600699558,
      17.455745107119846
    ],
    "SpawnPoint06": [
      26.748352828299165,
      19.247563647616442
    ],
    "SpawnPoint07": [
      31.40851116374915,
      19.853512637555234
    ],
    "SpawnPoint08": [
      21.342147514077833,
      22.677652051042596
    ],
    "SpawnPoint09": [
      14.917994283106037,
      15.89560395567705
    ],
    "SpawnPoint10": [
      11.536051103349838,
      18.499724478324577
    ],
    "SpawnPoint11": [
      9.363275572311046,
      25.479783878769986
    ],
    "SpawnPoint12": [
      15.146739702661725,
      24.346244928543353
    ],
    "SpawnPoint13": [
      20.882167373986984,
      31.2738970023944
    ],
    "SpawnPoint14": [
      24.583333333333332,
      30.2147216796875
    ],
    "SpawnPoint15": [
      26.71904566892281,
      24.277142587125574
    ],
    "SpawnPoint16": [
      29.91926601203396,
      29.916035076449184
    ],
    "SpawnPoint17": [
      34.54608414556826,
      24.48921617090784
    ],
    "DisplayPoints": 17,
    "Version": 2
  },
  "Huracan": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      16.981149807224025,
      7.069263223755411
    ],
    "SpawnPoint02": [
      19.689040501912434,
      10.670515378316244
    ],
    "SpawnPoint03": [
      24.953336321721313,
      11.516194127817624
    ],
    "SpawnPoint04": [
      22.198113221065665,
      14.094741608584005
    ],
    "SpawnPoint05": [
      22.946241600699558,
      17.455745107119846
    ],
    "SpawnPoint06": [
      26.748352828299165,
      19.247563647616442
    ],
    "SpawnPoint07": [
      31.40851116374915,
      19.853512637555234
    ],
    "SpawnPoint08": [
      21.342147514077833,
      22.677652051042596
    ],
    "SpawnPoint09": [
      14.917994283106037,
      15.89560395567705
    ],
    "SpawnPoint10": [
      11.536051103349838,
      18.499724478324577
    ],
    "SpawnPoint11": [
      9.363275572311046,
      25.479783878769986
    ],
    "SpawnPoint12": [
      15.146739702661725,
      24.346244928543353
    ],
    "SpawnPoint13": [
      20.882167373986984,
      31.2738970023944
    ],
    "SpawnPoint14": [
      24.583333333333332,
      30.2147216796875
    ],
    "SpawnPoint15": [
      26.71904566892281,
      24.277142587125574
    ],
    "SpawnPoint16": [
      29.91926601203396,
      29.916035076449184
    ],
    "SpawnPoint17": [
      34.54608414556826,
      24.48921617090784
    ],
    "DisplayPoints": 10,
    "Version": 2
  },
  "Forgiven Pedantry": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      16.981149807224025,
      7.069263223755411
    ],
    "SpawnPoint02": [
      19.689040501912434,
      10.670515378316244
    ],
    "SpawnPoint03": [
      24.953336321721313,
      11.516194127817624
    ],
    "SpawnPoint04": [
      22.198113221065665,
      14.094741608584005
    ],
    "SpawnPoint05": [
      22.946241600699558,
      17.455745107119846
    ],
    "SpawnPoint06": [
      26.748352828299165,
      19.247563647616442
    ],
    "SpawnPoint07": [
      31.40851116374915,
      19.853512637555234
    ],
    "SpawnPoint08": [
      21.342147514077833,
      22.677652051042596
    ],
    "SpawnPoint09": [
      14.917994283106037,
      15.89560395567705
    ],
    "SpawnPoint10": [
      11.536051103349838,
      18.499724478324577
    ],
    "SpawnPoint11": [
      9.363275572311046,
      25.479783878769986
    ],
    "SpawnPoint12": [
      15.146739702661725,
      24.346244928543353
    ],
    "SpawnPoint13": [
      20.882167373986984,
      31.2738970023944
    ],
    "SpawnPoint14": [
      24.583333333333332,
      30.2147216796875
    ],
    "SpawnPoint15": [
      26.71904566892281,
      24.277142587125574
    ],
    "SpawnPoint16": [
      29.91926601203396,
      29.916035076449184
    ],
    "SpawnPoint17": [
      34.54608414556826,
      24.48921617090784
    ],
    "DisplayPoints": 17,
    "Version": 2
  },
  "Maliktender": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      28.70709452285477,
      12.327865735619469
    ],
    "SpawnPoint02": [
      30.882130584810465,
      13.826006117153929
    ],
    "SpawnPoint03": [
      28.745770024318322,
      20.291198132084865
    ],
    "SpawnPoint04": [
      33.54934732945661,
      21.687720280126076
    ],
    "SpawnPoint05": [
      28.505884420955883,
      26.136937959558825
    ],
    "SpawnPoint06": [
      32.90854828042328,
      33.96104600694444
    ],
    "SpawnPoint07": [
      30.49253864052855,
      35.16734634211034
    ],
    "SpawnPoint08": [
      19.96521182936065,
      36.600471963687816
    ],
    "SpawnPoint09": [
      23.288674814485788,
      29.877497583089465
    ],
    "SpawnPoint10": [
      19.098034817331758,
      24.90225141650445
    ],
    "SpawnPoint11": [
      16.497703229398663,
      24.093064340026448
    ],
    "SpawnPoint12": [
      11.742772420247396,
      19.512997358273235
    ],
    "SpawnPoint13": [
      10.335917372375954,
      11.993290046517176
    ],
    "SpawnPoint14": [
      16.708545364760486,
      10.340178128543084
    ],
    "SpawnPoint15": [
      19.253932912038277,
      16.183785612118577
    ],
    "SpawnPoint16": [
      17.09774688225058,
      31.722757644830338
    ],
    "SpawnPoint17": [
      19.788501674107142,
      28.92587332589286
    ],
    "SpawnPoint18": [
      22.597190831417223,
      10.475766389266305
    ],
    "DisplayPoints": 9,
    "Version": 2
  },
  "Sugaar": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      28.70709452285477,
      12.327865735619469
    ],
    "SpawnPoint02": [
      30.882130584810465,
      13.826006117153929
    ],
    "SpawnPoint03": [
      28.745770024318322,
      20.291198132084865
    ],
    "SpawnPoint04": [
      33.54934732945661,
      21.687720280126076
    ],
    "SpawnPoint05": [
      28.505884420955883,
      26.136937959558825
    ],
    "SpawnPoint06": [
      32.90854828042328,
      33.96104600694444
    ],
    "SpawnPoint07": [
      30.49253864052855,
      35.16734634211034
    ],
    "SpawnPoint08": [
      19.96521182936065,
      36.600471963687816
    ],
    "SpawnPoint09": [
      17.09774688225058,
      31.722757644830338
    ],
    "SpawnPoint10": [
      19.098034817331758,
      24.90225141650445
    ],
    "SpawnPoint11": [
      16.497703229398663,
      24.093064340026448
    ],
    "SpawnPoint12": [
      11.742772420247396,
      19.512997358273235
    ],
    "SpawnPoint13": [
      10.335917372375954,
      11.993290046517176
    ],
    "SpawnPoint14": [
      16.708545364760486,
      10.340178128543084
    ],
    "SpawnPoint15": [
      19.253932912038277,
      16.183785612118577
    ],
    "SpawnPoint16": [
      23.288674814485788,
      29.877497583089465
    ],
    "SpawnPoint17": [
      19.788501674107142,
      28.92587332589286
    ],
    "SpawnPoint18": [
      22.597190831417223,
      10.475766389266305
    ],
    "DisplayPoints": 17,
    "Version": 2
  },
  "Tarchia": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      28.70709452285477,
      12.327865735619469
    ],
    "SpawnPoint02": [
      30.882130584810465,
      13.826006117153929
    ],
    "SpawnPoint03": [
      28.745770024318322,
      20.291198132084865
    ],
    "SpawnPoint04": [
      33.54934732945661,
      21.687720280126076
    ],
    "SpawnPoint05": [
      28.505884420955883,
      26.136937959558825
    ],
    "SpawnPoint06": [
      32.90854828042328,
      33.96104600694444
    ],
    "SpawnPoint07": [
      30.49253864052855,
      35.16734634211034
    ],
    "SpawnPoint08": [
      19.96521182936065,
      36.600471963687816
    ],
    "SpawnPoint09": [
      17.09774688225058,
      31.722757644830338
    ],
    "SpawnPoint10": [
      19.098034817331758,
      24.90225141650445
    ],
    "SpawnPoint11": [
      16.497703229398663,
      24.093064340026448
    ],
    "SpawnPoint12": [
      11.742772420247396,
      19.512997358273235
    ],
    "SpawnPoint13": [
      10.335917372375954,
      11.993290046517176
    ],
    "SpawnPoint14": [
      16.708545364760486,
      10.340178128543084
    ],
    "SpawnPoint15": [
      19.253932912038277,
      16.183785612118577
    ],
    "SpawnPoint16": [
      23.288674814485788,
      29.877497583089465
    ],
    "SpawnPoint17": [
      19.788501674107142,
      28.92587332589286
    ],
    "SpawnPoint18": [
      22.597190831417223,
      10.475766389266305
    ],
    "DisplayPoints": 15,
    "Version": 2
  },
  "The Mudman": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      19.302376752943214,
      27.304998593317176
    ],
    "SpawnPoint02": [
      22.94362429058395,
      28.688841030571815
    ],
    "SpawnPoint03": [
      24.5442098530155,
      32.95034896973972
    ],
    "SpawnPoint04": [
      24.906948551523655,
      37.260155231931634
    ],
    "SpawnPoint05": [
      19.731884923474542,
      34.974643838816675
    ],
    "SpawnPoint06": [
      13.73010008321006,
      34.123345044378695
    ],
    "SpawnPoint07": [
      9.732849187653354,
      30.735891760240595
    ],
    "SpawnPoint08": [
      8.067788058350988,
      27.104168045992232
    ],
    "SpawnPoint09": [
      7.523503887856828,
      22.902521933858154
    ],
    "SpawnPoint10": [
      10.578225363248562,
      20.278431656969143
    ],
    "SpawnPoint11": [
      11.15178906029557,
      15.90978315021868
    ],
    "SpawnPoint12": [
      20.068317794436847,
      8.635279690845028
    ],
    "SpawnPoint13": [
      25.57834618106844,
      7.055479424390694
    ],
    "SpawnPoint14": [
      29.367739100800605,
      5.405352200843437
    ],
    "SpawnPoint15": [
      34.10657019261509,
      7.274766224424552
    ],
    "SpawnPoint16": [
      31.518906704215116,
      13.727641260901162
    ],
    "SpawnPoint17": [
      27.155082370923914,
      18.970167404723565
    ],
    "DisplayPoints": 17,
    "Version": 2
  },
  "O Poorest Pauldia": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      19.302376752943214,
      27.304998593317176
    ],
    "SpawnPoint02": [
      22.94362429058395,
      28.688841030571815
    ],
    "SpawnPoint03": [
      24.5442098530155,
      32.95034896973972
    ],
    "SpawnPoint04": [
      24.906948551523655,
      37.260155231931634
    ],
    "SpawnPoint05": [
      19.731884923474542,
      34.974643838816675
    ],
    "SpawnPoint06": [
      13.73010008321006,
      34.123345044378695
    ],
    "SpawnPoint07": [
      9.732849187653354,
      30.735891760240595
    ],
    "SpawnPoint08": [
      8.067788058350988,
      27.104168045992232
    ],
    "SpawnPoint09": [
      7.523503887856828,
      22.902521933858154
    ],
    "SpawnPoint10": [
      10.578225363248562,
      20.278431656969143
    ],
    "SpawnPoint11": [
      11.15178906029557,
      15.90978315021868
    ],
    "SpawnPoint12": [
      20.068317794436847,
      8.635279690845028
    ],
    "SpawnPoint13": [
      25.57834618106844,
      7.055479424390694
    ],
    "SpawnPoint14": [
      29.367739100800605,
      5.405352200843437
    ],
    "SpawnPoint15": [
      34.10657019261509,
      7.274766224424552
    ],
    "SpawnPoint16": [
      31.518906704215116,
      13.727641260901162
    ],
    "SpawnPoint17": [
      27.155082370923914,
      18.970167404723565
    ],
    "DisplayPoints": 17,
    "Version": 2
  },
  "Aglaope": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      19.302376752943214,
      27.304998593317176
    ],
    "SpawnPoint02": [
      22.94362429058395,
      28.688841030571815
    ],
    "SpawnPoint03": [
      24.5442098530155,
      32.95034896973972
    ],
    "SpawnPoint04": [
      24.906948551523655,
      37.260155231931634
    ],
    "SpawnPoint05": [
      19.731884923474542,
      34.974643838816675
    ],
    "SpawnPoint06": [
      13.73010008321006,
      34.123345044378695
    ],
    "SpawnPoint07": [
      9.732849187653354,
      30.735891760240595
    ],
    "SpawnPoint08": [
      8.067788058350988,
      27.104168045992232
    ],
    "SpawnPoint09": [
      7.523503887856828,
      22.902521933858154
    ],
    "SpawnPoint10": [
      10.578225363248562,
      20.278431656969143
    ],
    "SpawnPoint11": [
      11.15178906029557,
      15.90978315021868
    ],
    "SpawnPoint12": [
      20.068317794436847,
      8.635279690845028
    ],
    "SpawnPoint13": [
      25.57834618106844,
      7.055479424390694
    ],
    "SpawnPoint14": [
      29.367739100800605,
      5.405352200843437
    ],
    "SpawnPoint15": [
      34.10657019261509,
      7.274766224424552
    ],
    "SpawnPoint16": [
      31.518906704215116,
      13.727641260901162
    ],
    "SpawnPoint17": [
      27.155082370923914,
      18.970167404723565
    ],
    "DisplayPoints": 17,
    "Version": 2
  },
  "Grassman": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      12.119945349322467,
      35.9179940654458
    ],
    "SpawnPoint02": [
      8.51339772140097,
      34.74379064915079
    ],
    "SpawnPoint03": [
      16.89070194564617,
      24.26188942071674
    ],
    "SpawnPoint04": [
      33.38851120831923,
      22.91597859893365
    ],
    "SpawnPoint05": [
      29.52003575410199,
      25.886032869093818
    ],
    "SpawnPoint06": [
      26.194423438683113,
      24.10742014962456
    ],
    "SpawnPoint07": [
      25.152134486607142,
      30.31639968487395
    ],
    "SpawnPoint08": [
      9.463894801251122,
      18.707778574534334
    ],
    "SpawnPoint09": [
      9.885103785407384,
      24.042709845938095
    ],
    "SpawnPoint10": [
      14.559194360123142,
      22.297541986275455
    ],
    "SpawnPoint11": [
      15.116239602767413,
      30.315316726912314
    ],
    "SpawnPoint12": [
      17.748935466167357,
      35.126037496771694
    ],
    "SpawnPoint13": [
      22.549195083524715,
      10.753837899168854
    ],
    "SpawnPoint14": [
      21.952584402901785,
      13.516530977625425
    ],
    "SpawnPoint15": [
      26.34315120941417,
      15.11376953125
    ],
    "DisplayPoints": 12,
    "Version": 2
  },
  "Supay": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      22.549195083524715,
      10.753837899168854
    ],
    "SpawnPoint02": [
      21.952584402901785,
      13.516530977625425
    ],
    "SpawnPoint03": [
      26.34315120941417,
      15.11376953125
    ],
    "SpawnPoint04": [
      33.38851120831923,
      22.91597859893365
    ],
    "SpawnPoint05": [
      29.52003575410199,
      25.886032869093818
    ],
    "SpawnPoint06": [
      26.194423438683113,
      24.10742014962456
    ],
    "SpawnPoint07": [
      25.152134486607142,
      30.31639968487395
    ],
    "SpawnPoint08": [
      9.463894801251122,
      18.707778574534334
    ],
    "SpawnPoint09": [
      9.885103785407384,
      24.042709845938095
    ],
    "SpawnPoint10": [
      14.559194360123142,
      22.297541986275455
    ],
    "SpawnPoint11": [
      15.116239602767413,
      30.315316726912314
    ],
    "SpawnPoint12": [
      17.748935466167357,
      35.126037496771694
    ],
    "SpawnPoint13": [
      12.119945349322467,
      35.9179940654458
    ],
    "SpawnPoint14": [
      8.51339772140097,
      34.74379064915079
    ],
    "SpawnPoint15": [
      16.89070194564617,
      24.26188942071674
    ],
    "DisplayPoints": 15,
    "Version": 2
  },
  "Ixtab": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      22.549195083524715,
      10.753837899168854
    ],
    "SpawnPoint02": [
      21.952584402901785,
      13.516530977625425
    ],
    "SpawnPoint03": [
      26.34315120941417,
      15.11376953125
    ],
    "SpawnPoint04": [
      33.38851120831923,
      22.91597859893365
    ],
    "SpawnPoint05": [
      29.52003575410199,
      25.886032869093818
    ],
    "SpawnPoint06": [
      26.194423438683113,
      24.10742014962456
    ],
    "SpawnPoint07": [
      25.152134486607142,
      30.31639968487395
    ],
    "SpawnPoint08": [
      9.463894801251122,
      18.707778574534334
    ],
    "SpawnPoint09": [
      9.885103785407384,
      24.042709845938095
    ],
    "SpawnPoint10": [
      14.559194360123142,
      22.297541986275455
    ],
    "SpawnPoint11": [
      15.116239602767413,
      30.315316726912314
    ],
    "SpawnPoint12": [
      17.748935466167357,
      35.126037496771694
    ],
    "SpawnPoint13": [
      12.119945349322467,
      35.9179940654458
    ],
    "SpawnPoint14": [
      8.51339772140097,
      34.74379064915079
    ],
    "SpawnPoint15": [
      16.89070194564617,
      24.26188942071674
    ],
    "DisplayPoints": 15,
    "Version": 2
  },
  "Rusalka": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      31.011395222891146,
      4.096785173649777
    ],
    "SpawnPoint02": [
      28.763872108492617,
      8.350455276661599
    ],
    "SpawnPoint03": [
      30.762717202187275,
      10.852851873192336
    ],
    "SpawnPoint04": [
      35.94151214765767,
      11.61531102895304
    ],
    "SpawnPoint05": [
      37.54891402159686,
      16.521194985274867
    ],
    "SpawnPoint06": [
      36.10630808023572,
      19.710729438179964
    ],
    "SpawnPoint07": [
      33.754790538877955,
      21.75911894069882
    ],
    "SpawnPoint08": [
      29.148989430574954,
      22.928507259660147
    ],
    "SpawnPoint09": [
      24.763701978652897,
      25.138913908369297
    ],
    "SpawnPoint10": [
      15.552435951765574,
      20.115620384667025
    ],
    "SpawnPoint11": [
      13.602187917577746,
      17.333914146015548
    ],
    "SpawnPoint12": [
      15.545005063657408,
      10.668183697089948
    ],
    "SpawnPoint13": [
      18.29634332705567,
      13.477852779462461
    ],
    "SpawnPoint14": [
      25.09182690507688,
      12.722474899535742
    ],
    "SpawnPoint15": [
      21.17007201993329,
      7.482334393861182
    ],
    "SpawnPoint16": [
      8.79852786009339,
      8.67553430316092
    ],
    "SpawnPoint17": [
      11.217057413129664,
      5.089400106401586
    ],
    "DisplayPoints": 17,
    "Version": 2
  },
  "Baal": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      31.011395222891146,
      4.096785173649777
    ],
    "SpawnPoint02": [
      28.763872108492617,
      8.350455276661599
    ],
    "SpawnPoint03": [
      30.762717202187275,
      10.852851873192336
    ],
    "SpawnPoint04": [
      35.94151214765767,
      11.61531102895304
    ],
    "SpawnPoint05": [
      37.54891402159686,
      16.521194985274867
    ],
    "SpawnPoint06": [
      36.10630808023572,
      19.710729438179964
    ],
    "SpawnPoint07": [
      33.754790538877955,
      21.75911894069882
    ],
    "SpawnPoint08": [
      29.148989430574954,
      22.928507259660147
    ],
    "SpawnPoint09": [
      24.763701978652897,
      25.138913908369297
    ],
    "SpawnPoint10": [
      15.552435951765574,
      20.115620384667025
    ],
    "SpawnPoint11": [
      13.602187917577746,
      17.333914146015548
    ],
    "SpawnPoint12": [
      15.545005063657408,
      10.668183697089948
    ],
    "SpawnPoint13": [
      18.29634332705567,
      13.477852779462461
    ],
    "SpawnPoint14": [
      25.09182690507688,
      12.722474899535742
    ],
    "SpawnPoint15": [
      21.17007201993329,
      7.482334393861182
    ],
    "SpawnPoint16": [
      8.79852786009339,
      8.67553430316092
    ],
    "SpawnPoint17": [
      11.217057413129664,
      5.089400106401586
    ],
    "DisplayPoints": 17,
    "Version": 2
  },
  "Gunitt": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      31.011395222891146,
      4.096785173649777
    ],
    "SpawnPoint02": [
      28.763872108492617,
      8.350455276661599
    ],
    "SpawnPoint03": [
      30.762717202187275,
      10.852851873192336
    ],
    "SpawnPoint04": [
      35.94151214765767,
      11.61531102895304
    ],
    "SpawnPoint05": [
      37.54891402159686,
      16.521194985274867
    ],
    "SpawnPoint06": [
      36.10630808023572,
      19.710729438179964
    ],
    "SpawnPoint07": [
      33.754790538877955,
      21.75911894069882
    ],
    "SpawnPoint08": [
      29.148989430574954,
      22.928507259660147
    ],
    "SpawnPoint09": [
      24.763701978652897,
      25.138913908369297
    ],
    "SpawnPoint10": [
      15.552435951765574,
      20.115620384667025
    ],
    "SpawnPoint11": [
      13.602187917577746,
      17.333914146015548
    ],
    "SpawnPoint12": [
      15.545005063657408,
      10.668183697089948
    ],
    "SpawnPoint13": [
      18.29634332705567,
      13.477852779462461
    ],
    "SpawnPoint14": [
      25.09182690507688,
      12.722474899535742
    ],
    "SpawnPoint15": [
      21.17007201993329,
      7.482334393861182
    ],
    "SpawnPoint16": [
      8.79852786009339,
      8.67553430316092
    ],
    "SpawnPoint17": [
      11.217057413129664,
      5.089400106401586
    ],
    "DisplayPoints": 15,
    "Version": 2
  },
  "Hulder": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      16.92082948626894,
      9.630132773042929
    ],
    "SpawnPoint02": [
      30.035920644989233,
      8.410726547545462
    ],
    "SpawnPoint03": [
      34.19126450210033,
      13.584449883068807
    ],
    "SpawnPoint04": [
      32.277371651785714,
      25.9683968823185
    ],
    "SpawnPoint05": [
      25.480938071956345,
      25.13695823023997
    ],
    "SpawnPoint06": [
      16.538580216179028,
      16.775942727947566
    ],
    "SpawnPoint07": [
      10.628687462934897,
      19.383021634694583
    ],
    "SpawnPoint08": [
      5.968116425929874,
      33.61838802356665
    ],
    "SpawnPoint09": [
      12.181365289196691,
      35.395068523021834
    ],
    "SpawnPoint10": [
      19.599740331139287,
      38.46623966946616
    ],
    "DisplayPoints": 10,
    "Version": 2
  },
  "Storsie": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      16.92082948626894,
      9.630132773042929
    ],
    "SpawnPoint02": [
      30.035920644989233,
      8.410726547545462
    ],
    "SpawnPoint03": [
      34.19126450210033,
      13.584449883068807
    ],
    "SpawnPoint04": [
      32.277371651785714,
      25.9683968823185
    ],
    "SpawnPoint05": [
      25.480938071956345,
      25.13695823023997
    ],
    "SpawnPoint06": [
      16.538580216179028,
      16.775942727947566
    ],
    "SpawnPoint07": [
      10.628687462934897,
      19.383021634694583
    ],
    "SpawnPoint08": [
      5.968116425929874,
      33.61838802356665
    ],
    "SpawnPoint09": [
      12.181365289196691,
      35.395068523021834
    ],
    "SpawnPoint10": [
      19.599740331139287,
      38.46623966946616
    ],
    "DisplayPoints": 10,
    "Version": 2
  },
  "Burfurlur the Canny": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      16.92082948626894,
      9.630132773042929
    ],
    "SpawnPoint02": [
      30.035920644989233,
      8.410726547545462
    ],
    "SpawnPoint03": [
      34.19126450210033,
      13.584449883068807
    ],
    "SpawnPoint04": [
      32.277371651785714,
      25.9683968823185
    ],
    "SpawnPoint05": [
      25.480938071956345,
      25.13695823023997
    ],
    "SpawnPoint06": [
      16.538580216179028,
      16.775942727947566
    ],
    "SpawnPoint07": [
      10.628687462934897,
      19.383021634694583
    ],
    "SpawnPoint08": [
      5.968116425929874,
      33.61838802356665
    ],
    "SpawnPoint09": [
      12.181365289196691,
      35.395068523021834
    ],
    "SpawnPoint10": [
      19.599740331139287,
      38.46623966946616
    ],
    "DisplayPoints": 10,
    "Version": 2
  },
  "Yilan": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      29.600958139760397,
      13.600322180497324
    ],
    "SpawnPoint02": [
      26.684260824303752,
      20.83935546875
    ],
    "SpawnPoint03": [
      32.69497924620569,
      20.201895950186966
    ],
    "SpawnPoint04": [
      27.64138923430979,
      25.581893301772983
    ],
    "SpawnPoint05": [
      18.455764527447457,
      11.550507780091952
    ],
    "SpawnPoint06": [
      14.43825901144802,
      12.21276196223138
    ],
    "SpawnPoint07": [
      17.93062498634042,
      16.40688443877073
    ],
    "SpawnPoint08": [
      18.4024200904709,
      23.505943980982547
    ],
    "SpawnPoint09": [
      20.366826003634866,
      31.27287647963349
    ],
    "DisplayPoints": 9,
    "Version": 2
  },
  "Sugriva": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      29.600958139760397,
      13.600322180497324
    ],
    "SpawnPoint02": [
      26.684260824303752,
      20.83935546875
    ],
    "SpawnPoint03": [
      32.69497924620569,
      20.201895950186966
    ],
    "SpawnPoint04": [
      27.64138923430979,
      25.581893301772983
    ],
    "SpawnPoint05": [
      18.455764527447457,
      11.550507780091952
    ],
    "SpawnPoint06": [
      14.43825901144802,
      12.21276196223138
    ],
    "SpawnPoint07": [
      17.93062498634042,
      16.40688443877073
    ],
    "SpawnPoint08": [
      18.4024200904709,
      23.505943980982547
    ],
    "SpawnPoint09": [
      20.366826003634866,
      31.27287647963349
    ],
    "DisplayPoints": 9,
    "Version": 2
  },
  "Sphatika": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      29.600958139760397,
      13.600322180497324
    ],
    "SpawnPoint02": [
      26.684260824303752,
      20.83935546875
    ],
    "SpawnPoint03": [
      32.69497924620569,
      20.201895950186966
    ],
    "SpawnPoint04": [
      27.64138923430979,
      25.581893301772983
    ],
    "SpawnPoint05": [
      18.455764527447457,
      11.550507780091952
    ],
    "SpawnPoint06": [
      14.43825901144802,
      12.21276196223138
    ],
    "SpawnPoint07": [
      17.93062498634042,
      16.40688443877073
    ],
    "SpawnPoint08": [
      18.4024200904709,
      23.505943980982547
    ],
    "SpawnPoint09": [
      20.366826003634866,
      31.27287647963349
    ],
    "DisplayPoints": 9,
    "Version": 2
  },
  "Minerva": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      9.780808396238642,
      11.644756054186006
    ],
    "SpawnPoint02": [
      12.127093637589928,
      12.820906165692445
    ],
    "SpawnPoint03": [
      11.755030732746432,
      17.21695592537411
    ],
    "SpawnPoint04": [
      15.803763275425517,
      19.648358100207485
    ],
    "SpawnPoint05": [
      23.462431195016435,
      25.76899395180781
    ],
    "SpawnPoint06": [
      29.047820479679913,
      20.910739623606364
    ],
    "SpawnPoint07": [
      33.010767493837754,
      21.85711487181886
    ],
    "SpawnPoint08": [
      27.567772516766127,
      33.9989255912573
    ],
    "SpawnPoint09": [
      32.478304280572424,
      32.564635653351196
    ],
    "DisplayPoints": 9,
    "Version": 2
  },
  "Aegeiros": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      9.780808396238642,
      11.644756054186006
    ],
    "SpawnPoint02": [
      12.127093637589928,
      12.820906165692445
    ],
    "SpawnPoint03": [
      11.755030732746432,
      17.21695592537411
    ],
    "SpawnPoint04": [
      15.803763275425517,
      19.648358100207485
    ],
    "SpawnPoint05": [
      23.462431195016435,
      25.76899395180781
    ],
    "SpawnPoint06": [
      29.047820479679913,
      20.910739623606364
    ],
    "SpawnPoint07": [
      33.010767493837754,
      21.85711487181886
    ],
    "SpawnPoint08": [
      27.567772516766127,
      33.9989255912573
    ],
    "SpawnPoint09": [
      32.478304280572424,
      32.564635653351196
    ],
    "DisplayPoints": 9,
    "Version": 2
  },
  "Armstrong": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      9.780808396238642,
      11.644756054186006
    ],
    "SpawnPoint02": [
      12.127093637589928,
      12.820906165692445
    ],
    "SpawnPoint03": [
      11.755030732746432,
      17.21695592537411
    ],
    "SpawnPoint04": [
      15.803763275425517,
      19.648358100207485
    ],
    "SpawnPoint05": [
      23.462431195016435,
      25.76899395180781
    ],
    "SpawnPoint06": [
      29.047820479679913,
      20.910739623606364
    ],
    "SpawnPoint07": [
      33.010767493837754,
      21.85711487181886
    ],
    "SpawnPoint08": [
      27.567772516766127,
      33.9989255912573
    ],
    "SpawnPoint09": [
      32.478304280572424,
      32.564635653351196
    ],
    "DisplayPoints": 9,
    "Version": 2
  },
  "Lunatender Queen": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      10.389166579972525,
      24.09223541156947
    ],
    "SpawnPoint02": [
      18.41949512789756,
      21.744370014955134
    ],
    "SpawnPoint03": [
      17.22250677614796,
      24.802362351190474
    ],
    "SpawnPoint04": [
      24.4250657211826,
      23.54638366362256
    ],
    "SpawnPoint05": [
      16.525154555464844,
      28.862770999637156
    ],
    "SpawnPoint06": [
      28.215389476102942,
      26.6873779296875
    ],
    "SpawnPoint07": [
      36.49414591945649,
      27.19748587439008
    ],
    "SpawnPoint08": [
      21.010046832898897,
      34.65122471493283
    ],
    "SpawnPoint09": [
      24.29707350863289,
      33.53871887784531
    ],
    "SpawnPoint10": [
      29.932600027159715,
      30.026376927152594
    ],
    "DisplayPoints": 10,
    "Version": 2
  },
  "Mousse Princess": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      10.389166579972525,
      24.09223541156947
    ],
    "SpawnPoint02": [
      18.41949512789756,
      21.744370014955134
    ],
    "SpawnPoint03": [
      17.22250677614796,
      24.802362351190474
    ],
    "SpawnPoint04": [
      24.4250657211826,
      23.54638366362256
    ],
    "SpawnPoint05": [
      16.525154555464844,
      28.862770999637156
    ],
    "SpawnPoint06": [
      28.215389476102942,
      26.6873779296875
    ],
    "SpawnPoint07": [
      36.49414591945649,
      27.19748587439008
    ],
    "SpawnPoint08": [
      21.010046832898897,
      34.65122471493283
    ],
    "SpawnPoint09": [
      24.29707350863289,
      33.53871887784531
    ],
    "SpawnPoint10": [
      29.932600027159715,
      30.026376927152594
    ],
    "DisplayPoints": 10,
    "Version": 2
  },
  "Ruminator": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      10.389166579972525,
      24.09223541156947
    ],
    "SpawnPoint02": [
      18.41949512789756,
      21.744370014955134
    ],
    "SpawnPoint03": [
      17.22250677614796,
      24.802362351190474
    ],
    "SpawnPoint04": [
      24.4250657211826,
      23.54638366362256
    ],
    "SpawnPoint05": [
      16.525154555464844,
      28.862770999637156
    ],
    "SpawnPoint06": [
      28.215389476102942,
      26.6873779296875
    ],
    "SpawnPoint07": [
      36.49414591945649,
      27.19748587439008
    ],
    "SpawnPoint08": [
      21.010046832898897,
      34.65122471493283
    ],
    "SpawnPoint09": [
      24.29707350863289,
      33.53871887784531
    ],
    "SpawnPoint10": [
      29.932600027159715,
      30.026376927152594
    ],
    "DisplayPoints": 10,
    "Version": 2
  },
  "Petalodus": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      6.963797826522436,
      29.07644293369391
    ],
    "SpawnPoint02": [
      12.927025143571003,
      32.18443338398717
    ],
    "SpawnPoint03": [
      17.90770521043796,
      30.29064422019848
    ],
    "SpawnPoint04": [
      18.694780353003054,
      24.605971897445862
    ],
    "SpawnPoint05": [
      29.698235432621047,
      27.55682312735456
    ],
    "SpawnPoint06": [
      32.711806945121104,
      18.55955889389616
    ],
    "SpawnPoint07": [
      34.31205849414197,
      14.245028572249023
    ],
    "SpawnPoint08": [
      33.928441948960014,
      10.788467333274435
    ],
    "SpawnPoint09": [
      21.36685738211673,
      13.355662447320293
    ],
    "SpawnPoint10": [
      21.685686905082616,
      6.156438717896911
    ],
    "SpawnPoint11": [
      12.591798316572648,
      10.04007221867092
    ],
    "DisplayPoints": 11,
    "Version": 2
  },
  "Gurangatch": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      6.963797826522436,
      29.07644293369391
    ],
    "SpawnPoint02": [
      12.927025143571003,
      32.18443338398717
    ],
    "SpawnPoint03": [
      17.90770521043796,
      30.29064422019848
    ],
    "SpawnPoint04": [
      18.694780353003054,
      24.605971897445862
    ],
    "SpawnPoint05": [
      29.698235432621047,
      27.55682312735456
    ],
    "SpawnPoint06": [
      32.711806945121104,
      18.55955889389616
    ],
    "SpawnPoint07": [
      34.31205849414197,
      14.245028572249023
    ],
    "SpawnPoint08": [
      33.928441948960014,
      10.788467333274435
    ],
    "SpawnPoint09": [
      21.36685738211673,
      13.355662447320293
    ],
    "SpawnPoint10": [
      21.685686905082616,
      6.156438717896911
    ],
    "SpawnPoint11": [
      12.591798316572648,
      10.04007221867092
    ],
    "DisplayPoints": 11,
    "Version": 2
  },
  "Ophioneus": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      6.963797826522436,
      29.07644293369391
    ],
    "SpawnPoint02": [
      12.927025143571003,
      32.18443338398717
    ],
    "SpawnPoint03": [
      17.90770521043796,
      30.29064422019848
    ],
    "SpawnPoint04": [
      18.694780353003054,
      24.605971897445862
    ],
    "SpawnPoint05": [
      29.698235432621047,
      27.55682312735456
    ],
    "SpawnPoint06": [
      32.711806945121104,
      18.55955889389616
    ],
    "SpawnPoint07": [
      34.31205849414197,
      14.245028572249023
    ],
    "SpawnPoint08": [
      33.928441948960014,
      10.788467333274435
    ],
    "SpawnPoint09": [
      21.36685738211673,
      13.355662447320293
    ],
    "SpawnPoint10": [
      21.685686905082616,
      6.156438717896911
    ],
    "SpawnPoint11": [
      12.591798316572648,
      10.04007221867092
    ],
    "DisplayPoints": 11,
    "Version": 2
  },
  "Arch-Eta": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      13.249077108496293,
      10.441880302195168
    ],
    "SpawnPoint02": [
      19.300102059527486,
      9.831599517690318
    ],
    "SpawnPoint03": [
      27.9817510748609,
      12.438831391734109
    ],
    "SpawnPoint04": [
      8.204247965085846,
      20.41952208961474
    ],
    "SpawnPoint05": [
      11.911531576861307,
      22.002915290803614
    ],
    "SpawnPoint06": [
      16.3104318202227,
      26.094445941091955
    ],
    "SpawnPoint07": [
      17.647720144965405,
      30.316136997974322
    ],
    "SpawnPoint08": [
      14.908917801381982,
      36.14373313866839
    ],
    "SpawnPoint09": [
      21.198732795085714,
      34.14613043963757
    ],
    "DisplayPoints": 9,
    "Version": 2
  },
  "Fan Ail": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      13.249077108496293,
      10.441880302195168
    ],
    "SpawnPoint02": [
      19.300102059527486,
      9.831599517690318
    ],
    "SpawnPoint03": [
      27.9817510748609,
      12.438831391734109
    ],
    "SpawnPoint04": [
      8.204247965085846,
      20.41952208961474
    ],
    "SpawnPoint05": [
      11.911531576861307,
      22.002915290803614
    ],
    "SpawnPoint06": [
      16.3104318202227,
      26.094445941091955
    ],
    "SpawnPoint07": [
      17.647720144965405,
      30.316136997974322
    ],
    "SpawnPoint08": [
      14.908917801381982,
      36.14373313866839
    ],
    "SpawnPoint09": [
      21.198732795085714,
      34.14613043963757
    ],
    "DisplayPoints": 9,
    "Version": 2
  },
  "Narrow-rift": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      13.249077108496293,
      10.441880302195168
    ],
    "SpawnPoint02": [
      19.300102059527486,
      9.831599517690318
    ],
    "SpawnPoint03": [
      27.9817510748609,
      12.438831391734109
    ],
    "SpawnPoint04": [
      8.204247965085846,
      20.41952208961474
    ],
    "SpawnPoint05": [
      11.911531576861307,
      22.002915290803614
    ],
    "SpawnPoint06": [
      16.3104318202227,
      26.094445941091955
    ],
    "SpawnPoint07": [
      17.647720144965405,
      30.316136997974322
    ],
    "SpawnPoint08": [
      14.908917801381982,
      36.14373313866839
    ],
    "SpawnPoint09": [
      21.198732795085714,
      34.14613043963757
    ],
    "DisplayPoints": 9,
    "Version": 2
  },
  "FATE Noctilucale": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      38.7,18.3
    ],
    "SpawnPoint02": [
      27.7,5.8
    ],
    "SpawnPoint03": [
      6,20
    ],
    "DisplayPoints": 3,
    "Version": 2
  },
  "FATE Coeurlregina": {
    "Dimensions": [
      43,
      43
    ],
    "SpawnPoint01": [
      12,10
    ],
    "SpawnPoint02": [
      30,5
    ],
    "SpawnPoint03": [
      10,36
    ],
    "DisplayPoints": 3,
    "Version": 2
  },
  "FATE Foxy Lady": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      11.7,17.6
    ],
    "SpawnPoint02": [
      16,12
    ],
    "SpawnPoint03": [
      24,34
    ],
    "DisplayPoints": 3,
    "Version": 2
  },
  "FATE Ixion": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      29,9
    ],
    "SpawnPoint02": [
      16,22
    ],
    "SpawnPoint03": [
      4.8,15.7
    ],
    "DisplayPoints": 3,
    "Version": 2
  },
  "FATE Archaeotania": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      27,26.6
    ],
    "DisplayPoints": 1,
    "Version": 2
  },
  "FATE Formidable": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      33.6,22
    ],
    "DisplayPoints": 1,
    "Version": 2
  },
  "FATE Daivadipa": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      9.4,37.8
    ],
    "DisplayPoints": 1,
    "Version": 2
  },
  "FATE Chi": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      34.6,21.6
    ],
    "DisplayPoints": 1,
    "Version": 2
  },
  "Queen Hawk": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 7,
    "Version": 18,
    "SpawnPoint01": [
      19.13203761888587,
      14.214587933084239
    ],
    "SpawnPoint02": [
      11.556561220039441,
      8.972583955931432
    ],
    "SpawnPoint03": [
      21.9154052734375,
      20.750244140625
    ],
    "SpawnPoint04": [
      28.880958897292995,
      9.398087098593418
    ],
    "SpawnPoint05": [
      7.568695873808651,
      25.520520699688415
    ],
    "SpawnPoint06": [
      28.19710968177356,
      22.82946457787958
    ],
    "SpawnPoint07": [
      15.74979674416866,
      24.176151549416865
    ]
  },
  "Nechuciho": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 7,
    "Version": 18,
    "SpawnPoint01": [
      15.74748602802267,
      24.20223330250315
    ],
    "SpawnPoint02": [
      7.570758322010869,
      25.530366847826087
    ],
    "SpawnPoint03": [
      19.14814696797025,
      14.217770625899712
    ],
    "SpawnPoint04": [
      28.19403076171875,
      22.824729919433594
    ],
    "SpawnPoint05": [
      21.92633142605634,
      20.740385673415492
    ],
    "SpawnPoint06": [
      28.874581322556697,
      9.409586764308855
    ],
    "SpawnPoint07": [
      11.549687982915522,
      8.987077985491071
    ]
  },
  "Kirlirger the Abhorrent": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 9,
    "Version": 17,
    "SpawnPoint01": [
      19.14647417887181,
      14.22030698653809
    ],
    "SpawnPoint02": [
      34.09383769132653,
      19.17246392697704
    ],
    "SpawnPoint03": [
      22.04791462465406,
      16.83572008571648
    ],
    "SpawnPoint04": [
      28.878742512462477,
      9.40739616276265
    ],
    "SpawnPoint05": [
      11.55297923298237,
      8.979204766313662
    ],
    "SpawnPoint06": [
      21.91938827762471,
      20.751071348728733
    ],
    "SpawnPoint07": [
      7.565043516995614,
      25.526763637609648
    ],
    "SpawnPoint08": [
      28.205031740351995,
      22.840595429786894
    ],
    "SpawnPoint09": [
      15.7449059683745,
      24.199469409733812
    ]
  },
  "The Raintriller": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 8,
    "Version": 18,
    "SpawnPoint01": [
      6.443653738839286,
      12.018235212053572
    ],
    "SpawnPoint02": [
      24.036818379103536,
      36.634091566708754
    ],
    "SpawnPoint03": [
      20.29567898525281,
      28.362650324789325
    ],
    "SpawnPoint04": [
      5.287914217794342,
      28.792374450496943
    ],
    "SpawnPoint05": [
      9.55937423105315,
      7.818830995734908
    ],
    "SpawnPoint06": [
      16.31524939903846,
      17.27477013221154
    ],
    "SpawnPoint07": [
      36.85601240335052,
      17.439406142611684
    ],
    "SpawnPoint08": [
      15.860306078767124,
      23.63693412885274
    ]
  },
  "Pkuucha": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 8,
    "Version": 18,
    "SpawnPoint01": [
      15.850919296653682,
      23.6329591226983
    ],
    "SpawnPoint02": [
      24.025106375558035,
      36.63340715680803
    ],
    "SpawnPoint03": [
      6.445256106954226,
      12.036006272007043
    ],
    "SpawnPoint04": [
      5.297333731087896,
      28.780820819074208
    ],
    "SpawnPoint05": [
      20.291562152998097,
      28.372339238975254
    ],
    "SpawnPoint06": [
      36.867996970057035,
      17.448062098978138
    ],
    "SpawnPoint07": [
      9.567135959201389,
      7.821432834201389
    ],
    "SpawnPoint08": [
      16.288673741042995,
      17.293411935210987
    ]
  },
  "Ihnuxokiy": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 9,
    "Version": 17,
    "SpawnPoint01": [
      6.441817629269449,
      12.033933693815227
    ],
    "SpawnPoint02": [
      16.306369120101795,
      17.298300080040498
    ],
    "SpawnPoint03": [
      9.548562093029462,
      7.819257545634073
    ],
    "SpawnPoint04": [
      36.86362003657262,
      17.436056075300417
    ],
    "SpawnPoint05": [
      15.856233016304348,
      23.63599694293478
    ],
    "SpawnPoint06": [
      24.02677764892578,
      36.631981658935544
    ],
    "SpawnPoint07": [
      5.281862314107717,
      28.79206597920016
    ],
    "SpawnPoint08": [
      20.29926505921379,
      28.36801002139137
    ],
    "SpawnPoint09": [
      34.54336106846098,
      36.501861395863436
    ]
  },
  "Starcrier": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 7,
    "Version": 18,
    "SpawnPoint01": [
      33.20296037946429,
      16.295951450892858
    ],
    "SpawnPoint02": [
      21.439229600694443,
      36.44764973958333
    ],
    "SpawnPoint03": [
      23.404892139220507,
      14.212215809339888
    ],
    "SpawnPoint04": [
      8.58749589296145,
      19.570360415449766
    ],
    "SpawnPoint05": [
      21.782930897400444,
      28.432604224280972
    ],
    "SpawnPoint06": [
      24.69703209918478,
      33.09537052762681
    ],
    "SpawnPoint07": [
      26.29345703125,
      9.64111328125
    ]
  },
  "Rrax Yity'a": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 7,
    "Version": 18,
    "SpawnPoint01": [
      21.814459331965043,
      28.426248841366526
    ],
    "SpawnPoint02": [
      33.22737540779533,
      16.236073252918956
    ],
    "SpawnPoint03": [
      21.365811941964285,
      36.45264508928572
    ],
    "SpawnPoint04": [
      23.381441463977833,
      14.205888719981527
    ],
    "SpawnPoint05": [
      8.58985756928066,
      19.612403725678067
    ],
    "SpawnPoint06": [
      26.33578166555851,
      9.600944356715425
    ],
    "SpawnPoint07": [
      24.727093337872706,
      33.10508350057339
    ]
  },
  "Neyoozoteel": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 9,
    "Version": 17,
    "SpawnPoint01": [
      21.79528049127466,
      28.430099464150228
    ],
    "SpawnPoint02": [
      33.21894740513393,
      16.271870814732143
    ],
    "SpawnPoint03": [
      21.38818522679766,
      36.446722636653426
    ],
    "SpawnPoint04": [
      23.40227693256579,
      14.24005162646199
    ],
    "SpawnPoint05": [
      8.589294355643359,
      19.580272689176244
    ],
    "SpawnPoint06": [
      26.335920234043492,
      9.611088473523692
    ],
    "SpawnPoint07": [
      24.705878059603886,
      33.100046565536246
    ],
    "SpawnPoint08": [
      13.535296223958333,
      25.713977864583335
    ],
    "SpawnPoint09": [
      6.343187549446203,
      26.381893624736286
    ]
  },
  "Yehehetoaua'pyo": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 8,
    "Version": 18,
    "SpawnPoint01": [
      31.327058638649426,
      23.21374090786638
    ],
    "SpawnPoint02": [
      21.772341170400942,
      33.360155789357314
    ],
    "SpawnPoint03": [
      16.07497756545608,
      8.33688766891892
    ],
    "SpawnPoint04": [
      25.15015330394553,
      23.26965945792598
    ],
    "SpawnPoint05": [
      34.34326827286074,
      6.570535339765101
    ],
    "SpawnPoint06": [
      22.068475402227723,
      28.220417891398515
    ],
    "SpawnPoint07": [
      23.105691528320314,
      18.87994384765625
    ],
    "SpawnPoint08": [
      9.044189453125,
      16.6031494140625
    ]
  },
  "Keheniheyamewi": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 8,
    "Version": 18,
    "SpawnPoint01": [
      16.062376160552535,
      8.310610563858695
    ],
    "SpawnPoint02": [
      23.13992394814944,
      18.896342527932962
    ],
    "SpawnPoint03": [
      9.076830110497237,
      16.573978655904696
    ],
    "SpawnPoint04": [
      21.755964006696427,
      33.380042666480655
    ],
    "SpawnPoint05": [
      34.32565969187063,
      6.556889887456293
    ],
    "SpawnPoint06": [
      31.375044818085748,
      23.236476732336957
    ],
    "SpawnPoint07": [
      25.113930410043352,
      23.292731665462426
    ],
    "SpawnPoint08": [
      22.076899462407177,
      28.202281385365097
    ]
  },
  "Sansheya": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 9,
    "Version": 17,
    "SpawnPoint01": [
      13.308478860294118,
      27.440746830958947
    ],
    "SpawnPoint02": [
      25.118728034764437,
      23.275166520231764
    ],
    "SpawnPoint03": [
      9.055995100635593,
      16.588503965101225
    ],
    "SpawnPoint04": [
      22.06995287698413,
      28.219793991815475
    ],
    "SpawnPoint05": [
      21.766476986345307,
      33.37316715542522
    ],
    "SpawnPoint06": [
      31.35893862503863,
      23.22544108274104
    ],
    "SpawnPoint07": [
      16.07533367226112,
      8.336119298290773
    ],
    "SpawnPoint08": [
      34.33221414856992,
      6.5730642545021185
    ],
    "SpawnPoint09": [
      23.110224545547386,
      18.9026162939134
    ]
  },
  "Heshuala": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 7,
    "Version": 18,
    "SpawnPoint01": [
      29.607332669771633,
      29.90637441781851
    ],
    "SpawnPoint02": [
      14.58144066220238,
      26.061116536458332
    ],
    "SpawnPoint03": [
      29.62429823764535,
      19.55605695857558
    ],
    "SpawnPoint04": [
      8.206000832950368,
      33.400139303768384
    ],
    "SpawnPoint05": [
      27.47237215909091,
      33.69735440340909
    ],
    "SpawnPoint06": [
      27.16542520328444,
      13.75908053651148
    ],
    "SpawnPoint07": [
      24.20253662109375,
      19.65550048828125
    ]
  },
  "Urna Variabilis": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 7,
    "Version": 18,
    "SpawnPoint01": [
      27.515392485119047,
      33.72430710565476
    ],
    "SpawnPoint02": [
      27.139158005231277,
      13.75314694039097
    ],
    "SpawnPoint03": [
      29.563443697415867,
      29.87904005784255
    ],
    "SpawnPoint04": [
      29.62603060153162,
      19.557314182929844
    ],
    "SpawnPoint05": [
      8.198856608072917,
      33.403446451822916
    ],
    "SpawnPoint06": [
      14.59571308992347,
      26.074258609693878
    ],
    "SpawnPoint07": [
      24.189663167772345,
      19.66938722067039
    ]
  },
  "Atticus the Primogenitor": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 9,
    "Version": 17,
    "SpawnPoint01": [
      27.489123679903656,
      33.69418926012846
    ],
    "SpawnPoint02": [
      27.149608930434372,
      13.76261825445751
    ],
    "SpawnPoint03": [
      29.588250257297904,
      29.891690008654567
    ],
    "SpawnPoint04": [
      29.630265299479166,
      19.548095703125
    ],
    "SpawnPoint05": [
      8.20165783662411,
      33.39825888880641
    ],
    "SpawnPoint06": [
      14.582396301517978,
      26.07319978587178
    ],
    "SpawnPoint07": [
      36.689981949458485,
      13.009550569720217
    ],
    "SpawnPoint08": [
      24.191015792219606,
      19.671058132223887
    ],
    "SpawnPoint09": [
      12.685400390625,
      20.82904541015625
    ]
  },
  "Sally the Sweeper": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 6,
    "Version": 18,
    "SpawnPoint01": [
      26.980823335193453,
      31.31266857328869
    ],
    "SpawnPoint02": [
      18.88502081725823,
      20.205238876028808
    ],
    "SpawnPoint03": [
      4.239917652027027,
      28.883013751055742
    ],
    "SpawnPoint04": [
      12.452046514714805,
      37.78122392672937
    ],
    "SpawnPoint05": [
      12.887869107521187,
      27.8208559542726
    ],
    "SpawnPoint06": [
      5.129758762668919,
      12.87201488597973
    ]
  },
  "Cat's Eye": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 6,
    "Version": 18,
    "SpawnPoint01": [
      18.87424156314037,
      20.20307897348873
    ],
    "SpawnPoint02": [
      26.956331653755253,
      31.31200966714811
    ],
    "SpawnPoint03": [
      12.452362225506757,
      37.774688555743246
    ],
    "SpawnPoint04": [
      4.2251332264072845,
      28.870369412251655
    ],
    "SpawnPoint05": [
      12.865954919763514,
      27.81946262668919
    ],
    "SpawnPoint06": [
      5.133077496859296,
      12.866954400910805
    ]
  },
  "The Forecaster": {
    "Dimensions": [
      41,
      41
    ],
    "DisplayPoints": 9,
    "Version": 17,
    "SpawnPoint01": [
      26.968901635633358,
      31.310492460299034
    ],
    "SpawnPoint02": [
      18.883523289161392,
      20.20994394036788
    ],
    "SpawnPoint03": [
      4.224667756453805,
      28.879101297129754
    ],
    "SpawnPoint04": [
      12.442238857071713,
      37.77575926761703
    ],
    "SpawnPoint05": [
      12.880784909449892,
      27.807155501089326
    ],
    "SpawnPoint06": [
      5.1283828703091965,
      12.863032463993129
    ],
    "SpawnPoint07": [
      32.862708606020945,
      20.998044318553664
    ],
    "SpawnPoint08": [
      37.15675636574074,
      18.749168113425927
    ],
    "SpawnPoint09": [
      38.94144642503956,
      30.02211172369462
    ]
  },
  "FATE Ttokrrone": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      33.6,
      22.6
    ],
    "DisplayPoints": 1,
    "Version": 2
  },
  "FATE Mascot": {
    "Dimensions": [
      41,
      41
    ],
    "SpawnPoint01": [
      37.2,
      33.4
    ],
    "DisplayPoints": 1,
    "Version": 2
  }
};