export var UserProfile = (function () {
    var username = "";
    var ingame_name = "";
    var ingame_server = "";
    var profile_src = null;

    var permission = "";
    var isActive = false;

    const setUser = (userInfo) => {
        username = userInfo.username;
        ingame_name = userInfo.ingameName;
        ingame_server = userInfo.ingameServer;
        profile_src = userInfo.profileSrc;
        permission = userInfo.permission;
        isActive = userInfo.isActive;
    };

    const getUser = () => {
        return {
            username: username,
            ingame_name: ingame_name,
            ingame_server: ingame_server,
            profile_src: profile_src,
            permission: permission,
            isActive: isActive,
        };
    }
    
    const nullifyUser = () => {
        username = "";
        ingame_name = "";
        ingame_server = "";
        profile_src = null;
        permission = "";
        isActive = false;
    }

    const getUserIngameName = () => {
        return ingame_name + " @ " + ingame_server;
    }

    const setUserProfileSrc = (url) => {
        profile_src = url;
    }

    const getUserProfileSrc = () => {
        return profile_src;
    }

    const setIngameName = (name) => {
        ingame_name = name;
    }

    const getIngameName = () => {
        return ingame_name;
    }

    const setIngameWorld = (name) => {
        ingame_server = name;
    }

    const getIngameWorld = () => {
        return ingame_server;
    }

    const getUserPermission = () => {
        return permission;
    }

    const setActive = () => {
        isActive = true;
    }

    return {
        setUser: setUser,
        getUser: getUser,
        nullifyUser: nullifyUser,
        setActive: setActive,
        getUserIngameName: getUserIngameName,
        getUserProfileSrc: getUserProfileSrc,
        setUserProfileSrc: setUserProfileSrc,
        setIngameName: setIngameName,
        getIngameName: getIngameName,
        setIngameWorld: setIngameWorld,
        getIngameWorld: getIngameWorld,
        getUserPermission: getUserPermission,
    }

})();
