/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Navbar,
  NavLink,
  NavItem,
  Nav,
  Container,
  Button
} from "reactstrap";

import {
  Typography
} from "@mui/material";

import { SessionConfigs } from "sessions/sessionState.js";
import { useHuntTrackerContext } from "tracker-context";

const SimplifiedNavbar = (props) => {
  const [controller, dispatch] = useHuntTrackerContext();
  const { darkMode, language } = controller;

  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [color, setColor] = React.useState("navbar-transparent");
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("bg-white");
    }
    setCollapseOpen(!collapseOpen);
  };

  const handleDarkModeUpdate = () => {
    SessionConfigs.setDarkMode(!darkMode);
    dispatch({type: "DarkMode", value: !darkMode});
  };

  const handleLanguageChange = (lang) => {
    SessionConfigs.setLanguage(lang);
    dispatch({type: "Language", value: lang});
  };

  return (
    <Navbar
      className={classnames("navbar-absolute fixed-top", color)}
      expand="lg"
    >
      <Container fluid>
        <div className="navbar-wrapper">
        </div>
        <button
          aria-controls="navigation-index"
          aria-expanded={false}
          aria-label="Toggle navigation"
          className="navbar-toggler"
          data-toggle="collapse"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        <Collapse isOpen={collapseOpen} navbar>
          <Nav navbar className="ml-auto">
            <NavItem key="darkMode">
              <NavLink onClick={() => handleDarkModeUpdate()} className="nav-link" style={{ cursor: "pointer" }}>
                <i className="fa-solid fa-lightbulb" />
              </NavLink>
            </NavItem>
            <UncontrolledDropdown key="languageChange" nav>
              <DropdownToggle
                caret
                color="default"
                data-toggle="dropdown"
                nav
              >
              
                <i className="fa-solid fa-language" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-navbar" right tag="ul">
                <NavLink key="en" tag="li">
                  <DropdownItem className="nav-item" onClick={() => handleLanguageChange("en")}>
                    English
                  </DropdownItem>
                </NavLink>
                <NavLink key="de" tag="li">
                  <DropdownItem className="nav-item" onClick={() => handleLanguageChange("de")}>
                    Deutsch
                  </DropdownItem>
                </NavLink>
                <NavLink key="fr" tag="li">
                  <DropdownItem className="nav-item" onClick={() => handleLanguageChange("fr")}>
                    Français
                  </DropdownItem>
                </NavLink>
                <NavLink key="jp" tag="li">
                  <DropdownItem className="nav-item" onClick={() => handleLanguageChange("jp")}>
                    日本語
                  </DropdownItem>
                </NavLink>
                <NavLink key="cn" tag="li">
                  <DropdownItem className="nav-item" onClick={() => handleLanguageChange("cn")}>
                    中文
                  </DropdownItem>
                </NavLink>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default SimplifiedNavbar;
