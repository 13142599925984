
const EPOCH = new Date('1970-01-01T00:00:00+00:00');
const SECONDS_PER_HOUR = 175;
const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24;
const SECONDS_PER_MONTH = SECONDS_PER_DAY * 32;
const WEATHER_PERIOD = SECONDS_PER_HOUR * 8;

const WeatherRates = {
  "Laideronnette": {
    "Rate": [5,15,10,10,15,30,15],
    "WeatherName": ["Thunder","Rain","Fog","Clouds","Fair Skies","Clear Skies","Fair Skies"]
  },
  "The Garlok": {
    "Rate": [5,45,30,10,5,5],
    "WeatherName": ["Fog","Clear Skies","Fair Skies","Clouds","Rain","Showers"]
  },
  "Zona Seeker": {
    "Rate": [40,20,25,10,5],
    "WeatherName": ["Clear Skies","Fair Skies","Clouds","Fog","Rain"]
  },
  "Burfurlur the Canny": {
    "Rate": [15,45,25,15],
    "WeatherName": ["Clear Skies","Fair Skies","Clouds","Rain"]
  },
  "Kirlirger the Abhorrent": {
    "Rate": [20,30,20,10,10,10],
    "WeatherName": ["Clear Skies","Fair Skies","Clouds","Fog","Wind","Snow"]
  }
};

const getReferenceTime = (time) => {
  return Math.floor((time.getTime()-EPOCH.getTime()) / 1000);
};

const calculateTarget = (unix) => {
  const bell = Math.floor(unix / 175);
  const increment = (bell + 8 - (bell % 8)) % 24;
  const total_days = Math.floor(unix / 4200);
  const calc_base = (total_days * 0x64) + increment >>> 0;
  const step1 = ((calc_base << 11) ^ calc_base) >>> 0;
  const step2 = ((step1 >>> 8)  ^ step1) >>> 0;
  return step2 % 100;
};

const getTerritoryWeatherRates = (name) => {
    return WeatherRates[name];
};

const getWeather = (weatherRates, target) => {
  var weatherRate = 0;
  for (var i = 0; i < weatherRates["Rate"].length; i++) {
    weatherRate += weatherRates["Rate"][i]
    if (target < weatherRate) {
      return weatherRates["WeatherName"][i];
    }
  }
};

const getCurrentWeather = (weatherRate, rootTime) => {
  var target = calculateTarget(rootTime);
  return getWeather(weatherRate, target);
};

const getWeatherDuration = (weatherRate, rootTime) => {
  var target = calculateTarget(rootTime);
  var currentWeather = getWeather(weatherRate, target);
  var weatherInfo = {"Name": currentWeather, "Duration": [0,0]}

  var newWeather = currentWeather;
  while (newWeather == currentWeather) {
    rootTime -= WEATHER_PERIOD;
    target = calculateTarget(rootTime);
    newWeather = getWeather(weatherRate, target);
  }
  weatherInfo["Duration"][0] = rootTime + WEATHER_PERIOD;

  newWeather = currentWeather;
  while (newWeather == currentWeather) {
    rootTime += WEATHER_PERIOD;
    target = calculateTarget(rootTime);
    newWeather = getWeather(weatherRate, target);
  }
  weatherInfo["Duration"][1] = rootTime;

  return weatherInfo;
};

const predictNextLaideronnette = (startTime) => {
  var weatherRate = getTerritoryWeatherRates("Laideronnette");
  startTime = getReferenceTime(startTime);
  var rootTime = startTime - startTime % WEATHER_PERIOD;
  
  var weatherInfo;
  while (true) {
    weatherInfo = getWeatherDuration(weatherRate, rootTime)
    if (weatherInfo["Name"] == "Rain" && (weatherInfo["Duration"][1]-weatherInfo["Duration"][0]) > WEATHER_PERIOD) {
      weatherInfo["Duration"][0] += 1800;
      return weatherInfo;
    }
    rootTime = weatherInfo["Duration"][1];
  }
};

const predictNextGarlok = (startTime) => {
  var weatherRate = getTerritoryWeatherRates("The Garlok");
  startTime = getReferenceTime(startTime);
  var rootTime = startTime - startTime % WEATHER_PERIOD;

  var weatherInfo = getWeatherDuration(weatherRate, rootTime);
  while (weatherInfo["Name"] != "Rain" && weatherInfo["Name"] != "Showers") {
    rootTime -= WEATHER_PERIOD;
    weatherInfo = getWeatherDuration(weatherRate, rootTime);
  }
  rootTime = weatherInfo["Duration"][1];

  var windowStart = rootTime;
  while (true) {
    weatherInfo = getWeatherDuration(weatherRate, rootTime)
    if (weatherInfo["Name"] == "Rain" || weatherInfo["Name"] == "Showers") {
      if (rootTime - windowStart > 200*60) {
        return {"Name": "Dry Weather", "Duration": [windowStart+200*60, rootTime]}
      }
      windowStart = weatherInfo["Duration"][1];
    }
    rootTime = weatherInfo["Duration"][1];
  }
};

const predictNextZonaSeeker = (startTime) => {
  var weatherRate = getTerritoryWeatherRates("Zona Seeker");
  startTime = getReferenceTime(startTime);
  var rootTime = startTime - startTime % WEATHER_PERIOD;

  var weatherInfo = getWeatherDuration(weatherRate, rootTime);
  while (weatherInfo["Name"] == "Clear Skies" || weatherInfo["Name"] == "Fair Skies") {
    rootTime -= WEATHER_PERIOD;
    weatherInfo = getWeatherDuration(weatherRate, rootTime);
  }
  rootTime = weatherInfo["Duration"][1];
  
  var windowStart = rootTime;
  while (true) {
    weatherInfo = getWeatherDuration(weatherRate, rootTime);
    if (weatherInfo["Name"] != "Clear Skies" && weatherInfo["Name"] != "Fair Skies") {
      if (rootTime - windowStart > 0) {
        return {"Name": "Clear Weather", "Duration": [windowStart, rootTime]};
      }
      windowStart = weatherInfo["Duration"][1];
    }
    rootTime = weatherInfo["Duration"][1];
  }
};

const getEorzeaTime = (unix) => {
  var totalDays = Math.floor(unix / 4200);
  var HourOfDay = Math.floor((unix % 4200) * 24 / 4200);
  var MinuteOfDay = Math.floor((unix % 175) * 60 / 175);
  var DayOfMonth = totalDays % 32;

  var MoonPhase;
  var MoonPhases = ["New Moon", "Waxing Crescent","Waxing Half Moon","Waxing Gibbous","Full Moon","Waning Gibbous","Waning Half Moon","Waning Crescent"];
  if (HourOfDay > 12) {
    MoonPhase = MoonPhases[Math.floor(((DayOfMonth + 1) % 32) / 4)];
  } else {
    MoonPhase = MoonPhases[Math.floor((DayOfMonth) / 4)];
  }
  return {"Day": DayOfMonth, "Hour": HourOfDay, "Minute": MinuteOfDay, "MoonPhase": MoonPhase};
};

const predictNextCroakadile = (startTime) => {
  startTime = getReferenceTime(startTime);
  var rootTime = startTime - startTime % SECONDS_PER_MONTH;

  var windowStart, windowEnd;
  while (true) {
    for (var i = 16; i < 20; i++) {
      windowStart = rootTime + i*SECONDS_PER_DAY-7*SECONDS_PER_HOUR;
      windowEnd = rootTime + i*SECONDS_PER_DAY+3*SECONDS_PER_HOUR;
      if (windowEnd > startTime) {
        return {"Name": "Night", "Duration": [windowStart, windowEnd]};
      }
    }
    rootTime += SECONDS_PER_MONTH;
  }
};

const predictNextMindflayer = (startTime) => {
  startTime = getReferenceTime(startTime)
  var rootTime = startTime - startTime % SECONDS_PER_MONTH

  var windowStart, windowEnd;
  while (true) {
    for (var i = 0; i < 4; i++) {
      if (i == 0) {
        windowStart = rootTime;
      } else {
        windowStart = rootTime + i*SECONDS_PER_DAY-7*SECONDS_PER_HOUR;
      }

      windowEnd = rootTime + i*SECONDS_PER_DAY+3*SECONDS_PER_HOUR;
      if (windowEnd > startTime) {
        return {"Name": "New Moon", "Duration": [windowStart, windowEnd]};
      }
    }
    rootTime += SECONDS_PER_MONTH;
  }
};

const predictNextOkina = (startTime) => {
  startTime = getReferenceTime(startTime);
  var rootTime = startTime - startTime % SECONDS_PER_MONTH;
  rootTime -= SECONDS_PER_DAY*16;

  var windowStart, windowEnd;
  while (true) {
    var windowStart = rootTime - 12*SECONDS_PER_HOUR;
    var windowEnd = rootTime + 3*SECONDS_PER_DAY+12*SECONDS_PER_HOUR;
    if (windowEnd > startTime) {
      return {"Name": "Full Moon", "Duration": [windowStart, windowEnd]};
    }
    rootTime += SECONDS_PER_MONTH;
  }
};

const predictNextCroqueMitaine = (startTime) => {
  startTime = getReferenceTime(startTime);
  var rootTime = startTime - startTime % SECONDS_PER_HOUR;
  var eorzeaTime = getEorzeaTime(rootTime);

  var windowStart, windowEnd;
  if (eorzeaTime["Hour"] >= 22) {
    windowStart = rootTime + SECONDS_PER_HOUR*(19+24-eorzeaTime["Hour"]);
  } else if (eorzeaTime["Hour"] < 19) {
    windowStart = rootTime + SECONDS_PER_HOUR*(19-eorzeaTime["Hour"]);
  } else {
    windowStart = rootTime - SECONDS_PER_HOUR*(eorzeaTime["Hour"]-19);
  }
  windowEnd = windowStart + 3*SECONDS_PER_HOUR;
  return {"Duration": [windowStart, windowEnd]};
};

const predictNextBonnacon = (startTime) => {
  startTime = getReferenceTime(startTime);
  var rootTime = startTime - startTime % SECONDS_PER_HOUR;
  var eorzeaTime = getEorzeaTime(rootTime);

  var windowStart, windowEnd;
  if (eorzeaTime["Hour"] >= 11) {
    windowStart = rootTime + SECONDS_PER_HOUR*(8+24-eorzeaTime["Hour"]);
  } else if (eorzeaTime["Hour"] < 8) {
    windowStart = rootTime + SECONDS_PER_HOUR*(8-eorzeaTime["Hour"]);
  } else {
    windowStart = rootTime - SECONDS_PER_HOUR*(eorzeaTime["Hour"]-8);
  }
  windowEnd = windowStart + 3*SECONDS_PER_HOUR;
  return {"Duration": [windowStart, windowEnd]};
};

const predictNextTheda = (startTime) => {
  startTime = getReferenceTime(startTime);
  var rootTime = startTime - startTime % SECONDS_PER_HOUR;
  var eorzeaTime = getEorzeaTime(rootTime);

  var windowStart, windowEnd;
  if (eorzeaTime["Hour"] >= 21) {
    windowStart = rootTime + SECONDS_PER_HOUR*(17+24-eorzeaTime["Hour"]);
  } else if (eorzeaTime["Hour"] < 17) {
    windowStart = rootTime + SECONDS_PER_HOUR*(17-eorzeaTime["Hour"]);
  } else {
    windowStart = rootTime - SECONDS_PER_HOUR*(eorzeaTime["Hour"]-17);
  }
  windowEnd = windowStart + 4*SECONDS_PER_HOUR;
  return {"Duration": [windowStart, windowEnd]};
};

const predictNextGandarewa = (startTme) => {
  startTme = getReferenceTime(startTme);
  var rootTime = startTme - startTme % SECONDS_PER_HOUR;
  var eorzeaTime = getEorzeaTime(rootTime);

  var windowStart, windowEnd;
  if (eorzeaTime["Hour"] >= 18) {
    windowStart = rootTime + SECONDS_PER_HOUR*(2+24-eorzeaTime["Hour"]);
  } else if (eorzeaTime["Hour"] < 2) {
    windowStart = rootTime + SECONDS_PER_HOUR*(2-eorzeaTime["Hour"])
  } else if (eorzeaTime["Hour"] >= 2 && eorzeaTime["Hour"] < 6) {
    windowStart = rootTime - SECONDS_PER_HOUR*(eorzeaTime["Hour"]-2)
  } else if (eorzeaTime["Hour"] >= 6 && eorzeaTime["Hour"] < 14) {
    windowStart = rootTime + SECONDS_PER_HOUR*(14-eorzeaTime["Hour"])
  } else if (eorzeaTime["Hour"] >= 14 && eorzeaTime["Hour"] < 18) {
    windowStart = rootTime - SECONDS_PER_HOUR*(eorzeaTime["Hour"]-14)
  } else {
    windowStart = rootTime - SECONDS_PER_HOUR*(eorzeaTime["Hour"]-14)
  }
  windowEnd = windowStart + 4*SECONDS_PER_HOUR;
  return {"Duration": [windowStart, windowEnd]};
};

const predictNextGamma = (startTime) => {
  startTime = getReferenceTime(startTime);
  var rootTime = startTime - startTime % SECONDS_PER_HOUR;
  var eorzeaTime = getEorzeaTime(rootTime);

  var windowStart, windowEnd;
  if (eorzeaTime["Hour"] >= 8 && eorzeaTime["Hour"] < 17) {
    windowStart = rootTime + SECONDS_PER_HOUR*(17-eorzeaTime["Hour"]);
  } else if (eorzeaTime["Hour"] >= 17) {
    windowStart = rootTime - SECONDS_PER_HOUR*(eorzeaTime["Hour"]-17);
  } else {
    windowStart = rootTime - SECONDS_PER_HOUR*(24+eorzeaTime["Hour"]-17);
  }
  windowEnd = windowStart + 15*SECONDS_PER_HOUR;
  return {"Duration": [windowStart, windowEnd]};
};

const predictNextBurfurlur = (startTime) => {
  startTime = getReferenceTime(startTime);
  var rootTime = startTime - startTime % SECONDS_PER_HOUR;
  var weatherRate = getTerritoryWeatherRates("Burfurlur the Canny");

  var windowStart, windowEnd;
  var weatherCorrect = false, timeCorrect = false;
  while (!weatherCorrect || !timeCorrect) {
    var weatherInfo = getWeatherDuration(weatherRate, rootTime);
    var eorzeaTime = getEorzeaTime(rootTime);

    timeCorrect = (eorzeaTime["Hour"] >= 9) && (eorzeaTime["Hour"] < 17);
    weatherCorrect = (weatherInfo["Name"] == "Clear Skies") || (weatherInfo["Name"] == "Fair Skies");
    rootTime += SECONDS_PER_HOUR;
  }
  windowStart = rootTime - SECONDS_PER_HOUR;

  while (weatherCorrect && timeCorrect) {
    var weatherInfo = getWeatherDuration(weatherRate, rootTime);
    var eorzeaTime = getEorzeaTime(rootTime);
    
    timeCorrect = (eorzeaTime["Hour"] >= 9) && (eorzeaTime["Hour"] < 17);
    weatherCorrect = (weatherInfo["Name"] == "Clear Skies") || (weatherInfo["Name"] == "Fair Skies");
    rootTime += SECONDS_PER_HOUR;
  }
  windowEnd = rootTime - SECONDS_PER_HOUR;

  return {"Duration": [windowStart, windowEnd]};
};

const predictNextKirlirger = (startTime) => {
  startTime = getReferenceTime(startTime);
  var rootTime = startTime - startTime % SECONDS_PER_HOUR;
  var weatherRate = getTerritoryWeatherRates("Kirlirger the Abhorrent");
  
  var weather = "";
  var windowStart, windowEnd;
  var weatherCorrect = false, timeCorrect = false, moonCorrect = false;
  while (!weatherCorrect || !timeCorrect || !moonCorrect) {
    var weatherInfo = getWeatherDuration(weatherRate, rootTime);
    var eorzeaTime = getEorzeaTime(rootTime);

    timeCorrect = (eorzeaTime["Hour"] < 8);
    weatherCorrect = (weatherInfo["Name"] == "Fog");
    moonCorrect = (eorzeaTime["MoonPhase"] == "New Moon") && eorzeaTime["Day"] < 4;
    weather = weatherInfo["Name"];
    rootTime += SECONDS_PER_HOUR;
  }
  windowStart = rootTime - SECONDS_PER_HOUR;

  while (weatherCorrect && timeCorrect && moonCorrect) {
    var weatherInfo = getWeatherDuration(weatherRate, rootTime);
    var eorzeaTime = getEorzeaTime(rootTime);
    
    timeCorrect = (eorzeaTime["Hour"] < 8);
    weatherCorrect = (weatherInfo["Name"] == "Fog");
    moonCorrect = (eorzeaTime["MoonPhase"] == "New Moon") && eorzeaTime["Day"] < 4;
    rootTime += SECONDS_PER_HOUR;
  }
  windowEnd = rootTime - SECONDS_PER_HOUR;

  return {"Duration": [windowStart, windowEnd], "Weather": weather};
};

export {
  predictNextBonnacon,
  predictNextBurfurlur,
  predictNextCroakadile,
  predictNextCroqueMitaine,
  predictNextGamma,
  predictNextGandarewa,
  predictNextGarlok,
  predictNextKirlirger,
  predictNextLaideronnette,
  predictNextMindflayer,
  predictNextOkina,
  predictNextTheda,
  predictNextZonaSeeker,
};