export const huntInfos = {
    "White Joker": {
        "Region": "Central Shroud",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 1
    },
    "Forneus": {
        "Region": "Central Shroud",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 2,
        "RespawnTimer": [
            3.5,
            1
        ],
        "MaintTimer": [
            2.1,
            0.6
        ]
    },
    "Laideronnette": {
        "Region": "Central Shroud",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 3,
        "RespawnTimer": [
            42,
            6
        ],
        "MaintTimer": [
            25.2,
            3.6
        ]
    },
    "Stinging Sophie": {
        "Region": "East Shroud",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 4
    },
    "Melt": {
        "Region": "East Shroud",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 5,
        "RespawnTimer": [
            3,
            1
        ],
        "MaintTimer": [
            1.8,
            0.6
        ]
    },
    "Wulgaru": {
        "Region": "East Shroud",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 6,
        "RespawnTimer": [
            67,
            11
        ],
        "MaintTimer": [
            40.2,
            6.6
        ]
    },
    "Monarch Ogrefly": {
        "Region": "South Shroud",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 7
    },
    "Ghede Ti Malice": {
        "Region": "South Shroud",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 8,
        "RespawnTimer": [
            3.5,
            1
        ],
        "MaintTimer": [
            2.1,
            0.6
        ]
    },
    "Mindflayer": {
        "Region": "South Shroud",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 9,
        "RespawnTimer": [
            50,
            1
        ],
        "MaintTimer": [
            30,
            0.6
        ]
    },
    "Phecda": {
        "Region": "North Shroud",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 10
    },
    "Girtab": {
        "Region": "North Shroud",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 11,
        "RespawnTimer": [
            3.5,
            1
        ],
        "MaintTimer": [
            2.1,
            0.6
        ]
    },
    "Thousand-cast Theda": {
        "Region": "North Shroud",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 12,
        "RespawnTimer": [
            57,
            8
        ],
        "MaintTimer": [
            34.2,
            4.8
        ]
    },
    "Sewer Syrup": {
        "Region": "Western Thanalan",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 13
    },
    "Alectryon": {
        "Region": "Western Thanalan",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 14,
        "RespawnTimer": [
            3.5,
            1
        ],
        "MaintTimer": [
            2.1,
            0.6
        ]
    },
    "Zona Seeker": {
        "Region": "Western Thanalan",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 15,
        "RespawnTimer": [
            57,
            8
        ],
        "MaintTimer": [
            34.2,
            4.8
        ]
    },
    "Ovjang": {
        "Region": "Central Thanalan",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 16
    },
    "Sabotender Bailarina": {
        "Region": "Central Thanalan",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 17,
        "RespawnTimer": [
            4,
            1
        ],
        "MaintTimer": [
            2.4,
            0.6
        ]
    },
    "Brontes": {
        "Region": "Central Thanalan",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 18,
        "RespawnTimer": [
            67,
            10
        ],
        "MaintTimer": [
            40.2,
            6
        ]
    },
    "Gatling": {
        "Region": "Eastern Thanalan",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 19
    },
    "Maahes": {
        "Region": "Eastern Thanalan",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 20,
        "RespawnTimer": [
            3,
            1
        ],
        "MaintTimer": [
            1.8,
            0.6
        ]
    },
    "Lampalagua": {
        "Region": "Eastern Thanalan",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 21,
        "RespawnTimer": [
            67,
            11
        ],
        "MaintTimer": [
            40.2,
            6.6
        ]
    },
    "Albin the Ashen": {
        "Region": "Southern Thanalan",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 22
    },
    "Zanigoh": {
        "Region": "Southern Thanalan",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 23,
        "RespawnTimer": [
            3.5,
            1
        ],
        "MaintTimer": [
            2.1,
            0.6
        ]
    },
    "Nunyunuwi": {
        "Region": "Southern Thanalan",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 24,
        "RespawnTimer": [
            44,
            10
        ],
        "MaintTimer": [
            26.4,
            6
        ]
    },
    "Flame Sergeant Dalvag": {
        "Region": "Northern Thanalan",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 25
    },
    "Dalvags Final Flame": {
        "Region": "Northern Thanalan",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 26,
        "RespawnTimer": [
            4,
            1
        ],
        "MaintTimer": [
            2.4,
            0.6
        ]
    },
    "Minhocao": {
        "Region": "Northern Thanalan",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 27,
        "RespawnTimer": [
            57,
            6
        ],
        "MaintTimer": [
            34.2,
            3.6
        ]
    },
    "Skogs Fru": {
        "Region": "Middle La Noscea",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 28
    },
    "Vogaal Ja": {
        "Region": "Middle La Noscea",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 29,
        "RespawnTimer": [
            3.5,
            1
        ],
        "MaintTimer": [
            2.1,
            0.6
        ]
    },
    "Croque-Mitaine": {
        "Region": "Middle La Noscea",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 30,
        "RespawnTimer": [
            65,
            10
        ],
        "MaintTimer": [
            39,
            6
        ]
    },
    "Barbastelle": {
        "Region": "Lower La Noscea",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 31
    },
    "Unktehi": {
        "Region": "Lower La Noscea",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 32,
        "RespawnTimer": [
            3.5,
            1
        ],
        "MaintTimer": [
            2.1,
            0.6
        ]
    },
    "Croakadile": {
        "Region": "Lower La Noscea",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 33,
        "RespawnTimer": [
            50,
            1
        ],
        "MaintTimer": [
            30,
            0.6
        ]
    },
    "Bloody Mary": {
        "Region": "Eastern La Noscea",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 34
    },
    "Hellsclaw": {
        "Region": "Eastern La Noscea",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 35,
        "RespawnTimer": [
            3.5,
            1
        ],
        "MaintTimer": [
            2.1,
            0.6
        ]
    },
    "The Garlok": {
        "Region": "Eastern La Noscea",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 36,
        "RespawnTimer": [
            42,
            6
        ],
        "MaintTimer": [
            25.2,
            3.6
        ]
    },
    "Dark Helmet": {
        "Region": "Western La Noscea",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 37
    },
    "Nahn": {
        "Region": "Western La Noscea",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 38,
        "RespawnTimer": [
            3.5,
            1
        ],
        "MaintTimer": [
            2.1,
            0.6
        ]
    },
    "Bonnacon": {
        "Region": "Western La Noscea",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 39,
        "RespawnTimer": [
            65,
            10
        ],
        "MaintTimer": [
            39,
            6
        ]
    },
    "Myradrosh": {
        "Region": "Upper La Noscea",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 40
    },
    "Marberry": {
        "Region": "Upper La Noscea",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 41,
        "RespawnTimer": [
            4,
            1
        ],
        "MaintTimer": [
            2.4,
            0.6
        ]
    },
    "Nandi": {
        "Region": "Upper La Noscea",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 42,
        "RespawnTimer": [
            47,
            6
        ],
        "MaintTimer": [
            28.2,
            4
        ]
    },
    "Vuokho": {
        "Region": "Outer La Noscea",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 43
    },
    "Cornu": {
        "Region": "Outer La Noscea",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 44,
        "RespawnTimer": [
            3.5,
            1
        ],
        "MaintTimer": [
            2.1,
            0.6
        ]
    },
    "Chernobog": {
        "Region": "Outer La Noscea",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 45,
        "RespawnTimer": [
            65,
            6
        ],
        "MaintTimer": [
            39,
            3.6
        ]
    },
    "Naul": {
        "Region": "Coerthas Central Highlands",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 46
    },
    "Marraco": {
        "Region": "Coerthas Central Highlands",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 47,
        "RespawnTimer": [
            3,
            1
        ],
        "MaintTimer": [
            1.8,
            0.6
        ]
    },
    "Safat": {
        "Region": "Coerthas Central Highlands",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 48,
        "RespawnTimer": [
            60,
            24
        ],
        "MaintTimer": [
            36,
            14.4
        ]
    },
    "Leech King": {
        "Region": "Mor Dhona",
        "Rank": 1,
        "Patch": "ARR",
        "ID": 49
    },
    "Kurrea": {
        "Region": "Mor Dhona",
        "Rank": 2,
        "Patch": "ARR",
        "ID": 50,
        "RespawnTimer": [
            3.5,
            1
        ],
        "MaintTimer": [
            2.1,
            0.6
        ]
    },
    "Agrippa the Mighty": {
        "Region": "Mor Dhona",
        "Rank": 3,
        "Patch": "ARR",
        "ID": 51,
        "RespawnTimer": [
            60,
            24
        ],
        "MaintTimer": [
            36,
            14.4
        ]
    },
    "Alteci": {
        "Region": "Coerthas Western Highlands",
        "Rank": 1,
        "Patch": "HW",
        "ID": 52
    },
    "Kreutzet": {
        "Region": "Coerthas Western Highlands",
        "Rank": 1,
        "Patch": "HW",
        "ID": 53
    },
    "Mirka": {
        "Region": "Coerthas Western Highlands",
        "Rank": 2,
        "Patch": "HW",
        "ID": 54,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Lyuba": {
        "Region": "Coerthas Western Highlands",
        "Rank": 2,
        "Patch": "HW",
        "ID": 55,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Kaiser Behemoth": {
        "Region": "Coerthas Western Highlands",
        "Rank": 3,
        "Patch": "HW",
        "ID": 56,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Gnath cometdrone": {
        "Region": "The Dravanian Forelands",
        "Rank": 1,
        "Patch": "HW",
        "ID": 57
    },
    "Thextera": {
        "Region": "The Dravanian Forelands",
        "Rank": 1,
        "Patch": "HW",
        "ID": 58
    },
    "Pylraster": {
        "Region": "The Dravanian Forelands",
        "Rank": 2,
        "Patch": "HW",
        "ID": 59,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Lord of the Wyverns": {
        "Region": "The Dravanian Forelands",
        "Rank": 2,
        "Patch": "HW",
        "ID": 60,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Senmurv": {
        "Region": "The Dravanian Forelands",
        "Rank": 3,
        "Patch": "HW",
        "ID": 61,
        "FATEs": ["FATE Cerf", "FATE Elephant in the Brume", "FATE El Miacid", "FATE Special Tarasque Force"],
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Scitalis": {
        "Region": "The Churning Mists",
        "Rank": 1,
        "Patch": "HW",
        "ID": 62
    },
    "The Scarecrow": {
        "Region": "The Churning Mists",
        "Rank": 1,
        "Patch": "HW",
        "ID": 63
    },
    "Bune": {
        "Region": "The Churning Mists",
        "Rank": 2,
        "Patch": "HW",
        "ID": 64,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Agathos": {
        "Region": "The Churning Mists",
        "Rank": 2,
        "Patch": "HW",
        "ID": 65,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Gandarewa": {
        "Region": "The Churning Mists",
        "Rank": 3,
        "Patch": "HW",
        "ID": 66,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Pterygotus": {
        "Region": "The Dravanian Hinterlands",
        "Rank": 1,
        "Patch": "HW",
        "ID": 67
    },
    "False Gigantopithecus": {
        "Region": "The Dravanian Hinterlands",
        "Rank": 1,
        "Patch": "HW",
        "ID": 68
    },
    "Slipkinx Steeljoints": {
        "Region": "The Dravanian Hinterlands",
        "Rank": 2,
        "Patch": "HW",
        "ID": 69,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Stolas": {
        "Region": "The Dravanian Hinterlands",
        "Rank": 2,
        "Patch": "HW",
        "ID": 70,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "The Pale Rider": {
        "Region": "The Dravanian Hinterlands",
        "Rank": 3,
        "Patch": "HW",
        "ID": 71,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Squonk": {
        "Region": "The Sea of Clouds",
        "Rank": 1,
        "Patch": "HW",
        "ID": 72
    },
    "Sanu Vali of Dancing Wings": {
        "Region": "The Sea of Clouds",
        "Rank": 1,
        "Patch": "HW",
        "ID": 73
    },
    "Enkelados": {
        "Region": "The Sea of Clouds",
        "Rank": 2,
        "Patch": "HW",
        "ID": 74,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Sisiutl": {
        "Region": "The Sea of Clouds",
        "Rank": 2,
        "Patch": "HW",
        "ID": 75,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Bird of Paradise": {
        "Region": "The Sea of Clouds",
        "Rank": 3,
        "Patch": "HW",
        "ID": 76,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Lycidas": {
        "Region": "Azys Lla",
        "Rank": 1,
        "Patch": "HW",
        "ID": 77
    },
    "Omni": {
        "Region": "Azys Lla",
        "Rank": 1,
        "Patch": "HW",
        "ID": 78
    },
    "Campacti": {
        "Region": "Azys Lla",
        "Rank": 2,
        "Patch": "HW",
        "ID": 79,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Stench Blossom": {
        "Region": "Azys Lla",
        "Rank": 2,
        "Patch": "HW",
        "ID": 80,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Leucrotta": {
        "Region": "Azys Lla",
        "Rank": 3,
        "Patch": "HW",
        "ID": 81,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Shadow-Dweller Yamini": {
        "Region": "The Fringes",
        "Rank": 1,
        "Patch": "SB",
        "ID": 82
    },
    "Ouzelum": {
        "Region": "The Fringes",
        "Rank": 1,
        "Patch": "SB",
        "ID": 83
    },
    "Orcus": {
        "Region": "The Fringes",
        "Rank": 2,
        "Patch": "SB",
        "ID": 84,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Erle": {
        "Region": "The Fringes",
        "Rank": 2,
        "Patch": "SB",
        "ID": 85,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Udumbara": {
        "Region": "The Fringes",
        "Rank": 3,
        "Patch": "SB",
        "ID": 86,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Gwas-y-neidr": {
        "Region": "The Peaks",
        "Rank": 1,
        "Patch": "SB",
        "ID": 87
    },
    "Buccaboo": {
        "Region": "The Peaks",
        "Rank": 1,
        "Patch": "SB",
        "ID": 88
    },
    "Vochstein": {
        "Region": "The Peaks",
        "Rank": 2,
        "Patch": "SB",
        "ID": 89,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Aqrabuamelu": {
        "Region": "The Peaks",
        "Rank": 2,
        "Patch": "SB",
        "ID": 90,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Bone Crawler": {
        "Region": "The Peaks",
        "Rank": 3,
        "Patch": "SB",
        "ID": 91,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Manes": {
        "Region": "The Lochs",
        "Rank": 1,
        "Patch": "SB",
        "ID": 92
    },
    "Kiwa": {
        "Region": "The Lochs",
        "Rank": 1,
        "Patch": "SB",
        "ID": 93
    },
    "Mahisha": {
        "Region": "The Lochs",
        "Rank": 2,
        "Patch": "SB",
        "ID": 94,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Luminare": {
        "Region": "The Lochs",
        "Rank": 2,
        "Patch": "SB",
        "ID": 95,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Salt and Light": {
        "Region": "The Lochs",
        "Rank": 3,
        "Patch": "SB",
        "ID": 96,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Gauki Strongblade": {
        "Region": "The Ruby Sea",
        "Rank": 1,
        "Patch": "SB",
        "ID": 97
    },
    "Guhuo Niao": {
        "Region": "The Ruby Sea",
        "Rank": 1,
        "Patch": "SB",
        "ID": 98
    },
    "Funa Yurei": {
        "Region": "The Ruby Sea",
        "Rank": 2,
        "Patch": "SB",
        "ID": 99,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Oni Yumemi": {
        "Region": "The Ruby Sea",
        "Rank": 2,
        "Patch": "SB",
        "ID": 100,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Okina": {
        "Region": "The Ruby Sea",
        "Rank": 3,
        "Patch": "SB",
        "ID": 101,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Deidar": {
        "Region": "Yanxia",
        "Rank": 1,
        "Patch": "SB",
        "ID": 102
    },
    "Gyorai Quickstrike": {
        "Region": "Yanxia",
        "Rank": 1,
        "Patch": "SB",
        "ID": 103
    },
    "Gajasura": {
        "Region": "Yanxia",
        "Rank": 2,
        "Patch": "SB",
        "ID": 104,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Angada": {
        "Region": "Yanxia",
        "Rank": 2,
        "Patch": "SB",
        "ID": 105,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Gamma": {
        "Region": "Yanxia",
        "Rank": 3,
        "Patch": "SB",
        "ID": 106,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Kurma": {
        "Region": "The Azim Steppe",
        "Rank": 1,
        "Patch": "SB",
        "ID": 107
    },
    "Aswang": {
        "Region": "The Azim Steppe",
        "Rank": 1,
        "Patch": "SB",
        "ID": 108
    },
    "Girimekhala": {
        "Region": "The Azim Steppe",
        "Rank": 2,
        "Patch": "SB",
        "ID": 109,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Sum": {
        "Region": "The Azim Steppe",
        "Rank": 2,
        "Patch": "SB",
        "ID": 110,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Orghana": {
        "Region": "The Azim Steppe",
        "Rank": 3,
        "Patch": "SB",
        "ID": 111,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "La Velue": {
        "Region": "Lakeland",
        "Rank": 1,
        "Patch": "ShB",
        "ID": 112
    },
    "Itzpapalotl": {
        "Region": "Lakeland",
        "Rank": 1,
        "Patch": "ShB",
        "ID": 113
    },
    "Nuckelavee": {
        "Region": "Lakeland",
        "Rank": 2,
        "Patch": "ShB",
        "ID": 114,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Nariphon": {
        "Region": "Lakeland",
        "Rank": 2,
        "Patch": "ShB",
        "ID": 115,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Tyger": {
        "Region": "Lakeland",
        "Rank": 3,
        "Patch": "ShB",
        "ID": 116,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Coquecigrue": {
        "Region": "Kholusia",
        "Rank": 1,
        "Patch": "ShB",
        "ID": 117
    },
    "Indomitable": {
        "Region": "Kholusia",
        "Rank": 1,
        "Patch": "ShB",
        "ID": 118
    },
    "Lil Murderer": {
        "Region": "Kholusia",
        "Rank": 2,
        "Patch": "ShB",
        "ID": 119,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Huracan": {
        "Region": "Kholusia",
        "Rank": 2,
        "Patch": "ShB",
        "ID": 120,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Forgiven Pedantry": {
        "Region": "Kholusia",
        "Rank": 3,
        "Patch": "ShB",
        "ID": 121,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Worm of the Well": {
        "Region": "Amh Araeng",
        "Rank": 1,
        "Patch": "ShB",
        "ID": 122
    },
    "Juggler Hecatomb": {
        "Region": "Amh Araeng",
        "Rank": 1,
        "Patch": "ShB",
        "ID": 123
    },
    "Maliktender": {
        "Region": "Amh Araeng",
        "Rank": 2,
        "Patch": "ShB",
        "ID": 124,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Sugaar": {
        "Region": "Amh Araeng",
        "Rank": 2,
        "Patch": "ShB",
        "ID": 125,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Tarchia": {
        "Region": "Amh Araeng",
        "Rank": 3,
        "Patch": "ShB",
        "ID": 126,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Domovoi": {
        "Region": "Il Mheg",
        "Rank": 1,
        "Patch": "ShB",
        "ID": 127
    },
    "Vulpangue": {
        "Region": "Il Mheg",
        "Rank": 1,
        "Patch": "ShB",
        "ID": 128
    },
    "The Mudman": {
        "Region": "Il Mheg",
        "Rank": 2,
        "Patch": "ShB",
        "ID": 129,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "O Poorest Pauldia": {
        "Region": "Il Mheg",
        "Rank": 2,
        "Patch": "ShB",
        "ID": 130,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Aglaope": {
        "Region": "Il Mheg",
        "Rank": 3,
        "Patch": "ShB",
        "ID": 131,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Mindmaker": {
        "Region": "The Rak'tika Greatwood",
        "Rank": 1,
        "Patch": "ShB",
        "ID": 132
    },
    "Pachamama": {
        "Region": "The Rak'tika Greatwood",
        "Rank": 1,
        "Patch": "ShB",
        "ID": 133
    },
    "Supay": {
        "Region": "The Rak'tika Greatwood",
        "Rank": 2,
        "Patch": "ShB",
        "ID": 134,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Grassman": {
        "Region": "The Rak'tika Greatwood",
        "Rank": 2,
        "Patch": "ShB",
        "ID": 135,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Ixtab": {
        "Region": "The Rak'tika Greatwood",
        "Rank": 3,
        "Patch": "ShB",
        "ID": 136,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Gilshs Aath Swiftclaw": {
        "Region": "The Tempest",
        "Rank": 1,
        "Patch": "ShB",
        "ID": 137
    },
    "Deacon": {
        "Region": "The Tempest",
        "Rank": 1,
        "Patch": "ShB",
        "ID": 138
    },
    "Rusalka": {
        "Region": "The Tempest",
        "Rank": 2,
        "Patch": "ShB",
        "ID": 139,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Baal": {
        "Region": "The Tempest",
        "Rank": 2,
        "Patch": "ShB",
        "ID": 140,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Gunitt": {
        "Region": "The Tempest",
        "Rank": 3,
        "Patch": "ShB",
        "ID": 141,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Forgiven Rebellion Lakeland": {
        "Region": "Lakeland",
        "Rank": 5,
        "Patch": "ShB",
        "ID": 178
    },
    "Forgiven Rebellion Kholusia": {
        "Region": "Kholusia",
        "Rank": 5,
        "Patch": "ShB",
        "ID": 179
    },
    "Forgiven Rebellion Amh Araeng": {
        "Region": "Amh Araeng",
        "Rank": 5,
        "Patch": "ShB",
        "ID": 180
    },
    "Forgiven Rebellion Il Mheg": {
        "Region": "Il Mheg",
        "Rank": 5,
        "Patch": "ShB",
        "ID": 181
    },
    "Forgiven Rebellion The Raktika Greatwood": {
        "Region": "The Rak'tika Greatwood",
        "Rank": 5,
        "Patch": "ShB",
        "ID": 182
    },
    "Forgiven Rebellion The Tempest": {
        "Region": "The Tempest",
        "Rank": 5,
        "Patch": "ShB",
        "ID": 183
    },
    "Green Archon": {
        "Region": "Labyrinthos",
        "Rank": 1,
        "Patch": "EW",
        "ID": 142
    },
    "\u00fc-u-\u00fc-u": {
        "Region": "Labyrinthos",
        "Rank": 1,
        "Patch": "EW",
        "ID": 143
    },
    "Hulder": {
        "Region": "Labyrinthos",
        "Rank": 2,
        "Patch": "EW",
        "ID": 144,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Storsie": {
        "Region": "Labyrinthos",
        "Rank": 2,
        "Patch": "EW",
        "ID": 145,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Burfurlur the Canny": {
        "Region": "Labyrinthos",
        "Rank": 3,
        "Patch": "EW",
        "ID": 146,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Vajrakumara": {
        "Region": "Thavnair",
        "Rank": 1,
        "Patch": "EW",
        "ID": 147
    },
    "Iravati": {
        "Region": "Thavnair",
        "Rank": 1,
        "Patch": "EW",
        "ID": 148
    },
    "Yilan": {
        "Region": "Thavnair",
        "Rank": 2,
        "Patch": "EW",
        "ID": 149,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Sugriva": {
        "Region": "Thavnair",
        "Rank": 2,
        "Patch": "EW",
        "ID": 150,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Sphatika": {
        "Region": "Thavnair",
        "Rank": 3,
        "Patch": "EW",
        "ID": 151,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Warmonger": {
        "Region": "Garlemald",
        "Rank": 1,
        "Patch": "EW",
        "ID": 152
    },
    "Emperors Rose": {
        "Region": "Garlemald",
        "Rank": 1,
        "Patch": "EW",
        "ID": 153
    },
    "Minerva": {
        "Region": "Garlemald",
        "Rank": 2,
        "Patch": "EW",
        "ID": 154,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Aegeiros": {
        "Region": "Garlemald",
        "Rank": 2,
        "Patch": "EW",
        "ID": 155,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Armstrong": {
        "Region": "Garlemald",
        "Rank": 3,
        "Patch": "EW",
        "ID": 156,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Daphnia Magna": {
        "Region": "Mare Lamentorum",
        "Rank": 1,
        "Patch": "EW",
        "ID": 157
    },
    "Genesis Rock": {
        "Region": "Mare Lamentorum",
        "Rank": 1,
        "Patch": "EW",
        "ID": 158
    },
    "Mousse Princess": {
        "Region": "Mare Lamentorum",
        "Rank": 2,
        "Patch": "EW",
        "ID": 159,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Lunatender Queen": {
        "Region": "Mare Lamentorum",
        "Rank": 2,
        "Patch": "EW",
        "ID": 160,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Ruminator": {
        "Region": "Mare Lamentorum",
        "Rank": 3,
        "Patch": "EW",
        "ID": 161,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Yumcax": {
        "Region": "Elpis",
        "Rank": 1,
        "Patch": "EW",
        "ID": 162
    },
    "Shockmaw": {
        "Region": "Elpis",
        "Rank": 1,
        "Patch": "EW",
        "ID": 163
    },
    "Petalodus": {
        "Region": "Elpis",
        "Rank": 2,
        "Patch": "EW",
        "ID": 164,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Gurangatch": {
        "Region": "Elpis",
        "Rank": 2,
        "Patch": "EW",
        "ID": 165,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Ophioneus": {
        "Region": "Elpis",
        "Rank": 3,
        "Patch": "EW",
        "ID": 166,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Level Cheater": {
        "Region": "Ultima Thule",
        "Rank": 1,
        "Patch": "EW",
        "ID": 167
    },
    "Oskh Rhei": {
        "Region": "Ultima Thule",
        "Rank": 1,
        "Patch": "EW",
        "ID": 168
    },
    "Arch-Eta": {
        "Region": "Ultima Thule",
        "Rank": 2,
        "Patch": "EW",
        "ID": 169,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Fan Ail": {
        "Region": "Ultima Thule",
        "Rank": 2,
        "Patch": "EW",
        "ID": 170,
        "RespawnTimer": [
            4,
            2
        ],
        "MaintTimer": [
            2.4,
            1.2
        ]
    },
    "Narrow-rift": {
        "Region": "Ultima Thule",
        "Rank": 3,
        "Patch": "EW",
        "ID": 171,
        "RespawnTimer": [
            84,
            48
        ],
        "MaintTimer": [
            50.4,
            28.8
        ]
    },
    "Ker Labyrinthos": {
        "Region": "Labyrinthos",
        "Rank": 5,
        "Patch": "EW",
        "ID": 172
    },
    "Ker Thavnair": {
        "Region": "Thavnair",
        "Rank": 5,
        "Patch": "EW",
        "ID": 173
    },
    "Ker Garlemald": {
        "Region": "Garlemald",
        "Rank": 5,
        "Patch": "EW",
        "ID": 174
    },
    "Ker Mare Lamentorum": {
        "Region": "Mare Lamentorum",
        "Rank": 5,
        "Patch": "EW",
        "ID": 175
    },
    "Ker Elpis": {
        "Region": "Elpis",
        "Rank": 5,
        "Patch": "EW",
        "ID": 176
    },
    "Ker Ultima Thule": {
        "Region": "Ultima Thule",
        "Rank": 5,
        "Patch": "EW",
        "ID": 177
    },
    "Chupacabra": {
        "Region": "Urqopacha",
        "Rank": 1,
        "Patch": "DT",
        "ID": 178
    },
    "Mad Maguey": {
        "Region": "Urqopacha",
        "Rank": 1,
        "Patch": "DT",
        "ID": 179
    },
    "Queen Hawk": {
        "Region": "Urqopacha",
        "Rank": 2,
        "Patch": "DT",
        "ID": 180,
        "RespawnTimer": [
          4,
          2
        ],
        "MaintTimer": [
          2.4,
          1.2
        ]
    },
    "Nechuciho": {
        "Region": "Urqopacha",
        "Rank": 2,
        "Patch": "DT",
        "ID": 181,
        "RespawnTimer": [
          4,
          2
        ],
        "MaintTimer": [
          2.4,
          1.2
        ]
    },
    "Kirlirger the Abhorrent": {
        "Region": "Urqopacha",
        "Rank": 3,
        "Patch": "DT",
        "ID": 182,
        "RespawnTimer": [
          84,
          48
        ],
        "MaintTimer": [
          50.4,
          28.8
        ]
    },
    "The Slammer": {
        "Region": "Kozama'uka",
        "Rank": 1,
        "Patch": "DT",
        "ID": 183
    },
    "Go'ozoabek'be": {
        "Region": "Kozama'uka",
        "Rank": 1,
        "Patch": "DT",
        "ID": 184
    },
    "The Raintriller": {
        "Region": "Kozama'uka",
        "Rank": 2,
        "Patch": "DT",
        "ID": 185,
        "RespawnTimer": [
          4,
          2
        ],
        "MaintTimer": [
          2.4,
          1.2
        ]
    },
    "Pkuucha": {
        "Region": "Kozama'uka",
        "Rank": 2,
        "Patch": "DT",
        "ID": 186,
        "RespawnTimer": [
          4,
          2
        ],
        "MaintTimer": [
          2.4,
          1.2
        ]
    },
    "Ihnuxokiy": {
        "Region": "Kozama'uka",
        "Rank": 3,
        "Patch": "DT",
        "ID": 187,
        "RespawnTimer": [
          84,
          48
        ],
        "MaintTimer": [
          50.4,
          28.8
        ]
    },
    "Leafscourge Hadoll Ja": {
        "Region": "Yak T'el",
        "Rank": 1,
        "Patch": "DT",
        "ID": 189
    },
    "Xty'iinbek": {
        "Region": "Yak T'el",
        "Rank": 1,
        "Patch": "DT",
        "ID": 179
    },
    "Starcrier": {
        "Region": "Yak T'el",
        "Rank": 2,
        "Patch": "DT",
        "ID": 190,
        "RespawnTimer": [
          4,
          2
        ],
        "MaintTimer": [
          2.4,
          1.2
        ]
    },
    "Rrax Yity'a": {
        "Region": "Yak T'el",
        "Rank": 2,
        "Patch": "DT",
        "ID": 191,
        "RespawnTimer": [
          4,
          2
        ],
        "MaintTimer": [
          2.4,
          1.2
        ]
    },
    "Neyoozoteel": {
        "Region": "Yak T'el",
        "Rank": 3,
        "Patch": "DT",
        "ID": 192,
        "RespawnTimer": [
          84,
          48
        ],
        "MaintTimer": [
          50.4,
          28.8
        ]
    },
    "Nopalitender Fabuloso": {
        "Region": "Shaaloani",
        "Rank": 1,
        "Patch": "DT",
        "ID": 193
    },
    "Uktena": {
        "Region": "Shaaloani",
        "Rank": 1,
        "Patch": "DT",
        "ID": 194
    },
    "Yehehetoaua'pyo": {
        "Region": "Shaaloani",
        "Rank": 2,
        "Patch": "DT",
        "ID": 195,
        "RespawnTimer": [
          4,
          2
        ],
        "MaintTimer": [
          2.4,
          1.2
        ]
    },
    "Keheniheyamewi": {
        "Region": "Shaaloani",
        "Rank": 2,
        "Patch": "DT",
        "ID": 196,
        "RespawnTimer": [
          4,
          2
        ],
        "MaintTimer": [
          2.4,
          1.2
        ]
    },
    "Sansheya": {
        "Region": "Shaaloani",
        "Rank": 3,
        "Patch": "DT",
        "ID": 197,
        "RespawnTimer": [
          84,
          48
        ],
        "MaintTimer": [
          50.4,
          28.8
        ]
    },
    "Gallowsbeak": {
        "Region": "Heritage Found",
        "Rank": 1,
        "Patch": "DT",
        "ID": 198
    },
    "Gargant": {
        "Region": "Heritage Found",
        "Rank": 1,
        "Patch": "DT",
        "ID": 199
    },
    "Heshuala": {
        "Region": "Heritage Found",
        "Rank": 2,
        "Patch": "DT",
        "ID": 200,
        "RespawnTimer": [
          4,
          2
        ],
        "MaintTimer": [
          2.4,
          1.2
        ]
    },
    "Urna Variabilis": {
        "Region": "Heritage Found",
        "Rank": 2,
        "Patch": "DT",
        "ID": 201,
        "RespawnTimer": [
          4,
          2
        ],
        "MaintTimer": [
          2.4,
          1.2
        ]
    },
    "Atticus the Primogenitor": {
        "Region": "Heritage Found",
        "Rank": 3,
        "Patch": "DT",
        "ID": 202,
        "RespawnTimer": [
          84,
          48
        ],
        "MaintTimer": [
          50.4,
          28.8
        ]
    },
    "Jewel Bearer": {
        "Region": "Living Memory",
        "Rank": 1,
        "Patch": "DT",
        "ID": 203
    },
    "13th Child": {
        "Region": "Living Memory",
        "Rank": 1,
        "Patch": "DT",
        "ID": 204
    },
    "Sally the Sweeper": {
        "Region": "Living Memory",
        "Rank": 2,
        "Patch": "DT",
        "ID": 205,
        "RespawnTimer": [
          4,
          2
        ],
        "MaintTimer": [
          2.4,
          1.2
        ]
    },
    "Cat's Eye": {
        "Region": "Living Memory",
        "Rank": 2,
        "Patch": "DT",
        "ID": 206,
        "RespawnTimer": [
          4,
          2
        ],
        "MaintTimer": [
          2.4,
          1.2
        ]
    },
    "The Forecaster": {
        "Region": "Living Memory",
        "Rank": 3,
        "Patch": "DT",
        "ID": 207,
        "RespawnTimer": [
          84,
          48
        ],
        "MaintTimer": [
          50.4,
          28.8
        ]
    },
    "Arch Aethereater Urqopacha": {
        "Region": "Urqopacha",
        "Rank": 5,
        "Patch": "DT",
        "ID": 208
    },
    "Arch Aethereater Kozama'uka": {
        "Region": "Kozama'uka",
        "Rank": 5,
        "Patch": "DT",
        "ID": 209
    },
    "Arch Aethereater Yak T'el": {
        "Region": "Yak T'el",
        "Rank": 5,
        "Patch": "DT",
        "ID": 210
    },
    "Arch Aethereater Shaaloani": {
        "Region": "Shaaloani",
        "Rank": 5,
        "Patch": "DT",
        "ID": 211
    },
    "Arch Aethereater Heritage Found": {
        "Region": "Heritage Found",
        "Rank": 5,
        "Patch": "DT",
        "ID": 212
    },
    "Arch Aethereater Living Memory": {
        "Region": "Living Memory",
        "Rank": 5,
        "Patch": "DT",
        "ID": 213
    }
};