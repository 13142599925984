import React from 'react';
import { Button, Modal } from 'reactstrap';

import {
  Box,
  Typography,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Avatar,
  Icon
} from "@mui/material"

import { useHuntTrackerContext } from 'tracker-context';
import { SessionConfigs } from 'sessions/sessionState';
import LanguageDependentText from '../TimerTable/LanguageDependentText';
import { BossFATEs, FATEChains } from 'sessions/resources/boss-fate';

function constructHoverText(point) {
  if (point.state) {
    return (
      <React.Fragment>
        <LanguageDependentText text={point.reporter} align={"center"} fontSize={15} fontWeight="light"/>
        <LanguageDependentText key={"date"} date={point.time} variant="body2" fontSize={12}></LanguageDependentText>
        {point.verified ? <LanguageDependentText key={"verified"} header={"tableVerified"} align={"center"} fontSize={12} fontWeight="bold"/> : null} 
      </React.Fragment>
    )
  } 
  return "";
}

const FATEMapModal = ({fateName, worldName, region, position}) => {
  const [show, setShow] = React.useState(false);
  const [mapContent, setMapContent] = React.useState(null);
  const [finalLocation, setFinalLocation] = React.useState([]);

  const boundingContainer = React.useRef();
  const mapContainer = React.useRef();

  var fateGroup = [];
  for (var key of Object.keys(BossFATEs)) {
    if (fateName.endsWith(" 1") || fateName.endsWith(" 2") || fateName.endsWith(" 3") || fateName.endsWith(" 4") || fateName.endsWith(" 5") || fateName.endsWith(" 6")) {
      if (fateName.slice(0,-2) == BossFATEs[key].DatabaseName && BossFATEs[key].Region == region) {
        fateGroup.push({...BossFATEs[key], id: key});
      }
    } else {
      if (fateName == BossFATEs[key].DatabaseName && BossFATEs[key].Region == region) {
        fateGroup.push({...BossFATEs[key], id: key});
      }
    }
  }
  for (var key of Object.keys(FATEChains)) {
    if (fateName.endsWith(" 1") || fateName.endsWith(" 2") || fateName.endsWith(" 3") || fateName.endsWith(" 4") || fateName.endsWith(" 5") || fateName.endsWith(" 6")) {
      if (fateName.slice(0,-2) == FATEChains[key].DatabaseName && FATEChains[key].Region == region) {
        fateGroup.push({...FATEChains[key], id: key});
      }
    } else {
      if (fateName == FATEChains[key].DatabaseName && FATEChains[key].Region == region) {
        fateGroup.push({...FATEChains[key], id: key});
      }
    }
  }

  const toggle = () => {
    setShow(!show);
  };

  const displayFATEMap = () => {
    setShow(true);
    setMapContent(fateGroup.map((fate, index) => {
      return {key: index, x: (fate.Coordinates.X+1024)/2048, y: (fate.Coordinates.Y+1024)/2048}
    }));
  }

  React.useEffect(() => {
    if (position) {
      for (var i in fateGroup) {
        if (parseInt(fateGroup[i]["id"]) == position) {
          setFinalLocation([(fateGroup[i].Coordinates.X+1024)/2048, (fateGroup[i].Coordinates.Y+1024)/2048])
        }
      }
    } else {
      setFinalLocation(null);
    }
  }, [position]);

  return ([
    <IconButton key={"OpenMapButton"} onClick={() => displayFATEMap()} sx={{ width: 30, height: 30, marginRight: "2px" }}>
        <p><i className={"fa-solid fa-map"} style={{fontSize: 15}}></i></p>
    </IconButton>,
    <Modal key={"MapContainer"} isOpen={show} toggle={() => toggle()} size="lg" className={"modal-fullscreen"} contentClassName={"card border border-danger rounded modal-fullscreen"} backdrop={true}>
      <Box sx={{minHeight: "100%", padding: "24px", display: "flex", flexDirection: "column" }}>
        <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Box>
            <LanguageDependentText huntName={fateName} fontSize={24} />
            <LanguageDependentText text={worldName} fontSize={15} />
          </Box>
        </Box>

        <div ref={boundingContainer} style={{position: "relative", overflow: "hidden", marginTop: "10px", marginBottom: "10px"}}>
          <div ref={mapContainer} style={{transition: "transform 5ms ease-out 5ms"}}>
            <Box key={"MapDisplay"}
              component="img"
              src={"/static/images/HuntRegions/" + region + ".png"}>
            </Box>
            {(mapContent && !position) ? (mapContent.map((point) => (
              <IconButton key={point.key} style={{position: "absolute", transform: "translate(-50%, -50%)", borderRadius: "50%", padding: 0, width: "2%", height: "2%", top: point.y*100 + "%", left: point.x*100 + "%" }}>
                <Avatar style={{width: "100%", height: "100%", border: "1px solid black"}} src={"/static/images/SpawnAvailable.png"} imgProps={{opacity: 0.2}} />
              </IconButton>
            ))) : null }
            {(finalLocation) ? (
              <IconButton style={{position: "absolute", transform: "translate(-50%, -50%)", borderRadius: "50%", padding: 0, width: "5%", height: "5%", top: finalLocation[1]*100 + "%", left: finalLocation[0]*100 + "%" }}>
                <Avatar style={{width: "100%", height: "100%"}} src={"/static/images/Flag_marker.png"}/>
              </IconButton>
            ) : null}
          </div>
        </div>
        <Box style={{display: "flex", flexDirection: "row", justifyContent: "stretch"}}>
        <Button block color="default" onClick={() => toggle()}>
          {SessionConfigs.translateText("header", "tableClose")}
        </Button>
        </Box>
      </Box>
    </Modal>
  ]);
}

export default FATEMapModal;