export const dataCenters = {
    "Aether" :{
        "Names": ["Adamantoise","Cactuar","Faerie","Gilgamesh","Jenova","Midgardsormr","Sargatanas","Siren"],
        "API": "/api/dcTravel/Aether"
    },
    "Crystal" :{
        "Names": ["Balmung","Brynhildr","Coeurl","Diabolos","Goblin","Malboro","Mateus","Zalera"],
        "API": "/api/dcTravel/Crystal"
    },
    "Dynamis" :{
        "Names": ["Cuchulainn","Golem","Kraken","Halicarnassus","Maduin","Marilith","Rafflesia","Seraph"],
        "API": "/api/dcTravel/Dynamis"
    },
    "Primal" :{
        "Names": ["Behemoth","Excalibur","Exodus","Famfrit","Hyperion","Lamia","Leviathan","Ultros"],
        "API": "/api/dcTravel/Primal"
    },
    "Chaos" :{
        "Names": ["Cerberus","Louisoix","Moogle","Omega","Phantom","Ragnarok","Sagittarius","Spriggan"],
        "API": "/api/dcTravel/Chaos"
    },
    "Light" :{
        "Names": ["Alpha","Lich","Odin","Phoenix","Raiden","Shiva","Twintania","Zodiark"],
        "API": "/api/dcTravel/Light"
    },
    "Shadow" :{
        "Names": ["Innocence","Pixie","Titania","Tycoon"],
        "API": "/api/dcTravel/Shadow"
    },
    "Materia" :{
        "Names": ["Bismarck","Ravana","Sephirot","Sophia","Zurvan"],
        "API": "/api/dcTravel/Materia"
    },
    "Elemental" :{
        "Names": ["Aegis","Atomos","Carbuncle","Garuda","Gungnir","Kujata","Tonberry","Typhon"],
        "API": "/api/dcTravel/Elemental"
    },
    "Gaia" :{
        "Names": ["Alexander","Bahamut","Durandal","Fenrir","Ifrit","Ridill","Tiamat","Ultima"],
        "API": "/api/dcTravel/Gaia"
    },
    "Mana" :{
        "Names": ["Anima","Asura","Chocobo","Hades","Ixion","Masamune","Pandaemonium","Titan"],
        "API": "/api/dcTravel/Mana"
    },
    "Meteor" :{
        "Names": ["Belias","Mandragora","Ramuh","Shinryu","Unicorn","Valefor","Yojimbo","Zeromus"],
        "API": "/api/dcTravel/Mana"
    },
    "豆豆柴" :{
        "Names": ["水晶塔","银泪湖","太阳海岸","伊修加德","红茶川"],
        "API": "/api/dcTravel/豆豆柴"
    },
    "陆行鸟" :{
        "Names": ["红玉海","神意之地","拉诺西亚","幻影群岛","萌芽池","宇宙和音","沃仙曦染","晨曦王座"],
        "API": "/api/dcTravel/陆行鸟"
    },
    "莫古力" :{
        "Names": ["白银乡","白金幻象","神拳痕","潮风亭","旅人栈桥","拂晓之间","龙巢神殿","梦羽宝境"],
        "API": "/api/dcTravel/莫古力"
    },
    "猫小胖" :{
        "Names": ["紫水栈桥","延夏","静语庄园","摩杜纳","海猫茶屋","柔风海湾","琥珀原"],
        "API": "/api/dcTravel/猫小胖"
    },
};