import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// reactstrap components
import {
  Button,
  Card, 
  CardHeader, 
  CardBody, 
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  Divider,
  Box,
} from "@mui/material"


import { CustomInput } from "reactstrap";

import { extractWorldInfo } from "sessions/gameInfo.js"
import { useHuntTrackerContext } from "tracker-context";
import { SessionConfigs } from "sessions/sessionState"
import { UserProfile } from "sessions/userState";
import { textSelector } from "assets/selector/language.js"
import { dataCenters } from "sessions/resources/datacenters.js"

import LanguageDependentText from "components/TimerTable/LanguageDependentText";


function RegisterModal({datacenter, registerModal, setRegisterModal, alert, setAlert}) {
  const [controller, dispatch] = useHuntTrackerContext();
  const { darkMode, language, datacenters, user } = controller;
  
  const textLibrary = SessionConfigs.translateText;
  const [state, setState] = React.useState({});

  var worlds = SessionConfigs.getCurrentDCInfo(datacenters);
  React.useEffect(() => {
    worlds = SessionConfigs.getCurrentDCInfo(datacenters);
    setRegisterModal({...registerModal, ingameServer: worlds[0]});
  }, [datacenter])

  const worldSelects = worlds.map((world)=>{
    return <option className="world-select" key={world} value={world}> {world} </option>
  });

  const handleUsernameChange = (event) => {
    setRegisterModal({...registerModal, username: event.target.value});
  }

  const handlePasswordChange = (event) => {
    setRegisterModal({...registerModal, password: event.target.value});
  }

  const handleWorldChange = (event) => {
    setRegisterModal({...registerModal, ingameServer: event.target.value});
  }

  const handleIngameNameChange = (event) => {
    setRegisterModal({...registerModal, ingameName: event.target.value});
  }

  const handleIngameIDChange = (event) => {
    setRegisterModal({...registerModal, ingameID: event.target.value});
  }

  const handleRegistration = () => {
    setAlert(
      <SweetAlert
          style={{display: "block",marginTop: "-100px"}}
          showCancel={false} showConfirm={false} showCloseButton={false}
          onConfirm={()=>{}} s
          title={textSelector.accountProcessing[language]}>
      </SweetAlert>);

    SessionConfigs.query("/api/register", {
      Username: registerModal.username,
      Password: registerModal.password,
      IngameName: registerModal.ingameName,
      IngameWorld: registerModal.ingameServer
    }).then((response) => {
      setAlert(null);
      window.location.reload();
    }).catch((error) => {
      setAlert(
        <SweetAlert
            error style={{display: "block",marginTop: "-100px"}}
            title="Error Received" onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)} confirmBtnBsStyle="info" >
        {error.response.data.message}
      </SweetAlert>);
    });
  }

  const handleHidingCard = () => {
    setRegisterModal({username: "", password: "", ingameName: "", ingameServer: worlds[0], show: false});
  }

  return <>
    <Modal isOpen={registerModal.show} toggle={handleHidingCard} size="sm" contentClassName={"card border border-danger rounded"} backdrop={true}>
      <Form className="form">
          <Card>
            <CardHeader>
              <div className="bg-gradient-info shadow-info border-radius-lg py-3 pe-1" style={{transform: "translate(0%, -50%)"}}>
                <LanguageDependentText variant="h4" header={"accountSignUp"} align="center" className="text-white font-weight-bolder text-center mt-2 mb-0"/>
              </div>
            </CardHeader>
            <CardBody>
              <InputGroup
                className={classNames({
                  "input-group-focus": state.emailFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa-solid fa-user"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={textLibrary("header","accountUsername")}
                  type="text"
                  value={registerModal.username}
                  onFocus={(e) => setState({ ...state, emailFocus: true })}
                  onBlur={(e) => setState({ ...state, emailFocus: false })}
                  onChange={handleUsernameChange}
                />
              </InputGroup>
              <InputGroup
                className={classNames({
                  "input-group-focus": state.passFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa-solid fa-key"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={textLibrary("header","accountPassword")}
                  type="password"
                  value={registerModal.password}
                  onFocus={(e) => setState({ ...state, passFocus: true })}
                  onBlur={(e) => setState({ ...state, passFocus: false })}
                  onChange={handlePasswordChange}
                />
              </InputGroup>
              <Divider> {textLibrary("header","accountIngameInfo")} </Divider>
              
              {state.lodestoneID ? (
                <>
                  <InputGroup
                    className={classNames({
                      "input-group-focus": state.ingameIDFocus
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa-solid fa-person"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={textLibrary("header","accountIngameID")}
                      type="text"
                      value={registerModal.ingameID}
                      onFocus={(e) => setState({ ...state, ingameIDFocus: true })}
                      onBlur={(e) => setState({ ...state, ingameIDFocus: false })}
                      onChange={handleIngameIDChange}
                    />
                  </InputGroup>
                  <Box className="pull-left">
                    <CustomInput type="switch" id="globalID" checked={state.globalID} onClick={(event) => setState({...state, globalID: !state.globalID})} 
                      label={<LanguageDependentText header={"accountGlobalID"} component="p" fontSize={15}/>} />
                  </Box>
                </>
              ) : (
                <>
                  <InputGroup
                    key={"username"}
                    className={classNames({
                      "input-group-focus": state.ingameNameFocus
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa-solid fa-person"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={textLibrary("header","accountIngameName")}
                      type="text"
                      value={registerModal.ingameName}
                      onFocus={(e) => setState({ ...state, ingameNameFocus: true })}
                      onBlur={(e) => setState({ ...state, ingameNameFocus: false })}
                      onChange={handleIngameNameChange}
                    />
                  </InputGroup>
                  <InputGroup
                    key={"userworld"}
                    className={classNames({
                      "input-group-focus": state.ingameWorldFocus
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa-solid fa-server"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" onChange={handleWorldChange}>
                      {
                        [
                          <option key={datacenter} className="world-select" value={datacenter} disabled> --- {datacenter} --- </option>,
                          ...worldSelects
                        ]
                      }
                    </Input>
                  </InputGroup>
                </>
              )}

            </CardBody>
            <CardFooter>
              <Button
                block
                className="mb-3"
                color="primary"
                onClick={handleRegistration}
                size="lg"
              >
                {textLibrary("header","profileSignup")}
              </Button>
            </CardFooter>
          </Card>
        </Form>
    </Modal>
  </>
}

export default RegisterModal;