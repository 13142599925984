import React from "react";
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";

import NotificationAlert from "react-notification-alert";

import AdminPage from "views/Admin";
import DashboardNavbar from "components/Navbars/DashboardNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";

import { useHuntTrackerContext } from "tracker-context";
import { SessionConfigs } from "sessions/sessionState";
import { UserProfile } from "sessions/userState";

import TrainPage from "views/Train.js"
import ProfilePage from "views/Profile";

const Admin = (props) => {
  const history = useHistory();
  const user = UserProfile.getUser();

  const [opacity, setOpacity] = React.useState(0);
  const [sidebarMini, setSidebarMini] = React.useState(document.body.classList.contains("sidebar-mini"));
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const mainPanelRef = React.useRef(null);
  const notificationAlertRef = React.useRef(null);
  const location = useLocation();

  const textLibrary = SessionConfigs.translateText;

  const [context, dispatch] = useHuntTrackerContext();
  const { darkMode, language } = context;
  React.useEffect(() => {
    if (darkMode != document.body.classList.contains("white-content")) {
      document.body.classList.toggle("white-content");
    }
  }, [darkMode]);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "Timer") {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const handleMiniClick = () => {
    setSidebarMini(!document.body.classList.contains("sidebar-mini"));
    document.body.classList.toggle("sidebar-mini");
  };
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
    document.documentElement.classList.toggle("nav-open");
  };
  const closeSidebar = () => {
    setSidebarOpened(false);
    document.documentElement.classList.remove("nav-open");
  };
  return (
    <div className="wrapper">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
        <button
          className="minimize-sidebar btn btn-link btn-just-icon"
          onClick={handleMiniClick}
        >
          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
        </button>
      </div>
      <Sidebar
        {...props}
        routes={routes}
        activeColor="blue"
        closeSidebar={closeSidebar}
      />
      <div className="main-panel" ref={mainPanelRef} data="blue">
        <DashboardNavbar
          {...props}
          handleMiniClick={handleMiniClick}
          sidebarOpened={sidebarOpened}
          toggleSidebar={toggleSidebar}
        />
        <Switch>
          <Route path={"/profile"} component={ProfilePage} key={"ProfilePage"} />
          <Route path={"/admin"} component={AdminPage} key={"AdminPage"} />
        </Switch>
        {
          // we don't want the Footer to be rendered on full screen maps page
          props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
            <Footer fluid style={sidebarMini ? null : {marginLeft: "240px"}}/>
          )
        }
      </div>
    </div>
  );
};

export default Admin;
