import React from "react";

import {
  Card, 
  CardHeader,
  CardBody,
} from "reactstrap";

import {
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Typography
} from "@mui/material";

import LanguageDependentText from "components/TimerTable/LanguageDependentText";

import { SessionConfigs } from "sessions/sessionState";

function RegionCard({regionName, worldName, scale, regions, setTrainMap}) {
  var baseName = regionName;
  if (regionName.endsWith(" 1") || regionName.endsWith(" 2") || regionName.endsWith(" 3") || regionName.endsWith(" 4") || regionName.endsWith(" 5") || regionName.endsWith(" 6")) {
    baseName = regionName.slice(0,-2);
  }

  var hunts = [];
  if (regions) {
    hunts = regions[regionName].routes;
  }

  const formatTooptip = (hunt) => {
    const currentTime = new Date().getTime();
    const timeDifference = (currentTime - new Date(hunt.time).getTime()) / 60000;
    return <React.Fragment>
      <LanguageDependentText key={"huntname"} huntName={hunt.huntName} fontSize={15} align="center" />
      <LanguageDependentText key={"huntfounddate"} date={new Date(hunt.time)} align="center" />
      <LanguageDependentText key={"timedifference"} text={timeDifference.toFixed(0) + " " + SessionConfigs.translateText("header", "timeMinutes")} align="center" />
    </React.Fragment>
  };
  
  const handleHuntFlagUpdate = (event, hunt) => {
    event.preventDefault();

    setTrainMap({
      "HuntName": hunt.huntName,
      "WorldName": worldName,
      "RegionName": regionName,
      "Scale": scale
    }, true);
    
  }

  return <CardBody>
    <Box style={{position: "relative", overflow: "hidden"}}>
      <Box key={"MapDisplay"}
        component="img"
        src={"/images/HuntRegions/" + baseName + ".png"}>
      </Box>
      
      {hunts.map((hunt) => {
        return <Typography key={hunt.huntName} align="center" color="black !important" fontSize={15} fontWeight={700} style={{position: "absolute", transform: "translate(-50%, -130%)", top: (hunt.y-1)/scale*100 + "%", left: (hunt.x-1)/scale*100 + "%", WebkitTextStroke: "0.1px white"}}> {SessionConfigs.translateText("huntName", hunt.huntName)} </Typography>
      })}

      {hunts.map((hunt) => {
        return <Tooltip key={hunt.huntName} title={formatTooptip(hunt)} placement="top" disableFocusListener disableTouchListener disableInteractive arrow>
          <IconButton onContextMenu={(event) => handleHuntFlagUpdate(event, hunt)} style={{position: "absolute", transform: "translate(-50%, -50%)", borderRadius: "50%", padding: 0, width: "5%", height: "5%", top: (hunt.y-1)/scale*100 + "%", left: (hunt.x-1)/scale*100 + "%" }}>
            <Avatar style={{width: "100%", height: "100%"}} src={"/images/Flag_marker.png"}/>
          </IconButton>
        </Tooltip>
      })}
    </Box>
  </CardBody>
}

export default RegionCard;