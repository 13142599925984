import React from 'react';
import { Button, Modal } from 'reactstrap';

import {
  Box,
  Typography,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Avatar,
  Icon
} from "@mui/material"

import { SessionConfigs } from 'sessions/sessionState';
import LanguageDependentText from './LanguageDependentText';
import { huntInfos } from 'sessions/resources/hunts';
import { SocketIO } from "sessions/socket"
import { extractSpawnPoints } from "sessions/gameInfo";

function constructHoverText(point) {
  if (point.state) {
    return (
      <React.Fragment>
        <LanguageDependentText text={point.reporter} align={"center"} fontSize={15} fontWeight={"500"} />
        <LanguageDependentText key={"date"} date={point.time} variant="body2" align={"center"} fontSize={12}></LanguageDependentText>
        {point.verified ? <LanguageDependentText key={"verified"} header={"tableVerified"} align={"center"} fontSize={15} fontWeight={"500"}/> : null} 
      </React.Fragment>
    )
  } 
  return "";
}

const SpawnMapModal = ({huntName, worldName, position, lastDeath=null, setTrainMap=null, showSSMap=false, setShowSSMap=()=>{}}) => {
  const [show, setShow] = React.useState(false);
  const [mapContent, setMapContent] = React.useState(null);
  const [mapCoordinate, setMapCoordinate] = React.useState("( 0, 0 )");

  const boundingContainer = React.useRef(null);
  const mapContainer = React.useRef(null);

  var hunt, huntKey;

  if (huntName.endsWith(" 1") || huntName.endsWith(" 2") || huntName.endsWith(" 3") || huntName.endsWith(" 4") || huntName.endsWith(" 5") || huntName.endsWith(" 6")) {
    if (huntName.startsWith("FATE")) {
      hunt = SessionConfigs.getFateResources(huntName.slice(0,-2));
    } else {
      hunt = SessionConfigs.getHuntResources(huntName.slice(0,-2));
    }
    huntKey = huntName.slice(0,-2);
  } else {
    if (huntName.startsWith("FATE")) {
      hunt = SessionConfigs.getFateResources(huntName);
    } else {
      hunt = SessionConfigs.getHuntResources(huntName);
    }
    huntKey = huntName;
  }

  var scale = 1;
  var translateX = 0;
  var translateY = 0;
  var mapScale = 41;
  if (hunt.Patch == "HW") {
    mapScale = 43;
  }

  const spawnPoints = extractSpawnPoints();
  const Aetheryte = SessionConfigs.getAetherytes(hunt.Region);

  const mapZoom = (event) => {
    event.preventDefault();

    if (event.deltaY < 0) {
      scale += event.deltaY * -0.002;
    } else {
      scale -= event.deltaY * 0.002;
    }

    // Restrict scale
    scale = Math.min(Math.max(1, scale), 5);

    const container = boundingContainer.current;
    var widthDifference = (container.getBoundingClientRect().width - 0) * scale - container.getBoundingClientRect().width;
    var translateLimit = (widthDifference/scale)/2;
    if (widthDifference < 0) translateLimit = -translateLimit;
    translateX = Math.min(Math.max(-translateLimit, translateX), translateLimit);
    translateY = Math.min(Math.max(-translateLimit, translateY), translateLimit);

    // Apply scale transform
    mapContainer.current.style.webkitTransform = `scale(${scale}) translate(${translateX}px,${translateY}px)`;
  }

  const mapTracking = (event) => {
    const regionMap = mapContainer.current.getBoundingClientRect();
    var xProportion = (event.clientX - regionMap.left) / regionMap.width * mapScale + 1;
    var yProportion = (event.clientY - regionMap.top) / regionMap.height * mapScale + 1;
    const mapPosition = "(" + xProportion.toFixed(1) + ", " + yProportion.toFixed(1) + ")";
    setMapCoordinate(mapPosition);
  }

  const mapMove = (event) => {
    event.preventDefault();
  
    translateX += event.movementX / scale;
    translateY += event.movementY / scale;
  
    const container = boundingContainer.current;
    var widthDifference = (container.getBoundingClientRect().width - 0) * scale - container.getBoundingClientRect().width;
    var translateLimit = (widthDifference/scale)/2;
    if (widthDifference < 0) translateLimit = -translateLimit;
    translateX = Math.min(Math.max(-translateLimit, translateX), translateLimit);
    translateY = Math.min(Math.max(-translateLimit, translateY), translateLimit);
  
    mapContainer.current.style.webkitTransform = `scale(${scale}) translate(${translateX}px,${translateY}px)`;
  }
  
  const initMapTranslation = (event) => {
    if (event.button == 0) {
      mapContainer.current.addEventListener("mousemove", mapMove);
    }
  }

  const endMapTranslation = (event) => {
    mapContainer.current.removeEventListener("mousemove", mapMove);
  }

  const startMapTracking = (event) => {
    mapContainer.current.addEventListener("mousemove", mapTracking);
  }

  const endMapTracking = (event) => {
    mapContainer.current.removeEventListener("mousemove", mapTracking);
  }

  const handleSSSpawned = () => {
    setShowSSMap(!showSSMap);
  }

  const queryHuntMap = () => {
    setShow(true)
    if (!position) {
      if (setTrainMap) {
        var baseName = "";
        if (huntName.endsWith(" 1") || huntName.endsWith(" 2") || huntName.endsWith(" 3") || huntName.endsWith(" 4") || huntName.endsWith(" 5") || huntName.endsWith(" 6")) {
          baseName = huntName.slice(0,-2);
        } else {
          baseName = huntName;
        }
        const spawnPointInfo = spawnPoints[baseName];
        const huntInfo = huntInfos[baseName];

        var huntMap = [];
        for (var i = 0; i < spawnPointInfo.DisplayPoints; i++) {
          const key = "SpawnPoint" + String(i+1).padStart(2, "0");
          var huntMapState = {
            x: (spawnPointInfo[key][0]-1) / mapScale,
            y: (spawnPointInfo[key][1]-1) / mapScale,
            key: key,
            state: false,
            verified: false,
            reporter: "",
          };
          huntMap.push(huntMapState);
        }
        setMapContent({
          version: spawnPointInfo.Version, 
          index: huntMap, 
          scale: mapScale, 
          map: huntInfo["Region"], 
          ssAvailable: false
        });

      } else {
        SessionConfigs.query("/api/querySpawnPoints", {
          "QuerySpawnPoint": "Query",
          "HuntName": huntName,
          "WorldName": worldName,
          "LastDeath": lastDeath
        }).then((response) => {
          const spawnPoints = SessionConfigs.getSpawnPoints(huntKey);
          let content = {scale: spawnPoints.Dimensions[0], version: spawnPoints.Version, index: [], aetherytes: []};
          for (let key in spawnPoints) {
            if (key.startsWith("SpawnPoint")) {
              if (parseInt(key.replace("SpawnPoint","")) > spawnPoints.DisplayPoints) continue;
              if (response.data[key]) {
                content.index.push({key: key, x: (spawnPoints[key][0]-1)/content.scale, y: (spawnPoints[key][1]-1)/content.scale, version: spawnPoints.Version,
                  state: response.data[key].State, time: response.data[key].Time*1000, reporter: response.data[key].Reporter, verified: response.data[key].Verified
                });
              } else {
                content.index.push({key: key, x: (spawnPoints[key][0]-1)/content.scale, y: (spawnPoints[key][1]-1)/content.scale, version: spawnPoints.Version});
              }
            }
          }
          setMapContent(content);
    
          mapScale = spawnPoints.Dimensions[0];
          mapContainer.current.addEventListener('wheel', mapZoom);
          mapContainer.current.addEventListener('mousedown', initMapTranslation);
          mapContainer.current.addEventListener('mouseup', endMapTranslation);
          mapContainer.current.addEventListener('mouseleave', endMapTranslation);
          mapContainer.current.addEventListener('mouseenter', startMapTracking);
          mapContainer.current.addEventListener('mouseleave', endMapTracking);
        })
      }
    } else {
      setMapContent(null);
    }
  }

  const toggle = () => {
    if (!setTrainMap) {
      mapContainer.current.removeEventListener('wheel', mapZoom);
      mapContainer.current.removeEventListener('mousedown', initMapTranslation);
      mapContainer.current.removeEventListener('mouseup', endMapTranslation);
      mapContainer.current.removeEventListener('mouseleave', endMapTranslation);
      mapContainer.current.removeEventListener('mouseenter', startMapTracking);
      mapContainer.current.removeEventListener('mouseleave', endMapTracking);
    }
    setShow(false);
  };

  const handleSpawnPointToggle = async (event) => {
    for (var i = 0; i < mapContent.index.length; i++) {
      if (mapContent.index[i].key == event.currentTarget.dataset.key) {
        if (setTrainMap) {
          setTrainMap({
            "HuntName": huntName,
            "WorldName": worldName,
            "SpawnPoint": mapContent.index[i],
            "RegionName": hunt.Region,
            "Scale": mapScale
          }, false);
          toggle();
        } else {
          const response = await SessionConfigs.query("/api/querySpawnPoints", {
            "QuerySpawnPoint": "Set",
            "MapPoint": event.currentTarget.dataset.key,
            "HuntName": huntName,
            "WorldName": worldName,
            "Version": mapContent.version,
            "Position": [0,0],
            "State": !mapContent.index[i].state,
          });

          if (response.status == 200) {
            mapContent.index[i].state = !mapContent.index[i].state;
          }
          setMapContent({...mapContent});
        }
        break;
      }
    }
  }

  React.useEffect(() => {
    
  }, [mapContent]);

  return ([
    <IconButton key={"OpenMapButton"} onClick={() => queryHuntMap()} sx={{ width: 30, height: 30, marginRight: "2px" }}>
      <p><i className={"fa-solid fa-map"} style={{fontSize: 15}}></i></p>
    </IconButton>,
    <Modal key={"MapContainer"} isOpen={show} toggle={() => toggle()} size="lg" className={"modal-fullscreen"} contentClassName={"card border border-danger rounded modal-fullscreen"} backdrop={true}>
      <Box sx={{minHeight: "100%", padding: "24px", display: "flex", flexDirection: "column" }}>
        <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Box>
            <LanguageDependentText huntName={huntName} fontSize={24} />
            <LanguageDependentText text={worldName} fontSize={15} />
          </Box>
          <Box>
            <LanguageDependentText text={mapCoordinate} fontSize={24} />
          </Box>
        </Box>

        <div ref={boundingContainer} style={{position: "relative", overflow: "hidden", marginTop: "10px", marginBottom: "10px"}}>
          <div ref={mapContainer} style={{transition: "transform 5ms ease-out 5ms"}}>
            <Box key={"MapDisplay"}
              component="img"
              src={showSSMap ? ("/static/images/HuntRegions/SS " + hunt.Region + ".png") : ("/static/images/HuntRegions/" + hunt.Region + ".png")}>
            </Box>
            {(mapContent && !showSSMap) ? (mapContent.index.map((point) => (
              <Tooltip key={point.key} title={constructHoverText(point)} placement="top" disableFocusListener disableTouchListener disableInteractive arrow>
                <IconButton data-key={point.key} onClick={handleSpawnPointToggle} style={{position: "absolute", transform: "translate(-50%, -50%)", borderRadius: "50%", padding: 0, width: "2%", height: "2%", top: point.y*100 + "%", left: point.x*100 + "%" }}>
                  <Avatar style={{width: "100%", height: "100%", border: "1px solid black"}} src={point.state ? "/static/images/SpawnUnavailable.png" : "/static/images/SpawnAvailable.png"} imgProps={{opacity: 0.2}} />
                </IconButton>
              </Tooltip>
            ))) : null }
            {(position && !showSSMap) ? (
              <IconButton style={{position: "absolute", transform: "translate(-50%, -50%)", borderRadius: "50%", padding: 0, width: "5%", height: "5%", top: (position[1]-1)/mapScale*100 + "%", left: (position[0]-1)/mapScale*100 + "%" }}>
                <Avatar style={{width: "100%", height: "100%"}} src={"/static/images/Flag_marker.png"}/>
              </IconButton>
            ) : null}
            {Object.keys(Aetheryte).map((name) => {
              const position = Aetheryte[name];
              return <Tooltip key={name} title={SessionConfigs.translateText("aetheryteName", name)} placement="top" disableFocusListener disableTouchListener disableInteractive arrow>
                  <IconButton style={{position: "absolute", transform: "translate(-50%, -50%)", borderRadius: "50%", padding: 0, width: "3%", height: "3%", top: (position[1]-1)/mapScale*100 + "%", left: (position[0]-1)/mapScale*100 + "%" }}>
                    <Avatar style={{width: "100%", height: "100%"}} src={"/static/images/Aetheryte.png"}/>
                  </IconButton>
                </Tooltip>
            })}

          </div>
        </div>
        <Box style={{display: "flex", flexDirection: "row", justifyContent: "stretch"}}>
        {(mapContent && mapContent.ssAvailable) ? <Button block color="primary" onClick={() => handleSSSpawned()}>
          {SessionConfigs.translateText("header", "tableSSSpawn")}
        </Button>: null}
        <Button block color="default" onClick={() => toggle()}>
          {SessionConfigs.translateText("header", "tableClose")}
        </Button>
        </Box>
      </Box>
    </Modal>
  ]);
}

export default SpawnMapModal;