import React, { useEffect } from "react";
import { useHistory } from "react-router-dom"

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  CustomInput,
} from "reactstrap";

import {
  Box,
  Button,
  TableContainer,
} from "@mui/material"

import SweetAlert from "react-bootstrap-sweetalert";
import { ToastContainer } from "react-toastify";
import notification from "assets/ringtone.ogg";

import TimerRow from "components/TimerTable/TimerRow"
import TimerTable from "components/TimerTable/TimerTable"
import FATETable from "components/FATETable/FATETable"
import LanguageDependentText from "components/TimerTable/LanguageDependentText";
import { useHuntTrackerContext, setContextState } from "tracker-context";

import { SocketIO } from "sessions/socket";
import { HuntTimers } from "sessions/databaseStorage";
import { SessionConfigs } from "sessions/sessionState";
import { dataCenters } from "sessions/resources/datacenters";

const Timer = (props) => {
  const [controller, dispatch ] = useHuntTrackerContext();
  const { language, datacenters, audioPermission } = controller;

  const history = useHistory();
  const [alert, setAlert] = React.useState(null);
  const audioRef = React.createRef();

  const [fateInfo, setFateInfo] = React.useState([]);
  const rankType = "fate"

  const [traveling, setTraveling] = React.useState(true);
  const [loadingState, setLoadingState] = React.useState(true);

  var chainInfo = {};
  chainInfo = HuntTimers.getChainInfo();

  const dataCenterTravel = async () => {
    if (props.match.params["world"]) {
      const dcList = SessionConfigs.getDataCenterResources();
      const worldKey = props.match.params["world"];
      for (var key of Object.keys(dcList)) {
        if (key.toLocaleLowerCase() === worldKey.toLocaleLowerCase()) {
          if (!datacenters.includes(key)) {
            setContextState(dispatch, "datacenters", [key]);
          }
          setTraveling(false);
          return;
        }

        for (var world of dcList[key]["Names"]) {
          if (world.toLocaleLowerCase() === worldKey.toLocaleLowerCase()) {
            if (!datacenters.includes(key)) {
              setContextState(dispatch, "datacenters", [key]);
            }
            SessionConfigs.setWorldSelect(rankType, "select", world, [key], true);
            setTraveling(false);
            return;
          }
        }
      }
    } else {
      setTraveling(false);
    }
  }
  
  React.useEffect(() => {
    setTraveling(true);
    setLoadingState(true);
    dataCenterTravel();
  }, []);

  React.useEffect(() => {
    if (traveling) return; 

    if (datacenters.length == 0) {
      history.push("/index");
      return;
    }

    SessionConfigs.query("/api/lastDeathTimers", {
      "QueryDeathTimers": SessionConfigs.getCurrentDCInfo(datacenters),
      "RankType": "fate"
    }).then((response) => {
      if (response.status == 200) {
        HuntTimers.setFATETimers(response.data.timers);
        setFateInfo(HuntTimers.getFATEInfo());
      }
      setLoadingState(false);
    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
      setLoadingState(false);
    });

  }, [traveling, datacenters]);

  React.useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = audioPermission > 0 ? audioPermission : 0
    }
  }, [audioRef.current, audioPermission]);

  const hideDeadRef = React.useRef();
  React.useEffect(() => {
    if (document.getElementById("fate" + "HideDead") && fateInfo.length > 0) document.getElementById("fate" + "HideDead").checked = !fateInfo[0].hideDead;
  }, [hideDeadRef.current, fateInfo])

  const toggleHiddenHunts = (type, event) => {
    HuntTimers.setHideDeadHunts(type, !event.currentTarget.checked);
    HuntTimers.updateDisplayState(type);
    SessionConfigs.setHideDeadHunts(type, !event.currentTarget.checked);
    updateDisplayStatus(type);
  }

  var timerConfigs = SessionConfigs.getToggleStates(rankType);
  const worlds = SessionConfigs.getCurrentDCInfo(datacenters);
  const patches = SessionConfigs.getPatches();
  var lastClick = new Date().getTime();
  var lastClickType = "";

  const updateDisplayStatus = () => {
    setFateInfo(HuntTimers.getFATEInfo());
    const currentTime = new Date().getTime();

    for (var i = 0; i < fateInfo.length; i++) {
      const row = document.getElementById(fateInfo[i].worldName + "_" + fateInfo[i].huntName + "_Row");
      if ((fateInfo[i].displayState && (!fateInfo[i].hideDead || currentTime > fateInfo[i].expectMinTime )) == row.classList.contains("d-none")) {
        row.classList.toggle("d-none");

        for (var j in fateInfo[i].Chained) {
          const chainedRow = document.getElementById(fateInfo[i].worldName + "_" + fateInfo[i].Chained[j] + "_Row");
          if (row.classList.contains("d-none")) {
            chainedRow.classList.add("d-none");
          } else {
            chainedRow.classList.remove("d-none");
          }
        }
      }
    }
  }

  const toggleWorldState = (type, world, event) => {
    var newClick = new Date().getTime();

    if (newClick - lastClick < 300 && lastClickType == world) {
      lastClick = newClick;

      for (var i = 0; i < worlds.length; i++) {
        if (worlds[i] != world) {
          document.getElementById(type+worlds[i]).classList.remove("btn-selected");
        } else {
          document.getElementById(type+worlds[i]).classList.add("btn-selected");
        }
      }
      SessionConfigs.setWorldSelect(type, "select", world, datacenters);
      HuntTimers.updateDisplayState(type);
      updateDisplayStatus(type);

    } else {
      lastClick = newClick;
      lastClickType = world;

      event.currentTarget.classList.toggle("btn-selected");
      const state = event.currentTarget.classList.contains("btn-selected");
      SessionConfigs.setWorldSelect(type, "toggle", world);
      HuntTimers.updateDisplayState(type);
      updateDisplayStatus(type);
    }
  }
  
  const togglePatchState = (type, patch, event) => {
    var newClick = new Date().getTime();
    if (newClick - lastClick < 300 && lastClickType == patch) {
      lastClick = newClick;
      
      for (var i = 0; i < patches.length; i++) {
        if (patches[i] != patch) {
          document.getElementById(type+patches[i]).classList.remove("btn-selected");
        } else {
          document.getElementById(type+patches[i]).classList.add("btn-selected");
        }
      }
      SessionConfigs.setPatchSelectOnly(type, patch);
      HuntTimers.updateDisplayState(type);
      updateDisplayStatus(type);

    } else {
      lastClick = newClick;
      lastClickType = patch;

      event.currentTarget.classList.toggle("btn-selected");
      const state = event.currentTarget.classList.contains("btn-selected");
      SessionConfigs.setPatchSelect(type, patch, state);
      HuntTimers.updateDisplayState(type);
      updateDisplayStatus(type);
    }
  }

  return (
    <>
      <div className="content">
        {alert}
        <audio src={notification} ref={audioRef}/>
        <Row style={{marginTop: 15}}>
          <ToastContainer
              style={{width: 400}}
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover
            />
          <Col id={"FATECard"}>
            <Card>
              <CardBody>
                {loadingState ? null : ([
                  <Box key={"tableTitle"} pt={1} px={3} style={{display: "flex", justifyContent: "space-between"}}>
                    <LanguageDependentText header="fateTable" variant="h4"/>

                    <Box className="pull-right">
                      <CustomInput type="switch" id={"fate" + "HideDead"} onClick={(event) => toggleHiddenHunts("fate", event)} label={<LanguageDependentText header={"tableHideDead"} component="p" fontSize={15}/>} />
                    </Box>
                  </Box>,
                  <Box key={"worldSelect"} pt={1} px={3}>
                    <LanguageDependentText header="worldSelectTitle" variant="h6"/>
                  </Box>,
                  <Box key={"worldGroup"} pt={1} px={3}>
                    {worlds.map((world)=>(
                      (timerConfigs.worlds.includes(world)) ? (
                      <Button id={rankType+world} key={world} variant="outlined" className="btn-selected" onClick={(event) => toggleWorldState(rankType, world, event)}>
                        <LanguageDependentText text={world} variant="body2" fontSize={13}></LanguageDependentText>
                      </Button>
                      ) : (
                      <Button id={rankType+world} key={world} variant="outlined" onClick={(event) => toggleWorldState(rankType, world, event)}>
                        <LanguageDependentText text={world} variant="body2" fontSize={13}></LanguageDependentText>
                      </Button> 
                    )))}
                  </Box>,
                  <Box key={"patchSelect"} pt={1} px={3}>
                    <LanguageDependentText header="patchSelectTitle" variant="h6"/>
                  </Box>,
                  <Box key={"patchGroup"} pt={1} px={3} mb={4}>
                    {patches.map((patch)=>(
                      (timerConfigs.patches.includes(patch)) ? (
                      <Button id={rankType+patch} key={patch} variant="outlined" className="btn-selected" onClick={(event) => togglePatchState(rankType, patch, event)}>
                        <LanguageDependentText patchName={patch} variant="body2" fontSize={13}></LanguageDependentText>
                      </Button>
                      ) : (
                      <Button id={rankType+patch} key={patch} variant="outlined" onClick={(event) => togglePatchState(rankType, patch, event)}>
                        <LanguageDependentText patchName={patch} variant="body2" fontSize={13}></LanguageDependentText>
                      </Button> 
                    )))}
                  </Box>,
                  <Box key={"tableContent"}>
                    <TableContainer sx={{ boxShadow: "none", overflowY: "hidden" }}>
                      <FATETable name={"fateTable"} bossFATEs={fateInfo} chainFATEs={chainInfo} />
                    </TableContainer>
                  </Box>
                ])}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Timer;
