import React from "react";
import { SessionConfigs } from "sessions/sessionState";

const HuntTrackerContext = React.createContext();
HuntTrackerContext.displayName = "Hunt Tracker Context";

// Example Call: dispatch({type: "language", value: value});
function reducer(state, action) {
    return { ...state, [action.name]: action.value};
}

function HuntTrackerContextProvider({initialStates, children}) {
    const [controller, dispatch] = React.useReducer(reducer, initialStates);
    const value = React.useMemo(() => [controller, dispatch], [controller, dispatch]);
    return <HuntTrackerContext.Provider value={value}>{children}</HuntTrackerContext.Provider>;
}

function useHuntTrackerContext() {
    const context = React.useContext(HuntTrackerContext);
    if (!context) {
        throw new Error();
    }
    return context;
}

function setContextState(dispatch, name, value, update=true) {
    dispatch({name: name, value: value});
    if (update && name != "user") SessionConfigs.setSession(name, value);
    if (name == "user") SessionConfigs.setUser(value);
}

function setContextStates(dispatch, names, values, update=true) {
    for (let i in names) {
        dispatch({name: names[i], value: values[i]});
    }
    SessionConfigs.setSessions(names, values);
}

export {
    HuntTrackerContextProvider,
    useHuntTrackerContext,
    setContextState,
    setContextStates
}