import React from "react"
import { useHistory } from "react-router-dom";

import { 
  Badge,
  Modal,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {
  Avatar,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
} from "@mui/material"

import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";

import { dataCenters } from "sessions/resources/datacenters";
import { SessionConfigs } from "sessions/sessionState";
import TablePagination from "../Pagination/Pagination.js";
import LanguageDependentText from "components/TimerTable/LanguageDependentText.js";
import { UserProfile } from "sessions/userState.js";

function UserTable({children}) {
  const history = useHistory();
  const [alert, setAlert] = React.useState(null);

  const viewPerPage = 20;
  const [displayData, setDisplayData] = React.useState([]);
  const [paginationControl, setPagination] = React.useState({
    currentPage: 0,
    totalPages: 0
  });

  const [searchName, setSearchName] = React.useState("");
  const [searchResult, setSearchResult] = React.useState([]);
  const [filterResult, setFilterResult] = React.useState([]);
  const [permissionSelected, setPermissionSelected] = React.useState({value: [], list: ["Unverified"]});
  const [permissionUpdate, setPermissionUpdate] = React.useState({show: false, user: "", permission: []});

  const [passwordUpdate, setPasswordUpdate] = React.useState({show: false, user: "", password: ""});

  const admin = UserProfile.getUser();

  React.useEffect(() => {
    SessionConfigs.queryData("/api/fetchUserList", {}).then((response) => {
      response.data.sort((a,b) => b.registeredDate - a.registeredDate);
      setSearchResult(response.data);
      setPermissionSelected((p) => {
        response.data.map((user) => {
          user.permission.split(",").map((permissionName) => {
            if (!p.list.includes(permissionName)) {
              p.list.push(permissionName);
            }
          });
        })
        return {...p};
      });
      setPagination({currentPage: 0, totalPages: Math.ceil(response.data.length / viewPerPage)});
      setFilterResult(response.data.filter((user) => user.username.includes(searchName) || user.ingameServer.includes(searchName)));
    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
    })
  }, []);
  
  const setUserPermission = (setContent) => {
    SessionConfigs.queryData("/api/updateUserPermission", {
      username: setContent.user,
      permission: setContent.permission.map((perm) => perm.value).join(",")
    }).then((response) => {
      for (var i in searchResult) {
        if (searchResult[i].username == setContent.user) {
          searchResult[i] = response.data;
        }
      }
      setSearchResult([...searchResult]);
    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
    })
  };

  const setUserPassword = (setContent) => {
    SessionConfigs.queryData("/api/resetPassword", {
      "AdminReset": true,
      "User": setContent.user,
      "Password": setContent.password
    }).then((response) => {
      setPasswordUpdate({show: false, user: "", password: ""});
    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
    })
  };

  React.useEffect(() => {
    const newFilterData = searchResult.filter((user) => { 
      let permissionState = false;
      for (let permission of permissionSelected.value) {
        if (user.permission.includes(permission.value)) {
          permissionState = true;
        } else if (permission.value === "Unverified") {
          if (!user.isActive) {
            permissionState = true;
          }
        }
      }
      
      if (permissionSelected.value.length == 0) return user.username.includes(searchName) || user.ingameServer.includes(searchName) || user.ingameName.includes(searchName);
      if (searchName === "") return permissionState;
      return user.username.includes(searchName) || user.ingameServer.includes(searchName) || user.ingameName.includes(searchName) || permissionState;
    });
    setFilterResult(newFilterData);
    setPagination({...paginationControl, totalPages: Math.ceil(newFilterData.length / viewPerPage)});
  }, [searchResult, searchName, permissionSelected])

  React.useEffect(() => {
    setDisplayData(filterResult.slice(paginationControl.currentPage * viewPerPage, paginationControl.currentPage * viewPerPage + viewPerPage));
  }, [filterResult, paginationControl])

  const textLibrary = SessionConfigs.translateText;

  return (
    <>
      <Form action="#">
      <LanguageDependentText variant="span" header={"adminTableFilter"} fontSize={12} fontWeight={"bold"}/>
        <FormGroup>
          <Input type="text" value={searchName} onChange={(event) => {setSearchName(event.currentTarget.value)}} />
          
        </FormGroup>
        
        <LanguageDependentText variant="span" header={"adminTablePermissionFilter"} fontSize={12} fontWeight={"bold"}/>
        <Select
            className="react-select info"
            classNamePrefix="react-select"
            name="Permission Filter"
            closeMenuOnSelect={false}
            isMulti
            minMenuHeight={600}
            maxMenuHeight={900}
            value={permissionSelected.value}
            onChange={(value) => {
              if (!value) setPermissionSelected({...permissionSelected, value: []});
              else setPermissionSelected({...permissionSelected, value: value});
            }}
            options={permissionSelected.list.map((world)=>{
              return {value: world, label: world}
            })}
            style={{marginTop: 15}}
          />
      </Form>
      <Table size="large">
        <TableHead>
          <TableRow>
          {["adminTableUsername", "adminTableIngameName", "adminTableWorld", "adminTableCreatedDate"].map((col) => {
            return (
              <TableCell key={col} variant="head" style={{width: col == "tableWorldName" ? "15%" : "20%", verticalAlign: "bottom", paddingBottom: 0, paddingTop: 0}}>
                <LanguageDependentText variant="span" header={col} fontSize={12} fontWeight={"bold"} color="#111111" align={col == "adminTableUsername" ? null : "center"} style={{cursor: "pointer"}}/>
              </TableCell>
          )})}
          <TableCell key={"viewedit"} variant="head" style={{width: "25%", verticalAlign: "bottom", paddingBottom: 0, paddingTop: 0}}>
            
          </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayData.map((user) => {
            return <TableRow key={user.username}>
              <TableCell>
                <Box sx={{display: "flex", flexDirection: "row"}}>
                  <IconButton sx={{ width: 24, height: 24, marginRight: "2px" }}>
                    <Avatar src={user.profilePic ? user.profilePic : ""} sx={{ width: 24, height: 24 }}/>
                  </IconButton>
                  <Box paddingBottom={0} paddingLeft={2} sx={{display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                    <LanguageDependentText variant="span" text={user.username} fontSize={15}/>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                <LanguageDependentText variant="span" text={user.ingameName} fontSize={12} align={"center"}/>
              </TableCell>
              <TableCell>
                <LanguageDependentText variant="span" text={user.ingameServer} fontSize={12} align={"center"}/>
              </TableCell>
              <TableCell>
                <LanguageDependentText variant="span" date={new Date(user.registeredDate)} fontSize={12} align={"center"}/>
              </TableCell>
              <TableCell>
                {user.isActive ? (
                  <LanguageDependentText variant="span" text={user.permission.split(",").map((perm) =>  textLibrary("permission", perm) + ", ")} fontSize={12} align={"center"}/>
                ) : (
                  <LanguageDependentText variant="span" text={textLibrary("permission", "Unverified")} fontSize={12} align={"center"}/>
                )}
                <Button size="lg" variant="contained" color="secondary" onClick={() => setPermissionUpdate({permission: user.isActive ? user.permission.split(",").map((perm) => ({value: perm, label: textLibrary("permission", perm)})) : [], user: user.username, show: true})} style={{width: "100%"}}>
                  <LanguageDependentText header="adminSelectPermission" fontSize={12}/>
                </Button>
                <Button size="lg" variant="contained" color="primary" onClick={() => setPasswordUpdate({password: "", user: user.username, show: true})} style={{width: "100%"}}>
                  <LanguageDependentText header="accountResetPassword" fontSize={12}/>
                </Button>
              </TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
      <TablePagination totalPages={paginationControl.totalPages} currentPage={paginationControl.currentPage} setPagination={setPagination} />

      <Modal isOpen={permissionUpdate.show} toggle={() => setPermissionUpdate({show: false, user: "", permission: ""})} size="md" contentClassName={"card border border-danger rounded"} backdrop={true}>
        <Box sx={{minHeight: "100%", padding: "24px", display: "flex", flexDirection: "column" }}>
          <Row style={{paddingTop: 0, paddingBottom: 15}}>
            <Col sm={6}>
              <LanguageDependentText text={permissionUpdate.user} fontSize={15} />
            </Col>
            <Col sm={6}>
              <LanguageDependentText header={"adminSelectPermission"} fontSize={15} />
            </Col>
            <Col sm={12} style={{marginTop: 15}}>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                name="Patch Filter"
                closeMenuOnSelect={false}
                isMulti
                minMenuHeight={600}
                maxMenuHeight={900}
                value={permissionUpdate.permission}
                onChange={(value) => {
                  if (!value) setPermissionUpdate({...permissionUpdate, permission: []});
                  else setPermissionUpdate({...permissionUpdate, permission: value});
                }}
                options={admin.permission.ControlledPermission.map((perm) => {
                  return {value: perm, label: textLibrary("permission", perm)};
                })}
              />
            </Col>
          </Row>
          <Button style={{marginTop: 15}} variant="contained" color="error" onClick={() => setUserPermission(permissionUpdate)}>
            {SessionConfigs.translateText("header", "trainScheduleSubmit")}
          </Button>
        </Box>        
      </Modal>

      <Modal isOpen={passwordUpdate.show} toggle={() => setPasswordUpdate({show: false, user: "", password: ""})} size="md" contentClassName={"card border border-danger rounded"} backdrop={true}>
        <Box sx={{minHeight: "100%", padding: "24px", display: "flex", flexDirection: "column" }}>
          <Row style={{paddingTop: 0, paddingBottom: 15}}>
            <Col sm={6}>
              <LanguageDependentText text={passwordUpdate.user} fontSize={15} />
            </Col>
            <Col sm={6}>
              <LanguageDependentText header={"accountResetPassword"} fontSize={15} />
            </Col>
            <Col sm={12} style={{marginTop: 15}}>
              <Input type="text" value={passwordUpdate.password} onChange={(event) => setPasswordUpdate({...passwordUpdate, password: event.currentTarget.value})} />
            </Col>
          </Row>
          <Button style={{marginTop: 15}} variant="contained" color="error" onClick={() => setUserPassword(passwordUpdate)}>
            {SessionConfigs.translateText("header", "trainScheduleSubmit")}
          </Button>
        </Box>        
      </Modal>
    </>
  );
}

export default UserTable;