
import React from 'react';
import { 
  Pagination, 
  PaginationItem, 
  PaginationLink 
} from 'reactstrap';

import { SessionConfigs } from "sessions/sessionState";

function TablePagination({totalPages, currentPage, setPagination}) {
  const textLibrary = SessionConfigs.translateText;

  const handlePageChange = (page) => {
    if (page >= 0 && page < totalPages) {
      setPagination({
        totalPages: totalPages,
        currentPage: page,
      });
    }
  };

  React.useEffect(() => {
    if (currentPage >= totalPages && totalPages > 0) {
      setPagination({currentPage: totalPages-1, totalPages: totalPages});
    }
  }, [currentPage, totalPages])

  var pageItems = [];
  if (totalPages > 8) {
    if (currentPage < 4) {
      pageItems = Array.from({length: 8}, (v, i) => i).map((page) => {
        const pageID = page;
        return (
          <PaginationItem key={pageID} active={pageID==currentPage}>
              <PaginationLink onClick={() => handlePageChange(pageID)} className='page-item-number'>
                  {pageID+1}
              </PaginationLink>
          </PaginationItem>
        );
      });
    } else if (currentPage < totalPages-4) {
      pageItems = Array.from({length: 8}, (v, i) => i-4).map((page) => {
        const pageID = currentPage+page;
        return (
          <PaginationItem key={pageID} active={pageID==currentPage}>
              <PaginationLink onClick={() => handlePageChange(pageID)} className='page-item-number'>
                  {pageID+1}
              </PaginationLink>
          </PaginationItem>
        );
      });
    } else {
      pageItems = Array.from({length: 8}, (v, i) => i).map((page) => {
        const pageID = totalPages-8+page;
        return (
          <PaginationItem key={pageID} active={pageID==currentPage}>
              <PaginationLink onClick={() => handlePageChange(pageID)} className='page-item-number'>
                  {pageID+1}
              </PaginationLink>
          </PaginationItem>
        );
      });
    }
  } else {
    pageItems = Array.from({length: totalPages}, (v, i) => i).map((page) => {
      const pageID = page;
      return (
        <PaginationItem key={pageID} active={pageID==currentPage}>
            <PaginationLink onClick={() => handlePageChange(pageID)} className='page-item-number'>
                {pageID+1}
            </PaginationLink>
        </PaginationItem>
      );
    });
  }

  return (
    <Pagination style={{marginTop: 15}}>
      <PaginationItem key={"previous"} disabled={currentPage == 0 || totalPages == 0}>
          <PaginationLink onClick={() => handlePageChange(currentPage-1)}>
            {textLibrary("header", "historyPrevious")}
          </PaginationLink>
      </PaginationItem>
      {pageItems}
      <PaginationItem key={"next"} disabled={currentPage == (totalPages-1) || totalPages == 0}>
          <PaginationLink onClick={() => handlePageChange(currentPage+1)}>
            {textLibrary("header", "historyNext")}
          </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
}

export default TablePagination;