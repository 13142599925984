import { useHuntTrackerContext } from "tracker-context";
import { Typography } from "@mui/material";

import { SessionConfigs } from "sessions/sessionState";

const LanguageDependentText = ({id, text, header, huntName, regionName, patchName, date, dateDifference, dateFormat, type, variant, color, fontSize, align, fontWeight, children}) => {
  const [controller, dispatch] = useHuntTrackerContext();

  var translatedText = "";
  if (header) {
    translatedText = SessionConfigs.translateText("header", header);
  } else if (huntName) {
    if (huntName.endsWith(" 1") || huntName.endsWith(" 2") || huntName.endsWith(" 3") || huntName.endsWith(" 4") || huntName.endsWith(" 5") || huntName.endsWith(" 6")) {
      translatedText = SessionConfigs.translateText("hunt", huntName.slice(0,-2)) + huntName.slice(-2);
    } else {
      translatedText = SessionConfigs.translateText("hunt", huntName);
    }
  } else if (regionName) {
    if (regionName.endsWith(" 1") || regionName.endsWith(" 2") || regionName.endsWith(" 3") || regionName.endsWith(" 4") || regionName.endsWith(" 5") || regionName.endsWith(" 6")) {
      translatedText = SessionConfigs.translateText("region", regionName.slice(0,-2)) + regionName.slice(-2);
    } else {
      translatedText = SessionConfigs.translateText("region", regionName);
    }
  } else if (patchName) {
    translatedText = SessionConfigs.translateText("patch", patchName);
  } else if (date) {
    switch (SessionConfigs.getLanguage()) {
      case "en": {
        translatedText = new Date(date).toLocaleDateString("en", dateFormat);
      }
      break;
      case "de": {
        translatedText = new Date(date).toLocaleDateString("de", dateFormat);
      }
      break;
      case "fr": {
        translatedText = new Date(date).toLocaleDateString("fr", dateFormat);
      }
      break;
      case "jp": {
        translatedText = new Date(date).toLocaleDateString("ja", dateFormat);
      }
      break;
      case "cn": {
        translatedText = new Date(date).toLocaleDateString("zh", dateFormat);
      }
      break;
    }
  } else if (dateDifference) {
    switch (SessionConfigs.getLanguage()) {



      case "en": {
        translatedText = new Date(date).toLocaleDateString("en", dateFormat);
      }
      break;
      case "de": {
        translatedText = new Date(date).toLocaleDateString("de", dateFormat);
      }
      break;
      case "fr": {
        translatedText = new Date(date).toLocaleDateString("fr", dateFormat);
      }
      break;
      case "jp": {
        translatedText = new Date(date).toLocaleDateString("ja", dateFormat);
      }
      break;
      case "cn": {
        translatedText = new Date(date).toLocaleDateString("zh", dateFormat);
      }
      break;
    }
  }

  return (
    <Typography 
      id={id}
      component={type}
      variant={variant ? variant : "body"}
      align={align ? align : "left"}
      fontWeight={fontWeight ? fontWeight : "medium"}
      sx={{fontSize: fontSize, color: color}}
    >
      {text ? text : translatedText} {children}
    </Typography>
  );
}

LanguageDependentText.defaultProps = {
  type: "p",
  variant: "body",
  align: "left",
  fontWeight: "medium",
  fontSize: "15",
  color: "inherit",
  dateFormat: { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" }
};

export default LanguageDependentText;