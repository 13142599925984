/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import SimplifiedNavbar from "components/Navbars/SimplifiedNavbar.js";
import Footer from "components/Footer/Footer.js";

import routes from "routes.js";

import HomePage from "views/HomePage.js";
import LoginPage from "views/Login.js";

import { useHuntTrackerContext } from "tracker-context";
import { SessionConfigs } from "sessions/sessionState.js";

const Pages = (props) => {
  React.useEffect(() => {
    document.documentElement.classList.remove("nav-open");
  });
  
  const [controller, dispatch] = useHuntTrackerContext();
  const { darkMode, language } = controller;
  if (darkMode == document.body.classList.contains("white-content")) {
    document.body.classList.toggle("white-content");
  }

  return (
    <>
      <SimplifiedNavbar/>
      <div className="wrapper wrapper-full-page">
        <div className={"full-page"}>
          <Switch>
            <Route path="/index" component={HomePage} key={"homepage"} />
            <Route path="/login" component={LoginPage} key={"homepage"} />
          </Switch>
          <Footer fluid />
        </div>
      </div>
    </>
  );
};

export default Pages;
