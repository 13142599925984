import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card, 
  CardHeader, 
  CardBody, 
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  Divider,
} from "@mui/material"

import { extractWorldInfo } from "sessions/gameInfo.js"
import { useHuntTrackerContext, setContextState } from "tracker-context";
import { SessionConfigs } from "sessions/sessionState"
import { UserProfile } from "sessions/userState";
import { textSelector } from "assets/selector/language.js"
import { dataCenters } from "sessions/resources/datacenters.js"

import LanguageDependentText from "components/TimerTable/LanguageDependentText";


function LoginModal({loginModal, setLoginModal, alert, setAlert}) {
  const [controller, dispatch] = useHuntTrackerContext();
  const { darkMode, language, user } = controller;
  
  const textLibrary = SessionConfigs.translateText;
  const [state, setState] = React.useState({});

  const handleUsernameChange = (event) => {
    setLoginModal({...loginModal, username: event.target.value});
  }

  const handlePasswordChange = (event) => {
    setLoginModal({...loginModal, password: event.target.value});
  }

  const handleAuthentication = () => {
    setLoginModal({...loginModal, password: ""});
    
    SessionConfigs.query("/api/login", {
      "Username": loginModal.username,
      "Password": loginModal.password,
      "RememberMe": true,
    }).then((response) => {
      setContextState(dispatch, "user", response.data.User);
      SessionConfigs.setCSRFToken(response.data.Token);
      window.location.reload();
    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
    });
  }

  const handleHidingCard = () => {
    setLoginModal({username: "", password: "", show: false});
  }

  return <>
    <Modal isOpen={loginModal.show} toggle={() => setLoginModal({...loginModal, show: false})} size="sm" contentClassName={"card border border-danger rounded"} backdrop={true}>
      <Form className="form">
          <Card>
            <CardHeader>
              <div className="bg-gradient-info shadow-info border-radius-lg py-3 pe-1" style={{transform: "translate(0%, -50%)"}}>
                <LanguageDependentText variant="h4" header={"accountSignIn"} align="center" className="text-white font-weight-bolder text-center mt-2 mb-0"/>
              </div>
            </CardHeader>
            <CardBody>
              <InputGroup
                className={classNames({
                  "input-group-focus": state.emailFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-email-85" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={textLibrary("header","accountUsername")}
                  type="text"
                  value={loginModal.username}
                  onFocus={(e) => setState({ ...state, emailFocus: true })}
                  onBlur={(e) => setState({ ...state, emailFocus: false })}
                  onChange={handleUsernameChange}
                />
              </InputGroup>
              <InputGroup
                className={classNames({
                  "input-group-focus": state.passFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-lock-circle" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={textLibrary("header","accountPassword")}
                  type="password"
                  value={loginModal.password}
                  onFocus={(e) => setState({ ...state, passFocus: true })}
                  onBlur={(e) => setState({ ...state, passFocus: false })}
                  onChange={handlePasswordChange}
                />
              </InputGroup>
              <div className="pull-right">
                <h6>
                  <Link
                    to="/resetpassword"
                    className="link footer-link"
                  >
                    {textLibrary("header","accountResetPassword")}
                  </Link>
                </h6>
              </div>
            </CardBody>
            <CardFooter>
              <Button
                block
                className="mb-3"
                color="primary"
                onClick={handleAuthentication}
                size="lg"
              >
                {textLibrary("header","profileSignin")}
              </Button>
            </CardFooter>
          </Card>
        </Form>
    </Modal>
      
  </>
}

export default LoginModal;
