import React from "react";

import {
  Badge,
  Modal,
  Row,
  Col,
  Collapse,
} from "reactstrap";

import Flatpickr from "react-flatpickr";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  Box,
  Button,
  ButtonGroup,
  Typography,
  TableRow,
  TableCell,
  IconButton,
  Avatar,
} from "@mui/material"

import { SocketIO } from "sessions/socket"

import { toast } from 'react-toastify';

import FATEMapModal from "./FATEMapModal";
import LanguageDependentText from "../TimerTable/LanguageDependentText";
import { CountDownTimer } from "../TimerTable/CountdownTimer";
import { CountUpTimer } from "./CountUpTimer";
import { dataCenters } from "sessions/resources/datacenters";
import { getDataCenterName } from "sessions/gameInfo";
import { HuntTimers } from "sessions/databaseStorage"
import { SessionConfigs } from "sessions/sessionState"

const FATEMainRow = (props) => {
  const [fateState, setFateState] = React.useState({...props.bossFATE});
  const [chainFATEs, setChainFATEs] = React.useState([...props.subFATEs]);
  const [modalShow, setModalShow] = React.useState({show: false, date: new Date(), time: new Date(), fateName: fateState.huntName});
  const [spawnInfo, setSpawnInfo] = React.useState({show: false});

  const [showChainFATEs, setShowChainFATEs] = React.useState(false);
  const [hideDead, setHideDead] = React.useState(props.bossFATE.hideDead);
  const [alert, setAlert] = React.useState(null);

  const datacenter = getDataCenterName(fateState.worldName);
  const allWorlds = dataCenters[datacenter]["Names"]
  const badgeColorID = allWorlds.indexOf(fateState.worldName);

  var baseKey = "";
  var fateRegions = [];

  var hunt;
  if (fateState.huntName.endsWith(" 1") || fateState.huntName.endsWith(" 2") || fateState.huntName.endsWith(" 3") || fateState.huntName.endsWith(" 4") || fateState.huntName.endsWith(" 5") || fateState.huntName.endsWith(" 6")) {
    hunt = SessionConfigs.getFateResources(fateState.huntName.slice(0,-2));
    baseKey = fateState.huntName.slice(0,-2);
  } else {
    hunt = SessionConfigs.getFateResources(fateState.huntName);
    baseKey = fateState.huntName;
  }

  fateRegions = hunt.Region;

  React.useEffect(() => {
    const currentTime = new Date().getTime();
    if (currentTime > fateState.expectMinTime && hideDead) {
      setHideDead(false);
    }
    
    setFateState({...props.bossFATE, lastDeathTime: props.bossFATE.lastDeathTime*1000})
    if (props.bossFATE.lastSeen) {
      moveRowToTop(fateState.worldName + "_" + fateState.huntName + "_Row");
    }

    document.addEventListener(fateState.worldName + "_" + fateState.huntName, (event) => {
      const message = event.detail;

      if (message["Notification"] == "FoundReport") {
        
      } else if (message["Notification"] == "FATEUpdate") {
        props.bossFATE["toVerify"] = false;
        props.bossFATE["hp"] = 0;
        props.bossFATE["missing"] = message["MissingCounter"] ? message["MissingCounter"] : 0;
        props.bossFATE["lastSeen"] = null;
        props.bossFATE["position"] = null;
        
        props.bossFATE["expectMinTime"] = (message["Time"]+(message["MaintState"] ? (hunt.MaintTimer[0]*3600) : (hunt.RespawnTimer[0]*3600)))*1000;
        props.bossFATE["expectMaxTime"] = (message["Time"]+(message["MaintState"] ? ((hunt.MaintTimer[0]+hunt.MaintTimer[1])*3600) : ((hunt.RespawnTimer[0]+hunt.RespawnTimer[1])*3600)))*1000;
        props.bossFATE["lastDeathTime"] = message["Time"]*1000;
        props.bossFATE["displayState"] = HuntTimers.getDisplay(fateState.Rank, fateState.worldName, fateState.Patch);
        setFateState({...props.bossFATE});
      }
    })

    /*
    socket.on(fateState.worldName + "_" + fateState.fateName, (message) => {
      const updatedTimer = {
        ...fateState,
        fateId: message.fateId,
        deathTime: new Date(message.lastDeath).getTime(),
        reportTime: new Date(message.lastSeen).getTime(),
        completed: message.completed
      };
      if (updatedTimer.deathTime > updatedTimer.reportTime) {
        if (message.fateId.length > 0) {
          updatedTimer["expectMinTime"] = updatedTimer.deathTime + BossFATEs[message.fateId].RespawnTimer[0]*3600000;
          updatedTimer["expectMaxTime"] = updatedTimer.deathTime + BossFATEs[message.fateId].RespawnTimer[0]*3600000 + BossFATEs[message.fateId].RespawnTimer[1]*3600000;
        } else {
          updatedTimer["expectMinTime"] = updatedTimer.deathTime + BossFATEs[baseKey].RespawnTimer[0]*3600000;
          updatedTimer["expectMaxTime"] = updatedTimer.deathTime + BossFATEs[baseKey].RespawnTimer[0]*3600000 + BossFATEs[baseKey].RespawnTimer[1]*3600000;
        }
      }
      HuntTimers.updateFATETimer(message.worldName + "_" + message.fateName, updatedTimer);
      setFateState(updatedTimer);
    });

    for (var i in fateState.Chained) {
      socket.on(fateState.worldName + "_" + fateState.Chained[i], (message) => {
        const fateTimer = HuntTimers.getFATETimer(message.worldName + "_" + message.fateName);
        const updatedTimer = {
          ...fateTimer,
          deathTime: new Date(message.lastDeath).getTime(),
          reportTime: new Date(message.lastSeen).getTime(),
          completed: message.completed
        };

        HuntTimers.updateFATETimer(message.worldName + "_" + message.fateName, updatedTimer);
        for (var j in chainFATEs) {
          if (chainFATEs[j].fateName == message.fateName) {
            chainFATEs[j] = updatedTimer;
            break;
          }
        }
        setChainFATEs([...chainFATEs]);
      });
    }

    return () => {
      socket.off(fateState.worldName + "_" + fateState.fateName);
      for (var i in fateState.Chained) {
        socket.off(fateState.worldName + "_" + fateState.Chained[i]);
      }
    }
      */
     
  }, []);

  const moveRowToTop = (id) => {
    const row = document.getElementById(id);
    row.parentElement.insertBefore(row, row.parentElement.firstChild);
  };

  const handleVerificationCheck = (state, ignore) => {
    
    if (!ignore) {
      SessionConfigs.queryData("/api/deathVerification", {
        "HuntName": fateState.huntName,
        "WorldName": fateState.worldName,
        "ReportTime": fateState.deathTime,
        "State": state,
      });
    }
    props.bossFATE["toVerify"] = false;
    if (props.bossFATE.hideDead) {
      setHideDead(true);
    }
    setFateState({...props.bossFATE});
    
  };

  const VerificationButtons = () => {
    setTimeout(() => {
      handleVerificationCheck(true, true);
    }, 300000);

    return (
      <ButtonGroup>
        <IconButton onClick={()=>handleVerificationCheck(true, false)} key="success" aria-label="delete">
          <i className="fa-solid fa-check btn-confirm"></i>
        </IconButton>
        <IconButton onClick={()=>handleVerificationCheck(false, false)} key="danger" aria-label="delete">
          <i className="fa-solid fa-xmark btn-deny"></i>
        </IconButton>
        <IconButton onClick={()=>handleVerificationCheck(true, true)} key="unknown" aria-label="delete">
          <i className="fa-solid fa-question btn-unknown"></i>
        </IconButton>
      </ButtonGroup>
    );
  };

  const Hover = ({ onHover, children }) => (
    <div className="hover">
      <div className="hover__no-hover">{children}</div>
      <div className="hover__hover">{onHover}</div>
    </div>
  );

  const reportDeath = (fate) => {
    setModalShow({show: true, date: new Date(), time: new Date(), fateName: fate.huntName});
  };

  const handleReportSubmission = (huntName, worldName, missing) => {
    const localeString = modalShow.date.toLocaleDateString("en", {
      year: 'numeric', 
      month: 'numeric', 
      day: 'numeric'
    }) + " " + modalShow.time.toLocaleTimeString("en", {
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric',
      hourCycle: 'h23',
    });

    const timestamp = new Date(localeString).getTime() / 1000;

    SessionConfigs.query("/api/deathReport", {
      "HuntName": huntName,
      "WorldName": worldName,
      "ReportTime":new Date(localeString).getTime() / 1000,
      "Missing": missing ? 1 : 0
    }).then((response) => {
      props.bossFATE["toVerify"] = false;
      props.bossFATE["hp"] = 0;
      props.bossFATE["missing"] = missing;
      props.bossFATE["lastSeen"] = null;
      props.bossFATE["position"] = null;
      
      props.bossFATE["expectMinTime"] = (timestamp+(hunt.RespawnTimer[0]*3600))*1000;
      props.bossFATE["expectMaxTime"] = (timestamp+((hunt.RespawnTimer[0]+hunt.RespawnTimer[1])*3600))*1000;
      props.bossFATE["lastDeathTime"] = timestamp*1000;
      props.bossFATE["displayState"] = HuntTimers.getDisplay(fateState.Rank, fateState.worldName, fateState.Patch);
      setFateState({...props.bossFATE});

    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
      console.log(error);
    });
  };

  const requestFATEDescription = (huntName, worldName) => {
    setSpawnInfo({...spawnInfo, MaintTimer: fateState.MaintTimer, RespawnTimer: fateState.RespawnTimer, show: true});
  };

  const handleDeadOpen = () => {
    if (HuntTimers.getDisplay(fateState.Rank, fateState.worldName, fateState.Patch)) {
      document.getElementById(fateState.worldName + "_" + fateState.huntName + "_Row").classList.remove("d-none");
    }
  };

  const handleSubmitRevertRequest = (huntName, worldName) => {
    setAlert(null);
    
    SessionConfigs.query("/api/revertDeathReport", {
      HuntName: huntName, WorldName: worldName, ID: "FATE"
    }).then((response) => {
      <SweetAlert
        success style={{display: "block",marginTop: "-100px"}}
        title={SessionConfigs.translateText("header", "historyRevertRequestSuccess")} onConfirm={() => {}}
        onCancel={() => setAlert(null)} confirmBtnBsStyle="info" >
      </SweetAlert>
    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
    });
  }

  const showRevertWarning = (huntName, worldName, deathTime) => {
    setAlert(
      <SweetAlert
          warning style={{display: "block",marginTop: "-100px"}}
          title={SessionConfigs.translateText("header", "historyRevertRequest")} onConfirm={() => handleSubmitRevertRequest(huntName, worldName)}
          onCancel={() => setAlert(null)} confirmBtnBsStyle="danger" showCancel cancelBtnBsStyle="default">
      </SweetAlert>
    );
  };

  return (
    Object.keys(fateState).length > 0 ? [
    <TableRow key={fateState.huntName} id={fateState.worldName + "_" + fateState.huntName + "_Row"} className={fateState.displayState ? "" : "d-none"}
        data-sort-name={fateState.huntName}
        data-sort-region={fateState.Region[0]}
        data-sort-default={fateState.defaultSortOrder}
        data-sort-max-time={fateState.expectMaxTime}
        data-sort-min-time={fateState.expectMinTime} >
      <TableCell style={{paddingTop: "3px", paddingBottom: "3px", borderBottom: "1px solid #88888855"}}>
        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <IconButton sx={{ width: 24, height: 24, marginRight: "2px" }} onClick={() => requestFATEDescription(fateState.huntName, fateState.worldName)}>
            <Avatar src={"/static/images/HuntIcons/" + baseKey + ".png"} sx={{ width: 24, height: 24 }}/>
          </IconButton>
          <Badge as="<label>" className={"world-badge " + `world-color-${badgeColorID}`}>
            {fateState.worldName.length > 5 ? fateState.worldName.slice(0,4) : fateState.worldName}
          </Badge>
          <Box paddingBottom={0} sx={{display: "flex", flexDirection: "column", justifyContent: "space-evenly", cursor: "pointer"}} onClick={() => setShowChainFATEs(!showChainFATEs)}>
            <LanguageDependentText huntName={fateState.huntName} variant="body" fontSize={16}/>
          </Box>
        </Box>
      </TableCell>

      <TableCell style={{paddingTop: "1px", paddingBottom: "1px", borderBottom: "1px solid #88888855"}}>
        {fateRegions.map((region) => {
          return <Box key={region} sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <FATEMapModal fateName={fateState.huntName} worldName={fateState.worldName} region={region} position={fateState.reportTime > fateState.deathTime ? fateState.fateId : null}/>
            <Box sx={{display: "flex", flexDirection: "column"}}>
              <LanguageDependentText regionName={region} variant="body" fontSize={16}/>
            </Box>
          </Box>
        })}
      </TableCell>

      <TableCell style={{paddingTop: "1px", paddingBottom: "1px", borderBottom: "1px solid #88888855"}} align="center">
        {fateState.toVerify ?  <VerificationButtons /> :
          <Hover onHover={<IconButton variant="outlined" color="error" onClick={() => reportDeath(fateState)} style={{height: 10}}> <i className="fa-solid fa-skull"></i> </IconButton>}>
            <CountDownTimer rowID={fateState.worldName + "_" + fateState.huntName + "_Row"} minTime={fateState.expectMinTime} maxTime={fateState.expectMaxTime} isFound={false} interval={1000} handleDeadOpen={handleDeadOpen}/>
          </Hover>
        }
      </TableCell>

      <Modal isOpen={modalShow.show} toggle={() => setModalShow({...modalShow, show: false})} size="md" contentClassName={"card border border-danger rounded"} backdrop={true}>
        <Box sx={{minHeight: "100%", padding: "24px", display: "flex", flexDirection: "column" }}>
          <Box>
            <LanguageDependentText huntName={modalShow.fateName} fontSize={24}> {SessionConfigs.translateText("header", "tableReportDeath")} </LanguageDependentText>
            <LanguageDependentText text={fateState.worldName} fontSize={15} />
          </Box>

          <Row style={{paddingTop: 15, paddingBottom: 15}}>
            <Col>
              <Flatpickr options={{
                enableTime: false,
                defaultDate: modalShow.date
              }} className={"form-control datetime-picker"} onChange={([date]) => { setModalShow({...modalShow, date: new Date(date)}) }}/>
            </Col>
            <Col>
              <Flatpickr options={{
                enableTime: true,
                noCalendar: true,
                minuteIncrement: 1,
                allowInput: true,
                defaultDate: modalShow.time
              }} className={"form-control datetime-picker"} onChange={([date]) => { setModalShow({...modalShow, time: new Date(date)}) }}/>
            </Col>
          </Row>
          <Button variant="contained" color="error" onClick={() => handleReportSubmission(modalShow.fateName, fateState.worldName)}>
            {SessionConfigs.translateText("header", "tableReportDeath")}
          </Button>
        </Box>
      </Modal>

      <Modal isOpen={spawnInfo.show} toggle={() => setSpawnInfo({...spawnInfo, show: false})} size="md" contentClassName={"card border border-danger rounded"} backdrop={true}>
        <Box sx={{minHeight: "100%", padding: "24px", display: "flex", flexDirection: "column" }}>
          <Box>
            <LanguageDependentText huntName={fateState.huntName} fontSize={24} />
            <LanguageDependentText text={fateState.worldName} fontSize={15} />
          </Box>

          <Row>
            <Col sm={12} style={{paddingTop: 15, paddingBottom: 15}}>
              <LanguageDependentText header={"tableLastKnownState"} fontSize={20}/>
              <Box style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <LanguageDependentText date={new Date(fateState.lastDeathTime)} fontSize={15}>
                  
                </LanguageDependentText>
                <Button variant="contained" color="error" onClick={() => showRevertWarning(fateState.huntName, fateState.worldName)}>
                  {SessionConfigs.translateText("header", "historyRevert")}
                </Button>
              </Box>
            </Col>
            <Col sm={12} style={{paddingTop: 15, paddingBottom: 15}}>
              <LanguageDependentText header={"tableHuntDescription"} fontSize={20}/>  
              <LanguageDependentText text={SessionConfigs.translateText("spawn-condition", baseKey)} fontWeight={"500"} fontSize={15} />
            </Col>
          </Row>
          <Row>
            <Col sm={6} style={{paddingTop: 15, paddingBottom: 15}}>
              <Row>
                <Col sm={12}>
                  <LanguageDependentText header={"tableHuntRespawnTime"} fontSize={20}/>
                  <LanguageDependentText header={"tableHuntRespawnTimePostMaint"} fontSize={16}/>
                  <LanguageDependentText color={"#03a9f4 !important"} text={spawnInfo["MaintTimer"] ? spawnInfo["MaintTimer"][0].toFixed(1) + " - " + (spawnInfo["MaintTimer"][0]+spawnInfo["MaintTimer"][1]).toFixed(1) + " " + SessionConfigs.translateText("header", "timeHours") : null} />
                  <LanguageDependentText header={"tableHuntRespawnTimePostKill"} fontSize={16}/>
                  <LanguageDependentText color={"#03a9f4 !important"} text={spawnInfo["RespawnTimer"] ? spawnInfo["RespawnTimer"][0].toFixed(1) + " - " + (spawnInfo["RespawnTimer"][0]+spawnInfo["RespawnTimer"][1]).toFixed(1) + " " + SessionConfigs.translateText("header", "timeHours") : null} />
                </Col>
              </Row>
            </Col>
            <Col sm={6} style={{paddingTop: 15, paddingBottom: 15}}>
              <img
                src={"/static/images/HuntIcons/" + baseKey + ".png"}
                className="img-fluid"
              />
            </Col>
          </Row>
          <Button variant="contained" color="info" style={{marginTop: 10}} onClick={() => setSpawnInfo({...spawnInfo, show: false})}>
            {SessionConfigs.translateText("header", "tableConfirm")}
          </Button>
        </Box>
      </Modal>  

      {alert}
    </TableRow>,
    chainFATEs ? chainFATEs.map((fate) => {
      return <TableRow id={fate.worldName + "_" + fate.fateName + "_Row"} key={fate.fateName} className={"chain-fate"} style={{display: (showChainFATEs ? "" : "none")}}>
        <TableCell style={{paddingTop: "3px", paddingBottom: "3px", borderBottom: "1px solid #88888855"}}>
          <Collapse isOpen={showChainFATEs}>
            <Box paddingBottom={0} sx={{display: "flex", flexDirection: "column", justifyContent: "flex-end"}}>
              <LanguageDependentText huntName={fate.fateName} variant="body" align={"right"} fontSize={15}/>
            </Box>
          </Collapse>
        </TableCell>
  
        <TableCell style={{paddingTop: "1px", paddingBottom: "1px", borderBottom: "1px solid #88888855"}}>
          <Collapse isOpen={showChainFATEs}>
            <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
              <FATEMapModal fateName={fate.fateName} worldName={fate.worldName} region={fate.Region} position={fate.reportTime > fate.deathTime ? fate.fateId : null}/>
              <Box sx={{display: "flex", flexDirection: "column"}}>
                <LanguageDependentText regionName={fate.Region} variant="body" fontSize={12}/>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
  
        <TableCell style={{paddingTop: "1px", paddingBottom: "1px", borderBottom: "1px solid #88888855"}} align="center">
          <Collapse isOpen={showChainFATEs}>
            {fateState.toVerify ?  <VerificationButtons /> :
              <Hover onHover={<IconButton variant="outlined" color="error" onClick={() => reportDeath(fate)} style={{height: 10}}> <i className="fa-solid fa-skull"></i> </IconButton>}>
                <CountUpTimer rowID={fateState.worldName + "_" + fateState.huntName + "_Row"} lastSeen={fate.reportTime} lastDeath={fate.deathTime} state={fate.completed} interval={1000}/>
              </Hover>
            }
          </Collapse>
        </TableCell>
      </TableRow>
    }) : null
  ] : null
  );
};

export default FATEMainRow;