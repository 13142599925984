import React from "react";

import {
  Badge,
  Modal,
  Input,
  Row,
  Col,
} from "reactstrap";

import ReactDatetime from "react-datetime"
import SweetAlert from "react-bootstrap-sweetalert";

import {
  Box,
  Button,
  ButtonGroup,
  Typography,
  TableRow,
  TableCell,
  IconButton,
  Avatar,
  Tooltip
} from "@mui/material"

import { toast } from 'react-toastify';

import Flatpickr from "react-flatpickr";

import LanguageDependentText from "./LanguageDependentText";
import { CountDownTimer, ConditionTimer } from "./CountdownTimer";
import { dataCenters } from "sessions/resources/datacenters";
import { getDataCenterName, extractHuntInfo } from "sessions/gameInfo";
import { HuntTimers } from "sessions/databaseStorage"
import { SessionConfigs } from "sessions/sessionState"
import SpawnMapModal from "./SpawnMapModal";

function constructHoverText(startTime) {
  switch (SessionConfigs.getLanguage()) {
    case "en": {
      const dateOptions = { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
      var hoverText = new Date(startTime).toLocaleDateString("en", dateOptions);

      return (
        <span style={{fontSize: 14}}>
          {hoverText}
        </span> 
      );
    }
    case "de": {
      const dateOptions = { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
      var hoverText = new Date(startTime).toLocaleDateString("de", dateOptions);

      return (
        <span style={{fontSize: 14}}>
          {hoverText}
        </span> 
      );
    }
    case "cn": {
      const dateOptions = { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
      var hoverText = new Date(startTime).toLocaleDateString("zh", dateOptions);

      return (
        <span style={{fontSize: 14}}>
          {hoverText}
        </span> 
      );
    }
    case "jp": {
      const dateOptions = { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
      var hoverText = new Date(startTime).toLocaleDateString("ja", dateOptions);

      return (
        <span style={{fontSize: 14}}>
          {hoverText}
        </span> 
      );
    }
    default: {
      const dateOptions = { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
      var hoverText = new Date(startTime).toLocaleDateString("en", dateOptions);

      return (
        <span style={{fontSize: 14}}>
          {hoverText}
        </span> 
      );
    }
  }
}


const TimerRow = (props) => {
  const [huntState, setHuntState] = React.useState({...props.timer});
  const [modalShow, setModalShow] = React.useState({show: false, date: new Date(), time: new Date()});
  const [spawnInfo, setSpawnInfo] = React.useState({show: false, lastAttempt: null, attemptCount: 0, fates: null});

  const [hideDead, setHideDead] = React.useState(props.timer.hideDead);
  const [showSSMap, setShowSSMap] = React.useState(false);
  const [alert, setAlert] = React.useState(null);

  const conditionTimer = ["Laideronnette","Mindflayer","Thousand-cast Theda","Zona Seeker","Croque-Mitaine","Croakadile","The Garlok","Bonnacon","Gandarewa","Okina","Gamma","Burfurlur the Canny"].includes(huntState.huntKey);

  const datacenter = getDataCenterName(huntState.worldName);
  const allWorlds = dataCenters[datacenter]["Names"]
  const badgeColorID = allWorlds.indexOf(huntState.worldName);
  const huntInfo = SessionConfigs.getHuntResources(huntState.huntKey);

  const moveRowToTop = (id) => {
    const row = document.getElementById(id);
    row.parentElement.insertBefore(row, row.parentElement.firstChild);
  };

  React.useEffect(() => {
    const currentTime = new Date().getTime();
    if (currentTime > huntState.expectMinTime && hideDead) {
      setHideDead(false);
    }
    
    setHuntState({...props.timer})
    if (props.timer.lastSeen) {
      moveRowToTop(huntState.worldName + "_" + huntState.huntName + "_Row");
    }

    document.addEventListener(huntState.worldName + "_" + huntState.huntName, (event) => {
      const message = event.detail;

      if (message["Notification"] == "FoundReport") {
        props.timer["hp"] = message.Progress;

        if (props.timer["alerted"]) {
          setHuntState({...props.timer});
          return;
        } else {
          props.timer["lastSeen"] = message.Time*1000;
          props.timer["position"] = message.Position;
          props.timer["displayState"] = HuntTimers.getDisplay(huntState.Rank, huntState.worldName, huntState.Patch);
          props.timer["alerted"] = true;
          setHuntState({...props.timer});
        }
        moveRowToTop(huntState.worldName + "_" + huntState.huntName + "_Row");

        const notification = SessionConfigs.getSession().notificationFilter;
        const toAlert = true;
        if (notification.worlds.includes(huntState.worldName)) {
          toAlert = false;
        }
        if (notification.patches.includes(huntState.Patch)) {
          toAlert = false;
        }
        for (let i in notification.excluding) {
          if (huntState.huntName.startswith(notification.excluding[i])) {
            toAlert = false;
          }
        }
        
        if (toAlert) {
          if (SessionConfigs.getSession().audioPermission > 0) {
            props.audioRef.current.play();
          }

          if (!document.hidden) {
            toast.info(<>
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <Avatar src={"/static/images/HuntIcons/" + huntState.huntKey + ".png"} sx={{ width: 30, height: 30 }}/>
                  <Box sx={{marginLeft: 3, display: "flex", flexDirection: "column", justifyContent: "left"}}>
                    <LanguageDependentText huntName={huntState.huntName} variant="p" color="#FFFFFF !important" fontSize={15}> @ {huntState.worldName} </LanguageDependentText>
                    <LanguageDependentText date={message.lastSeen} variant="h6" color="#FFFFFF !important" fontSize={11}> </LanguageDependentText>
                  </Box>
                </Box>
              </>, {
              icon: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored"
            });
          }
        }
      } else if (message["Notification"] == "DeathReport") {
        props.timer["toVerify"] = false;
        props.timer["hp"] = 0;
        props.timer["missing"] = message["MissingCounter"];
        props.timer["lastSeen"] = null;
        props.timer["position"] = null;
        
        props.timer["expectMinTime"] = (message["Time"]+(message["MaintState"] ? (huntInfo.MaintTimer[0]*3600) : (huntInfo.RespawnTimer[0]*3600)))*1000;
        props.timer["expectMaxTime"] = (message["Time"]+(message["MaintState"] ? ((huntInfo.MaintTimer[0]+huntInfo.MaintTimer[1])*3600) : ((huntInfo.RespawnTimer[0]+huntInfo.RespawnTimer[1])*3600)))*1000;
        props.timer["lastDeathTime"] = message["Time"]*1000;
        props.timer["displayState"] = HuntTimers.getDisplay(huntState.Rank, huntState.worldName, huntState.Patch);
        setHuntState({...props.timer});
      }
    })

    /*
    socket.on("", (message) => {
      if (message.hasOwnProperty("lastSeen")) {
        moveRowToTop(huntState.worldName + "_" + huntState.huntName + "_Row");
        props.timer["lastSeen"] = message.lastSeen;
        props.timer["position"] = message.position;
        props.timer["hp"] = 1;
        props.timer["displayState"] = HuntTimers.getDisplay(huntState.Rank, huntState.worldName, huntState.Patch);
        setHuntState({...props.timer});

        const notification = SessionConfigs.getConfigs().browserNotification.customized;
        const toAlert = true;
        if (Object.keys(notification).includes(huntState.worldName)) {
          if (notification[huntState.worldName].includes(huntState.Patch)) {
            toAlert = false;
          }
        }

        if (toAlert) {
          if (SessionConfigs.getAudioPermission()) {
            props.audioRef.current.play();
          }

          if (!document.hidden) {
            toast.info(<>
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <Avatar src={"/static/images/HuntIcons/" + huntState.huntKey + ".png"} sx={{ width: 30, height: 30 }}/>
                  <Box sx={{marginLeft: 3, display: "flex", flexDirection: "column", justifyContent: "left"}}>
                    <LanguageDependentText huntName={huntState.huntName} variant="p" color="#FFFFFF !important" fontSize={15}> @ {huntState.worldName} </LanguageDependentText>
                    <LanguageDependentText date={message.lastSeen} variant="h6" color="#FFFFFF !important" fontSize={11}> </LanguageDependentText>
                  </Box>
                </Box>
              </>, {
              icon: false,
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored"
            });
          }
        }
      } else if (message.hasOwnProperty("hp")) {
        props.timer["hp"] = message.hp;
        setHuntState({...props.timer});

      } else {
        if (message["missing_counter"]) {
          props.timer["toVerify"] = false;
          props.timer["hp"] = 0;
          props.timer["missing"] = message["missing_counter"];
          props.timer["lastSeen"] = null;
          props.timer["position"] = null;
          props.timer["expectMinTime"] = message["expectMinTime"];
          props.timer["expectMaxTime"] = message["expectMaxTime"];
          props.timer["lastDeathTime"] = message["lastDeathTime"];
          props.timer["displayState"] = HuntTimers.getDisplay(huntState.Rank, huntState.worldName, huntState.Patch);
          setHuntState({...props.timer});

        } else {
          props.timer["toVerify"] = message["expectMinTime"] > props.timer["expectMinTime"];
          props.timer["missing"] = message["missing_counter"];
          props.timer["lastSeen"] = null;
          props.timer["hp"] = 0;
          props.timer["position"] = null;
          props.timer["expectMinTime"] = message["expectMinTime"];
          props.timer["expectMaxTime"] = message["expectMaxTime"];
          props.timer["lastDeathTime"] = message["lastDeathTime"];
          props.timer["displayState"] = HuntTimers.getDisplay(huntState.Rank, huntState.worldName, huntState.Patch);
          setHuntState({...props.timer});
        }
      }
    })

    if (huntState.Rank == 3 && (huntState.Patch == "ShB" || huntState.Patch == "EW" || huntState.Patch == "DT")) {
      socket.on(huntState.worldName + "_SS_" + huntState.Region + huntState.instance, (message) => {
        if (message.hasOwnProperty("lastSeen")) {
          moveRowToTop(huntState.worldName + "_" + huntState.huntName + "_Row");
          props.timer["lastSeen"] = message.lastSeen;
          props.timer["displayState"] = HuntTimers.getDisplay(huntState.Rank, huntState.worldName, huntState.Patch);
          setHuntState({...props.timer});
          setShowSSMap(true);

          const notification = SessionConfigs.getConfigs().browserNotification.customized;
          const toAlert = true;
          if (Object.keys(notification).includes(huntState.worldName)) {
            if (notification[huntState.worldName].includes(huntState.Patch)) {
              toAlert = false;
            }
          }

          if (toAlert) {
            if (SessionConfigs.getAudioPermission()) {
              props.audioRef.current.play();
            }

            if (!document.hidden) {
              toast.info(<>
                  <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Avatar src={"/static/images/HuntIcons/" + huntState.huntKey + ".png"} sx={{ width: 30, height: 30 }}/>
                    <Box sx={{marginLeft: 3, display: "flex", flexDirection: "column", justifyContent: "left"}}>
                      <LanguageDependentText header={"tableMinions"} variant="p" color="#FFFFFF !important" fontSize={15}> @ {huntState.worldName} </LanguageDependentText>
                      <LanguageDependentText date={message.lastSeen} variant="h6" color="#FFFFFF !important" fontSize={11}> </LanguageDependentText>
                    </Box>
                  </Box>
                </>, {
                icon: false,
                position: "top-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored"
              });
            }
          }
        } else {
          props.timer["lastSeen"] = null;
          props.timer["displayState"] = HuntTimers.getDisplay(huntState.Rank, huntState.worldName, huntState.Patch);
          setHuntState({...props.timer});
          setShowSSMap(false);
        }
      })
    }
        */
    return () => {
      
    }
  }, [])

  const handleVerificationCheck = (state, ignore) => {
    if (!ignore) {
      SessionConfigs.queryData("/api/deathVerification", {
        "HuntName": huntState.huntName,
        "WorldName": huntState.worldName,
        "DeathTime": huntState.lastDeathTime,
        "State": state,
      });
    }
    props.timer["toVerify"] = false;
    if (props.timer.hideDead) {
      setHideDead(true);
    }
    setHuntState({...props.timer});
  };

  const VerificationButtons = () => {
    setTimeout(() => {
      handleVerificationCheck(true, true);
    }, 300000);

    return (
      <ButtonGroup style={{height: 25}}>
        <IconButton style={{width: 25}} onClick={()=>handleVerificationCheck(true, false)} key="success" aria-label="delete">
          <i className="fa-solid fa-check btn-confirm" style={{fontSize: 18}}></i>
        </IconButton>
        <IconButton style={{width: 25}} onClick={()=>handleVerificationCheck(false, false)} key="danger" aria-label="delete">
          <i className="fa-solid fa-xmark btn-deny" style={{fontSize: 18}}></i>
        </IconButton>
        <IconButton style={{width: 25}} onClick={()=>handleVerificationCheck(true, true)} key="unknown" aria-label="delete">
          <i className="fa-solid fa-question btn-unknown" style={{fontSize: 18}}></i>
        </IconButton>
      </ButtonGroup>
    );
  };

  const Hover = ({ onHover, children }) => (
    <div className="hover">
      <div className="hover__no-hover">{children}</div>
      <div className="hover__hover">{onHover}</div>
    </div>
  );

  const reportDeath = () => {
    setModalShow({show: true, date: new Date(), time: new Date()});
  };

  const handleReportSubmission = (huntName, worldName, missing) => {
    const localeString = modalShow.date.toLocaleDateString("en", {
      year: 'numeric', 
      month: 'numeric', 
      day: 'numeric'
    }) + " " + modalShow.time.toLocaleTimeString("en", {
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric',
      hourCycle: 'h23',
    });

    const timestamp = new Date(localeString).getTime() / 1000;

    SessionConfigs.query("/api/deathReport", {
      "HuntName": huntName,
      "WorldName": worldName,
      "ReportTime":new Date(localeString).getTime() / 1000,
      "Missing": missing ? 1 : 0
    }).then((response) => {
      setModalShow({...modalShow, show: false});
      
      props.timer["toVerify"] = false;
      props.timer["hp"] = 0;
      props.timer["missing"] = missing;
      props.timer["lastSeen"] = null;
      props.timer["position"] = null;
      
      props.timer["expectMinTime"] = (timestamp+(huntInfo.RespawnTimer[0]*3600))*1000;
      props.timer["expectMaxTime"] = (timestamp+(huntInfo.RespawnTimer[0]+huntInfo.RespawnTimer[1])*3600)*1000;
      props.timer["lastDeathTime"] = timestamp*1000;
      props.timer["displayState"] = HuntTimers.getDisplay(huntState.Rank, huntState.worldName, huntState.Patch);
      setHuntState({...props.timer});

    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
      console.log(error);
    });
  };

  const requestHuntDescription = (huntName, worldName) => {
    SessionConfigs.query("/api/huntInfo", {
      "HuntName": huntName,
      "WorldName": worldName,
    }).then((response) => {
      if (response.data.count) {
        setSpawnInfo({...spawnInfo, 
          show: true, 
          historyId: response.data.historyId,
          reporter: response.data.reporter,
          lastAttempt: response.data.time, 
          attemptCount: response.data.count,
        });
      } else {
        setSpawnInfo({...spawnInfo, 
          show: true, 
          historyId: response.data.historyId,
          reporter: response.data.reporter,
          attemptCount: 0,
        });
      }
    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
    });
  };

  const submitSpawnAttempt = (huntName, worldName) => {
    SessionConfigs.query("/api/querySpawnAttempts", {
      QueryMethod: "SetAttempt",
      HuntName: huntName,
      WorldName: worldName
    }).then((response) => {
      setSpawnInfo({...spawnInfo, lastAttempt: new Date().getTime(), attemptCount: spawnInfo.attemptCount + 1});
    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
    });
  };

  const handleReportDateChange = (event) => {
    if (event._isAMomentObject) {
      setModalShow({ ...modalShow, date: event.toDate()});
    }
  };

  const handleReportTimeChange = (event) => {
    if (event._isAMomentObject) {
      setModalShow({ ...modalShow, time: event.toDate()});
    }
  };

  const handleDeadOpen = () => {
    if (HuntTimers.getDisplay(huntState.Rank, huntState.worldName, huntState.Patch)) {
      document.getElementById(huntState.worldName + "_" + huntState.huntName + "_Row").classList.remove("d-none");
    }
  };

  const showRevertWarning = (huntName, worldName, deathTime) => {
    setAlert(
      <SweetAlert
          warning style={{display: "block",marginTop: "-100px"}}
          title={SessionConfigs.translateText("header", "historyRevertRequest")} onConfirm={() => handleSubmitRevertRequest(huntName, worldName, deathTime)}
          onCancel={() => setAlert(null)} confirmBtnBsStyle="danger" showCancel cancelBtnBsStyle="default">
      </SweetAlert>
    );
  };

  const handleSubmitRevertRequest = (huntName, worldName, deathTime) => {
    setAlert(null);
    
    SessionConfigs.query("/api/revertDeathReport", {
      HuntName: huntName, WorldName: worldName, ID: deathTime
    }).then((response) => {
      <SweetAlert
        success style={{display: "block",marginTop: "-100px"}}
        title={SessionConfigs.translateText("header", "historyRevertRequestSuccess")} onConfirm={() => {}}
        onCancel={() => setAlert(null)} confirmBtnBsStyle="info" >
      </SweetAlert>
    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
    });
  }

  return (
    Object.keys(huntState).length > 0 ?
    <TableRow id={huntState.worldName + "_" + huntState.huntName + "_Row"} className={(huntState.lastSeen ? "hunt-found " : " ") + (huntState.hp < 1 ? "hunt-progress " : " ") + ((huntState.displayState && !hideDead) ? " " : " d-none")}
        data-sort-name={huntState.huntName}
        data-sort-region={huntState.Region}
        data-sort-default={huntState.defaultSortOrder}
        data-sort-max-time={huntState.expectMaxTime}
        data-sort-min-time={huntState.expectMinTime} >
      <TableCell style={{paddingTop: "3px", paddingBottom: "3px", borderBottom: "1px solid #88888855"}}>
        <Box sx={{display: "flex", flexDirection: "row"}} alignItems={"center"}>
          <Tooltip title={constructHoverText(huntState.lastDeathTime*1000)} placement="top" disableFocusListener disableTouchListener disableInteractive arrow>
            <IconButton sx={{ width: 24, height: 24, marginRight: "2px"}} onClick={() => requestHuntDescription(huntState.huntName, huntState.worldName, huntState.percent)}>
              <Avatar src={(["豆豆柴","莫古力","猫小胖","陆行鸟"].includes(datacenter) ? "/static/images/HuntIcons_CN/" : "/static/images/HuntIcons/") + huntState.huntKey + ".png"} sx={{ width: 24, height: 24 }}/>
            </IconButton>
          </Tooltip>
          <Badge as="<label>" className={"world-badge " + `world-color-${badgeColorID}`}>
            {huntState.worldName.length > 5 ? huntState.worldName.slice(0,4) : huntState.worldName}
          </Badge>
          <Box paddingBottom={0} sx={{display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
            
            <LanguageDependentText huntName={huntState.huntName} variant="body" fontSize={16}/>
          </Box>
        </Box>
      </TableCell>

      <TableCell style={{paddingTop: "1px", paddingBottom: "1px", borderBottom: "1px solid #88888855"}}>
        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <SpawnMapModal huntKey={huntState.huntKey} huntName={huntState.huntName} worldName={huntState.worldName} lastDeath={huntState.lastDeathTime} position={huntState.position} showSSMap={showSSMap} setShowSSMap={setShowSSMap} />
          <Box sx={{display: "flex", flexDirection: "column"}}>
            <LanguageDependentText regionName={huntState.Region} variant="body" fontSize={14}/>
          </Box>
        </Box>
      </TableCell>

      <TableCell style={{paddingTop: "1px", paddingBottom: "1px", marginBottom: 0, borderBottom: "1px solid #88888855"}} align="center">
        {huntState.toVerify ?  <VerificationButtons  /> :
          <Hover onHover={<IconButton variant="outlined" color="error" onClick={() => reportDeath()} style={{height: 10}}>  <i className="fa-solid fa-skull"></i> </IconButton>}>
            <CountDownTimer rowID={huntState.worldName + "_" + huntState.huntName + "_Row"} missing={huntState.missing} minTime={huntState.expectMinTime} maxTime={huntState.expectMaxTime} interval={1000} isFound={huntState.lastSeen} progress={huntState.hp} handleDeadOpen={handleDeadOpen}></CountDownTimer>
          </Hover>
        }
      </TableCell>
      {huntState.Rank == 3 ?
        <TableCell style={{paddingTop: "1px", paddingBottom: "1px", borderBottom: "1px solid #88888855"}} align="center">
          {conditionTimer ? <ConditionTimer minTime={huntState.expectMinTime} name={huntState.huntKey} interval={1000}></ConditionTimer> : null}
        </TableCell>
      : null}

      <Modal isOpen={spawnInfo.show} style={{transform: "translateY(10%)"}} toggle={() => setSpawnInfo({...spawnInfo, show: false})} size="md" contentClassName={"card border border-danger rounded"} backdrop={true}>
        <Box sx={{minHeight: "100%", padding: "24px", display: "flex", flexDirection: "column" }}>
          <Box>
            <LanguageDependentText huntName={huntState.huntName} fontSize={24}> {SessionConfigs.translateText("header", "tableHuntInfo")} </LanguageDependentText>
            <LanguageDependentText text={huntState.worldName} fontSize={15} />
          </Box>

          <Row>
            <Col sm={12} style={{paddingTop: 15, paddingBottom: 15}}>
              <LanguageDependentText header={"tableLastKnownState"} fontSize={20}/>
              <Box style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <Box>
                  <LanguageDependentText date={new Date(huntState.lastDeathTime*1000)} fontSize={15}>
                    {spawnInfo.percent ? "(" + spawnInfo.percent.toFixed(2) + "%)" : null}
                  </LanguageDependentText>
                  <LanguageDependentText text={spawnInfo.reporter} fontSize={15} />
                </Box>
                <Button variant="contained" color="error" onClick={() => showRevertWarning(huntState.huntName, huntState.worldName, spawnInfo.historyId)}>
                  {SessionConfigs.translateText("header", "historyRevert")}
                </Button>
              </Box>  
            </Col>
            {huntState.Rank == 3 ? 
            <Col sm={12} style={{paddingTop: 15, paddingBottom: 15}}>
              <LanguageDependentText header={"tableHuntDescription"} fontSize={20}/>  
              <LanguageDependentText text={SessionConfigs.translateText("spawn-condition", huntState.huntKey)} fontWeight={"500"} fontSize={15} />
            </Col> : null}
          </Row>
          {spawnInfo.fates ? (
          <Box style={{maxHeight: 250, paddingBottom: 15, overflowY: "auto", overflowX: "hidden"}}>
            <Row>
              <Col sm={12} style={{paddingTop: 15, paddingBottom: 0}}>
                <LanguageDependentText header={"infoRelatedFates"} fontSize={20}/>
              </Col>
            </Row>
            {spawnInfo.fates.map((fate,id) => {
              return <Row key={id}>
                <Col xs={5}>
                  <LanguageDependentText huntName={fate.name} fontSize={12}/>
                </Col>
                <Col xs={5}>
                  <LanguageDependentText date={fate.date} fontSize={10}/>
                </Col>
                <Col xs={2}>
                  {fate.type === "Found" ? <LanguageDependentText header={"fateFound"} fontSize={12}/> : <LanguageDependentText header={"fateCompleted"} fontSize={10}/> }
                </Col>
              </Row>
            })}
          </Box>
          ) : null}
          <Row>
            <Col sm={6} style={{paddingTop: 15, paddingBottom: 15}}>
              <LanguageDependentText header={"tableHuntRespawnTime"} fontSize={20}/>
              <LanguageDependentText header={"tableHuntRespawnTimePostMaint"} fontSize={16}/>
              <LanguageDependentText color={"#03a9f4 !important"} text={huntInfo ? huntInfo["MaintTimer"][0].toFixed(1) + " - " + (huntInfo["MaintTimer"][0]+huntInfo["MaintTimer"][1]).toFixed(1) + " " + SessionConfigs.translateText("header", "timeHours") : null} />
              <LanguageDependentText header={"tableHuntRespawnTimePostKill"} fontSize={16}/>
              <LanguageDependentText color={"#03a9f4 !important"} text={huntInfo ? huntInfo["RespawnTimer"][0].toFixed(1) + " - " + (huntInfo["RespawnTimer"][0]+huntInfo["RespawnTimer"][1]).toFixed(1) + " " + SessionConfigs.translateText("header", "timeHours") : null} />
            </Col>

            {huntState.Rank == 3 ? 
            <Col sm={6} style={{paddingTop: 15}}>
              <LanguageDependentText header={"tableLastAttempts"} fontSize={20}/>  
              { spawnInfo.lastAttempt ? 
              <LanguageDependentText key={"attemptTime"} date={new Date(spawnInfo.lastAttempt)} fontSize={15} /> 
              : <LanguageDependentText key={"attemptTime"} header={"tableUnknown"} fontSize={15} />}
              <LanguageDependentText key={"attemptCount"} text={spawnInfo.attemptCount.toString()} fontSize={15}> {SessionConfigs.translateText("header", "tableAttemptSinceLastTime")} </LanguageDependentText>
            </Col>: null}
            <Col xs={12} style={{display: "flex", paddingTop: 15, paddingBottom: 15, justifyContent: "center"}}>
              <img
                src={(["豆豆柴","莫古力","猫小胖","陆行鸟"].includes(datacenter) ? "/static/images/HuntIcons_CN/" : "/static/images/HuntIcons/") + huntState.huntKey + ".png"}
                className="img-fluid"
              />
            </Col>
          </Row>
          {huntState.Rank == 3 ? 
          <Button variant="contained" color="warning" onClick={() => submitSpawnAttempt(huntState.huntName, huntState.worldName)}>
            {SessionConfigs.translateText("header", "tableSpawnAttempt")}
          </Button> : null}
          <Button variant="contained" color="info" style={{marginTop: 10}} onClick={() => setSpawnInfo({...spawnInfo, show: false})}>
            {SessionConfigs.translateText("header", "tableConfirm")}
          </Button>
        </Box>
      </Modal>  

      <Modal isOpen={modalShow.show} toggle={() => setModalShow(false)} size="md" contentClassName={"card border border-danger rounded"} backdrop={true}>
        <Box sx={{minHeight: "100%", padding: "24px", display: "flex", flexDirection: "column" }}>
          <Box>
            <LanguageDependentText huntName={huntState.huntName} fontSize={24}> {SessionConfigs.translateText("header", "tableReportDeath")} </LanguageDependentText>
            <LanguageDependentText text={huntState.worldName} fontSize={15} />
          </Box>

          <Row style={{paddingTop: 15, paddingBottom: 15}}>
            <Col>
              <Flatpickr options={{
                enableTime: false,
                defaultDate: modalShow.date
              }} className={"form-control datetime-picker"} onChange={([date]) => { setModalShow({...modalShow, date: new Date(date)}) }}/>
            </Col>
            <Col>
              <Flatpickr options={{
                enableTime: true,
                noCalendar: true,
                minuteIncrement: 1,
                allowInput: true,
                defaultDate: modalShow.time
              }} className={"form-control datetime-picker"} onChange={([date]) => { setModalShow({...modalShow, time: new Date(date)}) }}/>
            </Col>
          </Row>
          <Button style={{marginTop: 15}} variant="contained" color="error" onClick={() => handleReportSubmission(huntState.huntName, huntState.worldName)}>
            {SessionConfigs.translateText("header", "tableReportDeath")}
          </Button>
          {huntState.Rank == 3 ? 
          <Button style={{marginTop: 15}} variant="contained" color="warning" onClick={() => handleReportSubmission(huntState.huntName, huntState.worldName, true)}>
            {SessionConfigs.translateText("header", "tableReportMissing")}
          </Button> : null}
        </Box>
      </Modal>
      {alert}
    </TableRow> : null
  );
};

export default React.memo(TimerRow);