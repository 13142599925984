
import React from "react";

import { useHistory } from "react-router-dom";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal
} from "reactstrap";

import LanguageDependentText from "components/TimerTable/LanguageDependentText"
import ImageUpload from "components/Button/ImageUpload.js";
import notificationSound from "assets/ringtone.ogg";

import { dataCenters } from "sessions/resources/datacenters.js";
import { UserProfile } from "sessions/userState";
import { extractWorldInfo, extractPatchInfo } from "sessions/gameInfo";
import { useHuntTrackerContext } from "tracker-context";
import { SessionConfigs } from "sessions/sessionState";

import { 
  Button,
  TextField,
  IconButton, 
  Box, 
  Avatar,
  Slider
} from "@mui/material";
import { setContextState } from "tracker-context";

const ProfilePage = () => {
  const history = useHistory();
  const [context, dispatch] = useHuntTrackerContext();
  const { user, datacenters, audioPermission } = context;

  const [profileModal, setProfileModal] = React.useState({show: false, src: user.profile_src})
  const [characterModal, setCharacterModal] = React.useState({show: false, name: user.ingame_name, world: user.ingame_server})
  const [notification, setNotification] = React.useState(false);
  const [volume, setVolume] = React.useState(audioPermission);
  const audioRef = React.createRef();

  const worlds = SessionConfigs.getCurrentDCInfo(datacenters);
  const patches = SessionConfigs.getPatches();

  React.useEffect(() => {
    console.log(user)
    if (!user.Permissions) {
      history.push("/index", {replace: true});
      return;
    };

    var browserNotification = SessionConfigs.getSession().notificationFilter;
    setNotification(browserNotification);
  }, []);

  React.useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = audioPermission > 0 ? audioPermission : 0;
    }
  }, [notification, audioRef.current, audioPermission]);

  const handleSubmit = (event, file) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", file);
    SessionConfigs.uploadFile("/api/profileUpdate", formData).then((response) => {
      UserProfile.setUserProfileSrc(response.data.profile_src);
      setProfileModal({...profileModal, src: response.data.profile_src, show: false});
      dispatch({type: "User", value: UserProfile.getUser()});
    }).catch((error) => {
      
    })
  };

  const toggleNotification = (event, world, patch) => {
    event.currentTarget.classList.toggle("btn-info");
    const state = event.currentTarget.classList.contains("btn-info");

    if (world == "All") {
      if (notification.patches.includes(patch) && state) {
        notification.patches = notification.patches.filter((item) => item != patch);
      } else if (!notification.patches.includes(patch) && !state) {
        notification.patches.push(patch);
      }
    } else {
      if (notification.worlds.includes(world) && state) {
        notification.worlds = notification.worlds.filter((item) => item != world);
      } else if (!notification.worlds.includes(world) && !state) {
        notification.worlds.push(world);
      }
    }

    setNotification({...notification});
    setContextState(dispatch, "notificationFilter", notification);
    SessionConfigs.setSession("notificationFilter", notification);
  };

  const handleChangeName = () => {
    SessionConfigs.queryData("/api/characterUpdate", {
      name: characterModal.name,
      world: characterModal.world
    }).then((response) => {
      UserProfile.setIngameName(response.data.name);
      UserProfile.setIngameWorld(response.data.world);
      setCharacterModal({...characterModal, name: response.data.name, world: response.data.world, show: false});
      dispatch({type: "User", value: UserProfile.getUser()});
    }).catch((error) => {
      console.log(error);
    })
  };

  return (
    <>
      <div className="content">
        <Row>
          {notification ? 
          <Col md={12} lg={6}>
            <Card>
              <CardHeader>
                <Box pt={1} px={3}>
                  <LanguageDependentText header={"profileAudioVolume"} variant="h3" fontSize={24}></LanguageDependentText>
                </Box>
              </CardHeader>
              <CardBody>
                <Box pt={1} px={3}>
                  <Slider size="small" value={volume} onChange={(event, newValue) => {
                    setVolume(newValue);
                  }} onChangeCommitted={(event, newValue) => {
                    setVolume(newValue);
                    setContextState(dispatch, "audioPermission", newValue);
                    SessionConfigs.setSession("audioPermission", newValue);
                  }} max={0.5} min={0} step={.01} aria-label="volume" valueLabelDisplay="auto" />
                  <Button color={"error"} variant="contained" onClick={(event) => {
                    if (audioRef) {
                      audioRef.current.pause();
                      audioRef.current.currentTime = 0;
                      audioRef.current.play();
                    }
                  }} fullWidth sx={{
                    fontWeight: "bold"
                  }}>
                    {"Test"}
                  </Button>
                  <audio src={notificationSound} ref={audioRef}/>
                </Box>
              </CardBody>
              <CardHeader>
                <Box pt={1} px={3}>
                  <LanguageDependentText header={"profileBrowserNotification"} variant="h3" fontSize={24}></LanguageDependentText>
                </Box>
              </CardHeader>
              <CardBody>
                <Box pt={1} px={3}>
                  <Box key={"toggleAll"} pb={3}>
                    <LanguageDependentText header={"profileBrowserToggleAll"} variant="h3" fontSize={20}></LanguageDependentText>
                    {worlds.map((world)=> {
                      var toggleOFF = false;
                      if (notification.worlds.includes(world)) {
                        toggleOFF = true;
                      }

                      if (toggleOFF) {
                        return <Button key={world} variant="outlined" onClick={(event) => toggleNotification(event, world)}>
                          <LanguageDependentText header={world} variant="body2" fontSize={13}></LanguageDependentText>
                        </Button>
                      } else {
                        return <Button key={world} variant="outlined" className="btn-info" onClick={(event) => toggleNotification(event, world)}>
                          <LanguageDependentText header={world} variant="body2" fontSize={13}></LanguageDependentText>
                        </Button>
                      }
                    })}
                  </Box>
                </Box>
                <Box pt={1} px={3}>
                  <Box key={"toggleAll"} pb={3}>
                      <LanguageDependentText header={"profileBrowserToggleAll"} variant="h3" fontSize={20}></LanguageDependentText>
                      {patches.map((patch)=> {
                        var toggleOFF = false;
                        if (notification.patches.includes(patch)) {
                          toggleOFF = true;
                        }

                        if (toggleOFF) {
                          return <Button key={patch} variant="outlined" onClick={(event) => toggleNotification(event, "All", patch)}>
                            <LanguageDependentText patchName={patch} variant="body2" fontSize={13}></LanguageDependentText>
                          </Button>
                        } else {
                          return <Button key={patch} variant="outlined" className="btn-info" onClick={(event) => toggleNotification(event, "All", patch)}>
                            <LanguageDependentText patchName={patch} variant="body2" fontSize={13}></LanguageDependentText>
                          </Button>
                        }
                      })}
                  </Box>
                </Box>
              </CardBody>
              <CardFooter>
                
              </CardFooter>
            </Card>
          </Col>
          : null}
        </Row>
        
        <Modal isOpen={profileModal.show} toggle={() => setProfileModal({...profileModal, show: false})} size="sm" contentClassName={"card border border-danger rounded"} backdrop={true}>
          <Box sx={{minHeight: "100%", padding: "24px", display: "flex", flexDirection: "column" }}>
            <ImageUpload
              avatar
              defaultSrc={profileModal.src}
              addBtnColor="default"
              changeBtnColor="default"
              handleSubmit={handleSubmit}
            />
          </Box>
        </Modal>

        <Modal isOpen={characterModal.show} toggle={() => setCharacterModal({...characterModal, show: false})} size="sm" contentClassName={"card border border-danger rounded"} backdrop={true}>
          <Box sx={{minHeight: "100%", padding: "24px", display: "flex", flexDirection: "column" }}>
            <LanguageDependentText header={"accountIngameName"} />
            <Input value={characterModal.name} onChange={(event) => setCharacterModal({...characterModal, name: event.target.value})} variant="standard" style={{marginBottom: 15}} />
            <LanguageDependentText header={"accountIngameWorld"} />
            
            <Input type="select" value={characterModal.world} onChange={(event) => setCharacterModal({...characterModal, world: event.target.value})} style={{marginBottom: 15}}>
              {Object.keys(dataCenters).map((dc) => {
                if (["豆豆柴","陆行鸟","莫古力","猫小胖"].includes(dc)) {
                  const worlds = dataCenters[dc];
                  const worldSelects = worlds["Names"].map((world)=>{
                    return <option key={world} className={"world-select"} value={world}> {world} </option>
                  });
  
                  return [
                    <option key={dc} className={"world-select"} value={dc} disabled> --- {dc} --- </option>,
                    ...worldSelects
                  ]
                }
              })}
            </Input>
            <Button
              color={"info"}
              variant={"contained"}
              onClick={() => handleChangeName()}
            >
              {SessionConfigs.translateText("header", "profileChangeName")}
            </Button>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ProfilePage;
