import History from "views/History.js";
import StatisticPage from "views/Statistics";
import Timer from "views/Timer.js";
import SingleRankTimer from "views/SingleRankTimer";
import FATE from "views/FATE";

const routes = [
  {
    path: "/timer",
    name: "allRankTimer",
    icon: "fa-solid fa-clock",
    component: Timer,
    layout: "Timer"
  },
  {
    path: "/srank",
    name: "srank",
    icon: "fa-solid fa-s",
    component: SingleRankTimer,
    layout: "Timer"
  },
  {
    path: "/arank",
    name: "arank",
    icon: "fa-solid fa-a",
    component: SingleRankTimer,
    layout: "Timer"
  },
  {
    path: "/fate",
    name: "fate",
    icon: "fa-solid fa-f",
    component: FATE,
    layout: "Timer"
  },
];

export default routes;
