import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { ThemeProvider } from '@emotion/react'

import TimerLayout from "layouts/Timer/Timer.js";
import OnePageLayout from "layouts/OnePage/OnePage.js";
import AuthLayout from "layouts/Auth/Auth.js";
import AdminPageLayout from "layouts/Admin/Admin.js";

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import 'react-toastify/dist/ReactToastify.css';
import "assets/scss/black-dashboard-pro-react.scss?v=1.2.0";

import theme from "assets/theme/mui-theme.js";
import { HuntTrackerContextProvider } from "tracker-context";
import { SessionConfigs } from "sessions/sessionState";

const root = ReactDOM.createRoot(document.getElementById("root"));

function MainApp() {
  const [syncState, setSyncState] = React.useState(SessionConfigs.isSynced());
  if (!syncState) {
    SessionConfigs.syncSession().then((success) => {
      if (success) {
        setSyncState(true);
      } else {
        // Render Static Error Page
      }
    });
  }
  
  return (
    syncState ? (
      <>
      <HuntTrackerContextProvider initialStates={SessionConfigs.getSession()}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              <Route path="/index" render={(props) => <OnePageLayout {...props} />} />
              <Route path="/history" render={(props) => <TimerLayout {...props} />} />
              <Route path="/train" render={(props) => <TimerLayout {...props} />} />
              <Route path="/schedule" render={(props) => <TimerLayout {...props} />} />

              <Route path="/timer" render={(props) => <TimerLayout {...props} />} />
              <Route path="/srank" render={(props) => <TimerLayout {...props} />} />
              <Route path="/arank" render={(props) => <TimerLayout {...props} />} />
              <Route path="/fate" render={(props) => <TimerLayout {...props} />} />

              <Route path="/profile" render={(props) => <AdminPageLayout {...props} />} />
              <Route path="/admin" render={(props) => <AdminPageLayout {...props} />} />
              <Redirect from="*" to="/index" />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </HuntTrackerContextProvider>
      </>
    ) : (
      <>
      </>
    )
  );
}

root.render(
  <>
    <MainApp>
      
    </MainApp>
  </>
);
