
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  IconButton,
} from "@mui/material"

import LanguageDependentText from "components/TimerTable/LanguageDependentText"

function TrainRouteTable({ownedTrains, viewTrain, deleteTrain, changeTrainName, requestOwner}) {
  return <Table>
    <Box component="thead">
      <tr>
        <TableCell align={"left"} width={"30%"} style={{paddingBottom: "0px"}} >
          <LanguageDependentText component="p" header={"trainToolName"} fontWeight="medium"/>
        </TableCell>
        <TableCell align={"left"} width={"20%"} style={{paddingBottom: "0px"}} >
          <LanguageDependentText component="p" header={"trainToolWorld"} fontWeight="medium"/>
        </TableCell>
        <TableCell align={"left"} width={"40%"} style={{paddingBottom: "0px"}} >
          <LanguageDependentText component="p" header={"trainToolPassword"} fontWeight="medium"/>
        </TableCell>
        <TableCell align={"left"} width={"10%"} style={{paddingBottom: "0px"}} >

        </TableCell>
      </tr>
    </Box>
    <TableBody>
      {ownedTrains.map((train) => {
        return <TableRow key={train.trainId}>
          <TableCell align={"left"} style={{paddingBottom: "3px", paddingTop: "3px", borderBottom: "1px solid #88888855"}} >
            <Box display="flex" style={{alignItems: "center"}}>
              <IconButton onClick={() => changeTrainName(train.trainId)}>
                <p className="fa-solid fa-pen-to-square"></p>
              </IconButton>
              <LanguageDependentText component="p" text={train.trainName.length > 0 ? train.trainName : train.trainId} fontSize={12} fontWeight="medium"/>
            </Box>
          </TableCell>
          <TableCell align={"left"} style={{paddingBottom: "3px", paddingTop: "3px", borderBottom: "1px solid #88888855"}} >
            <LanguageDependentText component="p" text={train.worldName} fontSize={12} fontWeight="medium"/>
          </TableCell>
          <TableCell align={"left"} style={{paddingBottom: "3px", paddingTop: "3px", borderBottom: "1px solid #88888855"}} >
            {train.password ? <LanguageDependentText component="p" text={train.password} fontSize={12} fontWeight="medium"/> :
            <Button variant="contained" color="info" onClick={() => requestOwner(train)}>
              <LanguageDependentText header={"trainToolRequest"} color="#FFFFFF !important" fontSize={11}/>
            </Button>
            }
          </TableCell>
          <TableCell align={"left"} style={{paddingBottom: "3px", paddingTop: "3px", paddingTop: "0px", borderBottom: "1px solid #88888855"}} >
            <Box style={{display: "flex", flexDirection: "row", alignItems: "center", }}>
              <Button variant="contained" color="info" onClick={() => viewTrain(train)} style={{marginRight: 5}}>
                <LanguageDependentText header={"trainToolView"} color="#FFFFFF !important" fontSize={11}/>
              </Button>
              {train.password ? 
              <Button variant="contained" color="error" onClick={() => deleteTrain(train)}>
                <LanguageDependentText header={"trainToolDelete"} color="#FFFFFF !important" fontSize={11}/>
              </Button> : null}
            </Box>
          </TableCell>
        </TableRow>
      })}
    </TableBody>
  </Table>
};

export default TrainRouteTable;