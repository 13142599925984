export const textSelector = {
  title: {
    en: "Bear Tracker v2",
    de: "Bear Tracker v2",
    fr: "Bear Tracker v2",
    jp: "Bear Tracker v2",
    cn: "FFXIV狩猎时间表",
  },
  "discord-link": {
    en: "Discord Link",
    de: "Discord Link",
    fr: "Discord Link",
    jp: "Discord Link",
    cn: "来Discord联系我",
  },
  documentation: {
    en: "Documentation",
    de: "Documentation",
    fr: "Documentation",
    jp: "Documentation",
    cn: "使用说明",
  },
  returnTimer: {
    en: "Return to Timer",
    de: "Return to Timer",
    fr: "Return to Timer",
    jp: "Return to Timer",
    cn: "返回时间表页面",
  },
  useNewUI: {
    en: "Use New UI",
    de: "Use New UI",
    fr: "Use New UI",
    jp: "Use New UI",
    cn: "使用新版页面",
  },
  useOldUI: {
    en: "Use Old UI",
    de: "Use Old UI",
    fr: "Use Old UI",
    jp: "Use Old UI",
    cn: "使用旧版页面",
  },
  indexWelcomeMessage: {
    en: "Welcome to FFXIV Elite Hunt Tracker",
    de: "Willkommen auf dem Bear Tracker",
    fr: "Welcome to FFXIV Elite Hunt Tracker",
    jp: "Welcome to FFXIV Elite Hunt Tracker",
    cn: "欢迎使用FFXIV公共狩猎时间表",
  },
  indexWelcomeDescription: {
    en: "Account is only used for Elite Mark death reporting. All other capabilities can be done in Guest Mode",
    de: "Account is only used for Elite Mark death reporting. All other capabilities can be done in Guest Mode",
    fr: "Account is only used for Elite Mark death reporting. All other capabilities can be done in Guest Mode",
    jp: "Account is only used for Elite Mark death reporting. All other capabilities can be done in Guest Mode",
    cn: "登录已验证账号后可以手动申报死亡时间以及收到触发通知。其他所有功能不需要注册即能使用。",
  },
  registerWelcomeDescription: {
    en: "Account is only used for Elite Mark death reporting. All other capabilities can be done in Guest Mode",
    de: "Account is only used for Elite Mark death reporting. All other capabilities can be done in Guest Mode",
    fr: "Account is only used for Elite Mark death reporting. All other capabilities can be done in Guest Mode",
    jp: "Account is only used for Elite Mark death reporting. All other capabilities can be done in Guest Mode",
    cn: "填写游戏内角色名与服务器名字即可注册。注册后请使用石之家认证账号。",
  },
  indexWelcomeMessageSub: {
    en: "Select your Data Center to start browsing public timers",
    de: "Wähle dein Datenzentrum um die öffentlichen Timer einsehen zu können",
    fr: "Select your Data Center to start browsing public timers",
    jp: "Select your Data Center to start browsing public timers",
    cn: "请选择你所在的服务器大区",
  },
  viewDataCenter: {
    en: "View Data Center",
    de: "Datenzentrum ansehen",
    fr: "View Data Center",
    jp: "View Data Center",
    cn: "查看服务器",
  },
  northAmericaServers: {
    en: "North America Data Centers",
    de: "Nordamerikanische Datenzentren",
    fr: "North America Data Centers",
    jp: "North America Data Centers",
    cn: "北美服务器",
  },
  europeOceaniaServers: {
    en: "Europe and Oceania Data Centers",
    de: "Europäische und Ozeanische Datenzentren",
    fr: "Europe and Oceania Data Centers",
    jp: "Europe and Oceania Data Centers",
    cn: "欧洲与大洋洲服务器",
  },
  japanServers: {
    en: "Japan Data Centers",
    de: "Japanische Datenzentren",
    fr: "Japan Data Centers",
    jp: "Japan Data Centers",
    cn: "日本服务器",
  },
  chinaServers: {
    en: "China Data Centers",
    de: "Chinesische Datenzentren",
    fr: "China Data Centers",
    jp: "China Data Centers",
    cn: "中国服务器",
  },

  profile: {
    en: "Profile",
    de: "Profil",
    fr: "Profile",
    jp: "Profile",
    cn: "用户",
  },
  profileSetting: {
    en: "Profile Setting",
    de: "Profileinstellungen",
    fr: "Profile Setting",
    jp: "Profile Setting",
    cn: "用户设置",
  },
  profileSignin: {
    en: "Sign In",
    de: "Anmelden",
    fr: "Sign In",
    jp: "Sign In",
    cn: "登入",
  },
  profileSignout: {
    en: "Sign Out",
    de: "Abmelden",
    fr: "Sign Out",
    jp: "Sign Out",
    cn: "登出",
  },
  profileSignup: {
    en: "Sign Up",
    de: "Registrierung",
    fr: "Sign Up",
    jp: "Sign Up",
    cn: "注册",
  },
  profileVerification: {
    en: "Verification",
    de: "Verification",
    fr: "Verification",
    jp: "Verification",
    cn: "验证",
  },
  profileRequestVerification: {
    en: "Request Verification",
    de: "Request Verification",
    fr: "Request Verification",
    jp: "Request Verification",
    cn: "获取验证码",
  },
  profileUpdateLodestone: {
    en: "Update Lodestone Profile with the following Verification Code:",
    de: "Update Lodestone Profile with the following Verification Code:",
    fr: "Update Lodestone Profile with the following Verification Code:",
    jp: "Update Lodestone Profile with the following Verification Code:",
    cn: "请修改石之家个人简介，加入以下文字：",
  },
  profileBrowserToggleAll: {
    en: "Toggle All",
    de: "Toggle All",
    fr: "Toggle All",
    jp: "Toggle All",
    cn: "设定全服务器",
  },
  profileBrowserNotification: {
    en: "Browser Notification Settings",
    de: "Browser Notification Settings",
    fr: "Browser Notification Settings",
    jp: "Browser Notification Settings",
    cn: "播报设定",
  },
  profileBrowserNotificationExcludeHunt: {
    en: "Exclude Notification for the following Hunts",
    de: "Exclude Notification for the following Hunts",
    fr: "Exclude Notification for the following Hunts",
    jp: "Exclude Notification for the following Hunts",
    cn: "不接收以下对象的触发播报",
  },
  profileAudioVolume: {
    en: "Browser Notification Volume",
    de: "Browser Notification Volume",
    fr: "Browser Notification Volume",
    jp: "Browser Notification Volume",
    cn: "播报音量设定",
  },
  notificationView: {
    en: "View",
    de: "View",
    fr: "View",
    jp: "View",
    cn: "查看",
  },

  trainTool: {
    en: "Train Tool",
    de: "Train Tool",
    fr: "Train Tool",
    jp: "Train Tool",
    cn: "狩猎团车工具",
  },
  trainToolNew: {
    en: "New Train",
    de: "New Train",
    fr: "New Train",
    jp: "New Train",
    cn: "新建A车地图",
  },
  trainToolName: {
    en: "Train Name",
    de: "Train Name",
    fr: "Train Name",
    jp: "Train Name",
    cn: "团车名",
  },
  trainMapName: {
    en: "Map Route Name",
    de: "Map Route Name",
    fr: "Map Route Name",
    jp: "Map Route Name",
    cn: "地图名",
  },
  trainMapTime: {
    en: "Created Time",
    de: "Created Time",
    fr: "Created Time",
    jp: "Created Time",
    cn: "创建时间",
  },
  trainToolWorld: {
    en: "Train World",
    de: "Train World",
    fr: "Train World",
    jp: "Train World",
    cn: "团车服务器",
  },
  trainToolPassword: {
    en: "Train Administrative Password",
    de: "Train Administrative Password",
    fr: "Train Administrative Password",
    jp: "Train Administrative Password",
    cn: "团车管理密码",
  },
  trainToolView: {
    en: "Edit / Update",
    de: "Edit / Update",
    fr: "Edit / Update",
    jp: "Edit / Update",
    cn: "查看 / 更改",
  },
  trainToolSort: {
    en: "Set Preferred Map Orders",
    de: "Set Preferred Map Orders",
    fr: "Set Preferred Map Orders",
    jp: "Set Preferred Map Orders",
    cn: "地图排序",
  },
  trainToolSortUp: {
    en: "Move Up",
    de: "Move Up",
    fr: "Move Up",
    jp: "Move Up",
    cn: "移上",
  },
  trainToolSortDown: {
    en: "Move Down",
    de: "Move Down",
    fr: "Move Down",
    jp: "Move Down",
    cn: "移下",
  },
  trainToolDelete: {
    en: "Delete",
    de: "Delete",
    fr: "Delete",
    jp: "Delete",
    cn: "删除",
  },
  trainToolRequest: {
    en: "Request Owner",
    de: "Request Owner",
    fr: "Request Owner",
    jp: "Request Owner",
    cn: "申请管理权",
  },
  trainCopyURL: {
    en: "Copy URL",
    de: "Copy URL",
    fr: "Copy URL",
    jp: "Copy URL",
    cn: "复制地址",
  },
  trainCopyRoutes: {
    en: "Copy Routes",
    de: "Copy Routes",
    fr: "Copy Routes",
    jp: "Copy Routes",
    cn: "复制文本",
  },

  accountSignIn: {
    en: "Account Log In",
    de: "Account Log In",
    fr: "Account Log In",
    jp: "Account Log In",
    cn: "登入帐号",
  },
  accountSignUp: {
    en: "Account Registration",
    de: "Account Registrierung",
    fr: "Account Registration",
    jp: "Account Registration",
    cn: "注册帐号",
  },
  accountIngameInfo: {
    en: "Character Information",
    de: "Character Informationen",
    fr: "Character Information",
    jp: "Character Information",
    cn: "游戏角色信息",
  },
  accountIngameName: {
    en: "Ingame Name",
    de: "Ingame Name",
    fr: "Ingame Name",
    jp: "Ingame Name",
    cn: "角色名",
  },
  accountIngameWorld: {
    en: "Ingame World",
    de: "Ingame Welt",
    fr: "Ingame World",
    jp: "Ingame World",
    cn: "服务器",
  },
  accountUsername: {
    en: "Username",
    de: "Benutzername",
    fr: "Username",
    jp: "Username",
    cn: "用户名",
  },
  accountPassword: {
    en: "Password",
    de: "Passwort",
    fr: "Password",
    jp: "Password",
    cn: "密码",
  },
  accountNewPassword: {
    en: "New Password",
    de: "New Passwort",
    fr: "New Password",
    jp: "New Password",
    cn: "新密码",
  },
  accountResetPassword: {
    en: "Reset Password",
    de: "Passwort zurücksetzen",
    fr: "Reset Password",
    jp: "Reset Password",
    cn: "重置密码",
  },
  accountProcessing: {
    en: "Currently Processing",
    de: "In Bearbeitung",
    fr: "Currently Processing",
    jp: "Currently Processing",
    cn: "正在后台处理中",
  },
  accountErrors: {
    USERFOUND: {
      en: "Username Used",
      de: "Benutzername bereits verwendet",
      fr: "Username Used",
      jp: "Username Used",
      cn: "用户名已存在",
    },
    LODESTONEFAIL: {
      en: "Lodestone Verification Failure (Check Name and Character Lv above 60)",
      de: "Lodestone Verifikation fehlgeschlagen (Überprüfe Namen und ob Charakter höher als Lv 60)",
      fr: "Lodestone Verification Failure (Check Name and Character Lv above 60)",
      jp: "Lodestone Verification Failure (Check Name and Character Lv above 60)",
      cn: "Lodestone认证失败，请确认名字输入无误以及角色等级在60以上",
    },
    INCOMPLETEDATA: {
      en: "Incomplete Data",
      de: "Unvollständige Daten",
      fr: "Incomplete Data",
      jp: "Incomplete Data",
      cn: "尚未填完所有信息",
    },
    UNKNOWN: {
      en: "Unknown Error",
      de: "Unbekannter Fehler",
      fr: "Unknown Error",
      jp: "Unknown Error",
      cn: "未知错误",
    }
  },

  profileSavePic: {
    en: "Save",
    de: "Save",
    fr: "Save",
    jp: "Save",
    cn: "保存",
  },
  profileChangePic: {
    en: "Change Picture",
    de: "Change Picture",
    fr: "Change Picture",
    jp: "Change Picture",
    cn: "更改头像",
  },
  profileChangeName: {
    en: "Change Name",
    de: "Change Name",
    fr: "Change Name",
    jp: "Change Name",
    cn: "更改名字",
  },
  profileUploadPic: {
    en: "Upload Picture",
    de: "Upload Picture",
    fr: "Upload Picture",
    jp: "Upload Picture",
    cn: "上传头像",
  },

  navbarSetHuntFilter: {
    en: "Set Hunt Filter",
    de: "Set Hunt Filter",
    fr: "Set Hunt Filter",
    jp: "Set Hunt Filter",
    cn: "筛选",
  },
  navbarDarkModeSelect: {
    en: "Toggle Dark Mode",
    de: "Umschalten zu Dark Mode",
    fr: "Toggle Dark Mode",
    jp: "Toggle Dark Mode",
    cn: "切换黑暗模式",
  },
  navbarAudioPermission: {
    en: "Notification Permission",
    de: "Notification Permission",
    fr: "Notification Permission",
    jp: "Notification Permission",
    cn: "音效提醒",
  },
  navbarLanguageSelect: {
    en: "Select Language",
    de: "Sprachauswahl",
    fr: "Select Language",
    jp: "Select Language",
    cn: "选择语言",
  },
  navbarDataCenterSelect: {
    en: "Select Data Center",
    de: "Wähle Datenzentrum",
    fr: "Select Data Center",
    jp: "Select Data Center",
    cn: "选择服务器",
  },
  navbarDarkModeSelect: {
    en: "Toggle Dark Mode",
    de: "Umschalten zu Dark Mode",
    fr: "Toggle Dark Mode",
    jp: "Toggle Dark Mode",
    cn: "更改黑白模板",
  },

  index: {
    en: "Index",
    de: "Index",
    fr: "Index",
    jp: "Index",
    cn: "DC选择页面",
  },
  timer: {
    en: "S/A Rank Timer",
    de: "S/A Rang Timer",
    fr: "S/A Rank Timer",
    jp: "S/A Rank Timer",
    cn: "狩猎时间表",
  },
  allDCTimer: {
    en: "All DC Full Rank Timer",
    de: "All DC Full Rank Timer",
    fr: "All DC Full Rank Timer",
    jp: "All DC Full Rank Timer",
    cn: "全DC合并狩猎时间表",
  },
  allRankTimer: {
    en: "S/A Rank Timer",
    de: "S/A Rang Timer",
    fr: "S/A Rank Timer",
    jp: "S/A Rank Timer",
    cn: "狩猎时间表",
  },
  srank: {
    en: "S Rank Timer",
    de: "S Rang Timer",
    fr: "S Rank Timer",
    jp: "S Rank Timer",
    cn: "S 狩猎时间表",
  },
  arank: {
    en: "A Rank Timer",
    de: "A Rang Timer",
    fr: "A Rank Timer",
    jp: "A Rank Timer",
    cn: "A 狩猎时间表",
  },
  srankTable: {
    en: "S Rank Table",
    de: "S Rang Tabelle",
    fr: "S Rank Table",
    jp: "S Rank Table",
    cn: "S狩猎时间表",
  },
  arankTable: {
    en: "A Rank Table",
    de: "A Rang Tabelle",
    fr: "A Rank Table",
    jp: "A Rank Table",
    cn: "A狩猎时间表",
  },
  trainMap: {
    en: "Train Map",
    de: "Train Map",
    fr: "Train Map",
    jp: "Train Map",
    cn: "A车侦查地图",
  },
  "train schedule": {
    en: "Train Schedule",
    de: "Train Schedule",
    fr: "Train Schedule",
    jp: "Train Schedule",
    cn: "团车车站时间表",
  },
  trainSchedule: {
    en: "Train Schedule",
    de: "Train Schedule",
    fr: "Train Schedule",
    jp: "Train Schedule",
    cn: "团车车站时间表",
  },
  worldSelectTitle: {
    en: "World Selection",
    de: "Weltenauswahl",
    fr: "World Selection",
    jp: "World Selection",
    cn: "服务器选择",
  },
  worldSelectToggle: {
    en: "World Selection on Main Timer",
    de: "Weltenauswahl",
    fr: "World Selection on Main Timer",
    jp: "World Selection on Main Timer",
    cn: "在主页面显示服务器选择",
  },
  patchSelectTitle: {
    en: "Patch Selection",
    de: "Patchauswahl",
    fr: "Patch Selection",
    jp: "Patch Selection",
    cn: "版本选择",
  },
  patchSelectToggle: {
    en: "Patch Selection on Main Timer",
    de: "Patchauswahl",
    fr: "Patch Selection on Main Timer",
    jp: "Patch Selection on Main Timer",
    cn: "在主页面显示版本选择",
  },
  instanceSelectTitle: {
    en: "Instance Selection",
    de: "Instanzauswahl",
    fr: "Instance Selection",
    jp: "Instance Selection",
    cn: "线路选择",
  },

  tableARankCard: {
    en: "Show A Rank Table",
    de: "Show A Rank Table",
    fr: "Show A Rank Table",
    jp: "Show A Rank Table",
    cn: "显示A狩猎时间表",
  },
  tableSRankCard: {
    en: "Show S Rank Table",
    de: "Show S Rank Table",
    fr: "Show S Rank Table",
    jp: "Show S Rank Table",
    cn: "显示S狩猎时间表",
  },
  filterHideDead: {
    en: "Filter Hunt by Cooldown",
    de: "Filter Hunt by Cooldown",
    fr: "Filter Hunt by Cooldown",
    jp: "Filter Hunt by Cooldown",
    cn: "选择是否显示冷却中的猎物",
  },
  filterAlsoShowLongCD: {
    en: "Hide Dead also Hide Long Cooldown Hunts",
    de: "Hide Dead also Hide Long Cooldown Hunts",
    fr: "Hide Dead also Hide Long Cooldown Hunts",
    jp: "Hide Dead also Hide Long Cooldown Hunts",
    cn: "隐藏死亡猎物时同时隐藏超长CD怪",
  },
  sortCondition: {
    en: "Sort by Condition",
    de: "Sort by Condition",
    fr: "Sort by Condition",
    jp: "Sort by Condition",
    cn: "表格排列顺序",
  },
  groupBy: {
    en: "Grouping by",
    de: "Grouping by",
    fr: "Grouping by",
    jp: "Grouping by",
    cn: "分组",
  },
  noGroup: {
    en: "No Group",
    de: "No Group",
    fr: "No Group",
    jp: "No Group",
    cn: "不分组",
  },
  worldGroup: {
    en: "World",
    de: "World",
    fr: "World",
    jp: "World",
    cn: "服务器",
  },
  nameGroup: {
    en: "Hunt",
    de: "Hunt",
    fr: "Hunt",
    jp: "Hunt",
    cn: "区域",
  },
  sortBy: {
    en: "Sorting by",
    de: "Sorting by",
    fr: "Sorting by",
    jp: "Sorting by",
    cn: "排列方式",
  },
  sortUpdate: {
    en: "Update",
    de: "Update",
    fr: "Update",
    jp: "Update",
    cn: "更新",
  },
  sortAscending: {
    en: "Ascending",
    de: "Ascending",
    fr: "Ascending",
    jp: "Ascending",
    cn: "升序",
  },
  sortDescending: {
    en: "Descending",
    de: "Descending",
    fr: "Descending",
    jp: "Descending",
    cn: "降序",
  },
  defaultSort: {
    en: "Default",
    de: "Default",
    fr: "Default",
    jp: "Default",
    cn: "猎物ID",
  },
  regionSort: {
    en: "Region",
    de: "Region",
    fr: "Region",
    jp: "Region",
    cn: "区域",
  },
  nameSort: {
    en: "Name",
    de: "Name",
    fr: "Name",
    jp: "Name",
    cn: "名字",
  },
  timesSort: {
    en: "Time (s)",
    de: "Time (s)",
    fr: "Time (s)",
    jp: "Time (s)",
    cn: "可触发时间",
  },
  timepSort: {
    en: "Time (%)",
    de: "Time (%)",
    fr: "Time (%)",
    jp: "Time (%)",
    cn: "可触发时间（百分比）",
  },
  tableHideDead: {
    en: "Show Dead Hunts",
    de: "Show Dead Hunts",
    fr: "Show Dead Hunts",
    jp: "Show Dead Hunts",
    cn: "显示已死亡猎物",
  },
  tableHuntName: {
    en: "Hunt",
    de: "Hochwild",
    fr: "Hunt",
    jp: "Hunt",
    cn: "名字",
  },
  tableWorldName: {
    en: "World",
    de: "Welt",
    fr: "World",
    jp: "World",
    cn: "服务器",
  },
  tableInstanceName: {
    en: "Instance",
    de: "Instanz",
    fr: "Instance",
    jp: "Instance",
    cn: "线路",
  },
  tableRegionName: {
    en: "Region",
    de: "Region",
    fr: "Region",
    jp: "Region",
    cn: "区域",
  },
  tablePatchName: {
    en: "Patch",
    de: "Patch",
    fr: "Patch",
    jp: "Patch",
    cn: "版本",
  },
  tableTimer: {
    en: "Timer",
    de: "Timer",
    fr: "Timer",
    jp: "Timer",
    cn: "触发时间",
  },
  tableDepartTimer: {
    en: "Depart Time",
    de: "Depart Time",
    fr: "Depart Time",
    jp: "Depart Time",
    cn: "出发时间",
  },
  tableConductor: {
    en: "Conductor",
    de: "Conductor",
    fr: "Conductor",
    jp: "Conductor",
    cn: "车头",
  },
  tableConductorNote: {
    en: "Conductor Note",
    de: "Conductor Note",
    fr: "Conductor Note",
    jp: "Conductor Note",
    cn: "团车备注",
  },
  tableSecondaryTimer: {
    en: "Condition",
    de: "Zeitfenster in/für",
    fr: "Condition",
    jp: "Condition",
    cn: "条件时间",
  },
  tableClose: {
    en: "Close",
    de: "Schließen",
    fr: "Close",
    jp: "Close",
    cn: "关闭",
  },
  tableSpawnMap: {
    en: "Spawn Map",
    de: "Spawn Map",
    fr: "Spawn Map",
    jp: "Spawn Map",
    cn: "触发地图",
  },
  tableSSSpawn: {
    en: "Toggle SS Rank Map",
    de: "Zu SS Rang Karte umschalten",
    fr: "Toggle SS Rank Map",
    jp: "Toggle SS Rank Map",
    cn: "SS地图",
  },
  tableMinions: {
    en: "Minions",
    de: "Minions",
    fr: "Minions",
    jp: "Minions",
    cn: "感受到强大的气息",
  },
  tableVerified: {
    en: "Verified",
    de: "Verified",
    fr: "Verified",
    jp: "Verified",
    cn: "已验证",
  },
  tableReportDeath: {
    en: "Report Death",
    de: "Tod berichten",
    fr: "Report Death",
    jp: "Report Death",
    cn: "汇报死亡记录",
  },
  tableHuntInfo: {
    en: "Information",
    de: "Information",
    fr: "Information",
    jp: "Information",
    cn: "信息",
  },
  tableHuntRespawnTime: {
    en: "Respawn Time",
    de: "Respawn Time",
    fr: "Respawn Time",
    jp: "Respawn Time",
    cn: "再生时间",
  },
  tableHuntRespawnTimePostMaint: {
    en: "Respawn Time After Maintenance: ",
    de: "Respawn Time After Maintenance: ",
    fr: "Respawn Time After Maintenance: ",
    jp: "Respawn Time After Maintenance: ",
    cn: "维护后再生时间：",
  },
  tableHuntRespawnTimePostKill: {
    en: "Respawn Time After Last Death: ",
    de: "Respawn Time After Last Death: ",
    fr: "Respawn Time After Last Death: ",
    jp: "Respawn Time After Last Death: ",
    cn: "击杀后再生时间：",
  },
  tableHuntDescription: {
    en: "Spawn Condition",
    de: "Spawn Condition",
    fr: "Spawn Condition",
    jp: "Spawn Condition",
    cn: "触发条件",
  },
  tableLastKnownState: {
    en: "Last Report State",
    de: "Last Report State",
    fr: "Last Report State",
    jp: "Last Report State",
    cn: "最后汇报时间",
  },
  tableUnknown: {
    en: "Unknown",
    de: "Unknown",
    fr: "Unknown",
    jp: "Unknown",
    cn: "未知",
  },
  tableLastAttempts: {
    en: "Last Attempt State",
    de: "Last Attempt State",
    fr: "Last Attempt State",
    jp: "Last Attempt State",
    cn: "最后尝试时间",
  },
  tableAttemptSinceLastTime: {
    en: "time(s) in the past 5 hours",
    de: "time(s) in the past 5 hours",
    fr: "time(s) in the past 5 hours",
    jp: "time(s) in the past 5 hours",
    cn: "（过去5小时尝试总数）",
  },
  tableMissing: {
    en: "(Missing)",
    de: "(Missing)",
    fr: "(Missing)",
    jp: "(Missing)",
    cn: "（丢失）",
  },
  tableReportTime: {
    en: "Report Time",
    de: "Report Time",
    fr: "Report Time",
    jp: "Report Time",
    cn: "时间",
  },
  tableReportDate: {
    en: "Report Date",
    de: "Report Date",
    fr: "Report Date",
    jp: "Report Date",
    cn: "日期",
  },
  tableReportMissing: {
    en: "Report Missing",
    de: "Report Missing",
    fr: "Report Missing",
    jp: "Report Missing",
    cn: "汇报丢失",
  },
  tableSpawnAttempt: {
    en: "Mark Fail Attempt",
    de: "Mark Fail Attempt",
    fr: "Mark Fail Attempt",
    jp: "Mark Fail Attempt",
    cn: "提交触发失败记录",
  },
  tableConfirm: {
    en: "Confirm",
    de: "Confirm",
    fr: "Confirm",
    jp: "Confirm",
    cn: "关闭",
  },
  tableSpawnableHunt: {
    en: "Available for Spawning",
    de: "Available for Spawning",
    fr: "Available for Spawning",
    jp: "Available for Spawning",
    cn: "可触发数",
  },
  infoRelatedFates: {
    en: "Related FATEs: ",
    de: "Related FATEs: ",
    fr: "Related FATEs: ",
    jp: "Related FATEs: ",
    cn: "相关FATE：",
  },
  showRelays: {
    en: "Show Active Relays",
    de: "Show Active Relays",
    fr: "Show Active Relays",
    jp: "Show Active Relays",
    cn: "查看当前已触发S",
  },
  reportBadRelay: {
    en: "Report Bad Relay",
    de: "Report Bad Relay",
    fr: "Report Bad Relay",
    jp: "Report Bad Relay",
    cn: "报告错误信息",
  },
  reportBadRelayMessage: {
    en: "Confirm this message to inform mistakes in relay.",
    de: "Confirm this message to inform mistakes in relay.",
    fr: "Confirm this message to inform mistakes in relay.",
    jp: "Confirm this message to inform mistakes in relay.",
    cn: "点击确认将会报告错误信息",
  },

  fateTimer: {
    en: "FATE Timer",
    de: "FATE Timer",
    fr: "FATE Timer",
    jp: "FATE Timer",
    cn: "FATE时间表",
  },
  fateTable: {
    en: "FATE Table",
    de: "FATE Table",
    fr: "FATE Table",
    jp: "FATE Table",
    cn: "FATE时间表",
  },
  fateCompleted: {
    en: "Completed",
    de: "Completed",
    fr: "Completed",
    jp: "Completed",
    cn: "已完成",
  },
  sinceFateSeen: {
    en: "Since Fate Start: ",
    de: "Since Fate Start: ",
    fr: "Since Fate Start: ",
    jp: "Since Fate Start: ",
    cn: "FATE开始后：",
  },
  sinceFateDeath: {
    en: "After Fate Completed: ",
    de: "After Fate Completed: ",
    fr: "After Fate Completed: ",
    jp: "After Fate Completed: ",
    cn: "FATE完成后：",
  },
  fateUnknown: {
    en: "Unknown",
    de: "Unknown",
    fr: "Unknown",
    jp: "Unknown",
    cn: "未知",
  },
  fateFound: {
    en: "Found",
    de: "Found",
    fr: "Found",
    jp: "Found",
    cn: "已出",
  },

  history: {
    en: "History Search",
    de: "Historie durchsuchen",
    fr: "History Search",
    jp: "History Search",
    cn: "检索历史记录",
  },
  historyHuntFilter: {
    en: "Hunt Filter",
    de: "Hunt Filter",
    fr: "Hunt Filter",
    jp: "Hunt Filter",
    cn: "选择检索的猎物",
  },
  historyWorldFilter: {
    en: "World Filter",
    de: "World Filter",
    fr: "World Filter",
    jp: "World Filter",
    cn: "选择检索的服务器",
  },
  historyPatchFilter: {
    en: "Patch Filter",
    de: "Patch Filter",
    fr: "Patch Filter",
    jp: "Patch Filter",
    cn: "选择检索的版本",
  },
  historyRangeFilter: {
    en: "Select Start Time Window",
    de: "Select Start Time Window",
    fr: "Select Start Time Window",
    jp: "Select Start Time Window",
    cn: "选择时间范围",
  },
  historyRangeFilterEnd: {
    en: "Select End Time Window",
    de: "Select End Time Window",
    fr: "Select End Time Window",
    jp: "Select End Time Window",
    cn: "选择时间范围",
  },
  historyRankFilter: {
    en: "Rank Filter",
    de: "Rank Filter",
    fr: "Rank Filter",
    jp: "Rank Filter",
    cn: "选择检索的类型",
  },
  historyFilterAllRank: {
    en: "All Rank",
    de: "All Rank",
    fr: "All Rank",
    jp: "All Rank",
    cn: "全部",
  },
  historyFilterSRank: {
    en: "S Rank",
    de: "S Rank",
    fr: "S Rank",
    jp: "S Rank",
    cn: "仅S级猎物",
  },
  historyFilterARank: {
    en: "A Rank",
    de: "A Rank",
    fr: "A Rank",
    jp: "A Rank",
    cn: "仅A级猎物",
  },
  historyFilterFATE: {
    en: "FATE",
    de: "FATE",
    fr: "FATE",
    jp: "FATE",
    cn: "仅FATE",
  },
  historyTimeMin: {
    en: "Search Window (Earliest)",
    de: "Search Window (Earliest)",
    fr: "Search Window (Earliest)",
    jp: "Search Window (Earliest)",
    cn: "最早检索时间",
  },
  historyTimeMax: {
    en: "Search Window (Latest)",
    de: "Search Window (Latest)",
    fr: "Search Window (Latest)",
    jp: "Search Window (Latest)",
    cn: "最后检索时间",
  },
  historyMaint: {
    en: "Server Maintenance",
    de: "Server Maintenance",
    fr: "Server Maintenance",
    jp: "Server Maintenance",
    cn: "服务器重启",
  },
  historyRevert: {
    en: "Revert",
    de: "Revert",
    fr: "Revert",
    jp: "Revert",
    cn: "报错",
  },
  historyRevertRequest: {
    en: "Submit Request for Wrong Report?",
    de: "Submit Request for Wrong Report?",
    fr: "Submit Request for Wrong Report?",
    jp: "Submit Request for Wrong Report?",
    cn: "是否报告死亡记录错误？",
  },
  historyRevertRequestSuccess: {
    en: "Cancelled",
    de: "Cancelled",
    fr: "Cancelled",
    jp: "Cancelled",
    cn: "已撤回",
  },
  historySearch: {
    en: "Search",
    de: "durchsuchen",
    fr: "Search",
    jp: "Search",
    cn: "检索",
  },
  historyShowPlugins: {
    en: "Show Only Manual Reports",
    de: "Show Only Manual Reports",
    fr: "Show Only Manual Reports",
    jp: "Show Only Manual Reports",
    cn: "只显示手动提交",
  },
  historyReporter: {
    en: "Reporter",
    de: "Reporter",
    fr: "Reporter",
    jp: "Reporter",
    cn: "报告者",
  },
  historyDeadTime: {
    en: "Reported Time",
    de: "Reported Time",
    fr: "Reported Time",
    jp: "Reported Time",
    cn: "死亡时间",
  },
  historyNext: {
    en: "Next",
    de: "Next",
    fr: "Next",
    jp: "Next",
    cn: "下一页",
  },
  historyPrevious: {
    en: "Previous",
    de: "Previous",
    fr: "Previous",
    jp: "Previous",
    cn: "上一页",
  },

  statistic: {
    en: "Statistics",
    de: "Statistics",
    fr: "Statistics",
    jp: "Statistics",
    cn: "数据统计",
  },
  LastThreeMonth: {
    en: "Last 3 Months",
    de: "Last 3 Months",
    fr: "Last 3 Months",
    jp: "Last 3 Months",
    cn: "过去三个月",
  },
  All: {
    en: "All",
    de: "All",
    fr: "All",
    jp: "All",
    cn: "全部",
  },

  adminDashboard: {
    en: "Admin",
    de: "Admin",
    fr: "Admin",
    jp: "Admin",
    cn: "管理员",
  },
  adminUserTab: {
    en: "User Permission",
    de: "User Permission",
    fr: "User Permission",
    jp: "User Permission",
    cn: "用户权限设置",
  },
  adminTableUsername: {
    en: "Username",
    de: "Username",
    fr: "Username",
    jp: "Username",
    cn: "用户名",
  },
  adminSelectPermission: {
    en: "Choose Permission",
    de: "Choose Permission",
    fr: "Choose Permission",
    jp: "Choose Permission",
    cn: "更改权限",
  },
  adminTableFilter: {
    en: "Filter User",
    de: "Filter User",
    fr: "Filter User",
    jp: "Filter User",
    cn: "用户搜索",
  },
  adminTablePermissionFilter: {
    en: "Filter Permissions",
    de: "Filter Permissions",
    fr: "Filter Permissions",
    jp: "Filter Permissions",
    cn: "权限搜索",
  },
  adminTableIngameName: {
    en: "Ingame Name",
    de: "Ingame Name",
    fr: "Ingame Name",
    jp: "Ingame Name",
    cn: "游戏名",
  },
  adminTableWorld: {
    en: "World",
    de: "World",
    fr: "World",
    jp: "World",
    cn: "服务器",
  },
  adminTableCreatedDate: {
    en: "Created Date",
    de: "Created Date",
    fr: "Created Date",
    jp: "Created Date",
    cn: "创建日期",
  },
  adminMaintenance: {
    en: "Maintenance",
    de: "Maintenance",
    fr: "Maintenance",
    jp: "Maintenance",
    cn: "服务器重置",
  },

  maintTableFilterWorlds: {
    en: "Search World",
    de: "Search World",
    fr: "Search World",
    jp: "Search World",
    cn: "服务器搜索",
  },
  maintTableWorldName: {
    en: "Maintenance World",
    de: "Maintenance World",
    fr: "Maintenance World",
    jp: "Maintenance World",
    cn: "服务器",
  },
  maintTableDCName: {
    en: "Maintenance Data Center",
    de: "Maintenance Data Center",
    fr: "Maintenance Data Center",
    jp: "Maintenance Data Center",
    cn: "大区",
  },
  maintTableTime: {
    en: "Maintenance Time",
    de: "Maintenance Time",
    fr: "Maintenance Time",
    jp: "Maintenance Time",
    cn: "维护时间",
  },
  maintTableApply: {
    en: "Apply Time",
    de: "Apply Time",
    fr: "Apply Time",
    jp: "Apply Time",
    cn: "更新维护时间",
  },
  maintTableSync: {
    en: "Sync Time",
    de: "Sync Time",
    fr: "Sync Time",
    jp: "Sync Time",
    cn: "同步维护时间",
  },


  supportKofi: {
    en: "Support me on Ko-fi",
    de: "Support me on Ko-fi",
    fr: "Support me on Ko-fi",
    jp: "Support me on Ko-fi",
    cn: "Support me on Ko-fi",
  },
  trainStartIn: {
    en: "Train start in",
    de: "Train start in",
    fr: "Train start in",
    jp: "Train start in",
    cn: "团车开始剩余",
  },
  trainStartedBefore: {
    en: "Train started",
    de: "Train started",
    fr: "Train started",
    jp: "Train Started",
    cn: "团车开车时间已过去 ",
  },
  timeHours: {
    en: "Hours",
    de: "Hours",
    fr: "Hours",
    jp: "Hours",
    cn: "小时",
  },
  timeMinutes: {
    en: "Minutes",
    de: "Minutes",
    fr: "Minutes",
    jp: "Minutes",
    cn: "分钟",
  },
  timeLater: {
    en: "Later",
    de: "Later",
    fr: "Later",
    jp: "Later",
    cn: "后",
  },
  timeAgo: {
    en: "Ago",
    de: "Ago",
    fr: "Ago",
    jp: "Ago",
    cn: "前",
  },

  trainScheduleNew: {
    en: "New Schedule",
    de: "New Schedule",
    fr: "New Schedule",
    jp: "New Schedule",
    cn: "新建团车",
  },
  trainScheduleSubmit: {
    en: "Submit",
    de: "Submit",
    fr: "Submit",
    jp: "Submit",
    cn: "提交",
  },
  trainScheduleMap: {
    en: "Train Map Link",
    de: "Train Map Link",
    fr: "Train Map Link",
    jp: "Train Map Link",
    cn: "团车地图网址",
  },
  trainScheduleAutoMap: {
    en: "Automatic Generated",
    de: "Automatic Generated",
    fr: "Automatic Generated",
    jp: "Automatic Generated",
    cn: "自动制作",
  },
  trainScheduleTime: {
    en: "Schedule",
    de: "Schedule",
    fr: "Schedule",
    jp: "Schedule",
    cn: "时间",
  },
  trainScheduleMessage: {
    en: "Message",
    de: "Message",
    fr: "Message",
    jp: "Message",
    cn: "团车信息",
  },
  trainScheduleDelete: {
    en: "Delete",
    de: "Delete",
    fr: "Delete",
    jp: "Delete",
    cn: "删除",
  },
  trainScheduleSkip: {
    en: "Skip Schedule",
    de: "Skip Schedule",
    fr: "Skip Schedule",
    jp: "Skip Schedule",
    cn: "略过",
  },
  trainScheduleChangeDate: {
    en: "Change Schedule",
    de: "Change Schedule",
    fr: "Change Schedule",
    jp: "Change Schedule",
    cn: "更改时间",
  },
  trainScheduleProgress: {
    en: "Current Progress",
    de: "Current Progress",
    fr: "Current Progress",
    jp: "Current Progress",
    cn: "当前进度",
  },
  recurringOptions: {
    en: "Recurring Type",
    de: "Recurring Type",
    fr: "Recurring Type",
    jp: "Recurring Type",
    cn: "固定时间",
  },
  recurringOnce: {
    en: "No Repeat",
    de: "No Repeat",
    fr: "No Repeat",
    jp: "No Repeat",
    cn: "临时",
  },
  recurringDay: {
    en: "Repeat Daily",
    de: "Repeat Daily",
    fr: "Repeat Daily",
    jp: "Repeat Daily",
    cn: "每日重复",
  },
  recurringWeek: {
    en: "Repeat Weekly",
    de: "Repeat Weekly",
    fr: "Repeat Weekly",
    jp: "Repeat Weekly",
    cn: "每周重复",
  },
};

export const permissionDescription = {
  "Unverified": {
    "en": "Unverified",
    "de": "Unverified",
    "fr": "Unverified",
    "jp": "Unverified",
    "cn": "未验证"
  },
  "Default": {
    "en": "Default",
    "de": "Default",
    "fr": "Default",
    "jp": "Default",
    "cn": "一般权限"
  },
  "DefaultCN": {
    "en": "Chinese Default",
    "de": "Chinese Default",
    "fr": "Chinese Default",
    "jp": "Chinese Default",
    "cn": "国服一般权限"
  },
  "Blacklist": {
    "en": "Blacklist",
    "de": "Blacklist",
    "fr": "Blacklist",
    "jp": "Blacklist",
    "cn": "黑名单"
  },
  "TrainConductor": {
    "en": "Conductor",
    "de": "Conductor",
    "fr": "Conductor",
    "jp": "Conductor",
    "cn": "车头"
  },
  "TrainConductorCN": {
    "en": "Chinese Conductor",
    "de": "Chinese Conductor",
    "fr": "Chinese Conductor",
    "jp": "Chinese Conductor",
    "cn": "国服车头"
  },
  "AdminGlobe": {
    "en": "Admin",
    "de": "Admin",
    "fr": "Admin",
    "jp": "Admin",
    "cn": "管理权限"
  },
  "AdminCN": {
    "en": "Chinese Admin",
    "de": "Chinese Admin",
    "fr": "Chinese Admin",
    "jp": "Chinese Admin",
    "cn": "国服管理权限"
  },
  "Admin猫小胖": {
    "en": "猫小胖 Admin",
    "de": "猫小胖 Admin",
    "fr": "猫小胖 Admin",
    "jp": "猫小胖 Admin",
    "cn": "猫小胖管理权限"
  },
  "Admin莫古力": {
    "en": "莫古力 Admin",
    "de": "莫古力 Admin",
    "fr": "莫古力 Admin",
    "jp": "莫古力 Admin",
    "cn": "莫古力管理权限"
  },
  "Admin陆行鸟": {
    "en": "陆行鸟 Admin",
    "de": "陆行鸟 Admin",
    "fr": "陆行鸟 Admin",
    "jp": "陆行鸟 Admin",
    "cn": "陆行鸟管理权限"
  },
  "Admin豆豆柴": {
    "en": "豆豆柴 Admin",
    "de": "豆豆柴 Admin",
    "fr": "豆豆柴 Admin",
    "jp": "豆豆柴 Admin",
    "cn": "豆豆柴管理权限"
  }
}

export const huntNameSelector = {
  "White Joker": {
    "en": "White Joker",
    "de": "Weiß[a] Joker",
    "fr": "joker blanc",
    "jp": "ホワイトジョーカー",
    "cn": "白鬼鼠王"
  },
  "Forneus": {
    "en": "Forneus",
    "de": "Forneus",
    "fr": "Forneus",
    "jp": "ファルネウス",
    "cn": "弗内乌斯"
  },
  "Laideronnette": {
    "en": "Laideronnette",
    "de": "Laideronette",
    "fr": "Laideronnette",
    "jp": "レドロネット",
    "cn": "雷德罗巨蛇"
  },
  "Stinging Sophie": {
    "en": "Stinging Sophie",
    "de": "Stinkig[a] Sophie",
    "fr": "Sophie la dardante",
    "jp": "スティンギング・ソフィー",
    "cn": "击刺魔蜂索菲"
  },
  "Melt": {
    "en": "Melt",
    "de": "Schmelze",
    "fr": "Fondu",
    "jp": "メルティゼリー",
    "cn": "千眼凝胶"
  },
  "Wulgaru": {
    "en": "Wulgaru",
    "de": "Wulgaru",
    "fr": "Wulgaru",
    "jp": "ウルガル",
    "cn": "乌尔伽鲁"
  },
  "Monarch Ogrefly": {
    "en": "Monarch Ogrefly",
    "de": "Monarch Ogerlibelle",
    "fr": "Agrion Ogre Monarque",
    "jp": "モナーク・オーガフライ",
    "cn": "君王鬼蜻蜓"
  },
  "Ghede Ti Malice": {
    "en": "Ghede Ti Malice",
    "de": "Ghede Titus Häme",
    "fr": "Guédé Ti-Malice",
    "jp": "ゲーデ",
    "cn": "盖得"
  },
  "Mindflayer": {
    "en": "Mindflayer",
    "de": "Seelenbrenner",
    "fr": "Flagelleur Mental",
    "jp": "マインドフレア",
    "cn": "夺心魔"
  },
  "Phecda": {
    "en": "Phecda",
    "de": "Phecda",
    "fr": "Phecda",
    "jp": "フェクダ",
    "cn": "天玑巨熊"
  },
  "Girtab": {
    "en": "Girtab",
    "de": "Girtab",
    "fr": "Girtab",
    "jp": "ギルタブ",
    "cn": "尾宿蛛蝎"
  },
  "Thousand-cast Theda": {
    "en": "Thousand-cast Theda",
    "de": "Tausendzahn Theda",
    "fr": "Theda la Tripoteuse",
    "jp": "サウザンドキャスト・セダ",
    "cn": "千竿口花希达"
  },
  "Sewer Syrup": {
    "en": "Sewer Syrup",
    "de": "Abwasser-Sirup",
    "fr": "Syrop d'égout",
    "jp": "スェアーシロップ",
    "cn": "阴沟毒液"
  },
  "Alectryon": {
    "en": "Alectryon",
    "de": "Alectryon",
    "fr": "Alectryon",
    "jp": "アレクトリオン",
    "cn": "阿列刻特利昂"
  },
  "Zona Seeker": {
    "en": "Zona Seeker",
    "de": "Zona Sucher",
    "fr": "Zona Seeker",
    "jp": "ゾーナ・シーカー",
    "cn": "虚无探索者"
  },
  "Ovjang": {
    "en": "Ovjang",
    "de": "Ovjang",
    "fr": "Ovjang",
    "jp": "アヴゼン",
    "cn": "奥弗杰恩"
  },
  "Sabotender Bailarina": {
    "en": "Sabotender Bailarina",
    "de": "Sabotender Bailarina",
    "fr": "Pampa Ballerine",
    "jp": "サボテンダー・バイラリーナ",
    "cn": "花舞仙人刺"
  },
  "Brontes": {
    "en": "Brontes",
    "de": "Brontes",
    "fr": "Brontes",
    "jp": "ブロンテス",
    "cn": "布隆特斯"
  },
  "Gatling": {
    "en": "Gatling",
    "de": "Gatling",
    "fr": "Acanthor",
    "jp": "ガトリングス",
    "cn": "加特林针鼹"
  },
  "Maahes": {
    "en": "Maahes",
    "de": "Maahes",
    "fr": "Maahes",
    "jp": "マヘス",
    "cn": "玛赫斯"
  },
  "Lampalagua": {
    "en": "Lampalagua",
    "de": "Balaur",
    "fr": "Balaur",
    "jp": "バルウール",
    "cn": "巴拉乌尔"
  },
  "Albin the Ashen": {
    "en": "Albin the Ashen",
    "de": "Albin Aschfahl",
    "fr": "Albin\r\nle Blafard",
    "jp": "死灰のアルビン",
    "cn": "死灰复燃的阿尔宾"
  },
  "Zanigoh": {
    "en": "Zanig'oh",
    "de": "Zanig'oh",
    "fr": "Zanig'oh",
    "jp": "ザニゴ",
    "cn": "札尼戈"
  },
  "Nunyunuwi": {
    "en": "Nunyunuwi",
    "de": "Nunyunuwi",
    "fr": "Nunyunuwi",
    "jp": "ヌニュヌウィ",
    "cn": "努纽努维"
  },
  "Flame Sergeant Dalvag": {
    "en": "Flame Sergeant Dalvag",
    "de": "Dalvag",
    "fr": "Sergent-major Dalvag",
    "jp": "不滅のフェランド闘軍曹",
    "cn": "永恒不灭的菲兰德副耀士"
  },
  "Dalvags Final Flame": {
    "en": "Dalvag's Final Flame",
    "de": "Letzt[a] Flamme[p] Dalvags",
    "fr": "Dernière Flamme de Dalvag",
    "jp": "ファイナルフレイム",
    "cn": "菲兰德的遗火"
  },
  "Minhocao": {
    "en": "Minhocao",
    "de": "Minhocao",
    "fr": "Minhocao",
    "jp": "ミニョーカオン",
    "cn": "蚓螈巨虫"
  },
  "Skogs Fru": {
    "en": "Skogs Fru",
    "de": "Skogs Fru",
    "fr": "Skogs Fru",
    "jp": "スコッグ・フリュー",
    "cn": "花林女郎"
  },
  "Vogaal Ja": {
    "en": "Vogaal Ja",
    "de": "Vogaal Ja",
    "fr": "Vogaal Ja",
    "jp": "醜男のヴォガージャ",
    "cn": "丑男子 沃迦加"
  },
  "Croque-Mitaine": {
    "en": "Croque-mitaine",
    "de": "Croque Mitaine",
    "fr": "Croque-mitaine",
    "jp": "クロック・ミテーヌ",
    "cn": "护土精灵"
  },
  "Barbastelle": {
    "en": "Barbastelle",
    "de": "Barbastelle",
    "fr": "Barbastelle",
    "jp": "バーバステル",
    "cn": "宽耳凶蝠"
  },
  "Unktehi": {
    "en": "Unktehi",
    "de": "Unktehi",
    "fr": "Unktehi",
    "jp": "ウンクテヒ",
    "cn": "乌克提希"
  },
  "Croakadile": {
    "en": "Croakadile",
    "de": "Quakquak",
    "fr": "Croabéros",
    "jp": "ケロゲロス",
    "cn": "咕尔呱洛斯"
  },
  "Bloody Mary": {
    "en": "Bloody Mary",
    "de": "Bloody Mary",
    "fr": "Mary la Sanglante",
    "jp": "ブラッディ・マリー",
    "cn": "血腥玛丽"
  },
  "Hellsclaw": {
    "en": "Hellsclaw",
    "de": "Höllenklaue",
    "fr": "Griffe des Enfers Magitek",
    "jp": "魔導ヘルズクロー",
    "cn": "魔导地狱爪"
  },
  "The Garlok": {
    "en": "The Garlok",
    "de": "Garlok",
    "fr": "Garlok",
    "jp": "ガーロック",
    "cn": "伽洛克"
  },
  "Dark Helmet": {
    "en": "Dark Helmet",
    "de": "Dunkelhelm",
    "fr": "Casque Noir",
    "jp": "ダークヘルメット",
    "cn": "暗盔魔蟹"
  },
  "Nahn": {
    "en": "Nahn",
    "de": "Nahn",
    "fr": "Nahn",
    "jp": "ナン",
    "cn": "纳恩"
  },
  "Bonnacon": {
    "en": "Bonnacon",
    "de": "Bonnacon",
    "fr": "Bonnacon",
    "jp": "ボナコン",
    "cn": "火愤牛"
  },
  "Myradrosh": {
    "en": "Myradrosh",
    "de": "Myradrosh",
    "fr": "Myradrosh",
    "jp": "ミラドロッシュ",
    "cn": "米腊德罗斯蜂鸟"
  },
  "Marberry": {
    "en": "Marberry",
    "de": "Marberry",
    "fr": "Marberry",
    "jp": "マーベリー",
    "cn": "玛贝利"
  },
  "Nandi": {
    "en": "Nandi",
    "de": "Nandi",
    "fr": "Nandi",
    "jp": "ナンディ",
    "cn": "南迪"
  },
  "Vuokho": {
    "en": "Vuokho",
    "de": "Vuokho",
    "fr": "Vuokho",
    "jp": "ヴオコー",
    "cn": "巫刻猎鹫"
  },
  "Cornu": {
    "en": "Cornu",
    "de": "Cornu",
    "fr": "Cornu",
    "jp": "コンヌ",
    "cn": "角祖"
  },
  "Chernobog": {
    "en": "Chernobog",
    "de": "Czernobog",
    "fr": "Czernobog",
    "jp": "チェルノボーグ",
    "cn": "牛头黑神"
  },
  "Naul": {
    "en": "Naul",
    "de": "Naul",
    "fr": "Naul",
    "jp": "ナウル",
    "cn": "纳乌尔"
  },
  "Marraco": {
    "en": "Marraco",
    "de": "Marraco",
    "fr": "Marraco",
    "jp": "マラク",
    "cn": "马拉克"
  },
  "Safat": {
    "en": "Safat",
    "de": "Safat",
    "fr": "Safat",
    "jp": "サファト",
    "cn": "萨法特"
  },
  "Leech King": {
    "en": "Leech King",
    "de": "Egelkönig",
    "fr": "Roi des Sangsues",
    "jp": "リーチキング",
    "cn": "水蛭王"
  },
  "Kurrea": {
    "en": "Kurrea",
    "de": "Kurrea",
    "fr": "Kurrea",
    "jp": "クーレア",
    "cn": "库雷亚"
  },
  "Agrippa the Mighty": {
    "en": "Agrippa the Mighty",
    "de": "Agrippa",
    "fr": "Agrippa",
    "jp": "アグリッパ",
    "cn": "阿格里帕"
  },
  "Alteci": {
    "en": "Alteci",
    "de": "Artic",
    "fr": "Altek",
    "jp": "アルティック",
    "cn": "阿尔提克"
  },
  "Kreutzet": {
    "en": "Kreutzet",
    "de": "Kreutzet",
    "fr": "Kreutzet",
    "jp": "クルーゼ",
    "cn": "克鲁泽"
  },
  "Mirka": {
    "en": "Mirka",
    "de": "Mirka",
    "fr": "Mirka",
    "jp": "ミルカ",
    "cn": "米勒卡"
  },
  "Lyuba": {
    "en": "Lyuba",
    "de": "Lyuba",
    "fr": "Lyuba",
    "jp": "リューバ",
    "cn": "卢芭"
  },
  "Kaiser Behemoth": {
    "en": "Kaiser Behemoth",
    "de": "Kaiser-Behemoth",
    "fr": "Béhémoth Empereur",
    "jp": "カイザーベヒーモス",
    "cn": "凯撒贝希摩斯"
  },
  "Gnath cometdrone": {
    "en": "Gnath Cometdrone",
    "de": "Gnath-Kometdrohne",
    "fr": "Gnathe Myrmicomète",
    "jp": "グナース・コメットドローン",
    "cn": "骨颌彗星兵"
  },
  "Thextera": {
    "en": "Thextera",
    "de": "Dexter",
    "fr": "Texta",
    "jp": "テクスタ",
    "cn": "提克斯塔"
  },
  "Pylraster": {
    "en": "Pylraster",
    "de": "Xhauron",
    "fr": "Pirlasta",
    "jp": "パイルラスタ",
    "cn": "派拉斯特暴龙"
  },
  "Lord of the Wyverns": {
    "en": "Lord of the Wyverns",
    "de": "Wyvern-Lord",
    "fr": "Seigneur des Wyvernes",
    "jp": "ワイバーンロード",
    "cn": "双足飞龙之王"
  },
  "Senmurv": {
    "en": "Senmurv",
    "de": "Simurgh",
    "fr": "Sênmurw",
    "jp": "セーンムルウ",
    "cn": "神穆尔鸟"
  },
  "Scitalis": {
    "en": "Scitalis",
    "de": "Scitalis",
    "fr": "Scitalis",
    "jp": "スキタリス",
    "cn": "斯奇塔利斯"
  },
  "The Scarecrow": {
    "en": "The Scarecrow",
    "de": "Tyranno",
    "fr": "Dracosaure Primus",
    "jp": "スケアクロウ",
    "cn": "惊慌稻草龙"
  },
  "Bune": {
    "en": "Bune",
    "de": "Bune",
    "fr": "Bune",
    "jp": "ブネ",
    "cn": "布涅"
  },
  "Agathos": {
    "en": "Agathos",
    "de": "Agathos",
    "fr": "Agathos",
    "jp": "アガトス",
    "cn": "阿伽托斯"
  },
  "Gandarewa": {
    "en": "Gandarewa",
    "de": "Gandalva",
    "fr": "Gandharva",
    "jp": "ガンダルヴァ",
    "cn": "刚德瑞瓦"
  },
  "Pterygotus": {
    "en": "Pterygotus",
    "de": "Pterygotus",
    "fr": "Pterygotus",
    "jp": "プテリゴトゥス",
    "cn": "翼肢鲎"
  },
  "False Gigantopithecus": {
    "en": "False Gigantopithecus",
    "de": "Gigantopithecus",
    "fr": "Gigantopithèque",
    "jp": "ブラクキ",
    "cn": "布拉巨猿"
  },
  "Slipkinx Steeljoints": {
    "en": "Slipkinx Steeljoints",
    "de": "Rutschfix Stahlscharnier",
    "fr": "Slipkinx Joints-d'acier",
    "jp": "機兵のスリップキンクス",
    "cn": "机工兵 斯利普金克斯"
  },
  "Stolas": {
    "en": "Stolas",
    "de": "Stolas",
    "fr": "Stolas",
    "jp": "ストラス",
    "cn": "斯特拉斯"
  },
  "The Pale Rider": {
    "en": "The Pale Rider",
    "de": "Bleich[a] Reiter",
    "fr": "Cavalier Pâle",
    "jp": "ペイルライダー",
    "cn": "苍白骑士"
  },
  "Squonk": {
    "en": "Squonk",
    "de": "Squonk",
    "fr": "Squonk",
    "jp": "スクオンク",
    "cn": "斯奎克"
  },
  "Sanu Vali of Dancing Wings": {
    "en": "Sanu Vali of Dancing Wings",
    "de": "Sanu Vali [t] Tanzend[a] Schwinge",
    "fr": "Sanu Vali",
    "jp": "舞手のサヌバリ",
    "cn": "飞舞翼 萨努瓦力"
  },
  "Enkelados": {
    "en": "Enkelados",
    "de": "Engedoras",
    "fr": "Enkélados",
    "jp": "エンケドラス",
    "cn": "恩克拉多斯"
  },
  "Sisiutl": {
    "en": "Sisiutl",
    "de": "Sisiutl",
    "fr": "Sisiutl",
    "jp": "シシウトゥル",
    "cn": "西斯尤"
  },
  "Bird of Paradise": {
    "en": "Bird of Paradise",
    "de": "Paradiesvogel",
    "fr": "Oiseau de Paradis",
    "jp": "極楽鳥",
    "cn": "极乐鸟"
  },
  "Lycidas": {
    "en": "Lycidas",
    "de": "Lykidas",
    "fr": "Lycidas",
    "jp": "リュキダス",
    "cn": "利西达斯"
  },
  "Omni": {
    "en": "Omni",
    "de": "Omni",
    "fr": "Omni",
    "jp": "オムニ",
    "cn": "全能机甲"
  },
  "Campacti": {
    "en": "Campacti",
    "de": "Campacti",
    "fr": "Campacti",
    "jp": "キャムパクティ",
    "cn": "坎帕提"
  },
  "Stench Blossom": {
    "en": "Stench Blossom",
    "de": "Pestwurz",
    "fr": "Fleur Nauséabonde",
    "jp": "センチブロッサム",
    "cn": "恶臭狂花"
  },
  "Leucrotta": {
    "en": "Leucrotta",
    "de": "Leucrotta",
    "fr": "leucrotta",
    "jp": "レウクロッタ",
    "cn": "卢克洛塔"
  },
  "Shadow-Dweller Yamini": {
    "en": "Shadow-dweller Yamini",
    "de": "Schattenkauer-Yamini",
    "fr": "Yamini la Nocturne",
    "jp": "宵闇のヤミニ",
    "cn": "影中暗 雅弥尼"
  },
  "Ouzelum": {
    "en": "Ouzelum",
    "de": "Ouzelum",
    "fr": "Ouzelum",
    "jp": "オゼルム",
    "cn": "奥祖鲁姆"
  },
  "Orcus": {
    "en": "Orcus",
    "de": "Orcus",
    "fr": "Orcus",
    "jp": "オルクス",
    "cn": "奥迦斯"
  },
  "Erle": {
    "en": "Erle",
    "de": "Erle",
    "fr": "Erle",
    "jp": "アール",
    "cn": "女王蜂"
  },
  "Udumbara": {
    "en": "Udumbara",
    "de": "Udumbara",
    "fr": "Udumbara",
    "jp": "ウドンゲ",
    "cn": "优昙婆罗花"
  },
  "Gwas-y-neidr": {
    "en": "Gwas-y-neidr",
    "de": "Gwas-y-neidr",
    "fr": "Gwas-y-neidr",
    "jp": "グアス・ア・ニードル",
    "cn": "蛇仆蚂蜓"
  },
  "Buccaboo": {
    "en": "Buccaboo",
    "de": "Buccaboo",
    "fr": "Bucca-boo",
    "jp": "ブッカブー",
    "cn": "布卡卜"
  },
  "Vochstein": {
    "en": "Vochstein",
    "de": "Vochstein",
    "fr": "Vochstein",
    "jp": "バックスタイン",
    "cn": "弗克施泰因"
  },
  "Aqrabuamelu": {
    "en": "Aqrabuamelu",
    "de": "Aqrabuamelu",
    "fr": "Aqrabuamelu",
    "jp": "アクラブアメル",
    "cn": "熔骨炎蝎"
  },
  "Bone Crawler": {
    "en": "Bone Crawler",
    "de": "Knochenkriecher",
    "fr": "Mangeur d'os",
    "jp": "ボーンクローラー",
    "cn": "爬骨怪龙"
  },
  "Manes": {
    "en": "Manes",
    "de": "Manes",
    "fr": "Manes",
    "jp": "マネス",
    "cn": "玛涅斯"
  },
  "Kiwa": {
    "en": "Kiwa",
    "de": "Kiwa",
    "fr": "Kiwa",
    "jp": "キワ",
    "cn": "奇洼"
  },
  "Mahisha": {
    "en": "Mahisha",
    "de": "Mahisha",
    "fr": "Mahisha",
    "jp": "マヒシャ",
    "cn": "马希沙"
  },
  "Luminare": {
    "en": "Luminare",
    "de": "Luminare",
    "fr": "Luminare",
    "jp": "ルミナーレ",
    "cn": "泛光晶体"
  },
  "Salt and Light": {
    "en": "Salt and Light",
    "de": "Salzlicht",
    "fr": "Salaclux",
    "jp": "ソルト・アンド・ライト",
    "cn": "盐和光"
  },
  "Gauki Strongblade": {
    "en": "Gauki Strongblade",
    "de": "Gauki Starkklinge",
    "fr": "Gauki la Lame Forte",
    "jp": "剣豪ガウキ",
    "cn": "剑豪 刑具"
  },
  "Guhuo Niao": {
    "en": "Guhuo Niao",
    "de": "Guhuo Niao",
    "fr": "Guhuo Niao",
    "jp": "姑獲鳥",
    "cn": "姑获鸟"
  },
  "Funa Yurei": {
    "en": "Funa Yurei",
    "de": "Funa Yurei",
    "fr": "Funa Yurei",
    "jp": "船幽霊",
    "cn": "船幽灵"
  },
  "Oni Yumemi": {
    "en": "Oni Yumemi",
    "de": "Oni Yumemi",
    "fr": "Oni Yumemi",
    "jp": "オニユメミ",
    "cn": "鬼观梦"
  },
  "Okina": {
    "en": "Okina",
    "de": "Okina",
    "fr": "Okina",
    "jp": "オキナ",
    "cn": "巨大鳐"
  },
  "Deidar": {
    "en": "Deidar",
    "de": "Deidar",
    "fr": "Deidar",
    "jp": "デイダラ",
    "cn": "大太"
  },
  "Gyorai Quickstrike": {
    "en": "Gyorai Quickstrike",
    "de": "Gyorai Schnellschlag",
    "fr": "Gyorai le Vif",
    "jp": "雷撃のギョライ",
    "cn": "闪雷击 鱼雷"
  },
  "Gajasura": {
    "en": "Gajasura",
    "de": "Gajasura",
    "fr": "Gajasura",
    "jp": "ガジャースラ",
    "cn": "象魔修罗"
  },
  "Angada": {
    "en": "Angada",
    "de": "Angada",
    "fr": "Angada",
    "jp": "アンガダ",
    "cn": "安迦达"
  },
  "Gamma": {
    "en": "Gamma",
    "de": "Gamma",
    "fr": "Gamma",
    "jp": "ガンマ",
    "cn": "伽马"
  },
  "Kurma": {
    "en": "Kurma",
    "de": "Kurma",
    "fr": "Kurma",
    "jp": "クールマ",
    "cn": "俱利摩"
  },
  "Aswang": {
    "en": "Aswang",
    "de": "Aswang",
    "fr": "Aswang",
    "jp": "アスワング",
    "cn": "阿苏黄"
  },
  "Girimekhala": {
    "en": "Girimekhala",
    "de": "Girimekhala",
    "fr": "Girimekhala",
    "jp": "ギリメカラ",
    "cn": "基里麦卡拉"
  },
  "Sum": {
    "en": "Sum",
    "de": "Sum",
    "fr": "Sum",
    "jp": "ソム",
    "cn": "硕姆"
  },
  "Orghana": {
    "en": "Orghana",
    "de": "Orghana",
    "fr": "Orghana",
    "jp": "オルガナ",
    "cn": "兀鲁忽乃朝鲁"
  },
  "La Velue": {
    "en": "La Velue",
    "de": "La Velue",
    "fr": "Velue",
    "jp": "ラ・ヴェリュ",
    "cn": "浓毛兽"
  },
  "Itzpapalotl": {
    "en": "Itzpapalotl",
    "de": "Itzpapalotl",
    "fr": "Itzpapalotl",
    "jp": "イツパパロツル",
    "cn": "伊兹帕帕洛特尔"
  },
  "Nuckelavee": {
    "en": "Nuckelavee",
    "de": "Nuckelavee",
    "fr": "Nuckelavee",
    "jp": "ナックラヴィー",
    "cn": "纳克拉维"
  },
  "Nariphon": {
    "en": "Nariphon",
    "de": "Nariphon",
    "fr": "Nariphon",
    "jp": "ナリーポン",
    "cn": "纳里蓬"
  },
  "Tyger": {
    "en": "Tyger",
    "de": "Tyger",
    "fr": "Tygre",
    "jp": "ティガー",
    "cn": "戾虫"
  },
  "Coquecigrue": {
    "en": "Coquecigrue",
    "de": "Coquecigrue",
    "fr": "Coquecigrue",
    "jp": "コクシグルー",
    "cn": "三合鸟儿"
  },
  "Indomitable": {
    "en": "Indomitable",
    "de": "Unbezähmbar",
    "fr": "Indomptable",
    "jp": "インドミタブル",
    "cn": "不屈号"
  },
  "Lil Murderer": {
    "en": "Li'l Murderer",
    "de": "Klein[a] Mörder",
    "fr": "Traître",
    "jp": "リルマーダー",
    "cn": "小小杀手"
  },
  "Huracan": {
    "en": "Huracan",
    "de": "Huracan",
    "fr": "Huracan",
    "jp": "フラカン",
    "cn": "乌拉坎"
  },
  "Forgiven Pedantry": {
    "en": "Forgiven Pedantry",
    "de": "Geläutert[a] Pedanterie",
    "fr": "Pédanterie Pardonnée",
    "jp": "フォーギヴン・ペダントリー",
    "cn": "得到宽恕的炫学"
  },
  "Worm of the Well": {
    "en": "Worm of the Well",
    "de": "Wurm[p] des Brunnens",
    "fr": "Verpuy",
    "jp": "ウェルウォーム",
    "cn": "大井巨虫"
  },
  "Juggler Hecatomb": {
    "en": "Juggler Hecatomb",
    "de": "Gaukler[p] Hecatomb",
    "fr": "Jongleur Hécatombe",
    "jp": "ジャグラー・ヘカトゥーム",
    "cn": "残虐杂技师"
  },
  "Maliktender": {
    "en": "Maliktender",
    "de": "Malikkaktor",
    "fr": "Malikpampa",
    "jp": "マリクテンダー",
    "cn": "马利克巨人掌"
  },
  "Sugaar": {
    "en": "Sugaar",
    "de": "Sugaar",
    "fr": "Sugaar",
    "jp": "シュガール",
    "cn": "休格尔"
  },
  "Tarchia": {
    "en": "Tarchia",
    "de": "Tarchia",
    "fr": "Tarchia",
    "jp": "タルキア",
    "cn": "多智兽"
  },
  "Domovoi": {
    "en": "Domovoi",
    "de": "Domovoi",
    "fr": "Domovoï",
    "jp": "ドモヴォーイ",
    "cn": "杜莫伊"
  },
  "Vulpangue": {
    "en": "Vulpangue",
    "de": "Vulpangue",
    "fr": "Vulpangue",
    "jp": "ヴルパングエ",
    "cn": "狐首虺"
  },
  "The Mudman": {
    "en": "The Mudman",
    "de": "Matschklops",
    "fr": "Tas de Boue",
    "jp": "泥人",
    "cn": "泥人"
  },
  "O Poorest Pauldia": {
    "en": "O Poorest Pauldia",
    "de": "Gepeinigt[a] Pauldia",
    "fr": "Pauldia",
    "jp": "ポールディア",
    "cn": "保尔迪雅"
  },
  "Aglaope": {
    "en": "Aglaope",
    "de": "Aglaope",
    "fr": "Aglaope",
    "jp": "アグラオペ",
    "cn": "阿格拉俄珀"
  },
  "Mindmaker": {
    "en": "Mindmaker",
    "de": "Freigeist",
    "fr": "Maître-penseur",
    "jp": "マインドメーカー",
    "cn": "启灵果"
  },
  "Pachamama": {
    "en": "Pachamama",
    "de": "Pachamama",
    "fr": "Pachamama",
    "jp": "パチャママ",
    "cn": "帕查玛玛"
  },
  "Supay": {
    "en": "Supay",
    "de": "Supay",
    "fr": "Supay",
    "jp": "スペイ",
    "cn": "苏帕伊"
  },
  "Grassman": {
    "en": "Grassman",
    "de": "Grasmann",
    "fr": "Sasquatch Arboricole",
    "jp": "グラスマン",
    "cn": "格拉斯曼"
  },
  "Ixtab": {
    "en": "Ixtab",
    "de": "Ixtab",
    "fr": "Ixtab",
    "jp": "イシュタム",
    "cn": "伊休妲"
  },
  "Gilshs Aath Swiftclaw": {
    "en": "Gilshs Aath Swiftclaw",
    "de": "Gilshs Aath",
    "fr": "Gilshs Aath\r\nl'étrangleur",
    "jp": "手掴のギウスィー・アース",
    "cn": "徒手抓鱼 基乌嘶·渊斯"
  },
  "Deacon": {
    "en": "Deacon",
    "de": "Diakon",
    "fr": "Diacre",
    "jp": "ディーコン",
    "cn": "助祭大蟹"
  },
  "Rusalka": {
    "en": "Rusalka",
    "de": "Rusalka",
    "fr": "Roussalka",
    "jp": "ルサルカ",
    "cn": "卢莎卡"
  },
  "Baal": {
    "en": "Baal",
    "de": "Baal",
    "fr": "Baal",
    "jp": "バール",
    "cn": "巴力"
  },
  "Gunitt": {
    "en": "Gunitt",
    "de": "Gunitt",
    "fr": "Gunitt",
    "jp": "グニット",
    "cn": "顾尼图"
  },
  "Green Archon": {
    "en": "Green Archon",
    "de": "Grün[a] Exeget",
    "fr": "Preux Feuillu",
    "jp": "草賢人",
    "cn": "草贤人"
  },
  "ü-u-ü-u": {
    "en": "ü-u-ü-u",
    "de": "Ü-u-ü-u",
    "fr": "Ü-u-ü-u",
    "jp": "ウーヴウーヴ",
    "cn": "哞哞"
  },
  "Hulder": {
    "en": "Hulder",
    "de": "Hulder",
    "fr": "Huldre",
    "jp": "フルドラ",
    "cn": "胡睹"
  },
  "Storsie": {
    "en": "Storsie",
    "de": "Storsie",
    "fr": "Storsie",
    "jp": "ストーシー",
    "cn": "斯图希"
  },
  "Burfurlur the Canny": {
    "en": "Burfurlur the Canny",
    "de": "Burfurlur [t] Gerissen[a]",
    "fr": "Le Burfurlur",
    "jp": "ブーフールー",
    "cn": "布弗鲁"
  },
  "Vajrakumara": {
    "en": "Vajrakumara",
    "de": "Vajrakumara",
    "fr": "Vajrakumara",
    "jp": "ヴァジュラクマーラ",
    "cn": "金刚鸠摩罗"
  },
  "Iravati": {
    "en": "Iravati",
    "de": "Iravati",
    "fr": "Iravati",
    "jp": "イラーヴァティ",
    "cn": "伊罗婆缇"
  },
  "Yilan": {
    "en": "Yilan",
    "de": "Yilan",
    "fr": "Yilan",
    "jp": "ユラン",
    "cn": "尤兰"
  },
  "Sugriva": {
    "en": "Sugriva",
    "de": "Sugriva",
    "fr": "Sugriva",
    "jp": "スグリーヴァ",
    "cn": "须羯里婆"
  },
  "Sphatika": {
    "en": "Sphatika",
    "de": "Sphatika",
    "fr": "Sphatika",
    "jp": "スパティカ",
    "cn": "颇胝迦"
  },
  "Warmonger": {
    "en": "Warmonger",
    "de": "Kriegstreiber",
    "fr": "Belliciste",
    "jp": "ウォーモンガー",
    "cn": "战争贩子"
  },
  "Emperors Rose": {
    "en": "Emperor's Rose",
    "de": "Rose[p] des Kaisers",
    "fr": "Rosier de l'empereur",
    "jp": "エンペラーズローズ",
    "cn": "皇帝的玫瑰"
  },
  "Minerva": {
    "en": "Minerva",
    "de": "Minerva",
    "fr": "Minerva",
    "jp": "ミネルウァ",
    "cn": "密涅瓦"
  },
  "Aegeiros": {
    "en": "Aegeiros",
    "de": "Aegeiros",
    "fr": "Ægeiros",
    "jp": "アイゲイロス",
    "cn": "黑杨树精"
  },
  "Armstrong": {
    "en": "Armstrong",
    "de": "Armstrong",
    "fr": "Armstrong",
    "jp": "アームストロング",
    "cn": "阿姆斯特朗"
  },
  "Daphnia Magna": {
    "en": "Daphnia Magna",
    "de": "Daphnia Magna",
    "fr": "Magna Daphnie",
    "jp": "ダフニアマグナ",
    "cn": "巨月蚤"
  },
  "Genesis Rock": {
    "en": "Genesis Rock",
    "de": "Genesisfels",
    "fr": "Pierre de la Genèse",
    "jp": "ジェネシスロック",
    "cn": "起源石"
  },
  "Mousse Princess": {
    "en": "Mousse Princess",
    "de": "Mousse-Prinzessin",
    "fr": "Princesse Mousse",
    "jp": "ムースプリンセス",
    "cn": "慕斯公主"
  },
  "Lunatender Queen": {
    "en": "Lunatender Queen",
    "de": "Lunatender-Königin",
    "fr": "Pampa Sélénienne\r\nReine",
    "jp": "ルナテンダー・クイーン",
    "cn": "月面仙人刺女王"
  },
  "Ruminator": {
    "en": "Ruminator",
    "de": "Grübler",
    "fr": "Ruminateur",
    "jp": "ルミネイター",
    "cn": "沉思之物"
  },
  "Yumcax": {
    "en": "Yumcax",
    "de": "Yumcax",
    "fr": "Yumcax",
    "jp": "ユムカクス",
    "cn": "尤姆卡克斯"
  },
  "Shockmaw": {
    "en": "Shockmaw",
    "de": "Schockschlund",
    "fr": "Choc-gueule",
    "jp": "ショックモー",
    "cn": "肖科莫"
  },
  "Petalodus": {
    "en": "Petalodus",
    "de": "Petalodus",
    "fr": "Petalodus",
    "jp": "ペタロドゥス",
    "cn": "瓣齿鲨"
  },
  "Gurangatch": {
    "en": "Gurangatch",
    "de": "Gurangatch",
    "fr": "Gurangatch",
    "jp": "グランガチ",
    "cn": "固兰盖奇"
  },
  "Ophioneus": {
    "en": "Ophioneus",
    "de": "Ophioneus",
    "fr": "Ophion",
    "jp": "オピオネウス",
    "cn": "俄菲翁尼厄斯"
  },
  "Level Cheater": {
    "en": "Level Cheater",
    "de": "Stufenschummler",
    "fr": "Tricheniveau",
    "jp": "レベルチーター",
    "cn": "等级作弊仪"
  },
  "Oskh Rhei": {
    "en": "Oskh Rhei",
    "de": "Oskh Rhei",
    "fr": "Oskh Rhei",
    "jp": "オスク・レイ",
    "cn": "欧斯克·雷伊"
  },
  "Arch-Eta": {
    "en": "Arch-Eta",
    "de": "Erz-Eta",
    "fr": "Arch-Êta",
    "jp": "アーチイータ",
    "cn": "伊塔总领"
  },
  "Fan Ail": {
    "en": "Fan Ail",
    "de": "Fan Ail",
    "fr": "Fan Ail",
    "jp": "ファン・アイル",
    "cn": "凡·艾尔"
  },
  "Narrow-rift": {
    "en": "Narrow-rift",
    "de": "Enger Riss",
    "fr": "Rift-étroit",
    "jp": "ナロー＝リフト",
    "cn": "狭缝"
  },
  "Chupacabra": {
    "en": "Chupacabra",
    "de": "Chupacabra",
    "fr": "Chupacabra",
    "jp": "チュパカブラ",
    "cn": "チュパカブラ"
  },
  "Mad Maguey": {
    "en": "Mad Maguey",
    "de": "Aggressiv[a] Agave",
    "fr": "Pita Dément",
    "jp": "マッドマゲイ",
    "cn": "マッドマゲイ"
  },
  "Queen Hawk": {
    "en": "Queen Hawk",
    "de": "Falkenkönigin",
    "fr": "Reine Des Guêpes",
    "jp": "クイーンホーク",
    "cn": "クイーンホーク"
  },
  "Nechuciho": {
    "en": "Nechuciho",
    "de": "Nechuciho",
    "fr": "Nechukiho",
    "jp": "ネチュキホ",
    "cn": "ネチュキホ"
  },
  "Kirlirger the Abhorrent": {
    "en": "Kirlirger the Abhorrent",
    "de": "Kirlirger [T] Abscheulich[a]",
    "fr": "Kirlirger L'abominable",
    "jp": "厭忌のキーリーゲー",
    "cn": "厭忌のキーリーゲー"
  },
  "The Slammer": {
    "en": "The Slammer",
    "de": "Rammkopf",
    "fr": "Marteleur",
    "jp": "ラムヘッド",
    "cn": "ラムヘッド"
  },
  "Go'ozoabek'be": {
    "en": "Go'ozoabek'Be",
    "de": "Go'ozoabek'Be",
    "fr": "Go'ozoabek'Be",
    "jp": "ゴーゾアックベ",
    "cn": "ゴーゾアックベ"
  },
  "The Raintriller": {
    "en": "The Raintriller",
    "de": "Regentriller",
    "fr": "Trilleur De Pluie",
    "jp": "レイントリラー",
    "cn": "レイントリラー"
  },
  "Pkuucha": {
    "en": "Pkuucha",
    "de": "Pkuucha",
    "fr": "Pkuucha",
    "jp": "プクーチャ",
    "cn": "プクーチャ"
  },
  "Ihnuxokiy": {
    "en": "Ihnuxokiy",
    "de": "Ihnuxokiy",
    "fr": "Ihnuxokiy",
    "jp": "イヌショキー",
    "cn": "イヌショキー"
  },
  "Leafscourge Hadoll Ja": {
    "en": "Leafscourge Hadoll Ja",
    "de": "Blattgeißel[P] Hadoll Ja",
    "fr": "Hadoll Ja Le Foufeuillu",
    "jp": "緑葉のハドージャ",
    "cn": "緑葉のハドージャ"
  },
  "Xty'iinbek": {
    "en": "Xty'iinbek",
    "de": "Xty'iinbek",
    "fr": "Xty'iinbek",
    "jp": "シュティーンベク",
    "cn": "シュティーンベク"
  },
  "Starcrier": {
    "en": "Starcrier",
    "de": "Sternschreier",
    "fr": "Furopluminescent",
    "jp": "幻煌鳥",
    "cn": "幻煌鳥"
  },
  "Rrax Yity'a": {
    "en": "Rrax Yity'a",
    "de": "Rrax Yity'a",
    "fr": "Rrax Yity'a",
    "jp": "ラシュイチャ",
    "cn": "ラシュイチャ"
  },
  "Neyoozoteel": {
    "en": "Neyoozoteel",
    "de": "Neyoozoteel",
    "fr": "Neyozzoteel",
    "jp": "ネヨーゾテール",
    "cn": "ネヨーゾテール"
  },
  "Nopalitender Fabuloso": {
    "en": "Nopalitender Fabuloso",
    "de": "Feigenkaktor[P] Fabuloso",
    "fr": "Pampa De Barbarie Fabuleux",
    "jp": "ノパルテンダー・ファビュローソー",
    "cn": "ノパルテンダー・ファビュローソー"
  },
  "Uktena": {
    "en": "Uktena",
    "de": "Uktena",
    "fr": "Uktena",
    "jp": "ウクテナ",
    "cn": "ウクテナ"
  },
  "Yehehetoaua'pyo": {
    "en": "Yehehetoaua'pyo",
    "de": "Yehehetoaua'pyo",
    "fr": "Yehehetoaua'pyo",
    "jp": "エヘヘトーワポ",
    "cn": "エヘヘトーワポ"
  },
  "Keheniheyamewi": {
    "en": "Keheniheyamewi",
    "de": "Keheniheyamewi",
    "fr": "Keheniheyamewi",
    "jp": "ケヘニヘヤメウィ",
    "cn": "ケヘニヘヤメウィ"
  },
  "Sansheya": {
    "en": "Sansheya",
    "de": "Sansheya",
    "fr": "Sansheya",
    "jp": "サンシェヤ",
    "cn": "サンシェヤ"
  },
  "Gallowsbeak": {
    "en": "Gallowsbeak",
    "de": "Galgenschnabel",
    "fr": "Gibec",
    "jp": "ギャロウズビーク",
    "cn": "ギャロウズビーク"
  },
  "Gargant": {
    "en": "Gargant",
    "de": "Gargant",
    "fr": "Gargant",
    "jp": "ガルガント",
    "cn": "ガルガント"
  },
  "Heshuala": {
    "en": "Heshuala",
    "de": "Heshuala",
    "fr": "Heshuala",
    "jp": "ヘシュワラ",
    "cn": "ヘシュワラ"
  },
  "Urna Variabilis": {
    "en": "Urna Variabilis",
    "de": "Urna Variabilis",
    "fr": "Pod Variant",
    "jp": "ヴァリアポッド",
    "cn": "ヴァリアポッド"
  },
  "Atticus the Primogenitor": {
    "en": "Atticus the Primogenitor",
    "de": "Atticus [T] Primogenitor",
    "fr": "Atticus Le Primogéniteur",
    "jp": "先駆けのアティカス",
    "cn": "先駆けのアティカス"
  },
  "Jewel Bearer": {
    "en": "Jewel Bearer",
    "de": "Juwelenträger",
    "fr": "Porte-Joyaux",
    "jp": "ジュエルベアラー",
    "cn": "ジュエルベアラー"
  },
  "13th Child": {
    "en": "13th Child",
    "de": "Dreizehnt[a] Kind",
    "fr": "Treizième Enfant",
    "jp": "サーティーンス・チャイルド",
    "cn": "サーティーンス・チャイルド"
  },
  "Sally the Sweeper": {
    "en": "Sally the Sweeper",
    "de": "Sally [T] Fegerin",
    "fr": "Sally La Balayeuse",
    "jp": "サリー・ザ・スイーパー",
    "cn": "サリー・ザ・スイーパー"
  },
  "Cat's Eye": {
    "en": "Cat's Eye",
    "de": "Katzenauge",
    "fr": "Œil-De-Chat",
    "jp": "キャッツアイ",
    "cn": "キャッツアイ"
  },
  "The Forecaster": {
    "en": "The Forecaster",
    "de": "Wetterreporter",
    "fr": "Monsieur Météo",
    "jp": "ウェザーリポーター",
    "cn": "???????"
  },
  "FATE Odin": {
    "en": "Steel Reign",
    "de": "Der dunkle Reiter",
    "fr": "Défi\u0002\u001d\u0001\u0003: le Chevalier des ombres",
    "jp": "古の闘神「オーディン」",
    "cn": "上古斗神——奥丁"
  },
  "FATE Behemoth": {
    "en": "He Taketh It with His Eyes",
    "de": "Kein Weg zurück",
    "fr": "Défi\u0002\u001d\u0001\u0003: contre-traque",
    "jp": "手負いの魔獣「ベヒーモス」",
    "cn": "受伤的魔兽贝希摩斯"
  },
  "FATE Core Blimey": {
    "en": "Core Blimey",
    "de": "Erdkern für Erdseim",
    "fr": "Ne jetez pas les noyaux !",
    "jp": "青燐精製所のお手伝い",
    "cn": "核心危机"
  },
  "FATE Noctilucale": {
    "en": "Prey Online",
    "de": "Ultima: Ein Prototyp tankt auf",
    "fr": "Proto-Ultima\u0002\u001d\u0001\u0003: la menace surgie du passé",
    "jp": "太古の脅威：ノクチルカ撃滅戦",
    "cn": "太古威胁 夜光花歼灭战"
  },
  "FATE Coeurlregina": {
    "en": "Coeurls Chase Boys Chase Coeurls",
    "de": "Entmachtung der Königin",
    "fr": "Défi\u0002\u001d\u0001\u0003: coeurls brisés",
    "jp": "幻影の女王「クァールレギナ」",
    "cn": "幻影女王——长须豹女王"
  },
  "FATE El Miacid": {
    "en": "El Miacid",
    "de": "Entscheidung am Weiland-Fluss",
    "fr": "Ça Vathe mieux comme ça",
    "jp": "境界の川",
    "cn": "分界河"
  },
  "FATE Cerf": {
    "en": "Cerf's Up",
    "de": "Der Dracheneierkrieg",
    "fr": "Bas les hippogriffes",
    "jp": "卵をめぐる竜の戦争",
    "cn": "龙卵引起的战争"
  },
  "FATE Elephant in the Brume": {
    "en": "Elephant in the Brume",
    "de": "Svarog der Schreckliche",
    "fr": "Défi\u0002\u001d\u0001\u0003: l'exécution de Svarog",
    "jp": "雲霧街の虐殺者「スヴァローグ」",
    "cn": "云雾街虐杀者——斯瓦洛格"
  },
  "FATE Special Tarasque Force": {
    "en": "Special Tarasque Force",
    "de": "Die Hasspredigerin",
    "fr": "Défi\u0002\u001d\u0001\u0003: terrasser la Tarasque",
    "jp": "爆着の甲竜「タラスク」",
    "cn": "坚甲铁龙——塔拉斯克"
  },
  "FATE Ixion": {
    "en": "A Horse Outside",
    "de": "Höllenross",
    "fr": "Défi\u0002\u001d\u0001\u0003: l'étalon survolté",
    "jp": "伝説の雷馬「イクシオン」",
    "cn": "传说中的雷马——伊克西翁"
  },
  "FATE Foxy Lady": {
    "en": "Foxy Lady",
    "de": "Fuchsige Dame",
    "fr": "Défi\u0002\u001d\u0001\u0003: la renarde fait surface",
    "jp": "九尾の妖狐「玉藻御前」",
    "cn": "九尾妖狐玉藻御前"
  },
  "FATE Diakka Round": {
    "en": "Diakka Round",
    "de": "Dickes Ding",
    "fr": "Au diable les diakkas\u0002\u001d\u0001\u0003!",
    "jp": "グゥレイト！",
    "cn": "太棒了！"
  },
  "FATE Archaeotania": {
    "en": "The Head, the Tail, the Whole Damned Thing",
    "de": "Schrecken der Tiefsee Archaeotania: Die Bestie",
    "fr": "L'annonciatrice du chaos\u0002\u001d\u0001\u0003: bataille au sommet des fonds",
    "jp": "厄災のアルケオタニア：深海の討伐戦",
    "cn": "灾厄的古塔尼亚之深海讨伐战"
  },
  "FATE Formidable": {
    "en": "A Finale Most Formidable",
    "de": "Ein formidabler Kampf: Der Gigant",
    "fr": "Formidable\u0002\u001d\u0001\u0003: l'arme secrète",
    "jp": "激闘フォーミダブル：切り札起動",
    "cn": "激斗畏惧装甲之秘密武器"
  },
  "FATE Daivadipa": {
    "en": "Devout Pilgrims vs. Daivadipa",
    "de": "Im Dienste der Mrga: Blasphemie",
    "fr": "Le culte des Mrga\u0002\u001d\u0001\u0003: le faux dieu",
    "jp": "ムリガ信仰：偽りの神",
    "cn": "兽道诸神信仰：伪神降临"
  },
  "FATE Chi": {
    "en": "Omicron Recall: Killing Order",
    "de": "Aus dem Speicher von Chi: Zerstörung",
    "fr": "Mémoire vive\u0002\u001d\u0001\u0003: arrêter le processus",
    "jp": "カイのメモリーより：侵略兵器の破壊",
    "cn": "侵略兵器召回指令：破坏侵略兵器希"
  },
  "FATE Behemoth Chain #1": {
    "en": "Behold Now Behemoth",
    "de": "Der legendäre Behemoth",
    "fr": "Défi\u0002\u001d\u0001\u0003: l'ire du béhémoth",
    "jp": "伝説の魔獣「ベヒーモス」",
    "cn": "传说的魔兽——贝希摩斯"
  },
  "FATE Coeurlregina Chain #1": {
    "en": "Long Live the Coeurl",
    "de": "Angriff der Königin",
    "fr": "Défi\u0002\u001d\u0001\u0003: mort à la reine des coeurls",
    "jp": "雷獣の女王「クァールレギナ」",
    "cn": "雷兽女王——长须豹女王"
  },
  "FATE Coeurlregina Chain #2": {
    "en": "Coeurls Chase Boys",
    "de": "Rückkehr der Königin",
    "fr": "Défi\u0002\u001d\u0001\u0003: du coeurl à l'ouvrage",
    "jp": "逆襲の女王「クァールレギナ」",
    "cn": "逆袭女王——长须豹女王"
  },
  "FATE Foxy Lady Chain #1": {
    "en": "Outfoxed",
    "de": "Ausgefuchst",
    "fr": "Rusera bien qui rusera le dernier",
    "jp": "その名はミクズメ",
    "cn": "其名为藻女"
  },
  "FATE Archaeotania Chain #1": {
    "en": "Where Has the Dagon",
    "de": "Schrecken der Tiefsee Archaeotania: Die jpgd beginnt",
    "fr": "L'annonciatrice du chaos\u0002\u001d\u0001\u0003: l'ouverture de la chasse",
    "jp": "厄災のアルケオタニア：追跡開始",
    "cn": "灾厄的古塔尼亚之开始追踪"
  },
  "FATE Archaeotania Chain #2": {
    "en": "Ondo of Blood",
    "de": "Schrecken der Tiefsee Archaeotania: Zumshs Ritual",
    "fr": "L'annonciatrice du chaos\u0002\u001d\u0001\u0003: Zumshs la terreur des abysses",
    "jp": "厄災のアルケオタニア：ズムスィー登場",
    "cn": "灾厄的古塔尼亚之兹姆嘶登场"
  },
  "FATE Archaeotania Chain #3": {
    "en": "Lookin' Back on the Track",
    "de": "Schrecken der Tiefsee Archaeotania: Luntenschwarm",
    "fr": "L'annonciatrice du chaos\u0002\u001d\u0001\u0003: oléofurors furax",
    "jp": "厄災のアルケオタニア：テウスィー防衛",
    "cn": "灾厄的古塔尼亚之保护提乌嘶"
  },
  "FATE Archaeotania Chain #4": {
    "en": "Coral Support",
    "de": "Schrecken der Tiefsee Archaeotania: Dunkle Grotten",
    "fr": "L'annonciatrice du chaos\u0002\u001d\u0001\u0003: expédition spéléologique",
    "jp": "厄災のアルケオタニア：テウスィー護衛",
    "cn": "灾厄的古塔尼亚之护卫提乌嘶"
  },
  "FATE Archaeotania Chain #5": {
    "en": "Low Coral Fiber",
    "de": "Schrecken der Tiefsee Archaeotania: Blutkorallen",
    "fr": "L'annonciatrice du chaos\u0002\u001d\u0001\u0003: pêche aux coraux",
    "jp": "厄災のアルケオタニア：紅血珊瑚の収集",
    "cn": "灾厄的古塔尼亚之收集红血珊瑚"
  },
  "FATE Archaeotania Chain #6": {
    "en": "Nothing Like a Trappin' Life",
    "de": "Schrecken der Tiefsee Archaeotania: Köder",
    "fr": "L'annonciatrice du chaos\u0002\u001d\u0001\u0003: les rets de Zumshs",
    "jp": "厄災のアルケオタニア：決戦の下準備",
    "cn": "灾厄的古塔尼亚之准备决战"
  },
  "FATE Formidable Chain #1": {
    "en": "Ironbeard Builders - Rebuilt",
    "de": "Krieg der Baumeister: Konstruktionsphase",
    "fr": "Bataille d'ingénieurs\u0002\u001d\u0001\u0003: automate en con\u0002\u0016\u0001\u0003struc\u0002\u0016\u0001\u0003tion",
    "jp": "ビルドウォリアーズ：オートマトン製作",
    "cn": "制作战士之自走人偶"
  },
  "FATE Formidable Chain #2": {
    "en": "Ironbeard Builders - Resist",
    "de": "Krieg der Baumeister: Testphase",
    "fr": "Bataille d'ingénieurs\u0002\u001d\u0001\u0003: dernière mise au point",
    "jp": "ビルドウォリアーズ：試作品実戦テスト",
    "cn": "制作战士之实机测试"
  },
  "FATE Formidable Chain #3": {
    "en": "Ironbeard Builders - Revolt",
    "de": "Krieg der Baumeister: Duell der Giganten",
    "fr": "Bataille d'ingénieurs\u0002\u001d\u0001\u0003: la lutte finale",
    "jp": "ビルドウォリアーズ：没収試合",
    "cn": "制作战士之不公平对决"
  },
  "FATE Formidable Chain #4": {
    "en": "Foes Most Formidable",
    "de": "Ein formidabler Kampf: Die Bedrohung",
    "fr": "Formidable\u0002\u001d\u0001\u0003: la menace",
    "jp": "激闘フォーミダブル：迫る脅威",
    "cn": "激斗畏惧装甲之危险逼近"
  },
  "FATE Formidable Chain #5": {
    "en": "A Family Most Formidable",
    "de": "Ein formidabler Kampf: Ein finsterer Plan",
    "fr": "Formidable\u0002\u001d\u0001\u0003: la filature",
    "jp": "激闘動フォーミダブル：新たな情報",
    "cn": "激斗畏惧装甲之新的情报"
  },
  "FATE Daivadipa Chain #1": {
    "en": "Devout Pilgrims vs. the Jungle Lizards",
    "de": "Im Dienste der Mrga: Pilger in Not",
    "fr": "Le culte des Mrga\u0002\u001d\u0001\u0003: pélerin perdu",
    "jp": "ムリガ信仰：迷える巡礼者",
    "cn": "ムリガ信仰：迷える巡礼者"
  },
  "FATE Daivadipa Chain #2": {
    "en": "Devout Pilgrims vs. the Apalala",
    "de": "Im Dienste der Mrga: Stoßgebet",
    "fr": "Le culte des Mrga\u0002\u001d\u0001\u0003: l'heure de la prière",
    "jp": "ムリガ信仰：祈りの時",
    "cn": "ムリガ信仰：祈りの時"
  },
  "FATE Chi Chain #1": {
    "en": "Omicron Recall: Comms Expansion",
    "de": "Aus dem Speicher von Chi: Aufrüstung des Kommunikators",
    "fr": "Mémoire vive\u0002\u001d\u0001\u0003: mise à niveau",
    "jp": "カイのメモリーより：通信機拡張",
    "cn": "カイのメモリーより：通信機拡張"
  },
  "FATE Chi Chain #2": {
    "en": "Omicron Recall: Secure Connection",
    "de": "Aus dem Speicher von Chi: Gefahrenabwehr",
    "fr": "Mémoire vive\u0002\u001d\u0001\u0003: pare-feu",
    "jp": "カイのメモリーより：N-6205防衛",
    "cn": "カイのメモリーより：N-6205防衛"
  },
  "FATE Mascot Chain #1": {
    "en": "Mascot March",
    "de": "Alles andere als fade, diese Parade",
    "fr": "Mica le mu: début du spectacle",
    "jp": "マイカ・ザ・ムー：出発進行",
    "cn": "マイカ・ザ・ムー：出発進行"
  },
  "FATE Mascot Chain #2": {
    "en": "Mascot Mayhem",
    "de": "Bizarres Zwischenspiel",
    "fr": "Mica le mu: la grotesque parade",
    "jp": "マイカ・ザ・ムー：大行進",
    "cn": "マイカ・ザ・ムー：大行進"
  },
  "FATE Ttokrrone Chain #1": {
    "en": "The Serpentlord Stirs",
    "de": "Künder seines Erwachens",
    "fr": "Ttokkrone: prélude à l'éveil",
    "jp": "トクローネ：目覚めの予兆",
    "cn": "トクローネ：目覚めの予兆",
  },
  "FATE Ttokrrone Chain #2": {
    "en": "The Serpentlord Speaks",
    "de": "Das Erwachen rückt näher",
    "fr": "Ttokkrone: le roi des serpents",
    "jp": "トクローネ：目覚めの前兆",
    "cn": "トクローネ：目覚めの前兆",
  },
  "FATE Ttokrrone Chain #3": {
    "en": "The Serpentlord Sires",
    "de": "Vorbereitung der Jagd",
    "fr": "Ttokkrone: la grande chasse commence",
    "jp": "トクローネ：狩猟の下準備",
    "cn": "トクローネ：狩猟の下準備",
  },
  "FATE Ttokrrone Chain #4": {
    "en": "The Serpentlord Suffers",
    "de": "Mit Blut fängt man Schlangen",
    "fr": "Ttokrrone: massacre ophidien",
    "jp": "トクローネ：狩猟の秘策",
    "cn": "トクローネ：狩猟の秘策",
  },
  "FATE Ttokrrone": {
    "en": "The Serpentlord Seethes",
    "de": "In den sandigen Schlund",
    "fr": "Ttokrrone: lutte ensablée",
    "jp": "トクローネ：荒野の死闘",
    "cn": "????"
  },
  "FATE Mascot": {
    "en": "Mascot Murder",
    "de": "Mordendes Maskottchen",
    "fr": "Mica le mu: le grand final",
    "jp": "マイカ・ザ・ムー：大団円",
    "cn": "????"
  }
};

export const regionNameSelector = {
  "Central Shroud": {
    "en": "Central Shroud",
    "de": "Tiefer Wald",
    "fr": "Forêt centrale",
    "jp": "黒衣森：中央森林",
    "cn": "黑衣森林中央林区"
  },
  "East Shroud": {
    "en": "East Shroud",
    "de": "Ostwald",
    "fr": "Forêt de l'est",
    "jp": "黒衣森：東部森林",
    "cn": "黑衣森林东部林区"
  },
  "South Shroud": {
    "en": "South Shroud",
    "de": "Südwald",
    "fr": "Forêt du sud",
    "jp": "黒衣森：南部森林",
    "cn": "黑衣森林南部林区"
  },
  "North Shroud": {
    "en": "North Shroud",
    "de": "Nordwald",
    "fr": "Forêt du nord",
    "jp": "黒衣森：北部森林",
    "cn": "黑衣森林北部林区"
  },
  "Western Thanalan": {
    "en": "Western Thanalan",
    "de": "Westliches Thanalan",
    "fr": "Thanalan occidental",
    "jp": "西ザナラーン",
    "cn": "西萨纳兰"
  },
  "Central Thanalan": {
    "en": "Central Thanalan",
    "de": "Zentrales Thanalan",
    "fr": "Thanalan central",
    "jp": "中央ザナラーン",
    "cn": "中萨纳兰"
  },
  "Eastern Thanalan": {
    "en": "Eastern Thanalan",
    "de": "Östliches Thanalan",
    "fr": "Thanalan oriental",
    "jp": "東ザナラーン",
    "cn": "东萨纳兰"
  },
  "Southern Thanalan": {
    "en": "Southern Thanalan",
    "de": "Südliches Thanalan",
    "fr": "Thanalan méridional",
    "jp": "南ザナラーン",
    "cn": "南萨纳兰"
  },
  "Northern Thanalan": {
    "en": "Northern Thanalan",
    "de": "Nördliches Thanalan",
    "fr": "Thanalan septentrional",
    "jp": "北ザナラーン",
    "cn": "北萨纳兰"
  },
  "Middle La Noscea": {
    "en": "Middle La Noscea",
    "de": "Zentrales La Noscea",
    "fr": "Noscea centrale",
    "jp": "中央ラノシア",
    "cn": "中拉诺西亚"
  },
  "Lower La Noscea": {
    "en": "Lower La Noscea",
    "de": "Unteres La Noscea",
    "fr": "Basse-Noscea",
    "jp": "低地ラノシア",
    "cn": "拉诺西亚低地"
  },
  "Eastern La Noscea": {
    "en": "Eastern La Noscea",
    "de": "Östliches La Noscea",
    "fr": "Noscea orientale",
    "jp": "東ラノシア",
    "cn": "东拉诺西亚"
  },
  "Western La Noscea": {
    "en": "Western La Noscea",
    "de": "Westliches La Noscea",
    "fr": "Noscea occidentale",
    "jp": "西ラノシア",
    "cn": "西拉诺西亚"
  },
  "Upper La Noscea": {
    "en": "Upper La Noscea",
    "de": "Oberes La Noscea",
    "fr": "Haute-Noscea",
    "jp": "高地ラノシア",
    "cn": "拉诺西亚高地"
  },
  "Outer La Noscea": {
    "en": "Outer La Noscea",
    "de": "Äußeres La Noscea",
    "fr": "Noscea extérieure",
    "jp": "外地ラノシア",
    "cn": "拉诺西亚外地"
  },
  "Coerthas Central Highlands": {
    "en": "Coerthas Central Highlands",
    "de": "Zentrales Hochland von Coerthas",
    "fr": "Hautes terres du Coerthas central",
    "jp": "クルザス中央高地",
    "cn": "库尔札斯中央高地"
  },
  "Mor Dhona": {
    "en": "Mor Dhona",
    "de": "Mor Dhona",
    "fr": "Mor Dhona",
    "jp": "モードゥナ",
    "cn": "摩杜纳"
  },
  "All Shroud": {
    "en": "All Shroud",
    "de": "Tiefer Wald",
    "fr": "Forêt",
    "jp": "黒衣森",
    "cn": "黑衣森林"
  },
  "Coerthas Western Highlands": {
    "en": "Coerthas Western Highlands",
    "de": "Westliches Hochland von Coerthas",
    "fr": "Hautes terres du Coerthas occidental",
    "jp": "クルザス西部高地",
    "cn": "库尔札斯西部高地"
  },
  "The Dravanian Forelands": {
    "en": "The Dravanian Forelands",
    "de": "Dravanisches Vorland",
    "fr": "Avant-pays dravanien",
    "jp": "高地ドラヴァニア",
    "cn": "龙堡参天高地"
  },
  "The Churning Mists": {
    "en": "The Churning Mists",
    "de": "Wallende Nebel",
    "fr": "L'Écume des cieux de Dravania",
    "jp": "ドラヴァニア雲海",
    "cn": "翻云雾海"
  },
  "The Dravanian Hinterlands": {
    "en": "The Dravanian Hinterlands",
    "de": "Dravanisches Hinterland",
    "fr": "Arrière-pays dravanien",
    "jp": "低地ドラヴァニア",
    "cn": "龙堡内陆低地"
  },
  "The Sea of Clouds": {
    "en": "The Sea of Clouds",
    "de": "Abalathisches Wolkenmeer",
    "fr": "L'Écume des cieux d'Abalathia",
    "jp": "アバラシア雲海",
    "cn": "阿巴拉提亚云海"
  },
  "Azys Lla": {
    "en": "Azys Lla",
    "de": "Azys Lla",
    "fr": "Azys Lla",
    "jp": "アジス・ラー",
    "cn": "魔大陆阿济兹拉"
  },
  "The Fringes": {
    "en": "The Fringes",
    "de": "Abanisches Grenzland",
    "fr": "Les Marges",
    "jp": "ギラバニア辺境地帯",
    "cn": "基拉巴尼亚边区"
  },
  "The Peaks": {
    "en": "The Peaks",
    "de": "Die Zinnen",
    "fr": "Les Pics",
    "jp": "ギラバニア山岳地帯",
    "cn": "基拉巴尼亚山区"
  },
  "The Lochs": {
    "en": "The Lochs",
    "de": "Das Fenn",
    "fr": "Les Lacs",
    "jp": "ギラバニア湖畔地帯",
    "cn": "基拉巴尼亚湖区"
  },
  "The Ruby Sea": {
    "en": "The Ruby Sea",
    "de": "Rubinsee",
    "fr": "Mer de Rubis",
    "jp": "紅玉海",
    "cn": "红玉海"
  },
  "Yanxia": {
    "en": "Yanxia",
    "de": "Yanxia",
    "fr": "Yanxia",
    "jp": "ヤンサ",
    "cn": "延夏"
  },
  "The Azim Steppe": {
    "en": "The Azim Steppe",
    "de": "Azim-Steppe",
    "fr": "Steppe d'Azim",
    "jp": "アジムステップ",
    "cn": "太阳神草原"
  },
  "Lakeland": {
    "en": "Lakeland",
    "de": "Seenland",
    "fr": "Grand-Lac",
    "jp": "レイクランド",
    "cn": "雷克兰德"
  },
  "Kholusia": {
    "en": "Kholusia",
    "de": "Kholusia",
    "fr": "Kholusia",
    "jp": "コルシア島",
    "cn": "珂露西亚岛"
  },
  "Amh Araeng": {
    "en": "Amh Araeng",
    "de": "Amh Araeng",
    "fr": "Amh Araeng",
    "jp": "アム・アレーン",
    "cn": "安穆·艾兰"
  },
  "Il Mheg": {
    "en": "Il Mheg",
    "de": "Il Mheg",
    "fr": "Il Mheg",
    "jp": "イル・メグ",
    "cn": "伊尔美格"
  },
  "The Rak'tika Greatwood": {
    "en": "The Rak'tika Greatwood",
    "de": "Der Große Wald Rak'tika",
    "fr": "Rak'tika",
    "jp": "ラケティカ大森林",
    "cn": "拉凯提卡大森林"
  },
  "The Tempest": {
    "en": "The Tempest",
    "de": "Tempest",
    "fr": "La Tempête",
    "jp": "テンペスト",
    "cn": "黑风海"
  },
  "Labyrinthos": {
    "en": "Labyrinthos",
    "de": "Labyrinthos",
    "fr": "Le Labyrinthos",
    "jp": "ラヴィリンソス",
    "cn": "迷津"
  },
  "Thavnair": {
    "en": "Thavnair",
    "de": "Thavnair",
    "fr": "Thavnair",
    "jp": "サベネア島",
    "cn": "萨维奈岛"
  },
  "Garlemald": {
    "en": "Garlemald",
    "de": "Garlemald",
    "fr": "Garlemald",
    "jp": "ガレマルド",
    "cn": "加雷马"
  },
  "Mare Lamentorum": {
    "en": "Mare Lamentorum",
    "de": "Mare Lamentorum",
    "fr": "Mare Lamentorum",
    "jp": "嘆きの海",
    "cn": "叹息海"
  },
  "Elpis": {
    "en": "Elpis",
    "de": "Elpis",
    "fr": "Elpis",
    "jp": "エルピス",
    "cn": "厄尔庇斯"
  },
  "Ultima Thule": {
    "en": "Ultima Thule",
    "de": "Ultima Thule",
    "fr": "Ultima Thulé",
    "jp": "ウルティマ・トゥーレ",
    "cn": "天外天垓"
  },
  "Urqopacha": {
    "en": "Urqopacha",
    "de": "Urqopacha",
    "fr": "Urqopacha",
    "jp": "オルコ・パチャ",
    "cn": "????"
  },
  "Kozama'uka": {
    "en": "Kozama'uka",
    "de": "Kozama'uka",
    "fr": "Kozama'uka",
    "jp": "コザマル・カ",
    "cn": "????"
  },
  "Yak T'el": {
    "en": "Yak T'el",
    "de": "Yak T'el",
    "fr": "Yak T'el",
    "jp": "ヤクテル樹海",
    "cn": "????"
  },
  "Shaaloani": {
    "en": "Shaaloani",
    "de": "Shaaloani",
    "fr": "Shaaloani",
    "jp": "シャーローニ荒野",
    "cn": "????"
  },
  "Heritage Found": {
    "en": "Heritage Found",
    "de": "Ewiges Erbe",
    "fr": "L'hoirie Recouvrée",
    "jp": "ヘリテージファウンド",
    "cn": "????"
  },
  "Living Memory": {
    "en": "Living Memory",
    "de": "Lebende Erinnerung",
    "fr": "La Mémoire Vivante",
    "jp": "リビング・メモリー",
    "cn": "????"
  }
};

export const aetheryteNameSelector = {
  'New Gridania': {'en': 'New Gridania',
  'de': 'Neu-Gridania',
  'fr': 'Nouvelle Gridania',
  'jp': 'グリダニア：新市街',
  'cn': '格里达尼亚新街'},
 'Bentbranch Meadows': {'en': 'Bentbranch Meadows',
  'de': 'Gut Zwieselgrund',
  'fr': 'Ranch de Brancharquée',
  'jp': 'ベントブランチ牧場',
  'cn': '弯枝牧场'},
 'The Hawthorne Hut': {'en': 'The Hawthorne Hut',
  'de': 'Jagdhütte der Hawthornes',
  'fr': 'Hutte des Hawthorne',
  'jp': 'ホウソーン家の山塞',
  'cn': '霍桑山寨'},
 'Quarrymill': {'en': 'Quarrymill',
  'de': 'Mühlenbruch',
  'fr': 'Moulin de la Carrière',
  'jp': 'クォーリーミル',
  'cn': '石场水车'},
 'Camp Tranquil': {'en': 'Camp Tranquil',
  'de': 'Camp Seelenruhe',
  'fr': 'Camp des Sentes tranquilles',
  'jp': 'キャンプ・トランキル',
  'cn': '恬静路营地'},
 'Fallgourd Float': {'en': 'Fallgourd Float',
  'de': 'Herbstkürbis-See',
  'fr': 'Radeau de la Calebasse',
  'jp': 'フォールゴウド',
  'cn': '秋瓜浮村'},
 'Limsa Lominsa Lower Decks': {'en': 'Limsa Lominsa Lower Decks',
  'de': 'Untere Decks',
  'fr': "Limsa Lominsa - L'Entrepont",
  'jp': 'リムサ・ロミンサ：下甲板層',
  'cn': '利姆萨·罗敏萨下层甲板'},
 "Ul'dah - Steps of Nald": {'en': "Ul'dah - Steps of Nald",
  'de': 'Nald-Kreuzgang',
  'fr': "Ul'dah - Faubourg de Nald",
  'jp': 'ウルダハ：ナル回廊',
  'cn': '乌尔达哈现世回廊'},
 'Moraby Drydocks': {'en': 'Moraby Drydocks',
  'de': 'Moraby-Trockendocks',
  'fr': 'Chantier naval de Moraby',
  'jp': 'モラビー造船廠',
  'cn': '莫拉比造船厂'},
 'Costa del Sol': {'en': 'Costa del Sol',
  'de': 'Sonnenküste',
  'fr': 'Costa del Sol',
  'jp': 'コスタ・デル・ソル',
  'cn': '太阳海岸'},
 'Wineport': {'en': 'Wineport',
  'de': 'Weinhafen',
  'fr': 'Port-aux-Vins',
  'jp': 'ワインポート',
  'cn': '葡萄酒港'},
 'Swiftperch': {'en': 'Swiftperch',
  'de': 'Tölpelturm-Siedlung',
  'fr': 'Le Martinet',
  'jp': 'スウィフトパーチ入植地',
  'cn': '雨燕塔殖民地'},
 'Aleport': {'en': 'Aleport',
  'de': 'Bierhafen',
  'fr': 'Port-aux-Ales',
  'jp': 'エールポート',
  'cn': '小麦酒港'},
 'Camp Bronze Lake': {'en': 'Camp Bronze Lake',
  'de': 'Camp Bronzesee',
  'fr': "Camp du lac d'Airain",
  'jp': 'キャンプ・ブロンズレイク',
  'cn': '石绿湖营地'},
 'Camp Overlook': {'en': 'Camp Overlook',
  'de': 'Camp Ausblick',
  'fr': 'Camp du Guet',
  'jp': 'キャンプ・オーバールック',
  'cn': '瞭望阵营地'},
 'Horizon': {'en': 'Horizon',
  'de': 'Horizont',
  'fr': 'Horizon',
  'jp': 'ホライズン',
  'cn': '地平关'},
 'Camp Drybone': {'en': 'Camp Drybone',
  'de': 'Camp Knochenbleich',
  'fr': 'Camp des Os desséchés',
  'jp': 'キャンプ・ドライボーン',
  'cn': '枯骨营地'},
 'Little Ala Mhigo': {'en': 'Little Ala Mhigo',
  'de': 'Klein-Ala Mhigo',
  'fr': 'Petite Ala Mhigo',
  'jp': 'リトルアラミゴ',
  'cn': '小阿拉米格'},
 'Forgotten Springs': {'en': 'Forgotten Springs',
  'de': 'Die Vergessene Oase',
  'fr': 'Oasis oubliée',
  'jp': '忘れられたオアシス',
  'cn': '遗忘绿洲'},
 'Camp Bluefog': {'en': 'Camp Bluefog',
  'de': 'Camp Blauer Dunst',
  'fr': 'Camp de Brumebleue',
  'jp': 'キャンプ・ブルーフォグ',
  'cn': '蓝雾营地'},
 'Ceruleum Processing Plant': {'en': 'Ceruleum Processing Plant',
  'de': 'Erdseim-Fabrik',
  'fr': 'Usine de céruleum',
  'jp': '青燐精製所',
  'cn': '青磷精炼所'},
 'Camp Dragonhead': {'en': 'Camp Dragonhead',
  'de': 'Camp Drachenkopf',
  'fr': 'Camp de la Tête du dragon',
  'jp': 'キャンプ・ドラゴンヘッド',
  'cn': '巨龙首营地'},
 "Revenant's Toll": {'en': "Revenant's Toll",
  'de': 'Geisterzoll',
  'fr': 'Glas des revenants',
  'jp': 'レヴナンツトール',
  'cn': '丧灵钟'},
 'Summerford Farms': {'en': 'Summerford Farms',
  'de': 'Sommerfurt-Höfe',
  'fr': "Vergers d'Estival",
  'jp': 'サマーフォード庄',
  'cn': '盛夏农庄'},
 'Black Brush Station': {'en': 'Black Brush Station',
  'de': 'Kohlenstaub-Bahnhof',
  'fr': 'Gare de Roncenoire',
  'jp': 'ブラックブラッシュ停留所',
  'cn': '黑尘驿站'},
 "Wolves' Den Pier": {'en': "Wolves' Den Pier",
  'de': 'Wolfshöhlen-Pier',
  'fr': "Jetée de l'Antre des loups",
  'jp': 'ウルヴズジェイル係船場',
  'cn': '狼狱停船场'},
 'Estate Hall (Free Company)': {'en': 'Estate Hall (Free Company)',
  'de': 'Unterkunft (Freie Gesellschaft)',
  'fr': 'Maison (compagnie libre)',
  'jp': 'ハウス（フリーカンパニー）',
  'cn': '部队房屋'},
 'Estate Hall (Private)': {'en': 'Estate Hall (Private)',
  'de': 'Unterkunft (persönlich)',
  'fr': 'Maison (individuelle)',
  'jp': 'ハウス（個人）',
  'cn': '个人房屋'},
 'The Gold Saucer': {'en': 'The Gold Saucer',
  'de': 'Gold Saucer',
  'fr': 'Gold Saucer',
  'jp': 'ゴールドソーサー',
  'cn': '金碟游乐场'},
 'Foundation': {'en': 'Foundation',
  'de': 'Fundamente',
  'fr': "Ishgard - L'Assise",
  'jp': 'イシュガルド：下層',
  'cn': '伊修加德基础层'},
 "Falcon's Nest": {'en': "Falcon's Nest",
  'de': 'Falkenhorst',
  'fr': 'Nid du faucon',
  'jp': 'ファルコンネスト',
  'cn': '隼巢'},
 'Camp Cloudtop': {'en': 'Camp Cloudtop',
  'de': 'Camp Wolkenwipfel',
  'fr': 'Camp de Cime-des-nuages',
  'jp': 'キャンプ・クラウドトップ',
  'cn': '云顶营地'},
 "Ok' Zundu": {'en': "Ok' Zundu",
  'de': "Ok' Zundo",
  'fr': "Ok' Zundu",
  'jp': 'オク・ズンド',
  'cn': '尊杜集落'},
 'Helix': {'en': 'Helix',
  'de': 'Helix',
  'fr': 'Hélice',
  'jp': 'ポート・ヘリックス',
  'cn': '螺旋港'},
 'Idyllshire': {'en': 'Idyllshire',
  'de': 'Frohehalde',
  'fr': 'Idyllée',
  'jp': 'イディルシャイア',
  'cn': '田园郡'},
 'Tailfeather': {'en': 'Tailfeather',
  'de': 'Fiederhag',
  'fr': 'La Penne',
  'jp': 'テイルフェザー',
  'cn': '尾羽集落'},
 'Anyx Trine': {'en': 'Anyx Trine',
  'de': 'Trinär-Annex',
  'fr': 'Annexe Trine',
  'jp': '不浄の三塔',
  'cn': '不洁三塔'},
 'Moghome': {'en': 'Moghome',
  'de': 'Moglingen',
  'fr': 'Nid-Mog',
  'jp': 'モグモグホーム',
  'cn': '莫古力之家'},
 'Zenith': {'en': 'Zenith',
  'de': 'Kreidetempel',
  'fr': 'Zénith',
  'jp': '白亜の宮殿',
  'cn': '天极白垩宫'},
 'Castrum Oriens': {'en': 'Castrum Oriens',
  'de': 'Castrum Oriens',
  'fr': 'Castrum Oriens',
  'jp': 'カストルム・オリエンス',
  'cn': '帝国东方堡'},
 'The Peering Stones': {'en': 'The Peering Stones',
  'de': 'Die Spähenden Steine',
  'fr': "Rochers d'aguet",
  'jp': 'ピーリングストーンズ',
  'cn': '对等石'},
 'Ala Gannha': {'en': 'Ala Gannha',
  'de': 'Ala Gannha',
  'fr': 'Ala Gannha',
  'jp': 'アラガーナ',
  'cn': '阿拉加纳'},
 'Ala Ghiri': {'en': 'Ala Ghiri',
  'de': 'Ala Ghiri',
  'fr': 'Ala Ghiri',
  'jp': 'アラギリ',
  'cn': '阿拉基利'},
 'Porta Praetoria': {'en': 'Porta Praetoria',
  'de': 'Porta Praetoria',
  'fr': 'Porta Praetoria',
  'jp': 'ポルタ・プレトリア',
  'cn': '天营门'},
 'The Ala Mhigan Quarter': {'en': 'The Ala Mhigan Quarter',
  'de': 'Mhigisches Wohnviertel',
  'fr': 'Faubourg mhigois',
  'jp': 'アラミガン・クォーター',
  'cn': '阿拉米格人居住区'},
 "Rhalgr's Reach": {'en': "Rhalgr's Reach",
  'de': 'Rhalgrs Wacht',
  'fr': "L'Étendue de Rhalgr",
  'jp': 'ラールガーズリーチ',
  'cn': '神拳痕'},
 'Tamamizu': {'en': 'Tamamizu',
  'de': 'Tamamizu',
  'fr': 'Tamamizu',
  'jp': '碧のタマミズ',
  'cn': '碧玉水'},
 'Onokoro': {'en': 'Onokoro',
  'de': 'Onokoro',
  'fr': 'Onokoro',
  'jp': 'オノコロ島',
  'cn': '自凝岛'},
 'Namai': {'en': 'Namai',
  'de': 'Namai',
  'fr': 'Namai',
  'jp': 'ナマイ村',
  'cn': '茨菰村'},
 'The House of the Fierce': {'en': 'The House of the Fierce',
  'de': 'Klause der Grimmigen',
  'fr': 'Cercle des Intrépides',
  'jp': '烈士庵',
  'cn': '烈士庵'},
 'Reunion': {'en': 'Reunion',
  'de': 'Reunion',
  'fr': 'Ralliement',
  'jp': '再会の市',
  'cn': '重逢集市'},
 'The Dawn Throne': {'en': 'The Dawn Throne',
  'de': 'Morgenthron',
  'fr': "Trône de l'Aube",
  'jp': '明けの玉座',
  'cn': '晨曦王座'},
 'Kugane': {'en': 'Kugane',
  'de': 'Kugane',
  'fr': 'Kugane',
  'jp': 'クガネ',
  'cn': '黄金港'},
 'The Doman Enclave': {'en': 'The Doman Enclave',
  'de': 'Domanische Enklave',
  'fr': 'Quartier enclavé de Doma',
  'jp': 'ドマ町人地',
  'cn': '多玛飞地'},
 'Dhoro Iloh': {'en': 'Dhoro Iloh',
  'de': 'Dhoro Iloh',
  'fr': 'Dhoro Iloh',
  'jp': 'ドーロ・イロー',
  'cn': '朵洛衣楼'},
 'Fort Jobb': {'en': 'Fort Jobb',
  'de': 'Jobb-Feste',
  'fr': 'Fort Jobb',
  'jp': 'ジョッブ砦',
  'cn': '乔布要塞'},
 'The Crystarium': {'en': 'The Crystarium',
  'de': 'Crystarium',
  'fr': 'Cristarium',
  'jp': 'クリスタリウム',
  'cn': '水晶都'},
 'Eulmore': {'en': 'Eulmore',
  'de': 'Eulmore',
  'fr': 'Eulmore',
  'jp': 'ユールモア',
  'cn': '游末邦'},
 'The Ostall Imperative': {'en': 'The Ostall Imperative',
  'de': 'Ostalls Befehl',
  'fr': "L'Impératif d'Ostall",
  'jp': 'オスタル厳命城',
  'cn': '奥斯塔尔严命城'},
 'Stilltide': {'en': 'Stilltide',
  'de': 'Stillwasser',
  'fr': 'Douceonde',
  'jp': 'スティルタイド',
  'cn': '滞潮村'},
 'Wright': {'en': 'Wright',
  'de': 'Werfting',
  'fr': 'Cherpant',
  'jp': 'ライト村',
  'cn': '工匠村'},
 'Tomra': {'en': 'Tomra',
  'de': 'Tomra',
  'fr': 'Tomra',
  'jp': 'トメラの村',
  'cn': '图姆拉村'},
 'Mord Souq': {'en': 'Mord Souq',
  'de': 'Mordh Souq',
  'fr': 'Mord Souq',
  'jp': 'モルド・スーク',
  'cn': '鼹灵集市'},
 'Twine': {'en': 'Twine',
  'de': 'Pfotenschlag',
  'fr': 'Queues-liées',
  'jp': 'トゥワイン',
  'cn': '络尾聚落'},
 'Slitherbough': {'en': 'Slitherbough',
  'de': 'Schlangenzweig',
  'fr': 'Serpentige',
  'jp': 'スリザーバウ',
  'cn': '蛇行枝'},
 'Fanow': {'en': 'Fanow',
  'de': 'Fanow',
  'fr': 'Fanow',
  'jp': 'ファノヴの里',
  'cn': '法诺村'},
 'Lydha Lran': {'en': 'Lydha Lran',
  'de': 'Lydha Lran',
  'fr': 'Lydha Lran',
  'jp': 'リダ・ラーン',
  'cn': '群花馆'},
 'Pla Enni': {'en': 'Pla Enni',
  'de': 'Pla Enni',
  'fr': 'Pla Enni',
  'jp': 'プラ・エンニ茸窟',
  'cn': '普拉恩尼茸洞'},
 'Wolekdorf': {'en': 'Wolekdorf',
  'de': 'Wolekdorf',
  'fr': 'Wolekdorf',
  'jp': 'ヴォレクドルフ',
  'cn': '云村'},
 'The Ondo Cups': {'en': 'The Ondo Cups',
  'de': 'Ondo-Becken',
  'fr': 'Baïne',
  'jp': 'オンドの潮溜まり',
  'cn': '鳍人潮池'},
 'The Macarenses Angle': {'en': 'The Macarenses Angle',
  'de': 'Macarenses-Forum',
  'fr': 'Carré macarien',
  'jp': 'マカレンサス広場',
  'cn': '马克连萨斯广场'},
 "The Inn at Journey's Head": {'en': "The Inn at Journey's Head",
  'de': 'Ruhstatt vor der Langen Reise',
  'fr': 'Auberge du Grand Départ',
  'jp': '旅立ちの宿',
  'cn': '上路客店'},
 'The Archeion': {'en': 'The Archeion',
  'de': 'Archeion',
  'fr': 'Archéion',
  'jp': 'アルケイオン保管院',
  'cn': '公堂保管院'},
 'Sharlayan Hamlet': {'en': 'Sharlayan Hamlet',
  'de': 'Klein-Sharlayan',
  'fr': 'Petite Sharlayan',
  'jp': 'リトルシャーレアン',
  'cn': '小萨雷安'},
 'Aporia': {'en': 'Aporia',
  'de': 'Aporia',
  'fr': "L'Aporie",
  'jp': 'アポリア本部',
  'cn': '无路总部'},
 'Yedlihmad': {'en': 'Yedlihmad',
  'de': 'Yedlihmad',
  'fr': 'Yedlihmad',
  'jp': 'イェドリマン',
  'cn': '新港'},
 'The Great Work': {'en': 'The Great Work',
  'de': 'Daemirs Werk',
  'fr': 'Le Grand Œuvre',
  'jp': 'デミールの遺烈郷',
  'cn': '代米尔遗烈乡'},
 "Palaka's Stand": {'en': "Palaka's Stand",
  'de': 'Palakas Ruh',
  'fr': 'Écoute des Palaka',
  'jp': 'パーラカの里',
  'cn': '波洛伽护法村'},
 'Camp Broken Glass': {'en': 'Camp Broken Glass',
  'de': 'Camp Splitterglas',
  'fr': 'Camp Bris-de-glace',
  'jp': 'キャンプ・ブロークングラス',
  'cn': '碎璃营地'},
 'Tertium': {'en': 'Tertium',
  'de': 'Tertium',
  'fr': 'Gare de Tertium',
  'jp': 'テルティウム駅',
  'cn': '第三站'},
 'Sinus Lacrimarum': {'en': 'Sinus Lacrimarum',
  'de': 'Sinus Lacrimarum',
  'fr': 'Sinus Lacrimarum',
  'jp': '涙の入江',
  'cn': '泪湾'},
 'Bestways Burrow': {'en': 'Bestways Burrow',
  'de': 'Bestways Rast',
  'fr': 'Halot de Bestway',
  'jp': 'ベストウェイ・バロー',
  'cn': '最佳威兔洞'},
 'Anagnorisis': {'en': 'Anagnorisis',
  'de': 'Anagnorisis',
  'fr': "L'Anagnorisis",
  'jp': 'アナグノリシス天測園',
  'cn': '醒悟天测园'},
 'The Twelve Wonders': {'en': 'The Twelve Wonders',
  'de': 'Die Zwölf Gärten',
  'fr': 'Les Douze Merveilles',
  'jp': '十二節の園',
  'cn': '十二奇园'},
 'Poieten Oikos': {'en': 'Poieten Oikos',
  'de': 'Poieten Oikos',
  'fr': 'Poièten Oïkos',
  'jp': 'ポイエテーン・オイコス',
  'cn': '创作者之家'},
 'Reah Tahra': {'en': 'Reah Tahra',
  'de': 'Reah Tahra',
  'fr': 'Reah Tahra',
  'jp': 'リア・ターラ',
  'cn': '半途终旅'},
 'Abode of the Ea': {'en': 'Abode of the Ea',
  'de': 'Domizil der Ea',
  'fr': 'Demeure des Éas',
  'jp': 'イーアの里',
  'cn': '异亚村落'},
 'Base Omicron': {'en': 'Base Omicron',
  'de': 'Omikron-Basis',
  'fr': 'Base des Omicrons',
  'jp': 'オミクロンベース',
  'cn': '奥密克戎基地'},
 'Old Sharlayan': {'en': 'Old Sharlayan',
  'de': 'Alt-Sharlayan',
  'fr': 'Vieille Sharlayan',
  'jp': 'オールド・シャーレアン',
  'cn': '旧萨雷安'},
 'Radz-at-Han': {'en': 'Radz-at-Han',
  'de': 'Radz-at-Han',
  'fr': 'Radz-at-Han',
  'jp': 'ラザハン',
  'cn': '拉札罕'},
  "Wachunpelo": {
    "cn": "ワチュン・ペロ",
    "de": "Wachunpelo",
    "fr": "Wachunpelo",
    "ja": "ワチュン・ペロ",
    "en": "Wachunpelo"
  },
  "Worlar's Echo": {
    "cn": "ウォーラーの残響",
    "de": "Worlars Echo",
    "fr": "L'Écho de Worlar",
    "ja": "ウォーラーの残響",
    "en": "Worlar's Echo"
  },
  "Ok'hanu": {
    "cn": "オック・ハヌ",
    "de": "Ok'hanu",
    "fr": "Ok'hanu",
    "ja": "オック・ハヌ",
    "en": "Ok'hanu"
  },
  "Many Fires": {
    "cn": "朋友の灯火",
    "de": "Viele Flammen",
    "fr": "Maints-Feux",
    "ja": "朋友の灯火",
    "en": "Many Fires"
  },
  "Earthenshire": {
    "cn": "アースンシャイア",
    "de": "Irdehalde",
    "fr": "La Fournée",
    "ja": "アースンシャイア",
    "en": "Earthenshire"
  },
  "Iq Br'aax": {
    "cn": "イクブラーシャ",
    "de": "Iq Br'aax",
    "fr": "Iq Br'aax",
    "ja": "イクブラーシャ",
    "en": "Iq Br'aax"
  },
  "Mamook": {
    "cn": "マムーク",
    "de": "Mamook",
    "fr": "Mamook",
    "ja": "マムーク",
    "en": "Mamook"
  },
  "Hhusatahwi": {
    "cn": "フーサタイ宿場町",
    "de": "Hhusatahwi",
    "fr": "Hhusatahwi",
    "ja": "フーサタイ宿場町",
    "en": "Hhusatahwi"
  },
  "Sheshenewezi Springs": {
    "cn": "シェシェネ青燐泉",
    "de": "Sheshenewezi-Quellen",
    "fr": "Sources de Sheshenewezi",
    "ja": "シェシェネ青燐泉",
    "en": "Sheshenewezi Springs"
  },
  "Mehwahhetsoan": {
    "cn": "メワヘイゾーン",
    "de": "Mehwahhetsoan",
    "fr": "Mehwahhetsoan",
    "ja": "メワヘイゾーン",
    "en": "Mehwahhetsoan"
  },
  "Yyasulani Station": {
    "cn": "ヤースラニ駅",
    "de": "Bahnhof Yyasulani",
    "fr": "Gare de Yyasulani",
    "ja": "ヤースラニ駅",
    "en": "Yyasulani Station"
  },
  "The Outskirts": {
    "cn": "アウトスカーツ",
    "de": "Außenbezirke",
    "fr": "Les Faubourgs",
    "ja": "アウトスカーツ",
    "en": "The Outskirts"
  },
  "Electrope Strike": {
    "cn": "エレクトロープ採石場",
    "de": "Elektrob-Bruch",
    "fr": "Mines d'Électrope",
    "ja": "エレクトロープ採石場",
    "en": "Electrope Strike"
  },
  "Leynode Mnemo": {
    "cn": "レイノード・メモリス",
    "de": "Leyknoten Mnemo",
    "fr": "Nœud tellurique mnémo",
    "ja": "レイノード・メモリス",
    "en": "Leynode Mnemo"
  },
  "Leynode Pyro": {
    "cn": "レイノード・ファイア",
    "de": "Leyknoten Pyro",
    "fr": "Nœud tellurique pyro",
    "ja": "レイノード・ファイア",
    "en": "Leynode Pyro"
  },
  "Leynode Aero": {
    "cn": "レイノード・ウィンド",
    "de": "Leyknoten Aero",
    "fr": "Nœud tellurique aéro",
    "ja": "レイノード・ウィンド",
    "en": "Leynode Aero"
  },
  "Tuliyollal": {
    "cn": "トライヨラ",
    "de": "Tuliyollal",
    "fr": "Tuliyollal",
    "ja": "トライヨラ",
    "en": "Tuliyollal"
  },
  "Solution Nine": {
    "cn": "ソリューション・ナイン",
    "de": "Lösung Neun",
    "fr": "Solution Neuf",
    "ja": "ソリューション・ナイン",
    "en": "Solution Nine"
  }
}

export const patchNameSelector = {
  "ARR": {
    en: "A Realm Reborn",
    de: "A Realm Reborn",
    fr: "A Realm Reborn",
    jp: "A Realm Reborn",
    cn: "重生之境",
  },
  "HW": {
    en: "Heavensward",
    de: "Heavensward",
    fr: "Heavensward",
    jp: "Heavensward",
    cn: "苍穹之禁城",
  },
  "SB": {
    en: "Stormblood",
    de: "Stormblood",
    fr: "Stormblood",
    jp: "Stormblood",
    cn: "红莲之狂潮",
  },
  "ShB": {
    en: "Shadowbringer",
    de: "Shadowbringer",
    fr: "Shadowbringer",
    jp: "Shadowbringer",
    cn: "暗影之逆焰",
  },
  "EW": {
    en: "Endwalker",
    de: "Endwalker",
    fr: "Endwalker",
    jp: "Endwalker",
    cn: "晓月之终途",
  },
  "DT": {
    en: "Dawntrail",
    de: "Dawntrail",
    fr: "Dawntrail",
    jp: "Dawntrail",
    cn: "金曦之遗辉",
  },
};

export const spawnCondition = {
  "Laideronnette": {
    "en": "Automatic spawn after 30 minutes in real life time of continuous rain.",
    "de": "Automatic spawn after 30 minutes in real life time of continuous rain.",
    "fr": "Automatic spawn after 30 minutes in real life time of continuous rain.",
    "jp": "Automatic spawn after 30 minutes in real life time of continuous rain.",
    "cn": "当区域内持续下了30分钟（现实时间）的雨后，由系统自动触发"
  },
  "Wulgaru": {
    "en": "Upon initiation of a Battle Leve. Chance Spawn.",
    "de": "Upon initiation of a Battle Leve. Chance Spawn.",
    "fr": "Upon initiation of a Battle Leve. Chance Spawn.",
    "jp": "Upon initiation of a Battle Leve. Chance Spawn.",
    "cn": "开始战斗理符任务后有低几率触发"
  },
  "Mindflayer": {
    "en": "Walk (or fly) over spawn points during New Moon night (starting at 12am on first night and 5pm on 2nd to 4th night).",
    "de": "Walk (or fly) over spawn points during New Moon night (starting at 12am on first night and 5pm on 2nd to 4th night).",
    "fr": "Walk (or fly) over spawn points during New Moon night (starting at 12am on first night and 5pm on 2nd to 4th night).",
    "jp": "Walk (or fly) over spawn points during New Moon night (starting at 12am on first night and 5pm on 2nd to 4th night).",
    "cn": "在艾欧泽亚时间新月的午夜0点以后的夜晚（第一天是午夜0点开始，第二至第四天是晚5点开始），当玩家走过或飞过触发点的时候触发。"
  },
  "Thousand-cast Theda": {
    "en": "Upon catching Judgeray (!!!) between 5pm EzT to 9pm EzT. Chance Spawn.",
    "de": "Upon catching Judgeray (!!!) between 5pm EzT to 9pm EzT. Chance Spawn.",
    "fr": "Upon catching Judgeray (!!!) between 5pm EzT to 9pm EzT. Chance Spawn.",
    "jp": "Upon catching Judgeray (!!!) between 5pm EzT to 9pm EzT. Chance Spawn.",
    "cn": "在黑衣森林北部林区钓到大鱼（!!!）审判鳐的时候有几率触发。审判鳐只在艾欧泽亚时间晚5点至晚9点能钓到。"
  },
  "Zona Seeker": {
    "en": "Upon catching Glimmerscale (!!!) during Clear Sky or Fair Sky. Chance Spawn.",
    "de": "Upon catching Glimmerscale (!!!) during Clear Sky or Fair Sky. Chance Spawn.",
    "fr": "Upon catching Glimmerscale (!!!) during Clear Sky or Fair Sky. Chance Spawn.",
    "jp": "Upon catching Glimmerscale (!!!) during Clear Sky or Fair Sky. Chance Spawn.",
    "cn": "在西萨纳兰钓到大鱼（!!!）铜镜的时候有几率触发。铜镜只在碧空和晴朗天气时能钓到。"
  },
  "Brontes": {
    "en": "At (or above) spawn point, eat food.",
    "de": "At (or above) spawn point, eat food.",
    "fr": "At (or above) spawn point, eat food.",
    "jp": "At (or above) spawn point, eat food.",
    "cn": "在触发点上（可在飞行状态）吃任何食物后触发。"
  },
  "Lampalagua": {
    "en": "Upon initiation of a Battle Leve. Chance Spawn.",
    "de": "Upon initiation of a Battle Leve. Chance Spawn.",
    "fr": "Upon initiation of a Battle Leve. Chance Spawn.",
    "jp": "Upon initiation of a Battle Leve. Chance Spawn.",
    "cn": "开始战斗理符任务后有低几率触发"
  },
  "Nunyunuwi": {
    "en": "For 60 minutes in real life without a single FATE failed. Timer reset upon one FATE failed (uninitiated FATE count as fail)",
    "de": "For 60 minutes in real life without a single FATE failed. Timer reset upon one FATE failed (uninitiated FATE count as fail)",
    "fr": "For 60 minutes in real life without a single FATE failed. Timer reset upon one FATE failed (uninitiated FATE count as fail)",
    "jp": "For 60 minutes in real life without a single FATE failed. Timer reset upon one FATE failed (uninitiated FATE count as fail)",
    "cn": "在现实时间60分钟内，南萨纳兰没有任何FATE失败之后触发。未开始而消失的FATE也算是失败。"
  },
  "Minhocao": {
    "en": "Defeat 100 Earth Sprites. FATE also count (but not necessarily faster because of Sync)",
    "de": "Defeat 100 Earth Sprites. FATE also count (but not necessarily faster because of Sync)",
    "fr": "Defeat 100 Earth Sprites. FATE also count (but not necessarily faster because of Sync)",
    "jp": "Defeat 100 Earth Sprites. FATE also count (but not necessarily faster because of Sync)",
    "cn": "击杀100只土元精。该区域内的FATE“核心危机”内的土元精也算，单刷约10分钟能完成。"
  },
  "Croque-Mitaine": {
    "en": "Gather Grade 3 La Noscean Topsoil (Lv50 Legendary Mining Node) between 7pm EzT to 10pm EzT",
    "de": "Gather Grade 3 La Noscean Topsoil (Lv50 Legendary Mining Node) between 7pm EzT to 10pm EzT",
    "fr": "Gather Grade 3 La Noscean Topsoil (Lv50 Legendary Mining Node) between 7pm EzT to 10pm EzT",
    "jp": "Gather Grade 3 La Noscean Topsoil (Lv50 Legendary Mining Node) between 7pm EzT to 10pm EzT",
    "cn": "采集3级拉诺西亚土壤（50级未知的石场）后有几率触发。艾欧泽亚时间晚7点至晚10点可以采集，每人每晚仅1次机会。"
  },
  "Croakadile": {
    "en": "Walk (or fly) over spawn points during any of the Full Moon nights (starting at 5pm to second day 3am).",
    "de": "Walk (or fly) over spawn points during any of the Full Moon nights (starting at 5pm to second day 3am).",
    "fr": "Walk (or fly) over spawn points during any of the Full Moon nights (starting at 5pm to second day 3am).",
    "jp": "Walk (or fly) over spawn points during any of the Full Moon nights (starting at 5pm to second day 3am).",
    "cn": "在艾欧泽亚时间满月的夜晚（晚5点至早3点），当玩家走过或飞过触发点的时候触发。"
  },
  "The Garlok": {
    "en": "Automatic spawn after 200 minutes in real life time of no rain.",
    "de": "Automatic spawn after 200 minutes in real life time of no rain.",
    "fr": "Automatic spawn after 200 minutes in real life time of no rain.",
    "jp": "Automatic spawn after 200 minutes in real life time of no rain.",
    "cn": "当区域内连续200分钟（现实时间）没有下雨后，由系统自动触发"
  },
  "Bonnacon": {
    "en": "Gather La Noscean Leek (Lv50 Legendary Botanist Node) between 8am EzT to 11am EzT",
    "de": "Gather La Noscean Leek (Lv50 Legendary Botanist Node) between 8am EzT to 11am EzT",
    "fr": "Gather La Noscean Leek (Lv50 Legendary Botanist Node) between 8am EzT to 11am EzT",
    "jp": "Gather La Noscean Leek (Lv50 Legendary Botanist Node) between 8am EzT to 11am EzT",
    "cn": "采集拉诺西亚韭菜（50级未知的草场）后有几率触发。艾欧泽亚时间早8点至早11点可以采集。"
  },
  "Nandi": {
    "en": "Walk (or fly) over spawn points with any minion.",
    "de": "Walk (or fly) over spawn points with any minion.",
    "fr": "Walk (or fly) over spawn points with any minion.",
    "jp": "Walk (or fly) over spawn points with any minion.",
    "cn": "当玩家带着任意宠物走过或飞过触发点的时候触发。"
  },
  "Chernobog": {
    "en": "Upon dying in map. Chance spawn.",
    "de": "Upon dying in map. Chance spawn.",
    "fr": "Upon dying in map. Chance spawn.",
    "jp": "Upon dying in map. Chance spawn.",
    "cn": "当玩家死亡时有几率触发。蓝魔自爆也算死亡。"
  },
  "Safat": {
    "en": "Upon reaching critical health (1HP) in map. Chance spawn.",
    "de": "Upon reaching critical health (1HP) in map. Chance spawn.",
    "fr": "Upon reaching critical health (1HP) in map. Chance spawn.",
    "jp": "Upon reaching critical health (1HP) in map. Chance spawn.",
    "cn": "当玩家生命垂危时（1HP)有几率触发。"
  },
  "Agrippa the Mighty": {
    "en": "Upon opening Treasure Chest in map. Chance spawn. Alexandrite Map also count.",
    "de": "Upon opening Treasure Chest in map. Chance spawn. Alexandrite Map also count.",
    "fr": "Upon opening Treasure Chest in map. Chance spawn. Alexandrite Map also count.",
    "jp": "Upon opening Treasure Chest in map. Chance spawn. Alexandrite Map also count.",
    "cn": "当玩家打开寻宝宝箱时几率触发。神秘地图也算寻宝地图。"
  },
  "Kaiser Behemoth": {
    "en": "Walk (or fly) over spawn points with Behemoth Heir, minion purchased with 400 Centurio Seals from Ardolain in Foundation (x:12, y:11).",
    "de": "Walk (or fly) over spawn points with Behemoth Heir, minion purchased with 400 Centurio Seals from Ardolain in Foundation (x:12, y:11).",
    "fr": "Walk (or fly) over spawn points with Behemoth Heir, minion purchased with 400 Centurio Seals from Ardolain in Foundation (x:12, y:11).",
    "jp": "Walk (or fly) over spawn points with Behemoth Heir, minion purchased with 400 Centurio Seals from Ardolain in Foundation (x:12, y:11).",
    "cn": "当玩家带着皇家贝希摩斯宝宝(宠物)走过或飞过触发点的时候触发。该宠物是从忘忧骑士亭用400兵团徽章购买。"
  },
  "Senmurv": {
    "en": "Complete the 'Cerf's Up' FATE 5 times in a row. The FATE is blocked by 'El Miacid', 'Elephant in the Brume', or 'Special Tarasque Force'. Clearing those can increase spawn rate of 'Cerf's Up'.",
    "de": "Complete the 'Cerf's Up' FATE 5 times in a row. The FATE is blocked by 'El Miacid', 'Elephant in the Brume', or 'Special Tarasque Force'. Clearing those can increase spawn rate of 'Cerf's Up'.",
    "fr": "Complete the 'Cerf's Up' FATE 5 times in a row. The FATE is blocked by 'El Miacid', 'Elephant in the Brume', or 'Special Tarasque Force'. Clearing those can increase spawn rate of 'Cerf's Up'.",
    "jp": "Complete the 'Cerf's Up' FATE 5 times in a row. The FATE is blocked by 'El Miacid', 'Elephant in the Brume', or 'Special Tarasque Force'. Clearing those can increase spawn rate of 'Cerf's Up'.",
    "cn": "在龙堡参天高地连续完成5次临危受命“龙卵引起的战争”并无失败。"
  },
  "Gandarewa": {
    "en": "Gather Aurum Regis Ore (Lv60 Legendary Mining Node) and Seventh Heaven (Lv60 Legendary Botanist Node) 50 times each between 2am EzT to 6am EzT and 2pm EzT to 6pm EzT",
    "de": "Gather Aurum Regis Ore (Lv60 Legendary Mining Node) and Seventh Heaven (Lv60 Legendary Botanist Node) 50 times each between 2am EzT to 6am EzT and 2pm EzT to 6pm EzT",
    "fr": "Gather Aurum Regis Ore (Lv60 Legendary Mining Node) and Seventh Heaven (Lv60 Legendary Botanist Node) 50 times each between 2am EzT to 6am EzT and 2pm EzT to 6pm EzT",
    "jp": "Gather Aurum Regis Ore (Lv60 Legendary Mining Node) and Seventh Heaven (Lv60 Legendary Botanist Node) 50 times each between 2am EzT to 6am EzT and 2pm EzT to 6pm EzT",
    "cn": "采集皇金矿（60级传说的矿脉，02:00-04:00,14:00-16:00）和星极花（60级传说的草场，04:00-06:00,16:00-18:00）各50次后触发。"
  },
  "The Pale Rider": {
    "en": "Upon opening Treasure Chest in Timeworn Wyvernskin Map Treasure Maps. Chance spawn.",
    "de": "Upon opening Treasure Chest in Timeworn Wyvernskin Map Treasure Maps. Chance spawn.",
    "fr": "Upon opening Treasure Chest in Timeworn Wyvernskin Map Treasure Maps. Chance spawn.",
    "jp": "Upon opening Treasure Chest in Timeworn Wyvernskin Map Treasure Maps. Chance spawn.",
    "cn": "当玩家打开寻宝宝箱时几率触发。"
  },
  "Bird of Paradise": {
    "en": "When B-Rank Squonk cast 'Chirp'. Chance spawn.",
    "de": "When B-Rank Squonk cast 'Chirp'. Chance spawn.",
    "fr": "When B-Rank Squonk cast 'Chirp'. Chance spawn.",
    "jp": "When B-Rank Squonk cast 'Chirp'. Chance spawn.",
    "cn": "当B级狩猎目标斯奎克使用技能“唧唧咋咋”后有几率触发"
  },
  "Leucrotta": {
    "en": "Defeat 50 Allagan Chimera (x:31, y:7), 50 Meracydian Vouivre (x:12, y:38), and 50 Lesser Hydra (x:12, y:35)",
    "de": "Defeat 50 Allagan Chimera (x:31, y:7), 50 Meracydian Vouivre (x:12, y:38), and 50 Lesser Hydra (x:12, y:35)",
    "fr": "Defeat 50 Allagan Chimera (x:31, y:7), 50 Meracydian Vouivre (x:12, y:38), and 50 Lesser Hydra (x:12, y:35)",
    "jp": "Defeat 50 Allagan Chimera (x:31, y:7), 50 Meracydian Vouivre (x:12, y:38), and 50 Lesser Hydra (x:12, y:35)",
    "cn": "击杀50只亚拉戈奇美拉(x:31, y:7), 50只美拉西迪亚薇薇尔飞龙(x:12, y:38), 和50只小海德拉(x:12, y:35)后触发。"
  },
  "Udumbara": {
    "en": "Defeat 100 Leshy (x:11, y:14) and 100 Diakka (x:10, y:29). Diakka also spawn in a FATE but it is generally much slower than killing field mobs. However, it is likely that some FATEs are cleared after timer is ready, so always work on Diakka after Leshy to avoid overkilling.",
    "de": "Defeat 100 Leshy (x:11, y:14) and 100 Diakka (x:10, y:29). Diakka also spawn in a FATE but it is generally much slower than killing field mobs. However, it is likely that some FATEs are cleared after timer is ready, so always work on Diakka after Leshy to avoid overkilling.",
    "fr": "Defeat 100 Leshy (x:11, y:14) and 100 Diakka (x:10, y:29). Diakka also spawn in a FATE but it is generally much slower than killing field mobs. However, it is likely that some FATEs are cleared after timer is ready, so always work on Diakka after Leshy to avoid overkilling.",
    "jp": "Defeat 100 Leshy (x:11, y:14) and 100 Diakka (x:10, y:29). Diakka also spawn in a FATE but it is generally much slower than killing field mobs. However, it is likely that some FATEs are cleared after timer is ready, so always work on Diakka after Leshy to avoid overkilling.",
    "cn": "击杀100只莱西(x:11, y:14), 和100只狄亚卡(x:10, y:29)后触发。因为狄亚卡是本区域内其中一个临危受命的讨伐目标，有几率100只击杀目标在尝试触发前已经达成，所以一般推荐是击杀莱西先。"
  },
  "Bone Crawler": {
    "en": "Spawn when you take Chocobo Taxi between Ala Gannha and Ala Ghiri, message shows up half-way through the map. Chance spawn.",
    "de": "Spawn when you take Chocobo Taxi between Ala Gannha and Ala Ghiri, message shows up half-way through the map. Chance spawn.",
    "fr": "Spawn when you take Chocobo Taxi between Ala Gannha and Ala Ghiri, message shows up half-way through the map. Chance spawn.",
    "jp": "Spawn when you take Chocobo Taxi between Ala Gannha and Ala Ghiri, message shows up half-way through the map. Chance spawn.",
    "cn": "使用陆行鸟运输，当飞行至路程的一半的时候有几率触发。"
  },
  "Salt and Light": {
    "en": "Spawn when discard any item 50 times. Not a stack of 50, but 50 individual discards.",
    "de": "Spawn when discard any item 50 times. Not a stack of 50, but 50 individual discards.",
    "fr": "Spawn when discard any item 50 times. Not a stack of 50, but 50 individual discards.",
    "jp": "Spawn when discard any item 50 times. Not a stack of 50, but 50 individual discards.",
    "cn": "丢弃物品50次（不是50件，而是50次）。"
  },
  "Okina": {
    "en": "Defeat 100 Yumemi and 100 Naked Yumemi (They are spread over 3 underwater cave around (x:25, y:26), (x:21, y:26), (x:15, y:13). There are 2-3 of each type in each cave)",
    "de": "Defeat 100 Yumemi and 100 Naked Yumemi (They are spread over 3 underwater cave around (x:25, y:26), (x:21, y:26), (x:15, y:13). There are 2-3 of each type in each cave)",
    "fr": "Defeat 100 Yumemi and 100 Naked Yumemi (They are spread over 3 underwater cave around (x:25, y:26), (x:21, y:26), (x:15, y:13). There are 2-3 of each type in each cave)",
    "jp": "Defeat 100 Yumemi and 100 Naked Yumemi (They are spread over 3 underwater cave around (x:25, y:26), (x:21, y:26), (x:15, y:13). There are 2-3 of each type in each cave)",
    "cn": "击杀100只观梦螺和100只无壳观梦螺(x:25, y:26), (x:21, y:26), (x:15, y:13)后，在满月时间段触发。"
  },
  "Gamma": {
    "en": "Walk (or fly) over spawn points with Toy Alexander at night (5pm EzT to 8am EzT), minion obtained from Alexander Raid - The Soul of the Creator (A12)",
    "de": "Walk (or fly) over spawn points with Toy Alexander at night (5pm EzT to 8am EzT), minion obtained from Alexander Raid - The Soul of the Creator (A12)",
    "fr": "Walk (or fly) over spawn points with Toy Alexander at night (5pm EzT to 8am EzT), minion obtained from Alexander Raid - The Soul of the Creator (A12)",
    "jp": "Walk (or fly) over spawn points with Toy Alexander at night (5pm EzT to 8am EzT), minion obtained from Alexander Raid - The Soul of the Creator (A12)",
    "cn": "在艾欧泽亚时间晚5点至早9点的时候，当玩家带着迷你亚历山大(宠物)走过或飞过触发点的时候触发。该宠物是从亚历山大机神城天动之章4奖励箱子掉落。"
  },
  "Orghana": {
    "en": "Complete the 'Not Just A Tribute' FATE 1 time then fly over the spawn points. 'Not Just A Tribute' doesn't have to be cleared after Timer is ready, as long as the previous Tribute had not failed.",
    "de": "Complete the 'Not Just A Tribute' FATE 1 time then fly over the spawn points. 'Not Just A Tribute' doesn't have to be cleared after Timer is ready, as long as the previous Tribute had not failed.",
    "fr": "Complete the 'Not Just A Tribute' FATE 1 time then fly over the spawn points. 'Not Just A Tribute' doesn't have to be cleared after Timer is ready, as long as the previous Tribute had not failed.",
    "jp": "Complete the 'Not Just A Tribute' FATE 1 time then fly over the spawn points. 'Not Just A Tribute' doesn't have to be cleared after Timer is ready, as long as the previous Tribute had not failed.",
    "cn": "完成临危受命“石人英雄——格尔该朝鲁”后，从触发点上走过或飞过后触发。触发者本身不需要完成该临危受命，只要上一个“石人英雄——格尔该朝鲁”并未失败，就可以触发。"
  },
  "Tyger": {
    "en": "Spawn when discard a 'Rail Tenderloin' (drop from Kholusia, or gemstone merchant).",
    "de": "Spawn when discard a 'Rail Tenderloin' (drop from Kholusia, or gemstone merchant).",
    "fr": "Spawn when discard a 'Rail Tenderloin' (drop from Kholusia, or gemstone merchant).",
    "jp": "Spawn when discard a 'Rail Tenderloin' (drop from Kholusia, or gemstone merchant).",
    "cn": "丢弃一个秧鸡胸脯肉后触发。"
  },
  "Forgiven Pedantry": {
    "en": "Gather Dwarven Cotton Bolls. Very low chance spawn (rumor that it takes 50 gathering attempt to be count as one spawn attempt).",
    "de": "Gather Dwarven Cotton Bolls. Very low chance spawn (rumor that it takes 50 gathering attempt to be count as one spawn attempt).",
    "fr": "Gather Dwarven Cotton Bolls. Very low chance spawn (rumor that it takes 50 gathering attempt to be count as one spawn attempt).",
    "jp": "Gather Dwarven Cotton Bolls. Very low chance spawn (rumor that it takes 50 gathering attempt to be count as one spawn attempt).",
    "cn": "图内矮人棉采集次数到达50次触发"
  },
  "Tarchia": {
    "en": "Blue Mage Skill #21 'Self-Destruction' at spawn point. Chance spawn.",
    "de": "Blue Mage Skill #21 'Self-Destruction' at spawn point. Chance spawn.",
    "fr": "Blue Mage Skill #21 'Self-Destruction' at spawn point. Chance spawn.",
    "jp": "Blue Mage Skill #21 'Self-Destruction' at spawn point. Chance spawn.",
    "cn": "在触发点上使用青魔法师技能21：“自爆”后触发"
  },
  "Aglaope": {
    "en": "Walk (or fly) over spawn points with Scarlet Peacock, minion obtained from crafting using materials drop from Extreme Trial 'Hell's Kier'",
    "de": "Walk (or fly) over spawn points with Scarlet Peacock, minion obtained from crafting using materials drop from Extreme Trial 'Hell's Kier'",
    "fr": "Walk (or fly) over spawn points with Scarlet Peacock, minion obtained from crafting using materials drop from Extreme Trial 'Hell's Kier'",
    "jp": "Walk (or fly) over spawn points with Scarlet Peacock, minion obtained from crafting using materials drop from Extreme Trial 'Hell's Kier'",
    "cn": "当玩家带着朱孔雀(宠物)走过或飞过触发点的时候触发。该宠物是用从朱雀诗魂战掉落的材料制作（70级配方）。可交易可购买。"
  },
  "Ixtab": {
    "en": "Defeat 100 Cracked Ronkan Dolls, 100 Cracked Ronkan Thorn, and 100 Cracked Ronkan Vessel (They are all in the island at x:25, y:12)",
    "de": "Defeat 100 Cracked Ronkan Dolls, 100 Cracked Ronkan Thorn, and 100 Cracked Ronkan Vessel (They are all in the island at x:25, y:12)",
    "fr": "Defeat 100 Cracked Ronkan Dolls, 100 Cracked Ronkan Thorn, and 100 Cracked Ronkan Vessel (They are all in the island at x:25, y:12)",
    "jp": "Defeat 100 Cracked Ronkan Dolls, 100 Cracked Ronkan Thorn, and 100 Cracked Ronkan Vessel (They are all in the island at x:25, y:12)",
    "cn": "击杀100只破裂的隆卡石蒺藜, 100只破裂的隆卡人偶, 和100只破裂的隆卡器皿后触发。三种敌人都可在（x:25, y:12）的岛上找到。"
  },
  "Gunitt": {
    "en": "When Clionid (x:26, y:6) cast 'Buccal Cones'. Clionid cast 'Buccal Cones' after devour 3 Deep-sea Leeches neearby. Chance spawn.",
    "de": "When Clionid (x:26, y:6) cast 'Buccal Cones'. Clionid cast 'Buccal Cones' after devour 3 Deep-sea Leeches neearby. Chance spawn.",
    "fr": "When Clionid (x:26, y:6) cast 'Buccal Cones'. Clionid cast 'Buccal Cones' after devour 3 Deep-sea Leeches neearby. Chance spawn.",
    "jp": "When Clionid (x:26, y:6) cast 'Buccal Cones'. Clionid cast 'Buccal Cones' after devour 3 Deep-sea Leeches neearby. Chance spawn.",
    "cn": "当冰海天使(x:26, y:6)使用技能“口锥”后触发。该技能只在冰海天使吸收三只深海水蛭后使用。"
  },
  "Burfurlur the Canny": {
    "en": "During Day time (9am-5pm) walk (or fly) over spawn point with Tiny Troll minion, obtained from Lv89 dungeon. Require Clear Sky or Fair Sky.",
    "de": "During Day time (9am-5pm) walk (or fly) over spawn point with Tiny Troll minion, obtained from Lv89 dungeon. Require Clear Sky or Fair Sky.",
    "fr": "During Day time (9am-5pm) walk (or fly) over spawn point with Tiny Troll minion, obtained from Lv89 dungeon. Require Clear Sky or Fair Sky.",
    "jp": "During Day time (9am-5pm) walk (or fly) over spawn point with Tiny Troll minion, obtained from Lv89 dungeon. Require Clear Sky or Fair Sky.",
    "cn": "在艾欧泽亚时间早9点至晚5点的碧空或晴朗天气的时候，当玩家带着小巨魔(宠物)走过或飞过触发点的时候触发。该宠物是从89级副本最后奖励箱子掉落。"
  },
  "Sphatika": {
    "en": "Defeat 100 Asvattha, 100 Pisaca, and 100 Vajralangula.",
    "de": "Defeat 100 Asvattha, 100 Pisaca, and 100 Vajralangula.",
    "fr": "Defeat 100 Asvattha, 100 Pisaca, and 100 Vajralangula.",
    "jp": "Defeat 100 Asvattha, 100 Pisaca, and 100 Vajralangula.",
    "cn": "击杀100只魔界花，100只毕舍遮，和100只金刚尾后触发。"
  },
  "Armstrong": {
    "en": "Upon dying at spawn point while wearing 'Mended Imperial Pot Helm' and 'Mended Imperial Short Robe'. These are from ARR Main Scenario Quest, reclaimed from Calamity Salvagers.",
    "de": "Upon dying at spawn point while wearing 'Mended Imperial Pot Helm' and 'Mended Imperial Short Robe'. These are from ARR Main Scenario Quest, reclaimed from Calamity Salvagers.",
    "fr": "Upon dying at spawn point while wearing 'Mended Imperial Pot Helm' and 'Mended Imperial Short Robe'. These are from ARR Main Scenario Quest, reclaimed from Calamity Salvagers.",
    "jp": "Upon dying at spawn point while wearing 'Mended Imperial Pot Helm' and 'Mended Imperial Short Robe'. These are from ARR Main Scenario Quest, reclaimed from Calamity Salvagers.",
    "cn": "身穿“修好的帝国盔帽”和“修好的帝国短袍”后，在触发点上死亡（可自杀）后触发。这些装备不需要实际装备，可以使用投影装备。"
  },
  "Ruminator": {
    "en": "Defeat 100 Thinkers (x:19, y:22, 9 per loop), 100 Wanderers (x:27, y:26, 15 per loop), and 100 Weepers (x:31, y:32, 8 per loop).",
    "de": "Defeat 100 Thinkers (x:19, y:22, 9 per loop), 100 Wanderers (x:27, y:26, 15 per loop), and 100 Weepers (x:31, y:32, 8 per loop).",
    "fr": "Defeat 100 Thinkers (x:19, y:22, 9 per loop), 100 Wanderers (x:27, y:26, 15 per loop), and 100 Weepers (x:31, y:32, 8 per loop).",
    "jp": "Defeat 100 Thinkers (x:19, y:22, 9 per loop), 100 Wanderers (x:27, y:26, 15 per loop), and 100 Weepers (x:31, y:32, 8 per loop).",
    "cn": "击杀100只思考之物，100只彷徨之物，和100只叹息之物后触发。"
  },
  "Ophioneus": {
    "en": "Spawn when discard a stack of 5 'Eggs of Elpis' (drop from Bird of Elpis (x:28, y:23), gemstone merchant, or Lv86 Egg of Elpis Battlecraft Retainer Venture [iLvl 519]).",
    "de": "Spawn when discard a stack of 5 'Eggs of Elpis' (drop from Bird of Elpis (x:28, y:23), gemstone merchant, or Lv86 Egg of Elpis Battlecraft Retainer Venture [iLvl 519]).",
    "fr": "Spawn when discard a stack of 5 'Eggs of Elpis' (drop from Bird of Elpis (x:28, y:23), gemstone merchant, or Lv86 Egg of Elpis Battlecraft Retainer Venture [iLvl 519]).",
    "jp": "Spawn when discard a stack of 5 'Eggs of Elpis' (drop from Bird of Elpis (x:28, y:23), gemstone merchant, or Lv86 Egg of Elpis Battlecraft Retainer Venture [iLvl 519]).",
    "cn": "一次性丢弃5个“厄尔庇斯蛋”后触发。"
  },
  "Narrow-rift": {
    "en": "Walk (or fly) over spawn points with 10 Wee Ea (require 10 players), minion obtained from Rank 3 Share FATE at Ultima Thule, purchased with 500 gemstones",
    "de": "Walk (or fly) over spawn points with 10 Wee Ea (require 10 players), minion obtained from Rank 3 Share FATE at Ultima Thule, purchased with 500 gemstones",
    "fr": "Walk (or fly) over spawn points with 10 Wee Ea (require 10 players), minion obtained from Rank 3 Share FATE at Ultima Thule, purchased with 500 gemstones",
    "jp": "Walk (or fly) over spawn points with 10 Wee Ea (require 10 players), minion obtained from Rank 3 Share FATE at Ultima Thule, purchased with 500 gemstones",
    "cn": "当最少10名玩家带着10只小异亚(宠物)走过或飞过触发点的时候触发。该宠物是从天外天垓处NPC用500双色宝石购买（要求危命任务完成度3）。"
  },
  "Kirlirger the Abhorrent": {
    "en": "Walk (or fly) over spawn points during the New Moon nights (starting at 5pm to second day 9am) with Fog weather.",
    "de": "Walk (or fly) over spawn points during the New Moon nights (starting at 5pm to second day 9am) with Fog weather.",
    "fr": "Walk (or fly) over spawn points during the New Moon nights (starting at 5pm to second day 9am) with Fog weather.",
    "jp": "Walk (or fly) over spawn points during the New Moon nights (starting at 5pm to second day 9am) with Fog weather.",
    "cn": "在艾欧泽亚时间新月的夜晚（晚0点至早8点）并且天气为大雾时，当玩家走过或飞过触发点的时候触发。"
  },
  "Ihnuxokiy": {
    "en": "Walk (or fly) over spawn points with Morpho Minion, minion obtained from Lv60 dungeon: The Lost City of Amdapor (Hard)",
    "de": "Walk (or fly) over spawn points with Morpho Minion, minion obtained from Lv60 dungeon: The Lost City of Amdapor (Hard)",
    "fr": "Walk (or fly) over spawn points with Morpho Minion, minion obtained from Lv60 dungeon: The Lost City of Amdapor (Hard)",
    "jp": "Walk (or fly) over spawn points with Morpho Minion, minion obtained from Lv60 dungeon: The Lost City of Amdapor (Hard)",
    "cn": "当玩家带着蓝闪蝶(宠物)走过或飞过触发点的时候触发。该宠物是从60级副本掉落。"
  },
  "Neyoozoteel": {
    "en": "Discard Stack of 50 \"Fishmeal\". (Sold for 21 Gil in Merchant)",
    "de": "Discard Stack of 50 \"Fishmeal\". (Sold for 21 Gil in Merchant)",
    "fr": "Discard Stack of 50 \"Fishmeal\". (Sold for 21 Gil in Merchant)",
    "jp": "Discard Stack of 50 \"Fishmeal\". (Sold for 21 Gil in Merchant)",
    "cn": "一次性舍弃50个(或以上)道具“鱼粉”(可以在NPC处以21金币购买：也可以自己搓，3条罗敏萨鳀鱼和1个火之碎晶可以搓12个粉)"
  },
  "Sansheya": {
    "en": "Complete FATE \"You Are What You Drink\" (I will add FATE Tracker for this later)",
    "de": "Complete FATE \"You Are What You Drink\" (I will add FATE Tracker for this later)",
    "fr": "Complete FATE \"You Are What You Drink\" (I will add FATE Tracker for this later)",
    "jp": "Complete FATE \"You Are What You Drink\" (I will add FATE Tracker for this later)",
    "cn": "完成位于(14.0，23.5)的FATE “You Are What You Drink”"
  },
  "Atticus the Primogenitor": {
    "en": "Cook \"Rroneek Steak\" High Quality (Lv99) anywhere in the map.",
    "de": "Cook \"Rroneek Steak\" High Quality (Lv99) anywhere in the map.",
    "fr": "Cook \"Rroneek Steak\" High Quality (Lv99) anywhere in the map.",
    "jp": "Cook \"Rroneek Steak\" High Quality (Lv99) anywhere in the map.",
    "cn": "在本图任意地点(不一定要在触发点上)使用职业“烹调师”完成道具“Rroneek Steak”的HQ制作(99级厨子的配方)"
  },
  "The Forecaster": {
    "en": "Cast BLU Spell \"Norterlies\" (#52, Obtained from Coerthas Western Highlands) on spawn point.",
    "de": "Cast BLU Spell \"Norterlies\" (#52, Obtained from Coerthas Western Highlands) on spawn point.",
    "fr": "Cast BLU Spell \"Norterlies\" (#52, Obtained from Coerthas Western Highlands) on spawn point.",
    "jp": "Cast BLU Spell \"Norterlies\" (#52, Obtained from Coerthas Western Highlands) on spawn point.",
    "cn": "使用职业“青魔”在触发点使用技能“狂风暴雪”(52号技能)"
  },
  "FATE Odin": {
    "en": "You will see the weather turns Tension. Fun fact, Odin will have the appearance of who last kill him.",
    "de": "You will see the weather turns Tension. Fun fact, Odin will have the appearance of who last kill him.",
    "fr": "You will see the weather turns Tension. Fun fact, Odin will have the appearance of who last kill him.",
    "jp": "You will see the weather turns Tension. Fun fact, Odin will have the appearance of who last kill him.",
    "cn": "You will see the weather turns Tension. Fun fact, Odin will have the appearance of who last kill him."
  },
  "FATE Behemoth": {
    "en": "Behemoth comes with 2-Part FATE Behold Now Behemoth (tailing Behemoth) and He Taketh It with His Eyes (Boss FATE)",
    "de": "Behemoth comes with 2-Part FATE Behold Now Behemoth (tailing Behemoth) and He Taketh It with His Eyes (Boss FATE)",
    "fr": "Behemoth comes with 2-Part FATE Behold Now Behemoth (tailing Behemoth) and He Taketh It with His Eyes (Boss FATE)",
    "jp": "Behemoth comes with 2-Part FATE Behold Now Behemoth (tailing Behemoth) and He Taketh It with His Eyes (Boss FATE)",
    "cn": "Behemoth comes with 2-Part FATE Behold Now Behemoth (tailing Behemoth) and He Taketh It with His Eyes (Boss FATE)"
  },
  "FATE Coeurlregina": {
    "en": "Coeurlregina spawned during Royal Levin weather, with a 3-Part FATE. The first FATE (reward 1 Horn) is on 12 hours timer, and the 2nd (reward 3 Horns) and 3rd (reward 5 Horns) FATE are 4 hours after last FATE finished.",
    "de": "Coeurlregina spawned during Royal Levin weather, with a 3-Part FATE. The first FATE (reward 1 Horn) is on 12 hours timer, and the 2nd (reward 3 Horns) and 3rd (reward 5 Horns) FATE are 4 hours after last FATE finished.",
    "fr": "Coeurlregina spawned during Royal Levin weather, with a 3-Part FATE. The first FATE (reward 1 Horn) is on 12 hours timer, and the 2nd (reward 3 Horns) and 3rd (reward 5 Horns) FATE are 4 hours after last FATE finished.",
    "jp": "Coeurlregina spawned during Royal Levin weather, with a 3-Part FATE. The first FATE (reward 1 Horn) is on 12 hours timer, and the 2nd (reward 3 Horns) and 3rd (reward 5 Horns) FATE are 4 hours after last FATE finished.",
    "cn": "Coeurlregina spawned during Royal Levin weather, with a 3-Part FATE. The first FATE (reward 1 Horn) is on 12 hours timer, and the 2nd (reward 3 Horns) and 3rd (reward 5 Horns) FATE are 4 hours after last FATE finished."
  },
  "FATE Noctilucale": {
    "en": "Noctilucale spawned AFTER Hyperelectricity weather, which can interrupt any weathers so it is not in the standard 8 hours weather loop.",
    "de": "Noctilucale spawned AFTER Hyperelectricity weather, which can interrupt any weathers so it is not in the standard 8 hours weather loop.",
    "fr": "Noctilucale spawned AFTER Hyperelectricity weather, which can interrupt any weathers so it is not in the standard 8 hours weather loop.",
    "jp": "Noctilucale spawned AFTER Hyperelectricity weather, which can interrupt any weathers so it is not in the standard 8 hours weather loop.",
    "cn": "Noctilucale spawned AFTER Hyperelectricity weather, which can interrupt any weathers so it is not in the standard 8 hours weather loop."
  },
  "FATE Foxy Lady": {
    "en": "Chance to spawn random amount of time after Outfoxed is done (Chained by Kinko and Ginko FATEs), given the random timer is ready. When Foxy Lady spawns, the Namai Elder will appear at the broken Shrine.",
    "de": "Chance to spawn random amount of time after Outfoxed is done (Chained by Kinko and Ginko FATEs), given the random timer is ready. When Foxy Lady spawns, the Namai Elder will appear at the broken Shrine.",
    "fr": "Chance to spawn random amount of time after Outfoxed is done (Chained by Kinko and Ginko FATEs), given the random timer is ready. When Foxy Lady spawns, the Namai Elder will appear at the broken Shrine.",
    "jp": "Chance to spawn random amount of time after Outfoxed is done (Chained by Kinko and Ginko FATEs), given the random timer is ready. When Foxy Lady spawns, the Namai Elder will appear at the broken Shrine.",
    "cn": "Chance to spawn random amount of time after Outfoxed is done (Chained by Kinko and Ginko FATEs), given the random timer is ready. When Foxy Lady spawns, the Namai Elder will appear at the broken Shrine."
  },
  "FATE Ixion": {
    "en": "Spawn when weather is Quick Levin. It is important that you need Stygian Ash to remove Ixion's Invulnerability. You can buy it off marketboard or just do map in the region.",
    "de": "Spawn when weather is Quick Levin. It is important that you need Stygian Ash to remove Ixion's Invulnerability. You can buy it off marketboard or just do map in the region.",
    "fr": "Spawn when weather is Quick Levin. It is important that you need Stygian Ash to remove Ixion's Invulnerability. You can buy it off marketboard or just do map in the region.",
    "jp": "Spawn when weather is Quick Levin. It is important that you need Stygian Ash to remove Ixion's Invulnerability. You can buy it off marketboard or just do map in the region.",
    "cn": "Spawn when weather is Quick Levin. It is important that you need Stygian Ash to remove Ixion's Invulnerability. You can buy it off marketboard or just do map in the region."
  },
  "FATE Formidable": {
    "en": "Formidable is spawned, if the timer is ready, after completing a 5-Part FATE Chain: Ironbeard-Buliders (Resist, Revolt and Rebuilt) and Most Formidable (Foes, Family, then finally, the Finale).",
    "de": "Formidable is spawned, if the timer is ready, after completing a 5-Part FATE Chain: Ironbeard-Buliders (Resist, Revolt and Rebuilt) and Most Formidable (Foes, Family, then finally, the Finale).",
    "fr": "Formidable is spawned, if the timer is ready, after completing a 5-Part FATE Chain: Ironbeard-Buliders (Resist, Revolt and Rebuilt) and Most Formidable (Foes, Family, then finally, the Finale).",
    "jp": "Formidable is spawned, if the timer is ready, after completing a 5-Part FATE Chain: Ironbeard-Buliders (Resist, Revolt and Rebuilt) and Most Formidable (Foes, Family, then finally, the Finale).",
    "cn": "Formidable is spawned, if the timer is ready, after completing a 5-Part FATE Chain: Ironbeard-Buliders (Resist, Revolt and Rebuilt) and Most Formidable (Foes, Family, then finally, the Finale)."
  },
  "FATE Archaeotania": {
    "en": "Archaeotania is spawned, if the timer is ready, after completing a 6-Part FATE Chain: Where Has the Dagon (x:23.2, y:22.8), Ondo of Blood (x:28.8, y:12.6), Lookin' Back on the Track (x:24.2, y:17.9), Coral Support (x:35.3, y:9.5) (x:36.1, y:5.3), Low Coral Fiber, and Nothing Like A Trappin' Life (x:26.9, y:24.7).",
    "de": "Archaeotania is spawned, if the timer is ready, after completing a 6-Part FATE Chain: Where Has the Dagon (x:23.2, y:22.8), Ondo of Blood (x:28.8, y:12.6), Lookin' Back on the Track (x:24.2, y:17.9), Coral Support (x:35.3, y:9.5) (x:36.1, y:5.3), Low Coral Fiber, and Nothing Like A Trappin' Life (x:26.9, y:24.7).",
    "fr": "Archaeotania is spawned, if the timer is ready, after completing a 6-Part FATE Chain: Where Has the Dagon (x:23.2, y:22.8), Ondo of Blood (x:28.8, y:12.6), Lookin' Back on the Track (x:24.2, y:17.9), Coral Support (x:35.3, y:9.5) (x:36.1, y:5.3), Low Coral Fiber, and Nothing Like A Trappin' Life (x:26.9, y:24.7).",
    "jp": "Archaeotania is spawned, if the timer is ready, after completing a 6-Part FATE Chain: Where Has the Dagon (x:23.2, y:22.8), Ondo of Blood (x:28.8, y:12.6), Lookin' Back on the Track (x:24.2, y:17.9), Coral Support (x:35.3, y:9.5) (x:36.1, y:5.3), Low Coral Fiber, and Nothing Like A Trappin' Life (x:26.9, y:24.7).",
    "cn": "Archaeotania is spawned, if the timer is ready, after completing a 6-Part FATE Chain: Where Has the Dagon (x:23.2, y:22.8), Ondo of Blood (x:28.8, y:12.6), Lookin' Back on the Track (x:24.2, y:17.9), Coral Support (x:35.3, y:9.5) (x:36.1, y:5.3), Low Coral Fiber, and Nothing Like A Trappin' Life (x:26.9, y:24.7)."
  },
  "FATE Daivadipa": {
    "en": "The Daivadipa FATE spawn after completing the following FATE Chain: 1) Devout Pilgrims vs. the Jungle Lizards (x23.2, y22.8) and 2) Devout Pilgrims vs. the Apalala (x33.8, y27.8), if the random timer is ready.",
    "de": "The Daivadipa FATE spawn after completing the following FATE Chain: 1) Devout Pilgrims vs. the Jungle Lizards (x23.2, y22.8) and 2) Devout Pilgrims vs. the Apalala (x33.8, y27.8), if the random timer is ready.",
    "fr": "The Daivadipa FATE spawn after completing the following FATE Chain: 1) Devout Pilgrims vs. the Jungle Lizards (x23.2, y22.8) and 2) Devout Pilgrims vs. the Apalala (x33.8, y27.8), if the random timer is ready.",
    "jp": "The Daivadipa FATE spawn after completing the following FATE Chain: 1) Devout Pilgrims vs. the Jungle Lizards (x23.2, y22.8) and 2) Devout Pilgrims vs. the Apalala (x33.8, y27.8), if the random timer is ready.",
    "cn": "The Daivadipa FATE spawn after completing the following FATE Chain: 1) Devout Pilgrims vs. the Jungle Lizards (x23.2, y22.8) and 2) Devout Pilgrims vs. the Apalala (x33.8, y27.8), if the random timer is ready."
  },
  "FATE Chi": {
    "en": "The Chi FATE is on random timer, spawned by completing other Omicron related FATEs",
    "de": "The Chi FATE is on random timer, spawned by completing other Omicron related FATEs",
    "fr": "The Chi FATE is on random timer, spawned by completing other Omicron related FATEs",
    "jp": "The Chi FATE is on random timer, spawned by completing other Omicron related FATEs",
    "cn": "The Chi FATE is on random timer, spawned by completing other Omicron related FATEs"
  },
  "FATE Ttokrrone": {
    "en": "?????",
    "de": "?????",
    "fr": "?????",
    "jp": "?????",
    "cn": "?????"
  },
  "FATE Mascot": {
    "en": "?????",
    "de": "?????",
    "fr": "?????",
    "jp": "?????",
    "cn": "?????"
  }
};

export const guideSelector = {
  "timerNameHeader": {
    "en": "Click on Hunt Icon to bring up spawn condition information.",
    "cn": "点击此处可查看触发条件"
  },
  "timerRegionHeader": {
    "en": "Click on Map Icon to bring up spawn point map.",
    "cn": "点击此处可以查看狩猎点位"
  },
  "timerTimerHeader1": {
    "en": "Spawn Timer measured in 3 modes:",
    "cn": "触发时间分为3种状态："
  },
  "timerTimerHeader2": {
    "en": "Time until spawn window open (Hours:Minutes)",
    "cn": "红：不可触发期（为倒计时）"
  },
  "timerTimerHeader3": {
    "en": "Time since spawn window open (Hours:Minutes)",
    "cn": "绿：可触发期（为正计时，百分比为进度而非概率）"
  },
  "timerTimerHeader4": {
    "en": "Time since spawn window open, and is capped (Hours:Minutes)",
    "cn": "蓝：强制期（为正计时，绝大部分S在此时间内必定可触发）"
  },
  "timerSecondaryTimerHeader1": {
    "en": "Condition Timer:",
    "cn": "条件时间：部分S有特殊的要求，如月相、天气等。"
  },
  "timerSecondaryTimerHeader2": {
    "en": "Red: Condition is not met",
    "cn": "红：未满足触发条件（为倒计时）"
  },
  "timerSecondaryTimerHeader3": {
    "en": "Green: Condition is met",
    "cn": "绿：满足了触发条件（为倒计时）"
  },
  "timerSecondaryTimerHeader4": {
    "en": "FATE Related Timers:",
    "cn": "FATE相关计时："
  },
  "timerSecondaryTimerHeader6": {
    "en": "Red: Since last FATE Completion",
    "cn": "红：距离上次死亡已过时间"
  },
  "timerSecondaryTimerHeader5": {
    "en": "Green: Since last FATE seen",
    "cn": "绿：距离最近发现时间"
  },
  "timerSecondaryTimerHeader7": {
    "en": "Click to pull up last 6 FATE Status",
    "cn": "左键点击显示过去6个FATE的完成情况"
  },
  "selectWorldPatchInfo1": {
    "en": "Left Click to toggle ON/OFF",
    "cn": "左键单击开启/关闭选项"
  },
  "selectWorldPatchInfo2": {
    "en": "Right Click to select single or all",
    "cn": "右键可以单选或全选"
  },
  "huntInfoHistory1": {
    "en": "Click \"History Search\" will pull up last 20 known death reports.",
    "cn": "点击“检索历史记录”会显示过去20次死亡记录"
  },
  "huntInfoHistory2": {
    "en": "Click \"Revert\" will report error in records.",
    "cn": "点击”报错“会申请撤销错误记录"
  },
  "spawnMapControl1": {
    "en": "Green Dots indicate Not Mapped spots. Red Dots indicate Mapped spots.",
    "cn": "绿点为未排点，红点未已排点。假如全地图只有一个蓝点，那个蓝点就是最终触发点。"
  },
  "spawnMapControl2": {
    "en": "Hover over red dots will display date and player that reported the mapped spot.",
    "cn": "鼠标移至红点会显示排点的玩家名与时间"
  },
  "spawnMapControl3": {
    "en": "Click on a map spot will toggle state between Mapped/Not Mapped, unless it is marked as \"Verified\"",
    "cn": "点击触发点会切换排点状态，除非排点被标记为（已验证）"
  },
  "trainToolGuide1": {
    "en": "Click on map icon on left of the A ranks to bring up spawn point map. Clicking Blue Point will mark that location as Found location.",
    "cn": "点击A怪名字左边的地图会打开触发点地图。点击蓝色触发点会标识该点为发现地点。"
  },
  "trainToolGuide2": {
    "en": "Right click on Flagged A rank will remove the flag",
    "cn": "右键标识红旗会移除红旗。"
  },
}