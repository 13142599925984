import { dataCenters } from "sessions/resources/datacenters";
import { huntInfos } from "sessions/resources/hunts";
import { BossFATEs } from "sessions/resources/boss-fate";
import { spawnPoints } from "sessions/resources/spawnpoints";

import { 
    predictNextBonnacon, 
    predictNextBurfurlur, 
    predictNextCroakadile, 
    predictNextCroqueMitaine,
    predictNextGamma, 
    predictNextGandarewa, 
    predictNextGarlok, 
    predictNextLaideronnette, 
    predictNextMindflayer, 
    predictNextOkina, 
    predictNextTheda, 
    predictNextZonaSeeker 
} from "./spawn-predictors";

export const getDataCenterName = (world) => {
    if (["Adamantoise","Cactuar","Faerie","Gilgamesh","Jenova","Midgardsormr","Sargatanas","Siren"].includes(world)) {
        return "Aether";
    } else if (["Balmung","Brynhildr","Coeurl","Diabolos","Goblin","Malboro","Mateus","Zalera"].includes(world)) {
        return "Crystal";
    } else if (["Cuchulainn","Golem","Kraken","Halicarnassus","Maduin","Marilith","Rafflesia","Seraph"].includes(world)) {
        return "Dynamis";
    } else if (["Behemoth","Excalibur","Exodus","Famfrit","Hyperion","Lamia","Leviathan","Ultros"].includes(world)) {
        return "Primal";
    } else if (["Cerberus","Louisoix","Moogle","Omega","Phantom","Ragnarok","Sagittarius","Spriggan"].includes(world)) {
        return "Chaos";
    } else if (["Alpha","Lich","Odin","Phoenix","Raiden","Shiva","Twintania","Zodiark"].includes(world)) {
        return "Light";
    } else if (["Innocence","Pixie","Titania","Tycoon"].includes(world)) {
        return "Shadow";
    } else if (["Bismarck","Ravana","Sephirot","Sophia","Zurvan"].includes(world)) {
        return "Materia";
    } else if (["Aegis","Atomos","Carbuncle","Garuda","Gungnir","Kujata","Tonberry","Typhon"].includes(world)) {
        return "Elemental";
    } else if (["Alexander","Bahamut","Durandal","Fenrir","Ifrit","Ridill","Tiamat","Ultima"].includes(world)) {
        return "Gaia";
    } else if (["Anima","Asura","Chocobo","Hades","Ixion","Masamune","Pandaemonium","Titan"].includes(world)) {
        return "Mana";
    } else if (["Belias","Mandragora","Ramuh","Shinryu","Unicorn","Valefor","Yojimbo","Zeromus"].includes(world)) {
        return "Meteor";
    } else if (["红玉海","神意之地","拉诺西亚","幻影群岛","萌芽池","宇宙和音","沃仙曦染","晨曦王座"].includes(world)) {
        return "陆行鸟";
    } else if (["水晶塔","银泪湖","太阳海岸","伊修加德","红茶川"].includes(world)) {
        return "豆豆柴";
    } else if (["白银乡","白金幻象","神拳痕","潮风亭","旅人栈桥","拂晓之间","龙巢神殿","梦羽宝境"].includes(world)) {
        return "莫古力";
    } else if (["紫水栈桥","延夏","静语庄园","摩杜纳","海猫茶屋","柔风海湾","琥珀原"].includes(world)) {
        return "猫小胖";
    }
    return "";
};

export const extractWorldInfo = (datacenter) => {
    if (datacenter == "cnServer") {
        var worldInfo = {"Names": [], "Abbreviations": []};
        for (let datacenterName of ["豆豆柴","莫古力","陆行鸟","猫小胖"]) {
            worldInfo["Names"].push(...dataCenters[datacenterName]["Names"]);
        }
        return worldInfo;
    } else if (datacenter == "All") {
        var worldInfo = {"Names": [], "Abbreviations": []};
        for (let datacenterName of ["Aether","Primal","Crystal","Dynamis","Light","Chaos","Shadow","Materia","Elemental","Gaia","Mana","Meteor"]) {
            worldInfo["Names"].push(...dataCenters[datacenterName]["Names"]);
        }
        return worldInfo;
    } else {
        if (dataCenters.hasOwnProperty(datacenter)) {
            const worldInfo = dataCenters[datacenter];
            return worldInfo;
        }
    }
};

export const extractPatchInfo = () => {
    const patchInfo = {
        "ARR": {
            en: "A Realm Reborn",
            de: "A Realm Reborn",
            fr: "A Realm Reborn",
            jp: "A Realm Reborn",
            cn: "重生之境",
        },
        "HW": {
            en: "Heavensward",
            de: "Heavensward",
            fr: "Heavensward",
            jp: "Heavensward",
            cn: "苍穹之禁城",
        },
        "SB": {
            en: "Stormblood",
            de: "Stormblood",
            fr: "Stormblood",
            jp: "Stormblood",
            cn: "红莲之狂潮",
        },
        "ShB": {
            en: "Shadowbringer",
            de: "Shadowbringer",
            fr: "Shadowbringer",
            jp: "Shadowbringer",
            cn: "暗影之逆焰",
        },
        "EW": {
            en: "Endwalker",
            de: "Endwalker",
            fr: "Endwalker",
            jp: "Endwalker",
            cn: "晓月之终途",
        },
        "DT": {
            en: "Dawntrail",
            de: "Dawntrail",
            fr: "Dawntrail",
            jp: "Dawntrail",
            cn: "金曦之遗辉",
        },
    }
    return patchInfo;
};

export const extractHuntInfo = (name) => {
    try {
        if (name.endsWith(" 1") || name.endsWith(" 2") || name.endsWith(" 3") || name.endsWith(" 4") || name.endsWith(" 5") || name.endsWith(" 6")) {
            return huntInfos[name.slice(0,-2)];
        } else {
            return huntInfos[name];
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const extractFATEInfo = (name) => {
    try {
        var baseName = name;
        if (name.endsWith(" 1") || name.endsWith(" 2") || name.endsWith(" 3") || name.endsWith(" 4") || name.endsWith(" 5") || name.endsWith(" 6")) {
            baseName = name.slice(0,-2);
        }

        for (var key of Object.keys(BossFATEs)) {
            if (BossFATEs[key]["DatabaseName"] == baseName) {
                return BossFATEs[key];
            }
        }

    } catch (error) {
        console.log(error);
        return null;
    }
};

export const extractSpawnPoints = () => {
    return spawnPoints;
};

export const predictSpawnCondition = (name, minTime) => {
    switch (name) {
        case "Laideronnette": {
            return predictNextLaideronnette(minTime);
        }
        case "Mindflayer": {
            return predictNextMindflayer(minTime);
        }
        case "Thousand-cast Theda": {
            return predictNextTheda(minTime);
        }
        case "Zona Seeker": {
            return predictNextZonaSeeker(minTime);
        }
        case "Croque-Mitaine": {
            return predictNextCroqueMitaine(minTime);
        }
        case "Croakadile": {
            return predictNextCroakadile(minTime);
        }
        case "The Garlok": {
            return predictNextGarlok(minTime);
        }
        case "Bonnacon": {
            return predictNextBonnacon(minTime);
        }
        case "Gandarewa": {
            return predictNextGandarewa(minTime);
        }
        case "Okina": {
            return predictNextOkina(minTime);
        }
        case "Gamma": {
            return predictNextGamma(minTime);
        }
        case "Burfurlur the Canny": {
            return predictNextBurfurlur(minTime);
        }
        default: {
            return null;
        }
    }
};