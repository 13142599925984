import { TextSelector } from "assets/selector/language.js";
import { extractHuntInfo } from "./gameInfo";
import { SessionConfigs } from "./sessionState";
import { BossFATEs, FATEChains } from "./resources/boss-fate";

export var HuntTimers = (function () {

  var AHuntTimers = [];
  var SHuntTimers = [];
  var FATETimers = [];
  var ChainTimers = {};
  var sHideDead = false;
  var aHideDead = false;
  var fateHideDead = false;

  const isSynced = (type) => {
    if (type == "FATE") {
      return FATETimers.length > 0;
    }
    return SHuntTimers.length > 0 && AHuntTimers.length > 0;
  }

  const setTimers = (timers) => {
    AHuntTimers = [];
    SHuntTimers = [];

    const displayState = SessionConfigs.getToggleStates();
    for (var i = 0; i < timers.length; i++) {
      const huntInfo = SessionConfigs.getHuntResources(timers[i].huntKey);
      if (huntInfo["Rank"] == 2) {
        const state = displayState.arankConfig["worlds"].includes(timers[i]["worldName"]) && displayState.arankConfig["patches"].includes(huntInfo["Patch"]);
        if (timers[i].huntName !== timers[i].huntKey) {
          AHuntTimers.push(Object.assign({displayState: displayState.arankConfig["instance"].includes(timers[i].huntName.slice(-1)) && state, instance: timers[i].huntName.slice(-2), hideDead: aHideDead},
            huntInfo,
            {defaultSortOrder: huntInfo.ID+SessionConfigs.getWorldIndex(timers[i].worldName).worldIndex*1000, 
              expectMinTime: (timers[i].lastDeathTime+(timers[i].isMaint ? (huntInfo.MaintTimer[0]*3600) : (huntInfo.RespawnTimer[0]*3600)))*1000,
              expectMaxTime: (timers[i].lastDeathTime+(timers[i].isMaint ? ((huntInfo.MaintTimer[0]+huntInfo.MaintTimer[1])*3600) : ((huntInfo.RespawnTimer[0]+huntInfo.RespawnTimer[1])*3600)))*1000},
            timers[i]));
        } else {
          AHuntTimers.push(Object.assign({displayState: state, instance: "", hideDead: aHideDead},
            huntInfo,
            {defaultSortOrder: huntInfo.ID+SessionConfigs.getWorldIndex(timers[i].worldName).worldIndex*1000, 
              expectMinTime: (timers[i].lastDeathTime+(timers[i].isMaint ? (huntInfo.MaintTimer[0]*3600) : (huntInfo.RespawnTimer[0]*3600)))*1000,
              expectMaxTime: (timers[i].lastDeathTime+(timers[i].isMaint ? ((huntInfo.MaintTimer[0]+huntInfo.MaintTimer[1])*3600) : ((huntInfo.RespawnTimer[0]+huntInfo.RespawnTimer[1])*3600)))*1000},
            timers[i]));
        }
      } else if (huntInfo["Rank"] == 3) {
        const state = displayState.srankConfig["worlds"].includes(timers[i]["worldName"]) && displayState.srankConfig["patches"].includes(huntInfo["Patch"]);
        if (timers[i].huntName !== timers[i].huntKey) {
          SHuntTimers.push(Object.assign({displayState: state, instance: timers[i].huntName.slice(-2), hideDead: sHideDead},
            huntInfo,
            {defaultSortOrder: huntInfo.ID+SessionConfigs.getWorldIndex(timers[i].worldName).worldIndex*1000, 
              expectMinTime: (timers[i].lastDeathTime+(timers[i].isMaint ? (huntInfo.MaintTimer[0]*3600) : (huntInfo.RespawnTimer[0]*3600)))*1000,
              expectMaxTime: (timers[i].lastDeathTime+(timers[i].isMaint ? ((huntInfo.MaintTimer[0]+huntInfo.MaintTimer[1])*3600) : ((huntInfo.RespawnTimer[0]+huntInfo.RespawnTimer[1])*3600)))*1000},
            timers[i]));
        } else {
          SHuntTimers.push(Object.assign({displayState: state, instance: "", hideDead: sHideDead},
            huntInfo,
            {defaultSortOrder: huntInfo.ID+SessionConfigs.getWorldIndex(timers[i].worldName).worldIndex*1000, 
              expectMinTime: (timers[i].lastDeathTime+(timers[i].isMaint ? (huntInfo.MaintTimer[0]*3600) : (huntInfo.RespawnTimer[0]*3600)))*1000,
              expectMaxTime: (timers[i].lastDeathTime+(timers[i].isMaint ? ((huntInfo.MaintTimer[0]+huntInfo.MaintTimer[1])*3600) : ((huntInfo.RespawnTimer[0]+huntInfo.RespawnTimer[1])*3600)))*1000},
            timers[i]));
        }
      }
    }

    AHuntTimers.sort((a, b) => {
      return a.defaultSortOrder < b.defaultSortOrder ? -1 : 1;
    })
    SHuntTimers.sort((a, b) => {
      return a.defaultSortOrder < b.defaultSortOrder ? -1 : 1;
    })
  }

  const setFATETimers = (timers) => {
    /*
    for (var i in timers.ChainFATE) {
      ChainTimers[timers.ChainFATE[i].worldName + "_" + timers.ChainFATE[i].fateName] = timers.ChainFATE[i];
    }
    
    const displayState = SessionConfigs.getToggleStates();
    for (var i in timers.BossFATE) {
      const state = displayState.fateConfig["world"][timers.BossFATE[i]["worldName"]] && displayState.fateConfig["patch"][timers.BossFATE[i]["Patch"]];
      timers.BossFATE[i].displayState = state;
    }
    FATETimers = timers.BossFATE;
    */
   
    FATETimers = [];

    const displayState = SessionConfigs.getToggleStates();
    for (var i = 0; i < timers.length; i++) {
      const huntInfo = SessionConfigs.getFateResources(timers[i].huntKey);
      const state = displayState.fateConfig["worlds"].includes(timers[i]["worldName"]) && displayState.fateConfig["patches"].includes(huntInfo["Patch"]);
      if (timers[i].huntName !== timers[i].huntKey) {
        FATETimers.push(Object.assign({displayState: state, instance: timers[i].huntName.slice(-2), hideDead: fateHideDead},
          huntInfo,
          {defaultSortOrder: huntInfo.ID+SessionConfigs.getWorldIndex(timers[i].worldName).worldIndex*1000, 
            expectMinTime: (timers[i].lastDeathTime+(timers[i].isMaint ? (huntInfo.MaintTimer[0]*3600) : (huntInfo.RespawnTimer[0]*3600)))*1000,
            expectMaxTime: (timers[i].lastDeathTime+(timers[i].isMaint ? ((huntInfo.MaintTimer[0]+huntInfo.MaintTimer[1])*3600) : ((huntInfo.RespawnTimer[0]+huntInfo.RespawnTimer[1])*3600)))*1000},
          timers[i]));
      } else {
        FATETimers.push(Object.assign({displayState: state, instance: "", hideDead: fateHideDead},
          huntInfo,
          {defaultSortOrder: huntInfo.ID+SessionConfigs.getWorldIndex(timers[i].worldName).worldIndex*1000, 
            expectMinTime: (timers[i].lastDeathTime+(timers[i].isMaint ? (huntInfo.MaintTimer[0]*3600) : (huntInfo.RespawnTimer[0]*3600)))*1000,
            expectMaxTime: (timers[i].lastDeathTime+(timers[i].isMaint ? ((huntInfo.MaintTimer[0]+huntInfo.MaintTimer[1])*3600) : ((huntInfo.RespawnTimer[0]+huntInfo.RespawnTimer[1])*3600)))*1000},
          timers[i]));
      }
    }

    FATETimers.sort((a, b) => {
      return a.defaultSortOrder < b.defaultSortOrder ? -1 : 1;
    })
  }

  const updateFATETimer = (key, obj) => {
    if (Object.keys(ChainTimers).includes(key)) ChainTimers[key] = obj;
    else {
      for (var i in FATETimers) {
        if (FATETimers[i].worldName + "_" + FATETimers[i].huntName == key) {
          FATETimers[i] = obj;
        }
      }
    }
  }

  const getFATETimer = (key) => {
    if (Object.keys(ChainTimers).includes(key)) return ChainTimers[key];
    
    for (var i in FATETimers) {
      if (FATETimers[i].worldName + "_" + FATETimers[i].fateName == key) {
        return FATETimers[i];
      }
    }
  }

  const getDisplay = (rank, world, patch) => {
    const displayState = SessionConfigs.getToggleStates();
    if (rank == 3) {
      return displayState.srankConfig["worlds"].includes(world) && displayState.srankConfig["patches"].includes(patch);
    } else if (rank == 2) {
      return displayState.arankConfig["worlds"].includes(world) && displayState.arankConfig["patches"].includes(patch);
    } else {
      return displayState.fateConfig["worlds"].includes(world) && displayState.fateConfig["patches"].includes(patch);
    }
  }

  const getOpenHunts = () => {
    const currentTime = new Date().getTime();
    var huntToVerify = [];
    for (var i in SHuntTimers) {
      if (SHuntTimers[i].expectMinTime < currentTime) {
        huntToVerify.push({
          key: SHuntTimers[i].worldName + "_" + SHuntTimers[i].huntName,
          deathTime: SHuntTimers[i].lastDeathTime,
          missingCounter: SHuntTimers[i].missing
        });
      }
    }
    return huntToVerify;
  }

  const updateDisplayState = (type) => {
    const displayState = SessionConfigs.getToggleStates();
    if (type == "srank") {
      for (var i = 0; i < SHuntTimers.length; i++) {
        const state = displayState.srankConfig["worlds"].includes(SHuntTimers[i]["worldName"]) && displayState.srankConfig["patches"].includes(SHuntTimers[i]["Patch"]);
        SHuntTimers[i].displayState = state;
        SHuntTimers[i].hideDead = sHideDead;
      }
    } else if (type == "arank") {
      for (var i = 0; i < AHuntTimers.length; i++) {
        const state = displayState.arankConfig["worlds"].includes(AHuntTimers[i]["worldName"]) && displayState.arankConfig["patches"].includes(AHuntTimers[i]["Patch"]);
        if (AHuntTimers[i].huntName.endsWith(" 1") || AHuntTimers[i].huntName.endsWith(" 2") || AHuntTimers[i].huntName.endsWith(" 3") || AHuntTimers[i].huntName.endsWith(" 4") || AHuntTimers[i].huntName.endsWith(" 5") || AHuntTimers[i].huntName.endsWith(" 6")) {
          AHuntTimers[i].displayState = displayState.arankConfig["instance"].includes(AHuntTimers[i].huntName.slice(-1)) && state;
        } else {
          AHuntTimers[i].displayState = state;
        }
        AHuntTimers[i].hideDead = aHideDead;
      }
    } else if (type == "fate") {
      for (var i = 0; i < FATETimers.length; i++) {

        const state = displayState.fateConfig["worlds"].includes(FATETimers[i]["worldName"]) && displayState.fateConfig["patches"].includes(FATETimers[i]["Patch"]);
        FATETimers[i].displayState = state;
        FATETimers[i].hideDead = fateHideDead;
      }
    }
  }

  const setHideDeadHunts = (type, state) => {
    if (type == "srank") {
      sHideDead = state;
    } else if (type == "arank") {
      aHideDead = state;
    } else {
      fateHideDead = state;
    }
  }

  const getRankInfo = (type) => {
    if (type == "srank") return SHuntTimers;
    if (type == "arank") return AHuntTimers;
    if (type == "fate") return FATETimers;
    return [];
  }

  const getSRankInfo = () => {
    return SHuntTimers;
  }

  const getARankInfo = () => {
    return AHuntTimers;
  }

  const getFATEInfo = () => {
    return FATETimers;
  }

  const getChainInfo = () => {
    return ChainTimers;
  }

  return {
    isSynced: isSynced,
    setTimers: setTimers,
    setFATETimers: setFATETimers,

    updateFATETimer: updateFATETimer,
    getFATETimer: getFATETimer,

    updateDisplayState: updateDisplayState,
    getDisplay: getDisplay,

    getRankInfo: getRankInfo,
    getSRankInfo: getSRankInfo,
    getARankInfo: getARankInfo,
    getFATEInfo: getFATEInfo,
    getChainInfo: getChainInfo,
    getOpenHunts: getOpenHunts,

    setHideDeadHunts: setHideDeadHunts,
  }

})();
