import React, { useEffect } from "react";
import { useHistory } from "react-router-dom"

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  CustomInput,
} from "reactstrap";

import {
  Box,
  Button,
  TableContainer,
} from "@mui/material"

import SweetAlert from "react-bootstrap-sweetalert";
import { ToastContainer } from "react-toastify";
import notification from "assets/ringtone.ogg";

import TimerRow from "components/TimerTable/TimerRow"
import TimerTable from "components/TimerTable/TimerTable"
import LanguageDependentText from "components/TimerTable/LanguageDependentText";
import { useHuntTrackerContext, setContextState } from "tracker-context";

import { HuntTimers } from "sessions/databaseStorage"
import { SessionConfigs } from "sessions/sessionState";

const Timer = ({match}) => {
  const [controller, dispatch] = useHuntTrackerContext();
  const { language, datacenters, filterMode, audioPermission } = controller;

  const history = useHistory();
  const [alert, setAlert] = React.useState(null);
  const audioRef = React.createRef();
  const [sHuntInfo, setSHuntInfo] = React.useState([]);
  const [aHuntInfo, setAHuntInfo] = React.useState([]);

  const [traveling, setTraveling] = React.useState(true);
  const [loadingState, setLoadingState] = React.useState(true);
  
  const dataCenterTravel = async () => {
    if (match.params["world"]) {
      const dcList = SessionConfigs.getDataCenterResources();
      const worldKey = match.params["world"];
      for (var key of Object.keys(dcList)) {
        if (key.toLocaleLowerCase() === worldKey.toLocaleLowerCase()) {
          if (!datacenters.includes(key)) {
            setContextState(dispatch, "datacenters", [key]);
          }
          setTraveling(false);
          return;
        }

        for (var world of dcList[key]["Names"]) {
          if (world.toLocaleLowerCase() === worldKey.toLocaleLowerCase()) {
            if (!datacenters.includes(key)) {
              setContextState(dispatch, "datacenters", [key]);
            }
            SessionConfigs.setWorldSelect("srank", "select", world, [key], true);
            SessionConfigs.setWorldSelect("arank", "select", world, [key], true);
            setTraveling(false);
            return;
          }
        }
      }
    } else {
      setTraveling(false);
    }
  }

  React.useEffect(() => {
    setTraveling(true);
    setLoadingState(true);
    dataCenterTravel();
  }, []);

  React.useEffect(() => {
    if (traveling) return; 

    if (datacenters.length == 0) {
      history.push("/index");
      return;
    }

    SessionConfigs.query("/api/lastDeathTimers", {
      "QueryDeathTimers": SessionConfigs.getCurrentDCInfo(datacenters),
      "RankType": "SARank"
    }).then((response) => {
      if (response.status == 200) {
        HuntTimers.setTimers(response.data.timers);
        setSHuntInfo(HuntTimers.getSRankInfo());
        setAHuntInfo(HuntTimers.getARankInfo());
      }
      setLoadingState(false);
    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
      setLoadingState(false);
    });
  }, [traveling, datacenters]);

  React.useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = audioPermission > 0 ? audioPermission : 0;
    }
  }, [audioRef.current, audioPermission]);

  const hideDeadRef = React.useRef();
  React.useEffect(() => {
    if (document.getElementById("srankHideDead") && sHuntInfo.length > 0) document.getElementById("srankHideDead").checked = !sHuntInfo[0].hideDead;
    if (document.getElementById("arankHideDead") && aHuntInfo.length > 0) document.getElementById("arankHideDead").checked = !aHuntInfo[0].hideDead;
  }, [hideDeadRef.current, sHuntInfo, aHuntInfo])

  var timerConfigs = SessionConfigs.getToggleStates();
  const worlds = SessionConfigs.getCurrentDCInfo(datacenters);
  const patches = SessionConfigs.getPatches();
  var lastClick = new Date().getTime();
  var lastClickType = "";

  const updateDisplayStatus = (type, calculate) => {
    if (type == "srank") {
      var huntInfo = HuntTimers.getSRankInfo();
    } else if (type == "arank") {
      var huntInfo = HuntTimers.getARankInfo();
    }

    const currentTime = new Date().getTime();
    var huntCount = 0;

    for (var i = 0; i < huntInfo.length; i++) {
      const row = document.getElementById(huntInfo[i].worldName + "_" + huntInfo[i].huntName + "_Row");
      if (!row) continue;

      if (!calculate) {
        if ((huntInfo[i].displayState && (!huntInfo[i].hideDead || currentTime > huntInfo[i].expectMinTime )) == row.classList.contains("d-none")) {
          row.classList.toggle("d-none");
        }
      } else {
        if (!row.classList.contains("d-none")) {
          if (currentTime > huntInfo[i].expectMinTime) {
            huntCount+=1;
          }
        }
      }
    }
    return huntCount;
  }

  const toggleWorldState = (type, world, event) => {
    var newClick = new Date().getTime();

    if (newClick - lastClick < 300 && lastClickType == world) {
      lastClick = newClick;

      for (var i = 0; i < worlds.length; i++) {
        if (worlds[i] != world) {
          document.getElementById(type+worlds[i]).classList.remove("btn-selected");
        } else {
          document.getElementById(type+worlds[i]).classList.add("btn-selected");
        }
      }
      SessionConfigs.setWorldSelect(type, "select", world, datacenters);
      HuntTimers.updateDisplayState(type);
      updateDisplayStatus(type);

    } else {
      lastClick = newClick;
      lastClickType = world;

      event.currentTarget.classList.toggle("btn-selected");
      const state = event.currentTarget.classList.contains("btn-selected");
      SessionConfigs.setWorldSelect(type, "toggle", world);
      HuntTimers.updateDisplayState(type);
      updateDisplayStatus(type);
    }
  }
  
  const togglePatchState = (type, patch, event) => {
    var newClick = new Date().getTime();
    if (newClick - lastClick < 300 && lastClickType == patch) {
      lastClick = newClick;
      
      for (var i = 0; i < patches.length; i++) {
        if (patches[i] != patch) {
          document.getElementById(type+patches[i]).classList.remove("btn-selected");
        } else {
          document.getElementById(type+patches[i]).classList.add("btn-selected");
        }
      }
      SessionConfigs.setPatchSelect(type, "select", patch);
      HuntTimers.updateDisplayState(type);
      updateDisplayStatus(type);

    } else {
      lastClick = newClick;
      lastClickType = patch;

      event.currentTarget.classList.toggle("btn-selected");
      const state = event.currentTarget.classList.contains("btn-selected");
      SessionConfigs.setPatchSelect(type, "toggle", patch);
      HuntTimers.updateDisplayState(type);
      updateDisplayStatus(type);
    }
  }

  const toggleInstanceState = (type, instance, event) => {
    event.currentTarget.classList.toggle("btn-selected");
    const state = event.currentTarget.classList.contains("btn-selected");
    SessionConfigs.setInstanceSelect(type, instance);
    HuntTimers.updateDisplayState(type);
    updateDisplayStatus(type);
  }

  const toggleSRankCard = (event) => {
    event.currentTarget.classList.toggle("btn-selected");
    const state = event.currentTarget.classList.contains("btn-selected");

    const sRankCard = document.getElementById("SRankCard");
    const aRankCard = document.getElementById("ARankCard");
    
    sRankCard.classList.toggle("d-none");
    if (state) {
      if (aRankCard.classList.contains("d-none")) {
        sRankCard.classList.remove("col-xl-7");
        sRankCard.classList.add("col-xl-12");
      } else {
        sRankCard.classList.remove("col-xl-12");
        sRankCard.classList.add("col-xl-7");
        aRankCard.classList.remove("col-xl-12");
        aRankCard.classList.add("col-xl-5");
      }
    } else {
      aRankCard.classList.remove("col-xl-5");
      aRankCard.classList.add("col-xl-12");
    }
  }
  
  const toggleARankCard = (event) => {
    event.currentTarget.classList.toggle("btn-selected");
    const state = event.currentTarget.classList.contains("btn-selected");

    const sRankCard = document.getElementById("SRankCard");
    const aRankCard = document.getElementById("ARankCard");
    
    aRankCard.classList.toggle("d-none");
    if (state) {
      if (sRankCard.classList.contains("d-none")) {
        aRankCard.classList.remove("col-xl-5");
        aRankCard.classList.add("col-xl-12");
      } else {
        aRankCard.classList.remove("col-xl-12");
        aRankCard.classList.add("col-xl-5");
        sRankCard.classList.remove("col-xl-12");
        sRankCard.classList.add("col-xl-7");
      }
    } else {
      sRankCard.classList.remove("col-xl-7");
      sRankCard.classList.add("col-xl-12");
    }
  }
  
  const toggleHiddenHunts = (type, event) => {
    HuntTimers.setHideDeadHunts(type, !event.currentTarget.checked);
    HuntTimers.updateDisplayState(type);
    SessionConfigs.setHideDeadHunts(type, !event.currentTarget.checked);
    updateDisplayStatus(type);
  }

  React.useEffect(() => {
    const timerID = setInterval(() => {
      const huntCount = updateDisplayStatus("srank", true);
      if (document.getElementById("srankCounter")) document.getElementById("srankCounter").innerHTML = SessionConfigs.translateText("header", "tableSpawnableHunt") + " " + huntCount.toString();
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  return (
    <>
      <div className="content" ref={hideDeadRef} >
        {alert}
        <audio src={notification} ref={audioRef}/>
        <Box style={{display: "flex", flexDirection: "row"}}>
          <Button fullWidth variant="outlined" className="btn-selected" onClick={(event) => toggleSRankCard(event)}>
            <LanguageDependentText header={"tableSRankCard"} variant="body2" fontSize={20} color={"#FFFFFF"}></LanguageDependentText>
          </Button>
          <Button fullWidth variant="outlined" className="btn-selected" onClick={(event) => toggleARankCard(event)}>
            <LanguageDependentText header={"tableARankCard"} variant="body2" fontSize={20} color={"#FFFFFF"}></LanguageDependentText>
          </Button>
        </Box>
        <Row style={{marginTop: 15}}>
          <ToastContainer
              style={{width: 400}}
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover
            />
          <Col id={"SRankCard"} md={12} xl={7}>
            <Card>
              <CardBody>
                {loadingState ? null : ([
                  <Box key={"tableTitle"} pt={1} px={3}>
                    <Row>
                      <Col xs={12} sm={4}>
                        <LanguageDependentText header="srankTable" variant="h4" fontSize={24}/>
                      </Col>
                      <Col xs={6} sm={4}>
                        <Box display="flex" alignContent={"center"}>
                          <LanguageDependentText fontSize={20}>
                            <span id={"srankCounter"}></span>
                          </LanguageDependentText>
                        </Box>
                      </Col>
                      <Col xs={6} sm={4}>
                        <Box className="pull-right">
                          <CustomInput type="switch" id="srankHideDead" onClick={(event) => toggleHiddenHunts("srank", event)} label={<LanguageDependentText header={"tableHideDead"} component="p" fontSize={15}/>} />
                        </Box>
                      </Col>
                    </Row>
                  </Box>,
                  <Box key={"worldSelect"} pt={3} px={3}>
                    <LanguageDependentText header="worldSelectTitle" variant="h6"/>
                  </Box>,
                  <Box key={"worldGroup"} pt={1} px={3}>
                    {worlds.map((world)=>{
                      return (timerConfigs.srankConfig.worlds.includes(world)) ? (
                      <Button id={"srank"+world} key={world} variant="outlined" className="btn-selected" onClick={(event) => toggleWorldState("srank", world, event)}>
                        <LanguageDependentText text={world} variant="body2" fontSize={13}></LanguageDependentText>
                      </Button>
                      ) : (
                      <Button id={"srank"+world} key={world} variant="outlined" onClick={(event) => toggleWorldState("srank", world, event)}>
                        <LanguageDependentText text={world} variant="body2" fontSize={13}></LanguageDependentText>
                      </Button> 
                    )})}
                  </Box>,
                  <Box key={"patchSelect"} pt={1} px={3}>
                    <LanguageDependentText header="patchSelectTitle" variant="h6"/>
                  </Box>,
                  <Box key={"patchGroup"} pt={1} px={3} mb={4}>
                    {patches.map((patch)=>(
                      (timerConfigs.srankConfig.patches.includes(patch)) ? (
                      <Button id={"srank"+patch} key={patch} variant="outlined" className="btn-selected" onClick={(event) => togglePatchState("srank", patch, event)}>
                        <LanguageDependentText patchName={patch} variant="body2" fontSize={13}></LanguageDependentText>
                      </Button>
                      ) : (
                      <Button id={"srank"+patch} key={patch} variant="outlined" onClick={(event) => togglePatchState("srank", patch, event)}>
                        <LanguageDependentText patchName={patch} variant="body2" fontSize={13}></LanguageDependentText>
                      </Button> 
                    )))}
                  </Box>,
                  <Box key={"tableContent"}>
                    <TableContainer sx={{ boxShadow: "none", overflowY: "hidden" }}>
                      <TimerTable name="SHuntTable" audioRef={audioRef} huntInfo={sHuntInfo}/>
                    </TableContainer>
                  </Box>
                ])}
              </CardBody>
            </Card>
          </Col>
          <Col id={"ARankCard"} md={12} xl={5}>
            <Card>
              <CardBody>
              {loadingState ? null : ([
                  <Box key={"tableTitle"} pt={1} px={3} style={{display: "flex", justifyContent: "space-between"}}>
                    <LanguageDependentText header="arankTable" variant="h4" fontSize={24}/>
                    <CustomInput type="switch" id="arankHideDead" onClick={(event) => toggleHiddenHunts("arank", event)} label={<LanguageDependentText header={"tableHideDead"} component="p" fontSize={15}/>} />
                  </Box>,
                  <Box key={"worldSelect"} pt={3} px={3}>
                    <LanguageDependentText header="worldSelectTitle" variant="h6"/>
                  </Box>,
                  <Box key={"worldGroup"} pt={1} px={3}>
                    {worlds.map((world)=>(
                      (timerConfigs.arankConfig.worlds.includes(world)) ? (
                      <Button id={"arank"+world} key={world} variant="outlined" className="btn-selected" onClick={(event) => toggleWorldState("arank", world, event)}>
                        <LanguageDependentText text={world} variant="body2" fontSize={13}></LanguageDependentText>
                      </Button>
                      ) : (
                      <Button id={"arank"+world} key={world} variant="outlined" onClick={(event) => toggleWorldState("arank", world, event)}>
                        <LanguageDependentText text={world} variant="body2" fontSize={13}></LanguageDependentText>
                      </Button> 
                    )))}
                  </Box>,
                  <Box key={"patchSelect"} pt={1} px={3}>
                    <LanguageDependentText header="patchSelectTitle" variant="h6"/>
                  </Box>,
                  <Box key={"patchGroup"} pt={1} px={3}>
                    {patches.map((patch)=>(
                      (timerConfigs.arankConfig.patches.includes(patch)) ? (
                      <Button id={"arank"+patch} key={patch} variant="outlined" className="btn-selected" onClick={(event) => togglePatchState("arank", patch, event)}>
                        <LanguageDependentText patchName={patch} variant="body2" fontSize={13}></LanguageDependentText>
                      </Button>
                      ) : (
                      <Button id={"arank"+patch} key={patch} variant="outlined" onClick={(event) => togglePatchState("arank", patch, event)}>
                        <LanguageDependentText patchName={patch} variant="body2" fontSize={13}></LanguageDependentText>
                      </Button> 
                    )))}
                  </Box>,
                  <Box key={"instanceSelect"} pt={1} px={3}>
                    <LanguageDependentText header="instanceSelectTitle" variant="h6"/>
                  </Box>,
                  <Box key={"instanceGroup"} pt={1} px={3} mb={4}>
                    {["1","2","3","4","5","6"].map((instance)=>(
                      (timerConfigs.arankConfig.instance.includes(instance)) ? (
                      <Button key={instance} variant="outlined" className="btn-selected" onClick={(event) => toggleInstanceState("arank", instance, event)}>
                        <LanguageDependentText header={"tableInstanceName"} variant="body2" fontSize={13}> {instance} </LanguageDependentText>
                      </Button>
                      ) : (
                      <Button key={instance} variant="outlined" onClick={(event) => toggleInstanceState("arank", instance, event)}>
                        <LanguageDependentText header={"tableInstanceName"} variant="body2" fontSize={13}> {instance} </LanguageDependentText>
                      </Button> 
                    )))}
                  </Box>,
                  <Box key={"tableContent"}>
                    <TableContainer sx={{ boxShadow: "none", overflowY: "hidden"}}>
                      <TimerTable name="AHuntTable" audioRef={audioRef} huntInfo={aHuntInfo}>
                        
                      </TimerTable>
                    </TableContainer>
                  </Box>
                ])}
              </CardBody>
            </Card>
            
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Timer;
