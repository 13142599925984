import React, { useEffect } from "react";
import { useParams } from "react-router-dom"

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  CustomInput,
  Row,
  UncontrolledDropdown, 
  DropdownToggle, 
  DropdownMenu, 
  DropdownItem,
  Modal,
  ModalFooter,
  ModalBody,
} from "reactstrap";

import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TextField
} from "@mui/material"

import SweetAlert from "react-bootstrap-sweetalert";
import { ToastContainer } from "react-toastify";
import notification from "assets/ringtone.ogg";

import TrainRouteTable from "components/TrainTools/TrainRouteTable";
import TrainMaps from "components/TrainTools/TrainMaps";

import LanguageDependentText from "components/TimerTable/LanguageDependentText";
import { useHuntTrackerContext } from "tracker-context";

import { SocketIO } from "sessions/socket"
import { huntInfos } from 'sessions/resources/hunts';
import { SessionConfigs } from "sessions/sessionState";
import { extractPatchInfo, extractWorldInfo } from "sessions/gameInfo";
import { getDataCenterName } from "sessions/gameInfo";

const TrainPage = () => {
  const { trainId } = useParams();

  const [alert, setAlert] = React.useState(null);
  const [controller, dispatch] = useHuntTrackerContext();
  const { language, user } = controller;

  const [loadingState, setLoadingState] = React.useState(true);
  const [showTable, setShowTable] = React.useState(true);
  const [ownedTrains, setOwnedTrains] = React.useState([]);
  const [activeRoutes, setActiveRoutes] = React.useState({permission: false, routes: []});
  const [activeTrain, setActiveTrain] = React.useState(null);

  const [sortModal, setSortModal] = React.useState({show: false, order: []});

  const dataCenter = SessionConfigs.getDataCenter();

  React.useEffect(() => {
    if (dataCenter == "") {
      SessionConfigs.setDataCenter("Aether");
    }
    
    SessionConfigs.queryData("/api/fetchTrains", {
      trainId: trainId,
    }).then((response) => {
      if (response.status == 200) {
        setOwnedTrains(response.data.trains);
        if (trainId) {
          viewTrain(response.data.trains[0]);
          const datacenter = getDataCenterName(response.data.trains[0].worldName);
          SessionConfigs.setDataCenter(datacenter).then(() => {
            SessionConfigs.syncSession();
          });
        }
      }
    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
    });
  }, [user]);

  const addNewTrain = () => {
    SessionConfigs.queryData("/api/trainUpdate", {
      newTrainRequest: true,
    }).then((response) => {
      if (response.status == 200) {
        if (response.data.append) {
          ownedTrains.push(response.data.trains[0])
          setOwnedTrains([...ownedTrains]);
          setShowTable(true);
        } else {
          setOwnedTrains(response.data.trains);
          setShowTable(true);
        }
        viewTrain(response.data.trains[0]);
      }
    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
    });
  };

  const viewTrain = (train) => {
    setActiveTrain(train);
    setSortModal({...sortModal, order: train.mapOrder})
    SessionConfigs.queryData("/api/fetchTrainRoutes", {
      id: train.trainId,
      world: train.worldName
    }).then((response) => {
      if (response.status == 200) {
        setActiveRoutes(response.data.routes);
        setShowTable(false);
      }
    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
    })
  }

  const deleteTrain = (train) => {
    SessionConfigs.queryData("/api/trainUpdate", {
      id: train.trainId,
      delete: true
    }).then((response) => {
      if (response.status == 200) {
        for (var existingTrain of ownedTrains) {
          if (existingTrain.trainId == train.trainId) {
            existingTrain = null;
          }
        }
        setOwnedTrains(ownedTrains.filter(existingTrain => existingTrain.trainId != train.trainId));
      }
    }).catch((error) => {
      SessionConfigs.handleErrorDisplay(error, setAlert);
    })
  }

  const patches = extractPatchInfo();
  var worlds = [];
  if (dataCenter != "") {
    worlds = extractWorldInfo(dataCenter)["Names"];
  } else {
    worlds = extractWorldInfo("Aether")["Names"];
  }

  const handlePatchSelection = (event) => {
    const patch = event.currentTarget.value;
    setActiveTrain({...activeTrain, patchName: patch});
  }

  const handleWorldSelection = (event) => {
    const world = event.currentTarget.value;
    if (activeTrain.password) {
      SessionConfigs.queryData("/api/trainUpdate", {
        updateTrainWorld: world,
        id: activeTrain.trainId
      }).then((response) => {
        if (response.status == 200) {
          for (var existingTrain of ownedTrains) {
            if (existingTrain.trainId == activeTrain.trainId) {
              existingTrain.worldName = world;
            }
          }
          setOwnedTrains([...ownedTrains]);

          const train = {...activeTrain, worldName: world};
          setActiveTrain(train);
          viewTrain(train);
        }
      }).catch((error) => {
        setAlert(
          <SweetAlert
              error style={{display: "block",marginTop: "-100px"}}
              title="Unknown Error" onConfirm={() => setAlert(null)}
              onCancel={() => setAlert(null)} confirmBtnBsStyle="info" >
            {error.response.status}
          </SweetAlert>
        );
      })
    } else {
      const train = {...activeTrain, worldName: world};
      setActiveTrain(train);
      viewTrain(train);
    }
  }

  const reorderMap = (index, direction) => {
    if (index-direction >= 0 && index-direction < sortModal.order.length) {
      const toSwap = sortModal.order[index];
      sortModal.order[index] = sortModal.order[index-direction];
      sortModal.order[index-direction] = toSwap;
      setSortModal({...sortModal});
    }
  }

  const updateSortingOrder = () => {
    SessionConfigs.queryData("/api/trainUpdate", {
      updateMapOrder: sortModal.order,
      patch: activeTrain.patchName,
      id: activeTrain.trainId
    });
    setActiveTrain({...activeTrain, mapOrder: sortModal.order});
    setSortModal({...sortModal, show: false});
    
    for (var existingTrain of ownedTrains) {
      if (existingTrain.trainId == activeTrain.trainId) {
        existingTrain.mapOrder = sortModal.order;
      }
    }
    setOwnedTrains(ownedTrains);
  }

  const submitRequest = (password) => {
    SessionConfigs.queryData("/api/trainUpdate", {
      id: activeTrain.trainId,
      password: password,
      requestOwner: true
    }).then((response) => {
      setOwnedTrains(response.data.trains);
      if (trainId) {
        viewTrain(response.data.trains[0]);
      }
      setAlert(null);
    }).catch((error) => {
      setAlert(
        <SweetAlert
            error style={{display: "block",marginTop: "-100px"}}
            title="Unknown Error" onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)} confirmBtnBsStyle="info" >
          {error.response.status}
        </SweetAlert>
      );
    });
  }

  const updateTrainName = (id, name) => {
    SessionConfigs.queryData("/api/trainUpdate", {
      id: id,
      name: name,
      changeName: true
    }).then((response) => {
      for (var existingTrain of ownedTrains) {
        if (existingTrain.trainId == id) {
          existingTrain.trainName = name;
        }
      }
      setOwnedTrains(ownedTrains);
      setAlert(null);
    }).catch((error) => {
      setAlert(
        <SweetAlert
            error style={{display: "block",marginTop: "-100px"}}
            title="Unknown Error" onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)} confirmBtnBsStyle="info" >
          {error.response.status}
        </SweetAlert>
      );
    });
  }

  const requestOwner = () => {
    var password = ""
    setAlert(
      <SweetAlert
          style={{display: "block",marginTop: "-100px"}}
          title={SessionConfigs.translateText("header", "trainToolPassword")} onConfirm={() => submitRequest(password)}
          onCancel={() => {
            setAlert(null);
          }} confirmBtnBsStyle="info" >
        <TextField label="Password" variant="outlined" onChange={(event) => password = event.target.value}/>
      </SweetAlert>
    );
  }

  const changeTrainName = (id) => {
    var name = ""
    setAlert(
      <SweetAlert
          style={{display: "block",marginTop: "-100px"}}
          title={SessionConfigs.translateText("header", "trainToolName")} onConfirm={() => updateTrainName(id, name)}
          onCancel={() => {
            setAlert(null);
          }} confirmBtnBsStyle="info" >
        <TextField label="Name" variant="outlined" onChange={(event) => name = event.target.value}/>
      </SweetAlert>
    );
  }

  const copyTrainUrl = (id) => {
    navigator.clipboard.writeText(window.location.protocol + '//' + window.location.host + "/train/" + id);
  }
  
  const copyTrainRoutes = (id) => {
    var trainDetails = [];
    for (var regionName of sortModal.order) {
      for (var key of Object.keys(huntInfos)) {
        if (huntInfos[key].Rank == 2) {
          if (regionName.startsWith(huntInfos[key].Region)) {
            var huntName;
            if (regionName.endsWith(" 1") || regionName.endsWith(" 2") || regionName.endsWith(" 3") || regionName.endsWith(" 4") || regionName.endsWith(" 5") || regionName.endsWith(" 6")) {
              huntName = key + regionName.slice(-2);
            } else {
              huntName = key;
            }

            trainDetails.push({
              regionName: regionName,
              huntName: key,
              x: activeRoutes.routes[huntName] ? activeRoutes.routes[huntName].x : null,
              y: activeRoutes.routes[huntName] ? activeRoutes.routes[huntName].y : null
            });
          }
        }
      }
    }

    var message = "";
    for (var hunt of trainDetails) {
      
      var translatedText = "";
      if (hunt.regionName.endsWith(" 1") || hunt.regionName.endsWith(" 2") || hunt.regionName.endsWith(" 3") || hunt.regionName.endsWith(" 4") || hunt.regionName.endsWith(" 5") || hunt.regionName.endsWith(" 6")) {
        translatedText = SessionConfigs.translateText("regionName", hunt.regionName.slice(0,-2)) + hunt.regionName.slice(-2);
      } else {
        translatedText = SessionConfigs.translateText("regionName", hunt.regionName);
      }
      message += translatedText;
      
      if (hunt.x) {
        message += ` ( ${hunt.x.toFixed(1)} , ${hunt.y.toFixed(1)} )`;
      } else {
        message += ` ( NOT AVAILABLE )`;
      }
      
      translatedText = "";
      if (hunt.huntName.endsWith(" 1") || hunt.huntName.endsWith(" 2") || hunt.huntName.endsWith(" 3") || hunt.huntName.endsWith(" 4") || hunt.huntName.endsWith(" 5") || hunt.huntName.endsWith(" 6")) {
        translatedText = SessionConfigs.translateText("huntName", hunt.huntName.slice(0,-2)) + hunt.huntName.slice(-2);
      } else {
        translatedText = SessionConfigs.translateText("huntName", hunt.huntName);
      }
      message += ` ${translatedText}\r\n`;
    }
    navigator.clipboard.writeText(message);
  }
  
  return (
    <>
      <div className="content">
        {alert}
        <Modal isOpen={sortModal.show} toggle={() => setSortModal({...sortModal, show: false})} size="md" contentClassName={"card border border-danger rounded"} backdrop={true}>
            {sortModal.order ? <Box sx={{paddingBottom: 0, display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
              <Table size="sm">
                <TableBody>
                  {sortModal.order.map((region, index) => {
                    return <TableRow key={region}>
                      <TableCell style={{paddingTop: 3, paddingBottom: 3}}>
                        <LanguageDependentText regionName={region}/>
                      </TableCell>
                      <TableCell style={{paddingTop: 3, paddingBottom: 3}}>
                        <Box sx={{paddingBottom: 0, display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
                          <IconButton color="info" onClick={() => reorderMap(index, 1)}>
                            <i className="fa-solid fa-arrow-up"></i>
                          </IconButton>
                          <IconButton color="error" onClick={() => reorderMap(index, -1)}>
                            <i className="fa-solid fa-arrow-down"></i>
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  })}
                </TableBody>
              </Table>
            </Box> : null}
            
            <Box sx={{minHeight: "100%", padding: "24px", display: "flex", flexDirection: "column" }}>
              <Button variant="contained" color="primary" onClick={() => updateSortingOrder()}>
                {SessionConfigs.translateText("header", "trainToolSort")}
              </Button>
            </Box>
        </Modal>
        <Row style={{marginTop: 15}}>
          <Col id={"TrainTable"} sm={12}>
            <Card >
              <CardHeader>
                <Row style={{display: "flex", justifyContent: "space-between", paddingBottom: 15}}>
                  <Col sm={12} md={6}>
                    <a onClick={() => setShowTable(!showTable)} style={{cursor: "pointer"}}>
                      <LanguageDependentText header={"trainTool"} fontSize={24}/>
                    </a>
                  </Col>
                  {activeTrain ? <Col sm={12} md={6}>
                    <Box display="flex">
                      <LanguageDependentText fontSize={18}>
                        {activeTrain.trainId}
                        <IconButton onClick={() => copyTrainUrl(activeTrain.trainId)}>
                          <p className="fa-solid fa-copy"></p>
                        </IconButton>
                      </LanguageDependentText>
                    </Box>
                  </Col> : null}
                  <Col md={12}>
                    <Box style={{display: "flex", justifyContent: "space-between", marginTop: 15}}>
                      {!activeTrain ? null :
                        <UncontrolledDropdown key={"worldSelect"} direction="down">
                          <DropdownToggle caret data-toggle="dropdown">
                            {activeTrain.worldName}
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-black">
                            {worlds.map((value) => 
                              <DropdownItem key={value} value={value} onClick={handleWorldSelection}>{value}</DropdownItem>
                            )}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        }
                      {!activeTrain ? null :
                        <UncontrolledDropdown key={"patchSelect"} direction="down" style={{marginLeft: 15}}>
                          <DropdownToggle caret data-toggle="dropdown">
                            {SessionConfigs.translateText("header", "patchSelectTitle")}
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-black">
                            {Object.keys(patches).map((value) => 
                              <DropdownItem key={value} value={value} onClick={handlePatchSelection}>{SessionConfigs.translateText("patchName", value)}</DropdownItem>
                            )}
                          </DropdownMenu>
                        </UncontrolledDropdown>}
                      {!activeTrain ? null :
                        <Button key="sortMap" variant="contained" color="info" onClick={() => setSortModal({...sortModal, show: true})} style={{marginLeft: 15, paddingTop: 10, marginBottom: 0}}>
                          <LanguageDependentText type="h5" header={"trainToolSort"} color="#FFFFFF !important" fontSize={15}/>
                        </Button>}
                      {!activeTrain ? null :
                        <Button key="copyRoutes" variant="contained" color="info" onClick={() => copyTrainRoutes()} style={{marginLeft: 15, paddingTop: 10, marginBottom: 0}}>
                          <LanguageDependentText type="h5" header={"trainCopyRoutes"} color="#FFFFFF !important" fontSize={15}/>
                        </Button>}
                      <Button variant="contained" color="info" onClick={() => addNewTrain()} style={{marginLeft: 15, paddingTop: 10, marginBottom: 0}}>
                        <LanguageDependentText type="h5" header={"trainToolNew"} color="#FFFFFF !important" fontSize={15}/>
                      </Button>
                    </Box>
                  </Col>
                </Row>
              </CardHeader>
              <Collapse isOpen={showTable} >
                <CardBody style={{overflowX: "auto", overflowY: "auto", maxHeight: "600px"}}>
                  <TrainRouteTable ownedTrains={ownedTrains} viewTrain={viewTrain} deleteTrain={deleteTrain} changeTrainName={changeTrainName} requestOwner={requestOwner}/>
                </CardBody>
              </Collapse>
            </Card>
          </Col>
        </Row>
        {activeTrain ? <TrainMaps trainId={activeTrain.trainId} routes={activeRoutes} setRoutes={setActiveRoutes} patch={activeTrain.patchName} world={activeTrain.worldName} mapOrder={activeTrain.mapOrder} sortModal={sortModal} setSortModal={setSortModal} permission={activeTrain.password ? true : false}/>  : null}
      </div>
    </>
  );
};

export default TrainPage;
