import React from "react";

import {
  Badge,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";

import {
  Box,
} from "@mui/material";

import RegionCard from "./RegionCard";
import SpawnMapModal from "components/TimerTable/SpawnMapModal";
import LanguageDependentText from "components/TimerTable/LanguageDependentText";
import { useHuntTrackerContext } from "tracker-context";

import { SocketIO } from "sessions/socket"
import { SessionConfigs } from "sessions/sessionState";
import { huntInfos } from "sessions/resources/hunts";
import { spawnPoints } from "sessions/resources/spawnpoints";

function TrainMaps({trainId, routes, setRoutes, world, patch, permission=false, sortModal, setSortModal, mapOrder}) {
  
  const [regions, setRegions] = React.useState([]);
  const [regionOrder, setRegionOrder] = React.useState([]);
  const [instanceRegions, setInstanceRegions] = React.useState([]);

  React.useEffect(() => {
    SessionConfigs.queryData("/api/getInstanceRegions", {
      worldName: world
    }).then((response) => {
      var instanceRegions = response.data;
      setInstanceRegions(instanceRegions);
    });
  }, []);

  React.useEffect(() => {
    var displayOrder = [];
    var allRegions = {};

    if (instanceRegions) {
      let instancedRegionName = Object.keys(instanceRegions);
      for (var key of Object.keys(huntInfos)) {
        if (huntInfos[key].Rank == 2) {
          if (huntInfos[key].Patch == patch) {
            if (instancedRegionName.includes(huntInfos[key].Region)) {
              for (var i = 0; i < instanceRegions[huntInfos[key].Region]; i++) {
                const regionName = huntInfos[key].Region + ` ${i+1}`;
                if (!Object.keys(allRegions).includes(regionName)) {
                  allRegions[regionName] = {hunts: [], routes: []};
                  displayOrder.push(regionName);
                }
                allRegions[regionName].hunts.push(key + ` ${i+1}`);
                if (Object.keys(routes.routes).includes(key + ` ${i+1}`)) {
                  allRegions[regionName].routes.push(routes.routes[key + ` ${i+1}`]);
                }
              }
            } else {
              const regionName = huntInfos[key].Region;
              if (!Object.keys(allRegions).includes(regionName)) {
                allRegions[regionName] = {hunts: [], routes: []};
                displayOrder.push(regionName);
              }
              allRegions[regionName].hunts.push(key);
              if (Object.keys(routes.routes).includes(key)) {
                allRegions[regionName].routes.push(routes.routes[key]);
              }
            }
          }
        }
      }
  
      for (var i in mapOrder) {
        if (displayOrder.includes(mapOrder[mapOrder.length - 1 - i])) {
          displayOrder = displayOrder.filter(item => item !== mapOrder[mapOrder.length - 1 - i]);
          displayOrder.unshift(mapOrder[mapOrder.length - 1 - i]);
        }
      }
      setRegionOrder(displayOrder);
      setRegions(allRegions);
      setSortModal({...sortModal, order: displayOrder});
    }
  }, [instanceRegions, world, patch, mapOrder, routes]);

  const setTrainMap = (spawnpoint, turnOff) => {
    if (!permission) {
      return;
    }

    var zoneName = spawnpoint.RegionName;
    if (spawnpoint.HuntName.endsWith(" 1") || spawnpoint.HuntName.endsWith(" 2") || spawnpoint.HuntName.endsWith(" 3") || spawnpoint.HuntName.endsWith(" 4") || spawnpoint.HuntName.endsWith(" 5") || spawnpoint.HuntName.endsWith(" 6")) {   
      zoneName = spawnpoint.RegionName + spawnpoint.HuntName.slice(-2);
    }

    if (turnOff) {
      setRoutes((routes) => {
        delete routes.routes[spawnpoint.HuntName];
        return {...routes}
      });
      
      SessionConfigs.queryData("/api/trainUpdate", {
        setSpawnPoint: true,
        id: trainId,
        worldName: spawnpoint.WorldName,
        huntName: spawnpoint.HuntName,
        turnOff: true
      }).then((response) => {
        if (response.status == 200) {
          regions[zoneName].routes = regions[zoneName].routes.filter((route) => {
            if (route.huntName == spawnpoint.HuntName) {
              return false;
            }
            return true;
          });
          setRegions({...regions});
        }
      }).catch((error) => {
        console.log(error);
      });
    } else {
      setRoutes({permission: routes.permission, routes: {
        ...routes.routes,
        [spawnpoint.HuntName]: {
          huntName: spawnpoint.HuntName,
          time: new Date().getTime(),
          region: zoneName,
          x: spawnpoint.SpawnPoint.x*spawnpoint.Scale+1,
          y: spawnpoint.SpawnPoint.y*spawnpoint.Scale+1,
        }
      }});
      
      SessionConfigs.queryData("/api/trainUpdate", {
        setSpawnPoint: true,
        id: trainId,
        worldName: spawnpoint.WorldName,
        huntName: spawnpoint.HuntName,
        pos_x: spawnpoint.SpawnPoint.x*spawnpoint.Scale+1,
        pos_y: spawnpoint.SpawnPoint.y*spawnpoint.Scale+1
      }).then((response) => {
        if (response.status == 200) {
          var foundRoute = false;
          for (var route of regions[zoneName].routes) {
            if (route.huntName == spawnpoint.HuntName) {
              route.time = new Date().getTime();
              route.x = spawnpoint.SpawnPoint.x*spawnpoint.Scale+1;
              route.y = spawnpoint.SpawnPoint.y*spawnpoint.Scale+1;
              foundRoute = true;
            }
          }
  
          if (!foundRoute) {
            regions[zoneName].routes.push({
              huntName: spawnpoint.HuntName,
              time: new Date().getTime(),
              x: spawnpoint.SpawnPoint.x*spawnpoint.Scale+1,
              y: spawnpoint.SpawnPoint.y*spawnpoint.Scale+1,
            })
          }
          
          setRegions({...regions});
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  return (
    <Row>
      {regionOrder.map((regionName) => {
        return <Col key={regionName} md={6} lg={6} xl={4}>
          <Card>
            <CardHeader>
              <Box style={{display: "flex", flexDirection: "column"}}>
                <LanguageDependentText regionName={regionName} fontSize={20} />
              </Box>
              <Box style={{display: "flex", justifyContent: "space-between"}}>
              {regions[regionName] ? regions[regionName].hunts.map((hunt) => {
                return  <Box key={hunt} style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <SpawnMapModal worldName={world} huntName={hunt} setTrainMap={setTrainMap} />
                    <LanguageDependentText huntName={hunt} />
                  </Box>
              }): null}
              </Box>
            </CardHeader>
            <RegionCard regionName={regionName} scale={patch=="HW" ? 43 : 41} regions={regions} worldName={world} setTrainMap={setTrainMap}></RegionCard>
          </Card>
        </Col>
      })}
    </Row>
  );
}

export default TrainMaps;