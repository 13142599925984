import React from "react"

import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography
} from "@mui/material"

import TimerHeader from "components/TimerTable/TimerHeader"
import TimerRow from "components/TimerTable/TimerRow"
import { useHuntTrackerContext } from "tracker-context";
import { SessionConfigs } from "sessions/sessionState";
import LanguageDependentText from "components/TimerTable/LanguageDependentText";
import FATEMainRow from "./FATEMainRow";

function huntNameSort(rows, order) {
  var rowArray = [];
  for (var j = 0; j < rows.length; j++) {
    rowArray[rowArray.length] = [rows[j].dataset.sortName.toLowerCase(), rows[j]];
  }
  rowArray.sort(function(a,b) {
    if (a[0]==b[0]) return 0;
    return a[0] < b[0] ? -order : order;
  });

  return rowArray;
}

function regionNameSort(rows, order) {
  var rowArray = [];
  for (var j = 0; j < rows.length; j++) {
    rowArray[rowArray.length] = [rows[j].dataset.sortRegion.toLowerCase(), rows[j]];
  }
  rowArray.sort(function(a,b) {
    if (a[0]==b[0]) return 0;
    return a[0] < b[0] ? -order : order;
  });
  
  return rowArray;
}

function maxPercentSort(rows, order) {
  var rowArray = [];
  const currentTime = new Date().getTime();
  for (var j = 0; j < rows.length; j++) {
    rowArray[rowArray.length] = [(currentTime - rows[j].dataset.sortMinTime) / (rows[j].dataset.sortMaxTime - rows[j].dataset.sortMinTime), rows[j]];
  }

  rowArray.sort(function(a,b) {
    if (a[0]==b[0]) return 0;
    return a[0] < b[0] ? -order : order;
  });

  return rowArray;
}

function maxTimeSort(rows, order) {
  var rowArray = [];
  for (var j = 0; j < rows.length; j++) {
    rowArray[rowArray.length] = [rows[j].dataset.sortMaxTime, rows[j]];
  }

  rowArray.sort(function(a,b) {
    if (a[0]==b[0]) return 0;
    return a[0] < b[0] ? -order : order;
  });

  return rowArray;
}

function defaultSort(rows) {
  var rowArray = [];
  for (var j = 0; j < rows.length; j++) {
    rowArray[rowArray.length] = [parseInt(rows[j].dataset.sortDefault), rows[j]];
  }

  rowArray.sort(function(a,b) {
    if (a[0]==b[0]) return 0;
    return a[0] < b[0] ? -1 : 1;
  });

  return rowArray;
}

const FATETable = ({name, bossFATEs, chainFATEs, audioRef, children}) => {
  const [controller, dispatch] = useHuntTrackerContext();
  const { datacenters, language } = controller;

  const [restartWebsocket, setRestartWebsocket] = React.useState(1);

  const textLibrary = SessionConfigs.translateText;
  
  const onHandleSorting = (columnName, order) => {
    const table = document.getElementById(name);
    const rows = table.tBodies[0].rows;

    switch (columnName) {
      case "tableHuntName": {
        if (order == 1) {
          const rowArray = huntNameSort(rows, order);
          var tb = table.tBodies[0];
          for (var j = 0; j < rowArray.length; j++) {
            tb.appendChild(rowArray[j][1]);
          }
          
        } else if (order == -1) {
          const rowArray = huntNameSort(rows, order);
          var tb = table.tBodies[0];
          for (var j = 0; j < rowArray.length; j++) {
            tb.appendChild(rowArray[j][1]);
          }

        } else {
          const rowArray = defaultSort(rows);
          var tb = table.tBodies[0];
          for (var j = 0; j < rowArray.length; j++) {
            tb.appendChild(rowArray[j][1]);
          }
        }
      }
      break;
      case "tableRegionName": {
        if (order == 1) {
          const rowArray = regionNameSort(rows, order);
          var tb = table.tBodies[0];
          for (var j = 0; j < rowArray.length; j++) {
            tb.appendChild(rowArray[j][1]);
          }
          
        } else if (order == -1) {
          const rowArray = regionNameSort(rows, order);
          var tb = table.tBodies[0];
          for (var j = 0; j < rowArray.length; j++) {
            tb.appendChild(rowArray[j][1]);
          }

        } else {
          const rowArray = defaultSort(rows);
          var tb = table.tBodies[0];
          for (var j = 0; j < rowArray.length; j++) {
            tb.appendChild(rowArray[j][1]);
          }

        }
      }
      break;
      case "tableTimer": {
        if (order == 1) {
          const rowArray = maxPercentSort(rows, -1);
          var tb = table.tBodies[0];
          for (var j = 0; j < rowArray.length; j++) {
            tb.appendChild(rowArray[j][1]);
          }
          
        } else if (order == -1) {
          const rowArray = maxTimeSort(rows, 1);
          var tb = table.tBodies[0];
          for (var j = 0; j < rowArray.length; j++) {
            tb.appendChild(rowArray[j][1]);
          }

        } else {
          const rowArray = defaultSort(rows);
          var tb = table.tBodies[0];
          for (var j = 0; j < rowArray.length; j++) {
            tb.appendChild(rowArray[j][1]);
          }

        }
      }
    }
  }
  
  React.useEffect(() => {
    if (restartWebsocket == 0) return;

    var relayLogs = [{ code: "", timestamp: new Date().getTime()/1000 }]
    let client = new WebSocket(window.location.protocol.replace("http","ws") + window.location.host + "/socket/relay");
    client.onerror = function() {
      console.log('Connection Error');
    };
    client.onopen = () => {
      console.log("Entered Broadcase Channel");
      client.send(JSON.stringify({
        "ChangeRoom": datacenters,
        "RankType": "fate"
      }));
    };
    client.onclose = () => {
      console.log('Connection Closed');
      setRestartWebsocket((a) => {
        if (a == 0) return new Date().getTime();
        setTimeout(() => {
          setRestartWebsocket((a) => {
            return new Date().getTime();
          });
        }, 5000);
        return a;
      });
    };

    client.onmessage = (event) => {
      let content = JSON.parse(event.data);
      relayLogs.push({
        code: content.Code,
        timestamp: new Date().getTime()/1000
      });
      
      document.dispatchEvent(new CustomEvent(content.World + "_" + content.Fate, {"detail": content}))
    };

    return () => {
      client.close();
    }
  }, [restartWebsocket]);

  var defaultHeaders = [
    {name: "tableHuntName", suffix:"", width: "40%", minWidth: "250px", align: "left", callback: onHandleSorting},
    {name: "tableRegionName", suffix:"", width: "30%", minWidth: "200px", align: "left", callback: onHandleSorting},
    {name: "tableTimer", suffix:"", width: "30%", align: "center", callback: onHandleSorting},
  ];

  return (
    <Table id={name} style={{padding: 0}}>
      <TimerHeader defaultHeaders={defaultHeaders} style={{position: "fixed"}}></TimerHeader>
      <TableBody>
        {bossFATEs.map((fate) => {
          //const subFATEs = fate.Chained.map((chain) => chainFATEs[fate.worldName + "_" + chain])
          return <FATEMainRow key={fate.worldName + "_" + fate.huntKey} bossFATE={fate} subFATEs={[]}/>
        })}
      </TableBody>
      
    </Table>
  );
};

export default FATETable;