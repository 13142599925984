
import axios from "axios";

import { textSelector, huntNameSelector, regionNameSelector, patchNameSelector, guideSelector, permissionDescription, spawnCondition, aetheryteNameSelector } from "assets/selector/language";
import {
  predictNextBonnacon, 
  predictNextBurfurlur, 
  predictNextCroakadile, 
  predictNextCroqueMitaine,
  predictNextGamma, 
  predictNextGandarewa, 
  predictNextGarlok, 
  predictNextKirlirger,
  predictNextLaideronnette, 
  predictNextMindflayer, 
  predictNextOkina, 
  predictNextTheda, 
  predictNextZonaSeeker 
} from "./spawn-predictors";

import SweetAlert from "react-bootstrap-sweetalert";

export var SessionConfigs = (function () {
  var session = {};
  var user = {};
  var resources = {};
  var server = "https://tracker.beartoolkit.com";
  //var server = "https://192.168.0.97";
  
  var csrftoken = "";
  var synced = false;
  
  var historyStacks = [];

  var huntData = {};
  
  const query = (url, form, config, timeout, responseType) => {
    return axios.post(server + url, form, {
      timeout: timeout,
      responseType: responseType,
      headers: {
        ...config,
        "X-CSRFToken": csrftoken
      },
    });
  };

  const setDefaultFilters = (config) => {
    if (!config) config = {};

    const defaultFilters = {grouping: "world", key: "default", order: 1, instance: [],
                            worlds: getCurrentDCInfo(session.datacenters), 
                            patches: ["ARR","HW","SB","ShB","EW","DT"], showWorldButtons: 1, showPatchButtons: 1, showDead: true, hideLongCD: false}
    for (let key in defaultFilters) {
      if (!config.hasOwnProperty(key)) config[key] = defaultFilters[key];
    }
    return config;
  }

  const syncSession = async () => {
    if (localStorage.getItem("sessionContext")) {
      session = JSON.parse(localStorage.getItem("sessionContext"));
    }
    csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value;
    const response = await query("/api/syncSession");
    session = {...session, ...response.data.session, miniSidenav: window.innerWidth < 1200, huntRelays: []};
    resources = response.data.resources;
    if (!session.datacenters) session.datacenters = [];
    if (!session.language) session.language = "en";
    if (!session.notificationFilter) session.notificationFilter = {"worlds": [], "patches": [], "excluding": []}
    if (!session.filterMode) {
      session.filterMode = {};
      session.filterMode.srank = setDefaultFilters({});
      session.filterMode.arank = setDefaultFilters({});
      session.filterMode.fate = setDefaultFilters({});
      session.filterMode.schedule = setDefaultFilters({});
    } else {
      session.filterMode.srank = setDefaultFilters(session.filterMode.srank);
      session.filterMode.arank = setDefaultFilters(session.filterMode.arank);
      session.filterMode.fate = setDefaultFilters(session.filterMode.fate);
      session.filterMode.schedule = setDefaultFilters(session.filterMode.schedule);
    }
    
    localStorage.setItem("sessionContext", JSON.stringify(session));
    user = response.data.user;
    return true;
  };

  const isSynced = () => {
    return synced;
  };

  const getSession = () => {
    return {
      ...session,
      user
    }
  };

  const setSession = (type, value) => {
      session[type] = value;
      localStorage.setItem("sessionContext", JSON.stringify(session));
      query("/api/updateSession", {[type]: value}).catch((error) => console.log(error));
  };

  const setWorldSelect = (rankType, type, world, dcList, force) => {
    if (type == "select") {
      if (session.filterMode[rankType].worlds.includes(world) && session.filterMode[rankType].worlds.length == 1 && !force) {
        session.filterMode[rankType].worlds = getCurrentDCInfo(dcList);
      } else {
        session.filterMode[rankType].worlds = [world]
      }
    } else {
      if (!session.filterMode[rankType].worlds.includes(world)) {
        session.filterMode[rankType].worlds = [...session.filterMode[rankType].worlds, world];
      } else {
        session.filterMode[rankType].worlds = session.filterMode[rankType].worlds.filter(a => a!=world);
      }
    }
    setSession("filterMode", session.filterMode);
  };

  const setPatchSelect = (rankType, type, patch) => {
    if (type == "select") {
      if (session.filterMode[rankType].patches.includes(patch) && session.filterMode[rankType].patches.length == 1) {
        session.filterMode[rankType].patches = getPatches();
      } else {
        session.filterMode[rankType].patches = [patch]
      }
    } else {
      if (!session.filterMode[rankType].patches.includes(patch)) {
        session.filterMode[rankType].patches = [...session.filterMode[rankType].patches, patch];
      } else {
        session.filterMode[rankType].patches = session.filterMode[rankType].patches.filter(a => a!=patch);
      }
    }
    setSession("filterMode", session.filterMode);
  };

  const setInstanceSelect = (rankType, instance) => {
    if (!session.filterMode[rankType].instance.includes(instance)) {
      session.filterMode[rankType].instance = [...session.filterMode[rankType].instance, instance];
    } else {
      session.filterMode[rankType].instance = session.filterMode[rankType].instance.filter(a => a!=instance);
    }
    setSession("filterMode", session.filterMode);
  }
  
  const setHideDeadHunts = (rankType, state) => {
    session.filterMode[rankType].hideDead = state;
  }

  const setSessions = (type, value) => {
    let updateStruct = {};
    for (let i in type) {
      session[type[i]] = value[i];
      updateStruct[type[i]] = value[i];
    }
    
    localStorage.setItem("sessionContext", JSON.stringify(session));
    query("/api/updateSession", updateStruct).catch((error) => console.log(error));
};

  const setCSRFToken = (token) => {
    csrftoken = token;
  }

  const setUser = (value) => {
    user = value;
  };

  const getUser = () => {
    return user;
  };

  const getToggleStates = (rankType) => {
    if (rankType == "srank") return session.filterMode[rankType];
    if (rankType == "arank") return session.filterMode[rankType];
    if (rankType == "schedule") return session.filterMode[rankType];
    if (rankType == "fate") return session.filterMode[rankType];

    return {
      srankConfig: session.filterMode["srank"],
      arankConfig: session.filterMode["arank"],
      scheduleConfig: session.filterMode["schedule"],
      fateConfig: session.filterMode["fate"],
    }
  };

  const getDataCenter = (world) => {
    for (let i in resources.DataCenters) {
      if (resources.DataCenters[i].Names.includes(world)) return {DC: i, RegionName: resources.DataCenters[i].Region};
    }
    return false;
  };

  const getDataCenterResources = () => {
    return resources.DataCenters;
  };

  const getCurrentDCInfo = (dcList) => {
    let allNames = [];
    for (let i in dcList) {
      allNames = [...allNames, ...resources.DataCenters[dcList[i]].Names];
    }
    return allNames;
  };

  const getWorldIndex = (world) => {
    for (let i in resources.DataCenters) {
      if (resources.DataCenters[i].Names.includes(world)) {
        return {region: resources.DataCenters[i].Region, worldIndex: resources.DataCenters[i].Names.indexOf(world)};
      }
    }
    return 0;
  };

  const getARanksFromExpansion = (patch) => {
    const aRanks = [];
    for (let key in resources.DatabaseHunt) {
      if (resources.DatabaseHunt[key]["Patch"] == patch) {
        if (resources.DatabaseHunt[key]["Rank"] == 2) aRanks.push({...resources.DatabaseHunt[key], ...resources.SpawnPoint[key], Name: key});
      }
    }
    return aRanks;
  };

  const getFateResources = (huntKey) => {
    let huntSeries = {};
    let counter = 0;
    for (let i in resources.DatabaseFate.BossFATEs) {
      counter += 1;
      if (resources.DatabaseFate.BossFATEs[i].DatabaseName == huntKey) {
        if (Object.keys(huntSeries) == 0) {
          huntSeries = {...resources.DatabaseFate.BossFATEs[i]};
          huntSeries.Coordinates = [];
          huntSeries.ID = counter;
          huntSeries.Region = [];
        }

        if (!huntSeries.Region.includes(resources.DatabaseFate.BossFATEs[i].Region)) {
          huntSeries.Coordinates.push([resources.DatabaseFate.BossFATEs[i].Coordinates]);
          huntSeries.Region.push(resources.DatabaseFate.BossFATEs[i].Region);
        } else {
          huntSeries.Coordinates[huntSeries.Region.indexOf(resources.DatabaseFate.BossFATEs[i].Region)].push(resources.DatabaseFate.BossFATEs[i].Coordinates);
        }
      }
    }
    return huntSeries;
  };

  const getHuntResources = (huntKey) => {
    if (!huntKey) return resources.DatabaseHunt;
    if (resources.DatabaseHunt[huntKey]) return resources.DatabaseHunt[huntKey];
    return resources.DatabaseHunt[huntKey.slice(0,-2)];
  };

  const setHuntCache = (type, data) => {
    huntData[type] = data;
  };

  const getHuntCache = (type) => {
    return huntData[type] ? huntData[type] : [];
  };

  const getInstances = (region) => {
    for (let dc in resources.DataCenters) {
      if (resources.DataCenters[dc].Region == region) {
        return resources.Instances[dc];
      }
    }
    return resources.Instances[region];
  };

  const getPatches = () => {
    return ["ARR", "HW", "SB", "ShB", "EW", "DT"];
  }

  const getRegions = (patch) => {
    if (patch) return resources.Regions["Patches"][patch];
    return resources.Regions;
  }

  const getAetherytes = (region) => {
    return resources.Regions["Aetherytes"][region];
  }

  const getSpawnPoints = (huntInfo) => {
    if (!huntInfo.huntKey) return resources.SpawnPoint[huntInfo];
    return resources.SpawnPoint[huntInfo.huntKey];
  }

  const getLanguage = () => {
    return session.language;
  };

  const localeMessage = (language) => {
    switch (language) {
      case "en": 
        return "en-US"
      case "cn":
        return "zh-CN"
      case "jp":
        return "ja-JP"
    }
  }

  const translateText = (type, key) => {
    switch (type) {
      case "patch":
        if (patchNameSelector[key]) {
          if (patchNameSelector[key][session.language]) return patchNameSelector[key][session.language];
          return patchNameSelector[key]["en"];
        }
        return key;
      case "hunt":
        if (huntNameSelector[key]) return huntNameSelector[key][session.language];
        if (huntNameSelector[key.slice(0,-2)]) return huntNameSelector[key.slice(0,-2)][session.language] + key.slice(-2);
        return key;
      case "region":
        if (regionNameSelector[key]) return regionNameSelector[key][session.language];
        return key;
      case "spawn-condition":
        if (spawnCondition[key]) {
          if (spawnCondition[key][session.language]) return spawnCondition[key][session.language];
          return spawnCondition[key]["en"];
        }
        return key;
      case "header":
        if (textSelector[key]) {
          if (textSelector[key][session.language]) return textSelector[key][session.language];
          return textSelector[key]["en"];
        }
        return key;
      case "aetheryte":
        if (aetheryteNameSelector[key]) {
          if (aetheryteNameSelector[key][session.language]) return aetheryteNameSelector[key][session.language];
          return aetheryteNameSelector[key]["en"];
        }
        return key;
      case "guide":
        if (guideSelector[key]) {
          if (guideSelector[key][session.language]) return guideSelector[key][session.language];
          return guideSelector[key]["en"];
        }
        return key;
      case "permission":
        if (permissionDescription[key]) {
          if (permissionDescription[key][session.language]) return permissionDescription[key][session.language];
          return permissionDescription[key]["en"];
        }
        return key;
      case "time":
        return new Date(key*1000).toLocaleString(localeMessage(session.language), 
                  { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" })
    }
    return key;
  }
  
  const predictSpawnCondition = (name, minTime) => {
    switch (name) {
      case "Laideronnette": {
        return predictNextLaideronnette(minTime);
      }
      case "Mindflayer": {
        return predictNextMindflayer(minTime);
      }
      case "Thousand-cast Theda": {
        return predictNextTheda(minTime);
      }
      case "Zona Seeker": {
        return predictNextZonaSeeker(minTime);
      }
      case "Croque-Mitaine": {
        return predictNextCroqueMitaine(minTime);
      }
      case "Croakadile": {
        return predictNextCroakadile(minTime);
      }
      case "The Garlok": {
        return predictNextGarlok(minTime);
      }
      case "Bonnacon": {
        return predictNextBonnacon(minTime);
      }
      case "Gandarewa": {
        return predictNextGandarewa(minTime);
      }
      case "Okina": {
        return predictNextOkina(minTime);
      }
      case "Gamma": {
        return predictNextGamma(minTime);
      }
      case "Burfurlur the Canny": {
        return predictNextBurfurlur(minTime);
      }
      case "Kirlirger the Abhorrent": {
        return predictNextKirlirger(minTime);
      }
      default: {
        return null;
      }
    }
  };

  const browserBackStackUpdate = (location, action) => {
    switch (action) {
      case 'POP':
        historyStacks = historyStacks.slice(0, historyStacks.length - 1);
      case 'PUSH':
        historyStacks = [...historyStacks, location];
      case 'REPLACE':
        historyStacks = [...historyStacks.slice(0, historyStacks.length - 1), location];
    }
  }

  const useBrowserBackStack = () => {
    return historyStacks;
  }

  const handleErrorDisplay = (error, setAlert) => {
    var errorTitle = "Error";
    var errorMessage = "Unknown Error";
    console.log(error)

    if (error.response) {
      if (error.response.status === 400) {
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.response.data.toString();
        }
      } else if (error.response.status === 500) {
        errorTitle = "Backend Server Error";
        errorMessage = "Server Processing Error - Check Server Error Logs";
      }
    } else {
      errorMessage = error.toString();
    }
    setAlert(
        <SweetAlert
            error style={{display: "block", marginTop: "-100px"}}
            title={errorTitle}  onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)} confirmBtnBsStyle="info" >
            {errorMessage}
        </SweetAlert>
    )
  }
  
  return {
    query,
    isSynced,
    syncSession,
    getSession,
    setSession,
    setSessions,
    setCSRFToken,
    getUser, 
    setUser,
    getDataCenter,
    getToggleStates,
    setWorldSelect,
    setPatchSelect,
    setInstanceSelect,
    setHideDeadHunts,
    getDataCenterResources,
    getCurrentDCInfo,
    getWorldIndex,
    getHuntResources,
    getFateResources,
    getPatches,
    getInstances,
    getRegions,
    getAetherytes,
    getARanksFromExpansion,
    getSpawnPoints,
    getLanguage,
    localeMessage,
    translateText,
    predictSpawnCondition,

    browserBackStackUpdate,
    useBrowserBackStack,

    getHuntCache,
    setHuntCache,

    handleErrorDisplay,
  }

})();
